import React, { useState, useEffect } from 'react';
import { store } from 'react-notifications-component';
import ProgressRing from './ProgressRing';
import { IoIosClose, IoIosArrowForward } from 'react-icons/io';

import { PRIMARY_COLOR, PALE_WHITE_4, SECONDARY_COLOR, NOTE_BACKGROUND } from '../../appearance/Colors';

function GoToFolderNotification({ toFolder, actionClick, title, actionTitle, notificationId, icon }) {
    const [progress, setProgress] = useState(100);
    const [timeLeft, setTimeLeft] = useState(10);

    useEffect(() => {
        var downloadTimer = setInterval(function () {
            if (timeLeft <= 0) {
                clearInterval(downloadTimer);
            }
            // document.getElementById('progressBar').value = 10 - timeleft;
            setTimeLeft(timeLeft - 1);

            setProgress(progress - 10);
        }, 1000);
        return () => {
            // cleanup
        };
    }, [timeLeft]);

    // if (progress > 0) {
    //     setTimeout(() => {
    //         setProgress(progress - 20);
    //     }, 10);
    // } else {
    //     setProgress(100);
    // }

    return (
        <div
            style={{
                border: `1px solid ${PALE_WHITE_4}`,
                backgroundColor: SECONDARY_COLOR,
                shadowColor: PRIMARY_COLOR,
                borderRadius: 4,
                padding: 8,
                width: '100%',
                position: 'relative'
            }}
            onClick={() => {
                // close(key);
                console.log('go to this folder', toFolder);
                store.removeNotification(notificationId);
                if (actionClick) {
                    actionClick();
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'white',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        marginRight: 12,
                        justifyContent: 'center',
                        display: 'flex',
                        padding: 4
                    }}
                >
                    {icon}
                </div>
                {/* <ProgressRing radius={60} stroke={4} progress={progress} /> */}
                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <div>
                        <div style={{ fontSize: 14, lineHeight: '18px' }}>{title}</div>
                        <span style={{ fontSize: 12 }}>{actionTitle}</span>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    {/* <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <IoIosArrowForward size={20}></IoIosArrowForward>
                    </div> */}
                </div>
            </div>
            <div
                style={{
                    width: 20,
                    height: 20,
                    backgroundColor: NOTE_BACKGROUND,
                    borderRadius: '50%',
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex'
                }}
                onClick={e => {
                    e.stopPropagation();
                    store.removeNotification(notificationId);
                }}
            >
                <IoIosClose color={PRIMARY_COLOR} size={18} />
            </div>
        </div>
    );
}

export default GoToFolderNotification;
