import React from 'react';
import { serializeArray, mapArray } from '../util/serializeTreeArray';

import { AuthContext } from '../util/context';

import _ from 'lodash';

function useAddFoldersToTree() {
    const {
        state: { treeData },
        context: { setNewTree }
    } = React.useContext(AuthContext);

    const onlyNewOnes = folders => {
        let cloneTree = { ...treeData };
        let parent = folders[0].parent;

        let currentFolder = cloneTree.items[parent ? parent : 'root'];
        if (currentFolder) {
            let cleanFolders = folders.map(folder => folder.id);

            // console.log('current list', currentFolder.children);
            // console.log('incoming folders', cleanFolders);

            let differencing = _.difference(
                cleanFolders,
                currentFolder.children
            );
            // console.log('this is differencing', differencing);

            if (differencing.length > 0) {
                let toUpdate = [];
                differencing.forEach(folderId => {
                    let folder = folders.find(folder => folder.id === folderId);
                    toUpdate.push(folder);
                });
                // console.log('goin to update these', toUpdate);
                return addChildrenToFolder(toUpdate);
            }
        }
    };

    const addChildrenToFolder = async folders => {
        // folders.forEach((folder) => {
        let cloneTree = { ...treeData };
        // console.log('cloned tre', cloneTree);
        let parent = folders[0].parent;
        // console.log('this is parent', parent);
        let currentFolder = cloneTree.items[parent ? parent : 'root'];
        // console.log('this is current folder', currentFolder);
        // let currentFolder = cloneTree.items[folder.parent ? folder.parent : 'root'];
        if (currentFolder) {
            let serializeChild = await serializeArray(folders);
            let mappedChildren = await mapArray(folders);

            // setting new children to the selected folder
            // fix when there is already stuff moved inside, don't overwrite it!!!
            // let currentFolder = cloneTree.items[selectedFolderId];
            // ovde mozemo da proverimo ako postoje novi folderi u listi
            currentFolder.children = [
                ...currentFolder.children,
                ...mappedChildren
            ];
            // currentFolder.isExpanded = true;
            currentFolder.hasChildren = true;

            // setting up the new items
            let newItems = { ...cloneTree.items, ...serializeChild };

            cloneTree.items = newItems;

            setNewTree(cloneTree);
            return true;
            // return cloneTree;
        }
        // });
    };

    const runThis = foldersArray => {
        // console.log('you shold get there', foldersArray);
        if (foldersArray.length > 0) {
            return onlyNewOnes(foldersArray);
        }
    };

    return [runThis];
}

export default useAddFoldersToTree;
