import { gql } from 'apollo-boost';

// input ResetPasswordMailInput {
// 	token: String!
// 	newPassword: String!
// }

export const RESET_PASSWORD_MAIL = gql`
    mutation($data: ResetPasswordMailInput!) {
        resetPasswordMail(data: $data)
    }
`;
