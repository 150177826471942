import { gql } from 'apollo-boost';

// input GenerateReferralLinkInput {
// 	referralId: String
// }

export const GENERATE_REFERRAL_LINK = gql`
    mutation($data: GenerateReferralLinkInput) {
        generateReferralLink(data: $data)
    }
`;
