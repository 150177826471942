import { gql } from 'apollo-boost';

export const REODER_FOLDERS = gql`
    mutation($data: ReorderFoldersInput!) {
        reorderFolders(data: $data) {
            id
            title
            parent
            owner
            before
            deletedAt
        }
    }
`;