import { gql } from 'apollo-boost';

// input UpdateStorageInput {
//     fileName: String!
// }

export const UPDATE_STORAGE = gql`
    mutation($data: UpdateStorageInput!) {
        updateStorage(data: $data)
    }
`;
