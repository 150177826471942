import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Duration from '../../../Duration';
import { IoIosPlay, IoIosPause, IoIosVolumeHigh, IoIosVolumeMute, IoIosVolumeOff, IoIosRepeat } from 'react-icons/io';
import styled from 'styled-components';

import './AudioPlayer.scss';

import { ACTION_TAG, FILE_BLUE, GREEN, PRIMARY_COLOR, RED, WHITE } from '../../../../appearance/Colors';
import { prettySize } from '../../../../util/fileSizeCalculator';

import FileSaver from 'file-saver';

import ActionTagButton from '../../../Action/ActionTagButton';

function AudioPlayer({ url, title, size, style }) {
    const playerRef = useRef(null);

    const [playing, setPlaying] = useState(false);
    const [controls, setControls] = useState(false);
    const [light, setLight] = useState(false);
    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false);
    const [played, setPlayed] = useState(0);
    const [loaded, setLoaded] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const [loop, setLoop] = useState(false);
    const [pip, setPip] = useState(false);
    const [seeking, setSeeking] = useState(false);

    useEffect(() => {
        return () => {
            handleStop();
        };
    }, [url]);

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const resetPlayer = () => {
        playerRef.current.seekTo(0);
    };

    const handleStop = () => {
        resetPlayer();
        setPlaying(false);
    };

    //   const handleToggleControls = () => {
    //     const url = this.state.url
    //     this.setState({
    //       controls: !this.state.controls,
    //       url: null
    //     }, () => this.load(url))
    //   }

    const handleToggleLight = () => {
        setLight(!light);
    };

    const handleToggleLoop = () => {
        setLoop(!loop);
    };

    const handleVolumeChange = e => {
        setVolume(parseFloat(e.target.value));
    };

    const handleToggleMuted = () => {
        setMuted(!muted);
    };

    const handleSetPlaybackRate = speed => {
        // setPlaybackRate(parseFloat(e.target.value));
        setPlaybackRate(speed);
    };

    const handlePlay = () => {
        console.log('onPlay');
        setPlaying(true);
    };

    const handlePause = () => {
        console.log('onPause');
        setPlaying(false);
    };

    const handleSeekMouseDown = e => {
        setSeeking(true);
    };

    const handleSeekChange = e => {
        setPlayed(parseFloat(e.target.value));
    };

    const handleSeekMouseUp = e => {
        setSeeking(false);
        playerRef.current.seekTo(parseFloat(e.target.value));
    };

    const handleProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            setLoaded(state.loaded);
            setPlayed(state.played);
        }
    };

    const handleEnded = () => {
        console.log('onEnded');
        setPlaying(loop);
    };

    const handleDuration = duration => {
        console.log('onDuration', duration);
        setDuration(duration);
    };

    //   const handleClickFullscreen = () => {
    //     screenfull.request(findDOMNode(this.player))
    //   }

    // TODO Error on Yandex and strange behaviour on Firefox
    // desired solution convert the file on the server to ogg/mp3
    // https://stackoverflow.com/questions/37674223/domexception-failed-to-load-because-no-supported-source-was-found
    // var playPromise = document.querySelector('video').play();

    // // In browsers that don’t yet support this functionality,
    // // playPromise won’t be defined.
    // if (playPromise !== undefined) {
    //     playPromise
    //         .then(function () {
    //             // Automatic playback started!
    //         })
    //         .catch(function (error) {
    //             // Automatic playback failed.
    //             // Show a UI element to let the user manually start playback.
    //         });
    // }

    return (
        <>
            <div
                style={{
                    position: 'relative',
                    backgroundColor: 'white',
                    borderRadius: 4,
                    padding: 8,
                    ...style
                }}
            >
                <div>
                    {/* <audio controls>
                        <source src={url} type="audio/aac" />
                    </audio> */}
                    <ReactPlayer
                        ref={playerRef}
                        width="0"
                        height="0"
                        url={url}
                        playing={playing}
                        controls={controls}
                        light={light}
                        loop={loop}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        onReady={() => console.log('onReady')}
                        onStart={() => console.log('onStart')}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onBuffer={() => console.log('onBuffer')}
                        onSeek={e => console.log('onSeek', e)}
                        onEnded={handleEnded}
                        onError={e => console.log('onError', e)}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                    />
                </div>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div
                            style={{
                                borderRadius: 40,
                                height: 40,
                                width: 40,
                                backgroundColor: FILE_BLUE,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                            onClick={handlePlayPause}
                        >
                            {!playing ? (
                                <IoIosPlay style={{ height: 20, width: 20, color: WHITE }} />
                            ) : (
                                <IoIosPause style={{ height: 20, width: 20, color: WHITE }} />
                            )}
                        </div>
                        <div style={{ flexDirection: 'column', marginLeft: 12 }}>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: 4, cursor: 'pointer' }} onClick={handleToggleMuted}>
                                    {!muted ? <IoIosVolumeHigh size={24} /> : <IoIosVolumeOff size={24} color={RED} />}
                                </div>
                                <div style={{ marginRight: 4, cursor: 'pointer' }} onClick={handleToggleLoop}>
                                    {!loop ? <IoIosRepeat size={24} /> : <IoIosRepeat size={24} color={GREEN} />}
                                </div>
                                <ActionTagButton
                                    label="1x"
                                    style={{
                                        marginRight: 4,
                                        minWidth: 36,
                                        backgroundColor: playbackRate === 1 ? GREEN : ACTION_TAG
                                    }}
                                    onClick={() => handleSetPlaybackRate(1)}
                                ></ActionTagButton>
                                <ActionTagButton
                                    label="1.5x"
                                    style={{
                                        marginRight: 4,
                                        minWidth: 36,
                                        backgroundColor: playbackRate === 1.5 ? GREEN : ACTION_TAG
                                    }}
                                    onClick={() => handleSetPlaybackRate(1.5)}
                                ></ActionTagButton>
                                <ActionTagButton
                                    label="2x"
                                    style={{
                                        marginRight: 4,
                                        minWidth: 36,
                                        backgroundColor: playbackRate === 2 ? GREEN : ACTION_TAG
                                    }}
                                    onClick={() => handleSetPlaybackRate(2)}
                                ></ActionTagButton>
                                {/* <button onClick={() => handleSetPlaybackRate(1)}>1x</button>
                                <button onClick={() => handleSetPlaybackRate(1.5)}>1.5x</button>
                                <button onClick={() => handleSetPlaybackRate(2)}>2x</button> */}
                            </div>
                            {/* <div
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginTop: 4
                                }}
                            >
                                <input
                                    type="range"
                                    min={0}
                                    max={1}
                                    step="any"
                                    value={volume}
                                    onChange={handleVolumeChange}
                                    style={{

                                    }}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div style={{ marginTop: 0 }}>
                        {/* <div>{title}</div> */}
                        <input
                            style={{ marginTop: 8 }}
                            type="range"
                            min={0}
                            max={0.999999}
                            step="any"
                            value={played}
                            onMouseDown={handleSeekMouseDown}
                            onChange={handleSeekChange}
                            onMouseUp={handleSeekMouseUp}
                        />
                        <div style={{ fontSize: 12, marginTop: 4 }}>
                            <Duration seconds={duration * played} /> / <Duration seconds={duration} />
                            {/* Remaining */}
                            {/* <Duration seconds={duration * (1 - played)} /> */}
                        </div>
                    </div>
                </div>
                {/* <LinkButton href={url} target="_blank" download rel="noopener noreferrer">
                Download
            </LinkButton> */}
                {/* <table>
				<tbody>
				

					<tr>
						<th>Loaded</th>
						<td>
							<progress max={1} value={loaded} />
						</td>
					</tr>

					<tr>
						<th>played</th>
						<td>{played.toFixed(3)}</td>
					</tr>
					<tr>
						<th>loaded</th>
						<td>{loaded.toFixed(3)}</td>
					</tr>
					<tr>
						<th>remaining</th>
						<td>
							<Duration seconds={duration * (1 - played)} />
						</td>
					</tr>
				</tbody>
			</table> */}
            </div>
            {/* <Button
                onClick={async () => {
                    // downloadFile(cloudinaryCore.url(message.image.url));
                    FileSaver.saveAs(url, title);
                    // const link = document.createElement('a');
                    // link.href = cloudinaryCore.url(message.image);
                    // link.click();
                }}
            >
                Download {prettySize(size)}
            </Button> */}
        </>
    );
}

const Seeker = styled.input`
    background: linear-gradient(to right, #82cfd0 0%, #82cfd0 50%, #fff 50%, #fff 100%);
`;

const LinkButton = styled.a`
    color: ${PRIMARY_COLOR};
    text-decoration: none;
    display: flex;
    flex: 1;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border-width: 1px;
    border-color: ${PRIMARY_COLOR};
    border-style: solid;
    margin-top: 12px;
`;

const Button = styled.div`
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    padding: 10px 0;
    margin-left: 10px;
    margin-right: 10px;
    // color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }
`;

export default AudioPlayer;
