import React from 'react';
import { IoIosCloseCircleOutline, IoIosDocument } from 'react-icons/io';
import styled from 'styled-components';
import { PRIMARY_COLOR, WHITE } from '../appearance/Colors';
import ImagePreviewPlugin from '../components/MessagePreview/Plugins/ImagePreviewPlugin';

function MessageInputEditPreview({ lastMessage, show, onClose, resetForm }) {
    const previewSection = () => {
        if (!lastMessage.file && !lastMessage.image && lastMessage.urlMeta.length === 0) {
            return null;
        } else if (!lastMessage.file && !lastMessage.image && lastMessage.urlMeta.length > 0) {
            return null;
        } else if (
            (lastMessage.file && lastMessage.file.type.includes('image')) ||
            (lastMessage.image && lastMessage.image.type.includes('image'))
        ) {
            // const object = {
            //     url: (lastMessage.image && lastMessage.image.url) || (lastMessage.file && lastMessage.file.url),
            //     size: (lastMessage.image && lastMessage.image.size) || (lastMessage.file && lastMessage.file.size),
            //     name: (lastMessage.image && lastMessage.image.name) || (lastMessage.file && lastMessage.file.name)
            // };
            return (
                <ImagePreviewPlugin
                    message={lastMessage}
                    // width={40}
                    height={100}
                    style={{ borderRadius: 4 }}
                    hideActions
                ></ImagePreviewPlugin>
            );
        } else {
            return (
                <div
                    style={{
                        backgroundColor: 'cornflowerblue',
                        height: 40,
                        width: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 8,
                        display: 'flex',
                        borderRadius: 50
                    }}
                >
                    <IoIosDocument size={24} color={WHITE}></IoIosDocument>
                </div>
            );
        }
    };

    const textSection = () => {
        if (!lastMessage.file && !lastMessage.image && lastMessage.urlMeta.length === 0) {
            return (
                <div>
                    <div
                        style={{
                            color: PRIMARY_COLOR,
                            fontWeight: 600,
                            marginBottom: 4
                        }}
                    >
                        Editing the message
                    </div>
                    <TruncateWrapper>
                        <span>
                            {lastMessage.title
                                ? lastMessage.title
                                : (lastMessage.image && lastMessage.image.name) ||
                                  (lastMessage.file && lastMessage.file.name)}
                        </span>
                    </TruncateWrapper>
                </div>
            );
        } else if (!lastMessage.file && !lastMessage.image && lastMessage.urlMeta.length > 0) {
            let urlDescription;
            if (lastMessage.urlMeta[0]) {
                if (lastMessage.urlMeta[0].description) {
                    urlDescription = lastMessage.urlMeta[0].description;
                } else if (lastMessage.urlMeta[0].title) {
                    urlDescription = lastMessage.urlMeta[0].title;
                } else if (lastMessage.urlMeta[0].url) {
                    urlDescription = lastMessage.urlMeta[0].url;
                }
            } else {
                urlDescription = lastMessage.title;
            }
            return (
                <div>
                    <div
                        style={{
                            color: PRIMARY_COLOR,
                            fontWeight: 600,
                            marginBottom: 4
                        }}
                    >
                        {lastMessage.urlMeta[0] && lastMessage.urlMeta[0].publisher
                            ? lastMessage.urlMeta[0].publisher
                            : 'Editing the message'}
                    </div>

                    <TruncateWrapper>
                        <span style={{ lineHeight: '18px' }}>{urlDescription}</span>
                    </TruncateWrapper>
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{
                            color: PRIMARY_COLOR,
                            fontWeight: 600,
                            marginBottom: 4
                        }}
                    >
                        Editing the message
                    </div>
                    <TruncateWrapper>
                        <span>
                            {lastMessage.title
                                ? lastMessage.title
                                : (lastMessage.image && lastMessage.image.name) ||
                                  (lastMessage.file && lastMessage.file.name)}
                        </span>
                    </TruncateWrapper>
                </div>
            );
        }
    };

    if (show) {
        return (
            <div
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    display: 'flex',
                    borderBottomWidth: 1,
                    borderColor: PRIMARY_COLOR,
                    borderStyle: 'solid'
                }}
            >
                <div
                    style={{ marginRight: 8, marginLeft: 8, cursor: 'pointer' }}
                    onClick={() => {
                        onClose();
                        resetForm();
                    }}
                >
                    <IoIosCloseCircleOutline size={24}></IoIosCloseCircleOutline>
                </div>

                <div
                    style={{
                        marginTop: 8,
                        marginBottom: 8,
                        // display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        borderLeft: 'black',
                        borderLeftWidth: 3,
                        borderStyle: 'solid',
                        paddingLeft: 8,
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ marginRight: 8 }}>{previewSection()}</div>
                    {textSection()}
                </div>
            </div>
        );
    }
    return null;
}

const TruncateWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export default MessageInputEditPreview;
