import 'react-hot-loader';
import './rhlConfig';
import React from 'react';
import ReactDOM from 'react-dom';
// import './screens/Landing/assets/scss/style.scss';
import './index.scss';
import './appearance/Fonts/inter.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import * as jwt from 'jsonwebtoken';
import USER_ROLE from './util/userRole';
import environment from './env-config';
import mixpanel from 'mixpanel-browser';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

mixpanel.init(environment.REACT_MIXPANEL_TOKEN, {
    debug: environment.REACT_NODE_ENV !== 'production',
    ignore_dnt: true
});

const startAnalytics = async () => {
    // hide logs from production
    console.log = function () {};
    ReactGA.initialize(environment.REACT_GOOGLE_ANALYTICS, {
        debug: environment.REACT_NODE_ENV !== 'production'
    });
    console.log('pageview');
    ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash);

    // Facebook pixel
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: environment.REACT_NODE_ENV !== 'production' // enable logs
    };
    // ReactPixel.init('4825595947460021', advancedMatching, options); // business acc
    ReactPixel.init(environment.REACT_FACEBOOK_PIXEL, advancedMatching, options); // personal acc
    ReactPixel.pageView(); // For tracking page view
    // ReactPixel.revokeConsent(); // For GDPR
};

if (environment.REACT_NODE_ENV === 'production') {
    const token = localStorage.getItem('token');
    const payload = jwt.decode(token);

    if (!token) {
        startAnalytics();
    } else if (payload && payload.role !== USER_ROLE.ADMIN) {
        startAnalytics();
    } else {
        console.log('dont start analytics');
        // mixpanel must be init, but opting out admin
        mixpanel.opt_out_tracking();
    }
} else {
    // DEBUG AREA only OPT OUT
    // mixpanel needs to be initialised but not used
    // mixpanel.init(environment.REACT_MIXPANEL_TOKEN, {
    //     debug: environment.REACT_NODE_ENV !== 'production',
    //     ignore_dnt: true,
    // });
    // mixpanel.disable();
    mixpanel.opt_out_tracking();
}

// ReactGA.exception({
//     description: 'An error ocurred',
//     fatal: true
//   });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
