import React, { useState } from 'react';

import environment from '../../../env-config';

import { Image, Transformation } from 'cloudinary-react';

import getImagePreviews from '../../../util/getImagePreviews';

import styled from 'styled-components';

import { GREEN, PALE_WHITE, WHITE, DETAIL_TEXT } from '../../../appearance/Colors';

import ActionTag from '../../Action/ActionTagButton';
import DangerTag from '../../Action/DangerTagButton';

import useUpdateUserMutation from '../../../hooks/useUpdateUserMutation';
import useUpdateMessageMutation from '../../../hooks/useUpdateMessageMutation';

import DeleteModal from '../../Modals/DeleteModal';

function ProfilePicture({ url, name }) {
    const wrapperRef = React.useRef();
    const { updateProfilePicture, deleteProfilePicture } = useUpdateUserMutation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [imagesPreview, setImagesPreview] = useState(undefined);
    const inputFile = React.useRef(null);

    const getPreviews = async files => {
        let res = await getImagePreviews(files);
        if (res) {
            setImagesPreview(res);
        }
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const onChangeFile = async event => {
        event.stopPropagation();
        event.preventDefault();
        const fileListAsArray = Array.from(event.target.files);
        getPreviews(fileListAsArray);
        // this.setState({file}); /// if you want to upload latter
    };

    const clearFileToSend = () => {
        setImagesPreview(undefined);
        // inputFile.current.value = '';
    };

    const publishFiles = async index => {
        let filesToSend = imagesPreview.map(previewItem => previewItem.file);
        let imageFiles = [];
        // let ordinaryFiles = [];
        filesToSend.forEach(file => {
            if (file.type.includes('image')) {
                imageFiles.push(file);
            }
            // else {
            //     ordinaryFiles.push(file);
            // }
        });
        // somewhere here the successful upload
        clearFileToSend();
        // if (ordinaryFiles.length) {
        //     await updateMessageFiles(ordinaryFiles, message);
        // }
        if (imageFiles.length) {
            const onlyFirst = imageFiles[index];
            await updateProfilePicture(onlyFirst);
        }
    };

    const deleteImage = async () => {
        console.log('not working right now');
        // await deleteProfilePicture();
    };

    const renderPublishAction = (index, size) => {
        return (
            <ActionTag
                label={url ? 'Replace' : 'Publish'}
                style={{ backgroundColor: GREEN, marginRight: 8 }}
                onClick={() => publishFiles(index)}
            />
        );
    };

    const renderPreview = () => {
        if (imagesPreview) {
            return imagesPreview.map(({ reader, file }, index) => {
                const { name, size, type } = file;
                console.log('show it sisy', file);
                return (
                    <ImageWrapper key={index}>
                        <div style={{ color: WHITE, marginBottom: 8, fontSize: 18, fontWeight: '600' }}>Photo</div>
                        <div ref={wrapperRef}>
                            <img
                                onClick={() => console.log('this is the image')}
                                src={reader.result}
                                style={{
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '100%',
                                    height: 90,
                                    width: 90
                                }}
                            ></img>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
                            {renderPublishAction(index, size)}
                            <ActionTag
                                label="Cancel"
                                onClick={() => clearFileToSend()}
                                style={{ backgroundColor: DETAIL_TEXT }}
                            ></ActionTag>
                        </div>
                    </ImageWrapper>
                );
            });
        }
    };

    const renderPicture = url => {
        if (imagesPreview) {
            return renderPreview();
        } else if (url) {
            return (
                <>
                    <ImageWrapper>
                        <div style={{ color: WHITE, marginBottom: 8, fontSize: 18, fontWeight: '600' }}>Photo</div>
                        <div ref={wrapperRef} style={{ alignItems: 'center', display: 'flex' }}>
                            <Image
                                onClick={e => {
                                    // open the carousel
                                    e.stopPropagation();
                                    // onClick();
                                    console.log('this is the image');
                                }}
                                cloudName={environment.REACT_APP_CLOUD_NAME}
                                publicId={url}
                                style={{
                                    objectFit: 'cover',
                                    borderRadius: '100%',
                                    height: 90,
                                    width: 90,
                                    marginRight: 16
                                    // border: BORDER,
                                    // borderStyle: 'solid',
                                    // borderColor: 'rgba(255,255,255,.6)'
                                }}
                            >
                                <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                            </Image>
                            <input
                                type="file"
                                accept="image/*"
                                id="file"
                                ref={inputFile}
                                style={{ display: 'none' }}
                                onChange={onChangeFile}
                            />
                            <ActionTag
                                label="Upload photo"
                                style={{ backgroundColor: GREEN, marginRight: 8 }}
                                onClick={() => onButtonClick()}
                            ></ActionTag>
                            <ActionTag label="Remove" style={{}} onClick={() => deleteProfilePicture()}></ActionTag>
                        </div>
                        {/* <DangerTag label="Remove" onClick={() => setIsDeleteModalOpen(true)}></DangerTag> */}
                        {/* <DeleteModal
                            title="Delete image?"
                            description="You will not be able to get this file once deleted."
                            isOpen={isDeleteModalOpen}
                            closeModal={response => {
                                if (response) {
                                    console.log('what response', response);
                                    deleteImage();
                                }
                                setIsDeleteModalOpen(false);
                            }}
                        ></DeleteModal> */}
                    </ImageWrapper>
                </>
            );
        } else {
            return (
                <ImageWrapper>
                    <div style={{ color: WHITE, marginBottom: 8, fontSize: 18, fontWeight: '600' }}>Photo</div>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <div
                            style={{
                                height: 60,
                                width: 60,
                                borderRadius: '100%',
                                border: `1px solid ${DETAIL_TEXT}`,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                fontSize: 24,
                                marginRight: 16
                            }}
                        >
                            <span style={{ textTransform: 'uppercase', color: WHITE }}>
                                {name.length > 0 ? name.slice(0, 1) : 'X'}
                            </span>
                        </div>
                        <AddFileWrapper>
                            <div onClick={() => onButtonClick()} style={{ color: 'white' }}>
                                Add image
                            </div>

                            <input
                                type="file"
                                accept="image/*"
                                id="file"
                                ref={inputFile}
                                style={{ display: 'none' }}
                                onChange={onChangeFile}
                            />
                        </AddFileWrapper>
                    </div>
                </ImageWrapper>
            );
        }
    };

    return <div id="picture">{renderPicture(url)}</div>;
}

const ImageWrapper = styled.div`
    display: inline-block;
    position: relative;
    // cursor: zoom-in;
`;

const AddFileWrapper = styled.div`
    display: inline-block;
    border-radius: 4px;
    margin-top: 8px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    padding: 8px 12px;
    &:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }
`;

export default ProfilePicture;
