import { gql } from 'apollo-boost';

// input DeleteAccountInput {
// 	email: String!
// }

export const CHANGE_USER_PLAN = gql`
    mutation($data: ChangeUserPlanInput!) {
        changeUserPlan(data: $data)
    }
`;
