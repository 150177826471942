import React, { useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import ItemTypes from './ItemTypes';
import styled, { css } from 'styled-components';

import { IoIosArrowDown, IoIosArrowForward, IoIosAdd, IoMdHome } from 'react-icons/io';

import useMoveMessagesMutation from '../hooks/useMoveMessagesMutation';
import useMoveFolderMutation from '../hooks/useMoveFolderMutation';
import useUpdateFoldersOnTree from '../hooks/useUpdateFoldersOnTree';
import useDeleteFoldersMutation from '../hooks/useDeleteFoldersMutation';
import useUpdateFolderDataOnTree from '../hooks/useUpdateFolderDataOnTree';
import useUpdateGetFolderContent from '../hooks/useUpdateGetFolderContent';

import { AuthContext } from '../util/context';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { QUERY, MUTATION } from '../gql-operations';

import DeleteModal from './Modals/DeleteModal';
import SearchFoldersModal, { searchActions } from './Modals/SearchFoldersModal';
import CreateFolderModal from './Modals/CreateFolderModal';
import UpdateFolderModal from './Modals/UpdateFolderModal';

import ReactTooltip from 'react-tooltip';
import FolderCardDropdown from './FolderCardDropdown';
import { PALE_WHITE_2, PALE_WHITE_4, PALE_WHITE_6, PALE_WHITE_8 } from '../appearance/Colors';
import customAnalytics from '../util/custom-analytics';

const FolderCard = ({
    name,
    item,
    isDraggingActive,
    onDrop,
    onExpand,
    onCollapse,
    selected,
    onClick,
    disableDrop,
    disableRightIcon,
    homeFolder,
    ...props
}) => {
    const [moveMessages] = useMoveMessagesMutation();
    const [moveFolder] = useMoveFolderMutation();
    const { updateFoldersOnTree } = useUpdateFoldersOnTree();
    const { deleteFolders } = useDeleteFoldersMutation();

    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showUpdateFolderModal, setShowUpdateFolderModal] = useState(false);
    const [showFoldersSearch, setShowFoldersSearch] = useState(false);
    const [hoveringContainer, setHoveringContainer] = useState(false);

    const { data, loading, error } = useQuery(QUERY.ME);

    // const [updateFolder] = useMutation(MUTATION.UPDATE_FOLDER);
    // const [updateFolderData] = useUpdateFolderDataOnTree();
    const { refreshFolderLocal } = useUpdateGetFolderContent();

    const {
        state: { messagesToSend, selectedFolder, getSubfolders, showDeletedMessages, showMultiSelect },
        context: { setSelectedFolder, setShowDeletedMessages, setShowMultiSelect, setShowBackgroundSelect }
    } = React.useContext(AuthContext);

    const { data: folder } = item;

    // DRAGGING FOLDER
    const [{ isDragging }, drag] = useDrag({
        item: { name, type: ItemTypes.FOLDER, folder },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            // console.log(dropResult);
            // console.log(item);
            // alert(`You dropped ${message.id} into ${dropResult.name}!`);

            // just to refresh a message when getting dropped
            if (item && dropResult) {
                console.log('do you go in?', item, dropResult, folder);
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: () => {
            return true;
        }
    });

    const moveAndRefresh = async (drop, toFolder) => {
        await moveFolder(drop.folder, toFolder);
        await refreshFolderLocal(selectedFolder, drop.folder);
        customAnalytics.folder_edit({
            "action": 'Move',
            "type": "Drag and Drop",
            "destination": "Folder"
        });
    };

    // DROPPING MESSAGE
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [ItemTypes.MESSAGE, ItemTypes.FOLDER],
        drop: drop => {
            if (drop.type === ItemTypes.FOLDER) {
                // dropping to folder
                const toFolder = item.data;
                if (toFolder.id !== drop.folder.id) {
                    moveAndRefresh(drop, toFolder);
                }
            } else if (drop.type === ItemTypes.MESSAGE) {
                console.log('messagesToSend', messagesToSend);
                if (messagesToSend && messagesToSend.length > 0) {
                    console.log('item and drop', item, drop);
                    onDropMutation(messagesToSend, item.data);
                } else {
                    let message = drop.message;
                    onDropMutation([message], item.data);
                }
            }
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        canDrop: () => {
            if (disableDrop) {
                return false;
            } else {
                return true;
            }
        }
    });
    // DROPPING MESSAGE END

    const onDropMutation = (messages, toFolder) => {
        // const listMessages = [item.message];
        // custom hook
        moveMessages(messages, toFolder, selectedFolder);
        messages.forEach(message => {
            customAnalytics.message_edit({
                "action": "Move",
                "type": "Drag and Drop",
                "multi_select": messages.length,
                "from": selectedFolder.title === "Inbox" ? "Inbox" : selectedFolder.parent ? "Subfolder" : "HomeFolder",
                "destination": toFolder.title === "Inbox" ? "Inbox" : toFolder.parent ? "Subfolder" : "HomeFolder",
            }, message)
        });
    };

    const renderEmptyChildren = item => {
        if (item && item.isExpanded) {
            if (item.children && item.children.length === 0) {
                return (
                    <div style={{ paddingLeft: 16, paddingBottom: 6 }} onClick={onClick}>
                        {getSubfolders[item.data.id] ? (
                            <span style={{ color: PALE_WHITE_6, userSelect: 'none' }}>Loading...</span>
                        ) : (
                            <span style={{ color: PALE_WHITE_4 }}>No folders inside</span>
                        )}
                    </div>
                );
            } else if (item.children && item.children.length > 0) {
                return null;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    // const updateFolderMutation = async (folder, event) => {
    //     if (folder.title !== event.target.innerText) {
    //         let response = await updateFolder({
    //             variables: {
    //                 data: {
    //                     folderId: folder.id,
    //                     title: event.target.innerText.trim()
    //                 }
    //             }
    //         });
    //         console.log('this is res from update', response);
    //         if (response.data) {
    //             let updatedFolder = response.data.updateFolder;
    //             updateFolderData(updatedFolder);
    //         }
    //         // folder.title = response.data.updateFolder.title;
    //     }
    // };

    if (item) {
        return (
            <>
                <CustomItem
                    id={item.data.id}
                    // not working like this
                    // isDragging={!disableDrag ? isDragging : false}
                    selected={selected}
                    ref={drag}
                    {...props}
                    isOver={isOver}
                    canDrop={!disableDrop ? canDrop : false}
                    onMouseEnter={() => setHoveringContainer(true)}
                    onMouseLeave={() => {
                        setHoveringContainer(false);
                    }}
                >
                    <div ref={drop} style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{getIcon(item, onExpand, onCollapse)} </span>
                        {/* Just clicking on the folder, not the icons */}
                        <span
                            onClick={onClick}
                            style={{
                                width: '100%',
                                paddingTop: 6,
                                paddingBottom: 6
                            }}
                        >
                            {' '}
                            {/* {item.data.id
								? item.data.id.substr(item.data.id.length - 3)
								: null}
							= */}
                            {item.data ? item.data.title : ''}
                            {/* =
							{item.data.before
								? item.data.before.substr(item.data.before.length - 3)
								: null} */}
                        </span>
                        {homeFolder ? (
                            <>
                                <a data-tip data-for="homeIcon">
                                    <HomeIconWrapper>
                                        <IoMdHome size={16} color={'rgba(255,255,255,.8)'}></IoMdHome>
                                    </HomeIconWrapper>
                                </a>
                                <ReactTooltip place="top" type="dark" effect="solid" id="homeIcon">
                                    <div>{`Home folder`}</div>
                                </ReactTooltip>
                            </>
                        ) : null}
                        {!disableRightIcon ? (
                            <>
                                <div style={{ flex: 1 }}></div>
                                <FolderCardDropdown
                                    hovering={hoveringContainer}
                                    setShowCreateFolderModal={val => setShowCreateFolderModal(val)}
                                    setShowDeleteFolderModal={val => setShowDeleteFolderModal(val)}
                                    setShowUpdateFolderModal={val => setShowUpdateFolderModal(val)}
                                    setShowFoldersSearch={val => setShowFoldersSearch(val)}
                                    // onSelect={() => {
                                    //     console.log('you lose');
                                    //     setHoveringContainer(false);
                                    // }}
                                    folder={item.data}
                                ></FolderCardDropdown>

                                <IconWrapper
                                    className="plus"
                                    onClick={() => {
                                        setShowCreateFolderModal(true);
                                    }}
                                >
                                    <IoIosAdd size={16} color={'rgba(255,255,255,.8)'}></IoIosAdd>
                                </IconWrapper>
                            </>
                        ) : null}
                    </div>
                    {renderEmptyChildren(item)}
                </CustomItem>
                <CreateFolderModal
                    currentFolder={item.data}
                    subfolder
                    isOpen={showCreateFolderModal}
                    closeModal={() => {
                        setShowCreateFolderModal(false);
                    }}
                />
                <UpdateFolderModal
                    folder={item.data}
                    isOpen={showUpdateFolderModal}
                    closeModal={() => {
                        setShowUpdateFolderModal(false);
                    }}
                />
                <DeleteModal
                    isOpen={showDeleteFolderModal}
                    title={`${item.data.title} will be deleted forever`}
                    description={[
                        `This folder will be `,
                        <b key={'title_1'}>deleted forever</b>,
                        ` with `,
                        <b key={'title_2'}>all subfolders and messages</b>,
                        ` and you will not going to be able to access it's content any longer.`
                    ]}
                    closeModal={async response => {
                        setShowDeleteFolderModal(false);
                        if (response) {
                            await deleteFolders([item.data]);
                        }
                    }}
                ></DeleteModal>
                <SearchFoldersModal
                    isOpen={showFoldersSearch}
                    currentFolder={item.data}
                    title={`Move ${item.data.title} to...`}
                    enableMoveToRoot
                    addNew
                    action={searchActions.MOVE_FOLDER}
                    closeModal={async toFolder => {
                        setShowFoldersSearch(false);
                        console.log('are you fucking me', item.data);
                        if (toFolder) {
                            console.log('sending', item.data, toFolder);
                            // let response = await moveFolder(folder, toFolder);
                            updateFoldersOnTree(item.data, toFolder);
                            // analytics inside this function
                        }
                        console.log('selected folder is', toFolder);
                    }}
                ></SearchFoldersModal>
            </>
        );
    }
    return null;
};

const PreTextIcon = styled.span`
    display: inline-block;
    width: 16px;
    justify-content: center;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    // padding: 2px;
    display: none;
    border: 1px solid ${PALE_WHITE_4};
    border-radius: 4px;
    cursor: pointer;
    margin-left: 4px;
    &: hover {
        border: 1px solid rgba(255, 255, 255, 0.8);
        background-color: ${PALE_WHITE_2};
    }
`;

const OtherIconWrapper = styled.div`
    // padding: 2px;
    display: none;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    cursor: pointer;
    margin-left: 4px;
    &: hover {
        border: 1px solid rgba(255, 255, 255, 0.8);
    }
`;

const HomeIconWrapper = styled.div`
    // padding: 2px;
    display: block;
    // border: 1px solid rgba(255, 255, 255, 0.4);
    // border-radius: 4px;
    // cursor: pointer;
    // &: hover {
    //     border: 1px solid rgba(255, 255, 255, 0.8);
    // }
`;

const CustomItem = styled.div`
    ${props =>
        props.isDraggingActive &&
        css`
            // transition: opacity 0.2s cubic-bezier(0.2,0,0,1) 0s;
            // transform: translate(9px, -23px);
            box-sizing: border-box;
            background-color: rgb(235, 236, 240);
            fill: rgb(235, 236, 240);
            box-shadow: rgba(9, 30, 66, 0.31) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
        `}
    ${props =>
        props.canDrop &&
        css`
            // background-color: rgb(235, 136, 200);
        `}
	${props =>
        props.isOver &&
        props.canDrop &&
        css`
            background-color: rgb(135, 236, 240);
        `}
	
	${props =>
        props.selected &&
        css`
            background-color: rgb(255, 255, 255, 0.13);
        `}
    
    color: white;
    padding-left: 8px;
    padding-right: 8px;
    // padding-top: 6px;
    // padding-bottom: 6px;
    cursor: default;
    border-radius: 4px;
    border: none;
    min-width: 150px;
    height: 100%;
    position: relative;
    &:hover {
        background: rgba(255, 255, 255, 0.1);
        .plus {
            display: block;
        }
        .home {
            display: none;
        }
    }
`;

const getIcon = (item, onExpand, onCollapse) => {
    // in case that we don't have expand
    if (!onExpand) {
        return null;
    }
    if (item && item.isExpanded) {
        return (
            <PreTextIcon onClick={() => onCollapse(item.id)}>
                <IoIosArrowDown size={12} color={PALE_WHITE_8}></IoIosArrowDown>
            </PreTextIcon>
        );
    } else {
        return (
            <PreTextIcon onClick={() => onExpand(item.id)}>
                <IoIosArrowForward size={12} color={PALE_WHITE_8}></IoIosArrowForward>
            </PreTextIcon>
        );
    }
};

export default FolderCard;
