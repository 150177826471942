import { gql } from 'apollo-boost';

// input RestoreFolderInput {
//     folder: ID!
// }
export const RESTORE_FOLDER = gql`
    mutation($data: RestoreFolderInput!) {
        restoreFolder(data: $data) {
            id
            title
            parent
            owner
            before
            deletedAt
        }
    }
`;
