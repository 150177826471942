import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FILE_BLUE } from '../../appearance/Colors';
import './ErrorScreen.scss';
import { useQuery } from "@apollo/react-hooks";
import { QUERY } from "../../gql-operations";

import { useHistory } from 'react-router-dom';

function ErrorPage() {
    let history = useHistory();

    const { loading, error, data } = useQuery(QUERY.ME, {
        onCompleted: res => {
            if (res){
                console.log('this is res', res)
                if (res.me){
                    history.push('/login')
                }
            }
        }
    });

    return (
        <div
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                padding: 20
            }}
        >
            <div style={{}}>
                <h4 style={{ fontSize: 24, fontWeight: '600', color: '#333333' }}>Our service is currently down.</h4>
                <h4 style={{ fontSize: 24, fontWeight: '600', color: '#333333' }}>
                    We'll be back as soon as possible.
                </h4>
                <h4 style={{ fontSize: 24, fontWeight: '600', color: '#333333' }}>Thanks!</h4>

                <br></br>
                <h4 style={{ fontSize: 20, fontWeight: '500', color: '#333333' }}>
                    You can always shoot us an email to let us know
                    <br></br>
                    <span style={{ textDecoration: 'underline' }}>support@luckynote.io</span>
                </h4>
                <br></br>

                <a href={'/'} style={{ fontSize: 20, fontWeight: '600', color: FILE_BLUE }}>
                    Reload
                </a>
            </div>
        </div>
    );
}

export default ErrorPage;
