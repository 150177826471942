import { SUBSCRIPTION } from '../../../gql-operations';

function subscribeToMessages(folder, subscribeToMore) {
    let unsubscribe = subscribeToMore({
        document: SUBSCRIPTION.NEW_MESSAGE_SUBSCRIPTION,
        variables: { folderId: folder.id },
        updateQuery: (previousResult2, { subscriptionData }) => {
            console.log('subsription previous res', previousResult2);
            console.log('subsription New Data', subscriptionData);
            if (!subscriptionData.data) return previousResult2;
            const newMessage = subscriptionData.data.newMessage;

            const oldMessages = previousResult2.getFolderContent.messages.messages;

            let newestMessages = [];

            let oldMessIndes = oldMessages.findIndex(obj => obj.id === newMessage.id);

            // [metaUrl trick] checking if the incoming updated message is already in the list
            // if yes, update metaUrl and post it to the client
            if (oldMessIndes > -1) {
                if (oldMessages[oldMessIndes].id === newMessage.id) {
                    newestMessages = [...previousResult2.getFolderContent.messages.messages];

                    newestMessages[oldMessIndes] = newMessage;
                }
            } else {
                newestMessages = [newMessage, ...previousResult2.getFolderContent.messages.messages];
            }

            return {
                getFolderContent: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    folder: previousResult2.getFolderContent.folder,
                    parent: previousResult2.getFolderContent.parent,
                    folders: previousResult2.getFolderContent.folders,
                    path: previousResult2.getFolderContent.path,
                    messages: {
                        messages: newestMessages,
                        cursor: previousResult2.getFolderContent.messages.cursor,
                        __typename: previousResult2.getFolderContent.messages.__typename
                    },
                    __typename: previousResult2.getFolderContent.__typename
                }
            };
        }
    });
    return unsubscribe;
}

export default subscribeToMessages;
