import React, { useState, useEffect } from 'react';

import { FaRegCheckCircle, FaRegCircle, FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';

import { useQuery, useMutation } from '@apollo/react-hooks';

import * as COLOR from '../../../appearance/Colors';

import { QUERY, MUTATION } from '../../../gql-operations';
import styled from 'styled-components';

import { AuthContext } from '../../../util/context';
import customAnalytics from '../../../util/custom-analytics';

function Checkbox({ message }) {
    const [showPlus, setShowPlus] = useState(false);
    // const [messageHeight, setMessageHeight] = useState(0);

    const {
        state: { holdingKeys, keys }
    } = React.useContext(AuthContext);

    // useEffect(() => {
    //     if (height.current) {
    //         setMessageHeight(height.current.offsetHeight);
    //     }
    //     return () => {};
    // }, [height]);
    // const MESSAGE_HEIGHT = height.current ? height.current.offsetHeight : 0;

    const metaActive = holdingKeys && (keys.Meta || keys.Control);

    // const [addCheckbox] = useMutation(MUTATION.ADD_CHECKBOX, {
    //     variables: {
    //         data: {
    //             messageId: message.id
    //         }
    //     },
    //     onCompleted: res => {
    //         customAnalytics.message_edit({
    //             'action': 'Task'
    //         }, message)
    //     },
    //     refetchQueries: [
    //         {
    //             query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL
    //         }
    //     ]
    // });

    const [checkMessage] = useMutation(MUTATION.CHECK_MESSAGE, {
        variables: {
            data: {
                messageId: message.id
            }
        },
        onCompleted: res => {
            customAnalytics.message_edit({
                'action': 'Check',
            }, message)
        }
    });
    const [uncheckMessage] = useMutation(MUTATION.UNCHECK_MESSAGE, {
        variables: {
            data: {
                messageId: message.id
            }
        }, onCompleted: res => {
            customAnalytics.message_edit({
                'action': 'Uncheck',
            }, message)
        }
    });

    const [removeCheckbox] = useMutation(MUTATION.REMOVE_CHECKBOX, {
        variables: {
            data: {
                messageId: message.id
            }
        }, onCompleted: res => {
            customAnalytics.message_edit({
                'action': 'Untask',
            }, message)
        }
    });

    if (message.isCheckbox) {
        if (message.isCheckbox && metaActive) {
            return (
                <IconContainer
                    onClick={e => {
                        e.stopPropagation();
                        console.log('Removing checkbox');
                        removeCheckbox();
                    }}
                >
                    <FaRegTimesCircle size={18} color={COLOR.WHITE} />
                </IconContainer>
            );
        } else if (message.isChecked) {
            return (
                <IconContainer
                    onClick={e => {
                        e.stopPropagation();
                        console.log('Clicking only on checkbox');
                        uncheckMessage();
                    }}
                >
                    <FaRegCheckCircle size={18} color={COLOR.WHITE} />
                </IconContainer>
            );
        } else {
            return (
                <IconContainer
                    onClick={e => {
                        e.stopPropagation();
                        console.log('Clicking only on checkbox');
                        checkMessage();
                    }}
                >
                    <FaRegCircle size={18} color={COLOR.WHITE} />
                </IconContainer>
            );
        }
    } else {
        return null;
    }
}

const IconContainer = styled.div`
    width: 20px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    // height: ${props => `${props.height}px` || '0'};
`;

export default Checkbox;
