import React from 'react';
import { AuthContext } from '../../util/context';
import styled from 'styled-components';

import { IoIosClose } from 'react-icons/io';

import { GREEN, WHITE } from '../../appearance/Colors';

import useS3Request from '../../hooks/useS3Request';

function LoadingProgress({}) {
    const {
        state: { uploadProgress }
    } = React.useContext(AuthContext);

    const { cancelPromise } = useS3Request();

    if (uploadProgress) {
        // console.log('why the fuck', uploadProgress)
        return (
            <ProgressWrapper>
                {Object.entries(uploadProgress).map(([key, val]) => {
                    if (val.percentage < 100) {
                        return (
                            <Notice
                                key={key}
                                style={{
                                    backgroundColor: GREEN,
                                    borderRadius: 50,
                                    minWidth: 300,
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flex: 1,
                                            color: 'white'
                                        }}
                                    >
                                        Uploading {val.name}... {parseInt(val.percentage)}%
                                    </div>

                                    {/* <IoIosClose
                                        size={24}
                                        color={WHITE}
                                        onClick={() => {
                                            console.log('close this');
                                            cancelPromise();
                                        }}
                                    ></IoIosClose> */}
                                </div>
                            </Notice>
                        );
                    }
                })}
            </ProgressWrapper>
        );
    }
    return null;
}

const ProgressWrapper = styled.div`
    top: 65px;
    left: 50%;
    position: fixed;
    z-index: 1000;
`;

const Notice = styled.div`
    right: 50%;
    padding: 4px 10px 4px 10px;
    border-radius: 3px 3px;
    border: 1px solid #999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 0px solid rgba(0, 0, 0, 0);
    background: #fff;
    display: block;
    width: auto;
    line-height: 1.5;
    position: relative;
    margin: 10px 0;
`;

export default LoadingProgress;
