import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import { AuthContext } from '../util/context';

import useGetFolderContent from './useGetFolderContent';
import { MUTATION, QUERY } from '../gql-operations';
import customAnalytics from '../util/custom-analytics';

function useTrashFoldersMutation() {
    const {
        state: { treeData, homeFolder },
        context: { setNewTree, setSelectedFolder }
    } = React.useContext(AuthContext);

    const [trashFoldersMutation, { data: successResponse }] = useMutation(MUTATION.TRASH_FOLDERS, {
        onCompleted: res => {
            // analytics on the bottom
        }
    });
    const { getFolderParent } = useGetFolderContent();

    // const [updateFolder, { data }] = useMutation(UPDATE_FOLDER_MUTATION);

    const deleteChildFromTree = folders => {
        let cloneTree = { ...treeData };
        const lastFolder = folders[folders.length - 1];
        // going to the parent when deleting
        if (lastFolder.parent) {
            let parent = getFolderParent(lastFolder.id);
            // console.log('got the parent', parent);
            setSelectedFolder(parent);
        } else {
            if (lastFolder.before) {
                let previousFolder = cloneTree.items[lastFolder.before] && cloneTree.items[lastFolder.before].data;
                if (previousFolder) {
                    setSelectedFolder(previousFolder);
                } else {
                    setSelectedFolder(homeFolder);
                }
            } else {
                // because the first one was deleted
                // getting all the folders
                let allFolders = cloneTree.items['root'].children;
                // getting the folder after the first one because we haven't spliced it yet
                let firstFolder = cloneTree.items[allFolders[1]] && cloneTree.items[allFolders[1]].data;
                if (firstFolder) {
                    setSelectedFolder(firstFolder);
                }
            }
        }
        folders.forEach(folder => {
            let currentFolder = cloneTree.items[folder.parent ? folder.parent : 'root'];

            let nextFolder = Object.values(cloneTree.items).find(item => {
                // this is how we find the next folder
                if (item.data.before === folder.id) {
                    return item;
                }
            });

            // console.log('next fol', nextFolder);
            if (nextFolder) {
                // if the next folder exists change his before to id of the previous one
                if (cloneTree.items[lastFolder.before]) {
                    // checking if the before does exist
                    let beforeFolder = cloneTree.items[lastFolder.before];
                    cloneTree.items[nextFolder.id].data.before = beforeFolder.data.id;
                } else {
                    cloneTree.items[nextFolder.id].data.before = null;
                }
            }

            if (currentFolder) {
                let newSourceChildren = currentFolder.children;
                // console.log('his kids', newSourceChildren);

                if (newSourceChildren) {
                    let currentFolderIndex = newSourceChildren.findIndex(obj => obj === folder.id);
                    if (currentFolderIndex > -1) {
                        console.log('index is', currentFolderIndex);
                        var itemRemoved = newSourceChildren.splice(currentFolderIndex, 1)[0];

                        console.log('item was removeted', itemRemoved);

                        let newItems = { ...newSourceChildren };

                        cloneTree.items[currentFolder.id].items = newItems;

                        console.log('new tree', cloneTree);

                        setNewTree(cloneTree);
                        // return cloneTree;
                    }
                }
            }
        });
    };

    const trashFolders = async foldersArray => {
        console.log('trashing', foldersArray);
        let response = await trashFoldersMutation({
            variables: {
                data: {
                    folderIds: foldersArray.map(folder => folder.id)
                }
            },
            refetchQueries: [
                {
                    query: QUERY.ME
                },
                {
                    query: QUERY.GET_TRASH_FOLDERS_GLOBAL
                }
            ]
        });
        if (response) {
            // checking if it's true from the server so we can delete from the tree
            deleteChildFromTree(foldersArray);
            console.log('folders array', foldersArray);
            customAnalytics.trash_folder();
        }
    };

    return { trashFolders, deleteChildFromTree };
}

export default useTrashFoldersMutation;
