function fileSizeCalculator(bytes) {
    if (bytes && bytes > 0) {
        let size = bytes / 1024 / 1024;
        return size.toFixed(2);
    }
    return null;
}

function prettySize(bytes, separator = '', postFix = '') {
    if (bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
        return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
    }
    return 'n/a';
}

export { fileSizeCalculator, prettySize };

// import React from 'react'

// function fileSizeCalculator() {
//     return (
//         <div>
            
//         </div>
//     )
// }

// export default fileSizeCalculator
