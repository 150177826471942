import { gql } from 'apollo-boost';

// input DeleteUserInput {
// 	userId: String!
//  email: String!
// }

export const DELETE_USER_ADMIN = gql`
    mutation($data: DeleteUserInput!) {
        deleteUserAdmin(data: $data) {
            __typename
            ... on User {
                email
                fullName
                isVerified
                role
                deletedAt
                deletedCursor
                isVerified
            }
            ... on InternalServerError {
                message
            }
            ... on DbStartTransactionError {
                message
            }
        }
    }
`;
