import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { cloudinaryCore } from '../../../util/cloudinary';
import customAnalytics from '../../../util/custom-analytics';

function ImageCarousel({ data, currentMessage, isVisible, closeModal }) {
    // not used
    // const renderCarousel = (message) => {
    // 	if (message.image) {
    // 		return [{ src: cloudinaryCore.url(message.image.url) }];
    // 	} else if (message.file.url) {
    // 		return [{ src: message.file.url }];
    // 	}
    // 	return null;
    // };

    const CustomView = ({ data }) => (
        <TransformWrapper
            initialScale={1}
            minScale={0.5}
            maxScale={16}
            centerOnInit={true}
            wheel={{
                step: 0.4, // Controls zoom step size (smaller = slower zoom)
                smoothStep: 0.005, // For smoother zooming
                wheelEnabled: true, // Enable wheel/touchpad zooming
                touchPadEnabled: true, // Enable touchpad pinch
                pinch: true // Enable pinch gestures
            }}
            pinch={{
                step: 5, // Controls pinch zoom sensitivity
                enabled: true,
                scalePadding: 0.2, // Adds padding to scale operations
                velocityDisabled: false, // Enable/disable velocity on pinch
                velocityScale: 1 // Control velocity scaling (lower = slower)
            }}
        >
            <TransformComponent
                wrapperStyle={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                contentStyle={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    src={data.src}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100vh',
                        objectFit: 'contain',
                        width: 'auto',
                        height: 'auto'
                    }}
                    alt=""
                />
            </TransformComponent>
        </TransformWrapper>
    );


    // loop over all the images and show them in carousel
    // without showing the video for now component is in CustomCarouselComponenet.js
    const messagesWithImage = () => {
        const images = data
            .slice(0)
            .filter(message => {
                if (
                    message.image ||
                    (message.file && message.file.type.includes('image'))
                    // (message.file.type === 'image/png' ||
                    //     message.file.type === 'image/jpg' ||
                    //     message.file.type === 'image/jpeg' ||
                    //     message.file.type === 'image/svg' ||
                    //     message.file.type === 'image/svg+xml' ||
                    //     message.file.type === 'image/heic'
                    //     )
                    // || message.file.type === 'video/mp4')
                ) {
                    return message;
                }
            })
            .reverse()
            .map((message, i) => {
                if (message.image) {
                    if (message.image.format !== 'heic') {
                        return {
                            src: cloudinaryCore.url(message.image.url, {
                                quality: 50,
                                fetchFormat: 'auto',
                                crop: 'fit',
                                width: 1080,
                                flags: 'lossy'
                            }),
                            currentIndex: i,
                            messageId: message.id
                        };
                    } else {
                        return {
                            src: cloudinaryCore.url(message.image.public_id, { quality: 50, fetchFormat: 'auto' }),
                            currentIndex: i,
                            messageId: message.id
                        };
                    }
                } else if (message.file) {
                    return {
                        src: message.file.url,
                        currentIndex: i,
                        messageId: message.id
                    };
                }
            });
        return images;
    };

    return (
        <ModalGateway>
            {isVisible ? (
                <Modal
                    onClose={e => {
                        e.stopPropagation();
                        customAnalytics.message_action({
                            'action': 'View Image',
                            "type": "Image"
                        });
                        closeModal();
                    }}
                    styles={{
                        blanket: base => ({
                            ...base,
                            zIndex: 9999999
                        }),
                        positioner: base => ({
                            ...base,
                            zIndex: 9999999,
                            width: '100vw',
                            height: '100vh',
                            overflow: 'hidden',
                            margin: 0,
                            padding: 0
                        }),
                        dialog: base => ({
                            ...base,
                            width: '100vw',
                            height: '100vh',
                            margin: 0,
                            padding: 0,
                            overflow: 'hidden'
                        }),
                        view: base => ({
                            ...base,
                            width: '100vw',
                            height: '100vh'
                        })
                    }}
                >
                    <Carousel
                        components={{ View: CustomView }}
                        currentIndex={messagesWithImage().find(obj => obj.messageId === currentMessage.id).currentIndex}
                        // formatters={{}}
                        // styles={{
                        // 	container: base => ({
                        // 		...base,
                        // 		backgroundColor: 'rgba(255,255,255,0.85)'
                        // 	})
                        // }}
                        frameProps={{}}
                        views={messagesWithImage()}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
    );
}

export default ImageCarousel;
