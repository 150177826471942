import React from 'react';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { QUERY } from '../gql-operations';
import useAddFoldersToTree from './useAddFoldersToTree';

import { AuthContext } from '../util/context';

import useUpdateGetFolderContent from '../hooks/useUpdateGetFolderContent';
import useNotificationSystem from '../components/Notifications/useNotificationSystem';

function useGetSubfolders() {
    const {
        state: { treeData, selectedFolder, showDeletedMessages, getSubfolders },
        context: { setGetSubfolders }
    } = React.useContext(AuthContext);

    const { updateNewFolders } = useUpdateGetFolderContent();
    const client = useApolloClient();
    const { showCreatedFolderNotification } = useNotificationSystem();

    const [addChildrenToFolder] = useAddFoldersToTree();
    const [getContent] = useLazyQuery(QUERY.GET_FOLDER_CONTENT, {
        fetchPolicy: 'network-only',
        onError: error => {
            // console.log('say the error new new child', error);
        },
        onCompleted: async res => {
            // console.log('children new new child', res.getFolderContent.folders);
            if (res.getFolderContent.folders.length > 0) {
                // console.log('i have children');
                let sortedFolders = res.getFolderContent.folders;
                // console.log('sending the children', sortedFolders);
                addChildrenToFolder(sortedFolders);
                const loading = { ...getSubfolders };
                delete loading[sortedFolders[0].parent];
            }
            setGetSubfolders({});
        }
    });

    const setChildrenManually = async parentId => {
        let loading = { ...getSubfolders };
        loading[parentId] = { loading: true };
        setGetSubfolders(loading);
        getContent({
            variables: {
                data: {
                    folderId: parentId,
                    isTrash: showDeletedMessages
                }
            }
        });

        // because the response can not be given from lazyQuery
        return true;
    };

    const storeOrFetch = parentId => {
        try {
            if (parentId) {
                const store = client.readQuery({
                    query: QUERY.GET_FOLDER_CONTENT,
                    variables: {
                        data: {
                            folderId: parentId,
                            isTrash: showDeletedMessages
                        }
                    }
                });
                if (store) {
                    console.log('folder already loaded');
                    if (store.getFolderContent.folders.length > 0) {
                        // console.log('i have children');
                        let sortedFolders = store.getFolderContent.folders;
                        // console.log('sending the children', sortedFolders);
                        addChildrenToFolder(sortedFolders);
                    }
                } else {
                    console.log('store is null')
                    setChildrenManually(parentId);
                }
            } else {
                // console.log('folder not loaded yet');
            }
        } catch (error) {
            console.log('folder not loaded yet');
            setChildrenManually(parentId);
        }
    };

    const runThis = async (folder, justCreated) => {
        if (justCreated) {
            console.log('this is the folder', folder);
            showCreatedFolderNotification(folder);
            // so we don't fetch the getContent
            if (folder.parent) {
                let parentFolder = treeData.items[folder.parent];
                if (parentFolder && parentFolder.children.length >= 0) {
                    let response = await addChildrenToFolder([folder]);
                    // this is so we reload the cache with new folder
                    updateNewFolders(parentFolder.data, [folder]);
                    console.log('just created and has a parent');
                    return response;
                } else {
                    updateNewFolders(selectedFolder, [folder]);
                    return true;
                }
            } else {
                let response = await addChildrenToFolder([folder]);
                console.log('just created, just add him to the tree', response);
                return response;
            }
        }
        //  else if (folder.parent) {
        //     let response = await setChildrenManually(folder.parent);
        //     // RETURN is just so we can focus on the folder later on
        //     return response;
        // }
        else {
            // he's the parent, just call for him
            let response = await storeOrFetch(folder.id);
            return response;
        }
    };

    return [runThis];
}

export default useGetSubfolders;
