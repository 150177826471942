import { useState } from 'react';

function useCheckValidity() {
    const [validImage, setValidImage] = useState(false);

    function CheckError(response) {
        if (response.status >= 200 && response.status <= 299) {
            if (response.status !== 200) {
                setValidImage(false);
            } else {
                setValidImage(true);
            }
        } else {
            setValidImage(false);
            throw Error(response.statusText);
        }
    }

    const checkImageValidity = source => {
        return true;
        fetch(source)
            .then(CheckError)
            .then(jsonResponse => {
                // console.log('whats the response', jsonResponse);
            })
            .catch(error => {
                setValidImage(false);
            });
        return validImage;
    };

    return { checkImageValidity };
}

export default useCheckValidity;
