import { QUERY } from '../../../gql-operations';

function loadMoreMessages(text, folder, cursor, isTrash, fetchMore, setLoadMoreCheck) {
    fetchMore({
        query: QUERY.FIND_MESSAGES,
        variables: {
            data: {
                text: text ? text : '',
                folderId: folder ? folder : '',
                cursor: cursor,
                isTrash: isTrash
            }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
            // console.log('This is previousRes', previousResult);
            // console.log('This is fetch more', fetchMoreResult);
            const previousEntry = previousResult.findMessages.messages;

            // case of the first message in the folder
            if (!previousResult.findMessages.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            // if there is no fetchMore, just return the previous data
            if (!fetchMoreResult.findMessages.cursor) {
                setLoadMoreCheck(false);
                return previousResult;
            }

            // cursor is the same
            if (previousResult.findMessages.cursor === fetchMoreResult.findMessages.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            const newMessages = fetchMoreResult.findMessages.messages;
            const newCursor = fetchMoreResult.findMessages.cursor;

            setLoadMoreCheck(false);
            return {
                findMessages: {
                    // we are not cursoring the folders, that's why it's only previousResult
                    messages: [...(previousEntry && previousEntry.length > 0 ? previousEntry : []), ...newMessages],
                    cursor: newCursor,
                    __typename: previousResult.findMessages.__typename
                }
            };
        }
    });
}

export default loadMoreMessages;
