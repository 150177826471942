import React, { useState } from 'react';

import classNames from 'classnames';
import { SectionTilesProps } from '../screens/Landing/utils/SectionProps';
import SectionHeader from '../screens/Landing/components/sections/partials/SectionHeader';
import { GREEN, GREEN_OP_2, PRIMARY_COLOR_1, PRIMARY_COLOR_4, PRIMARY_COLOR_6, RED, WHITE } from '../appearance/Colors';

import { useMediaQuery } from 'react-responsive';
import customAnalytics from '../util/custom-analytics';
import SAVED_TYPE from '../util/saved-from-enum';
import Logo from './Landing/components/layout/partials/Logo';
import { Helmet } from 'react-helmet';

const propTypes = {
    ...SectionTilesProps.types
};

const defaultProps = {
    ...SectionTilesProps.defaults
};

const Mobile = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    history,
    ...props
}) => {
    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    let referralIdLocal = localStorage.getItem('referralId');

    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const htmlObject = () => {
        return (
            <div style={{ marginBottom: -32 }}>
                <p>Download the app and input the referral code during account registration to receive a bonus.</p>
                <p
                    onClick={() => copyToClip()}
                    className="m-0 container-xs ml-auto mr-auto mt-32 fw-600"
                    style={{
                        color: GREEN,
                        backgroundColor: copiedToClipboard ? GREEN_OP_2 : PRIMARY_COLOR_1,
                        // borderColor: ,
                        border: `1px solid ${PRIMARY_COLOR_6}`,
                        borderRadius: 10,
                        padding: 16
                    }}>
                    {referralIdLocal}
                </p>
                <p style={{ fontSize: 16, color: PRIMARY_COLOR_4, marginTop: 8 }}>
                    {copiedToClipboard ? "Copied!" : "Tap to copy"}
                </p>
            </div>
        )
    }

    const copyToClip = async () => {
        navigator.clipboard
            .writeText(referralIdLocal)
            .then(() => {
                setCopiedToClipboard(true);
                setTimeout(() => {
                    setCopiedToClipboard(false);
                }, 1500);
                customAnalytics.message_action({
                    "action": "Copy",
                    "type": "Referral"
                })
            })
            .catch(() => {
                alert("Something went wrong, please copy the code manually");
            });

    }

    const sectionHeader = {
        title: referralIdLocal ? "You've got an invite!" : 'Download',
        paragraph: referralIdLocal ? htmlObject() : ''
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} >
            <Helmet>
                <title>Downloads</title>
                <meta name="description" content="Luckynote works wherever you are. Download Luckynote for iPhone and Android." />
            </Helmet>
            <div
                style={{ margin: 16, position: 'absolute', top: 0, left: 0, background: WHITE }}
                onClick={() => {
                    history.push('/home');
                }}
            >
                <Logo />
            </div>
            <section {...props} className={outerClasses} id="download-mobile" style={{ marginTop: 100 }}>
                <div className="container">
                    <div className={innerClasses}>
                        <SectionHeader data={sectionHeader} className="center-content" />
                        <div className={tilesClasses}>
                            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                                <div
                                    className="tiles-item-inner"
                                    style={{ backgroundColor: WHITE, cursor: 'pointer' }}
                                    onClick={() => {
                                        customAnalytics.mobile_download(SAVED_TYPE.ANDROID);
                                        window.open(
                                            'https://play.google.com/store/apps/details?id=com.luckynote',
                                            '_blank'
                                        );
                                    }}
                                >
                                    <img
                                        src={require('../assets/google-play-badge.png')}
                                        style={{ objectFit: 'contain', height: 100 }}
                                    />
                                </div>
                            </div>

                            <div className="tiles-item reveal-from-bottom">
                                <div
                                    className="tiles-item-inner"
                                    style={{ backgroundColor: WHITE, cursor: 'pointer' }}
                                    onClick={() => {
                                        customAnalytics.mobile_download(SAVED_TYPE.IOS);
                                        window.open('https://apps.apple.com/app/luckynotehq/id1566320984', '_blank');
                                    }}
                                >
                                    <img
                                        src={require('../assets/ios-badge.png')}
                                        style={{ objectFit: 'contain', height: 100 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

Mobile.propTypes = propTypes;
Mobile.defaultProps = defaultProps;

export default Mobile;
