import React from 'react';
import FILTER_TYPE from '../../../util/messageType';
import styled from 'styled-components';

import { AuthContext } from '../../../util/context';
import { GREEN, PALE_WHITE_4, PALE_WHITE_6, PALE_WHITE_8, PRIMARY_COLOR_6, PRIMARY_COLOR_8, WHITE } from '../../../appearance/Colors';
import {
    IoIosClose,
    IoIosImage,
    IoIosDocument,
    IoIosStar,
    IoIosCheckmarkCircleOutline,
    IoIosBookmark
} from 'react-icons/io';
import { IoBookmarkOutline, IoImageOutline, IoDocumentOutline, IoStarOutline } from 'react-icons/io5';
import { FaRegStickyNote } from 'react-icons/fa';
import { FiMessageSquare } from 'react-icons/fi';

function FilterComponent({ }) {
    const {
        state: { folderFilterType, searchFolderFilterType, searchActive },
        context: { setFolderFilter, setSearchFolderFilter }
    } = React.useContext(AuthContext);

    const FilterPill = ({ title, type, icon }) => {
        if (!searchActive) {
            return (
                <FilterPillStyled
                    active={folderFilterType.indexOf(type) > -1}
                    type={type}
                    onClick={() => {
                        let index = folderFilterType.indexOf(type);
                        if (index > -1) {
                            folderFilterType.splice(index, 1);
                        } else {
                            folderFilterType.push(type);
                        }

                        setFolderFilter(folderFilterType);
                    }}
                >
                    {icon ? <div style={{ height: 16, width: 16, marginRight: 4, flex: 1 }}>{icon}</div> : null}
                    <span style={{ lineHeight: '16px', flex: 1, whiteSpace: 'nowrap' }}>{title}</span>
                    {folderFilterType.indexOf(type) > -1 ? (
                        <div style={{ height: 20, width: 20 }}>
                            <IoIosClose size={20} />
                        </div>
                    ) : null}
                </FilterPillStyled>
            );
        } else {
            return (
                <FilterPillStyled
                    active={searchFolderFilterType.indexOf(type) > -1}
                    light
                    type={type}
                    onClick={() => {
                        let index = searchFolderFilterType.indexOf(type);
                        if (index > -1) {
                            searchFolderFilterType.splice(index, 1);
                        } else {
                            searchFolderFilterType.push(type);
                        }

                        setSearchFolderFilter(searchFolderFilterType);
                    }}
                >
                    {icon ? <div style={{ height: 16, width: 16, marginRight: 4, flex: 1 }}>{icon}</div> : null}
                    <span style={{ lineHeight: '16px', flex: 1, whiteSpace: 'nowrap' }}>{title}</span>
                    {searchFolderFilterType.indexOf(type) > -1 ? (
                        <div style={{ height: 20, width: 20 }}>
                            <IoIosClose size={20} />
                        </div>
                    ) : null}
                </FilterPillStyled>
            );
        }
    };

    return (
        <div
            style={{
                // marginBottom: 10,
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            {/* <FilterPill title="Links">Links</FilterPill> */}
            <FilterPill title="Text" type={FILTER_TYPE.TEXT} icon={<FiMessageSquare size={14} />} />

            {/* Simpler version for text but not going for this one */}
            {/* <FilterPill title="Text" type={FILTER_TYPE.TEXT_SOLO} icon={<FaRegStickyNote size={14} />} /> */}

            <FilterPill title="Notes" type={FILTER_TYPE.NOTE} icon={<FaRegStickyNote size={14} />} />

            <FilterPill title="Tasks" type={FILTER_TYPE.CHECK} icon={<IoIosCheckmarkCircleOutline size={16} />} />


            <FilterPill title="Links" type={FILTER_TYPE.META_URL} icon={<IoBookmarkOutline size={16} />} />

            <FilterPill title="Stars" type={FILTER_TYPE.STAR} icon={<IoStarOutline size={16} />} />

            {/* TBD when multiple images are there */}
            {/* <FilterPill title="Solo Images" type={FILTER_TYPE.IMAGE_SOLO} icon={<IoDocumentOutline size={16} />} /> */}

            <FilterPill title="Images" type={FILTER_TYPE.IMAGE} icon={<IoImageOutline size={16} />} />

            {/* TBD when multiple files are there */}
            {/* <FilterPill title="Solo Files" type={FILTER_TYPE.FILE_SOLO} icon={<IoDocumentOutline size={16} />} /> */}

            <FilterPill title="Files" type={FILTER_TYPE.FILE} icon={<IoDocumentOutline size={16} />} />

            {/* <FilterPill title="Audio">Audio</FilterPill> */}
            {/* <FilterPill title="Reset">Reset</FilterPill> */}
        </div>
    );
}

const FilterPillStyled = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    background-color: ${props => (props.active ? GREEN : !props.light ? PRIMARY_COLOR_6 : PALE_WHITE_4)};
    border-radius: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => (props.active ? WHITE : 'transparent')};
    padding-left: 16px;
    padding-right: ${props => (props.active ? '8px' : '16px')};
    // padding-top: 4px;
    // padding-bottom: 4px;
    cursor: pointer;
    color: white;
    margin-left: 10px;
    height: 32px;
    backdrop-filter: saturate(180%) blur(20px);
    &:hover {
        background-color: ${props => (props.active ? GREEN : !props.light ? PRIMARY_COLOR_8 : PALE_WHITE_6)};
    }
`;

export default FilterComponent;
