import { ApolloLink } from 'apollo-link';
import { changeSubscriptionToken } from '../App';

export const jwtHeadersAfterware = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const context = operation.getContext();
        const {
            response: { headers }
        } = context;

        if (headers) {
            const token = headers.get('x-token');

            if (token) {
                localStorage.setItem('token', token);
                console.log('how many times do you start', token);
                changeSubscriptionToken(token);
            }
        }
        return response;
    });
});
