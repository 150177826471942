import React from 'react';
import ServerErrorPage from '../../screens/ErrorScreen/ServerErrorPage';
import NotFoundPageErrorPage from '../../screens/ErrorScreen/NotFoundPageErrorPage';
import UnauthorizedErrorPage from '../../screens/ErrorScreen/UnauthorizedErrorPage';
// import {
//   ServerError,
//   AbsenceError,
//   AuthorizationError,
//   ResourceNotFound
// } from '../errors';

class GraphqlErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }

    componentDidCatch(error) {
        console.log('catching in GraphqlErrorBoundary', error);
        // if ( error.name === AuthorizationError.name ) {
        //   this.setState({ error: AuthorizationError.name });
        // } else if ( error.name === ServerError.name ) {
        //   this.setState({ error: ServerError.name });
        // } else if ( error.name === ResourceNotFound.name ) {
        //   this.setState({ error: ResourceNotFound.name });
        // } else {
        //   this.setState({ error: ServerError.name });
        // }
    }

    render() {
        // if (this.state.error === ServerError.name ) {
        //   return <ServerErrorPage />
        // } else if (this.state.error === AuthorizationError.name) {
        //   return <UnauthorizedErrorPage />
        // } else if (this.state.error === ResourceNotFound.name) {
        //   return <NotFoundErrorPage />
        // }
        return this.props.children;
    }
}

export default GraphqlErrorBoundary;
