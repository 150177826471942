import React, { Component } from 'react';

import styled from 'styled-components';
import * as COLOR from '../appearance/Colors';

import { REDIRECT_TO_HOME, REDIRECT_TO_LOGIN } from '../errors/errors';

import DnDComponent from '../components/DnDComponent';

import ReactNotification from 'react-notifications-component';
import '../CustomNotification.scss';

import { withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';

export class Home extends Component {
    componentDidCatch(errors) {
        console.log('componentDidCatch', errors);
        if (errors && errors.length) {
            errors.forEach(error => {
                if (error.extensions.code === REDIRECT_TO_HOME) {
                    // do something
                } else if (error.extensions.code === REDIRECT_TO_LOGIN) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    this.props.history.push('/login');
                } else {
                    this.props.history.push('/error');
                }
            });
        } else {
            // console.log('Just a normal error', errors);
            // console.log('Error on componentDidCatch', errors);
        }
    }

    render() {
        return (
            <Main>
                <Helmet>
                    <title>Luckynote</title>
                    <meta
                        name="description"
                        content="Message yourself notes, documents, bookmarks and web content. Everything in lucky note can be converted to a task or a todo. Message yourself like on whatsapp."
                    />
                </Helmet>
                <ReactNotification />
                <DnDComponent
                    passingErrors={value => {
                        // throw value;
                    }}
                />
            </Main>
        );
    }
}

const Main = styled.div`
    background-color: ${COLOR.PRIMARY_COLOR};
    height: 100%;
`;

export default withRouter(Home);
