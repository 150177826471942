import React, { useState, useEffect, useRef } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';

import { QUERY } from '../gql-operations';

import Select from 'react-select';

import useRedirectErrors from '../errors/useRedirectErrors';
import { PALE_WHITE_2, PALE_WHITE_4, PALE_WHITE_6, SECONDARY_COLOR, WHITE } from '../appearance/Colors';
import chroma from 'chroma-js';

function SelectFolderComponent({ onSelectedOption, currentOption, ...rest }) {
    const [selectedOption, setSelectedOption] = useState(currentOption);
    const [folders, setFolders] = useState([]);

    const selectRef = useRef(null);

    const { passingErrors } = useRedirectErrors();

    const [
        findFolders,
        { data: findFolderData, error: findFolderError, loading: findFolderLoading, client }
    ] = useLazyQuery(QUERY.FIND_FOLDERS, {
        fetchPolicy: 'network-only',
        onError: res => {
            passingErrors(res);
        },
        onCompleted: res => {
            let newFolders = [...res.findFolders.folders];
            setUpNewFolders(newFolders);
        }
    });

    const setUpNewFolders = folders => {
        let newFolders = folders.map(folder => {
            let path = folder.path ? ` \n(${folder.path}) ` : '';
            return {
                value: folder.id,
                label: folder.title + path
            };
        });
        setFolders(newFolders);
        return newFolders;
    };

    const makeOptionsList = () => {
        if (findFolderData) {
            let newList = findFolderData.findFolders.folders.map(folder => {
                return {
                    value: folder.id,
                    label: folder.title
                };
            });
            return newList;
        }
    };

    const getAllFolders = () => {
        findFolders({
            variables: {
                data: {
                    text: ''
                }
            }
        });
    };

    const handleChange = async selectedOption => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            onSelectedOption({
                id: selectedOption.value,
                title: selectedOption.label
            });
        } else {
            onSelectedOption(null);
        }
    };

    const handleInputChange = newValue => {
        findFolders({
            variables: {
                data: {
                    text: newValue
                }
            }
        });
    };

    return (
        <Select
            // styles={{
            //   container: (provided, state) => ({
            //     marginBottom: 12
            //   })
            // }}
            ref={selectRef}
            placeholder="Search for folder..."
            isClearable={true}
            onInputChange={handleInputChange}
            value={selectedOption}
            maxMenuHeight={200}
            styles={customStyles}
            autoFocus
            blurInputOnSelect
            onKeyDown={e => {
                // TODO not sure about if this is the best way
                if (e.key === 'Escape') {
                    if (selectRef.current.select.props.inputValue.length === 0) {
                        // to make it close the whole thing
                        // onblur();
                    }
                }
            }}
            onChange={handleChange}
            options={folders}
            {...rest}
        />
    );
}

const customStyles = {
    placeholder: (styles, state) => ({
        ...styles,
        color: PALE_WHITE_4
    }),
    input: (styles, state) => ({
        ...styles,
        color: WHITE
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? PALE_WHITE_6 : PALE_WHITE_4
    }),
    menu: (styles, state) => ({
        ...styles,
        background: SECONDARY_COLOR,
        color: WHITE
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                ? PALE_WHITE_6
                : isFocused
                ? chroma(PALE_WHITE_6)
                      .alpha(0.2)
                      .css()
                : null,

            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled &&
                    (isSelected
                        ? PALE_WHITE_2
                        : chroma(PALE_WHITE_2)
                              .alpha(0.3)
                              .css())
            }
        };
    },
    // container: (base, state) => ({
    //     ...base,
    //     border: state.isFocused ? null : null,
    //     transition: 'border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease',
    //     '&:hover': {
    //         boxShadow: '0 2px 4px 0 rgba(41, 56, 78, 0.1)'
    //     }
    // }),
    control: (base, state) => ({
        ...base,
        background: SECONDARY_COLOR,
        color: WHITE,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? PALE_WHITE_6 : PALE_WHITE_4,
        '&:hover': { borderColor: PALE_WHITE_6 } // border style on hover
    }),
    // valueContainer: (base, state) => ({
    //     ...base
    // }),
    singleValue: (provided, state) => ({
        ...provided,
        color: WHITE,
        lineHeight: 24
    })
};

export default SelectFolderComponent;
