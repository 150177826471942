export const REDIRECT_TO_HOME = 'REDIRECT_TO_HOME';
export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';
export const MAX_MESSAGES_REACHED = 'MAX_MESSAGES_REACHED'
export const MAX_STORAGE_REACHED = 'MAX_STORAGE_REACHED'

// const formatError = (error) => {
//     const { constructor } = error.originalError;

//     let code;

//     switch (constructor) {
//       case AuthorizationError:
//         code = 'authorization_error';
//       case ResourceNotFound:
//         code = 'resource_not_found';
//       default:
//         code = 'server_error';
//     }

//     return {
//       extensions: {
//         code
//       },
//       ...error
//     };
//   };

//   app.use('/graphql', (req, res) => graphqlHTTP({
//     schema,
//     graphiql: config.graphiql,
//     context: { req, res },
//     formatError
//   })(req, res));
