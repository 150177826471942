import React from 'react';
import { IoIosImage } from 'react-icons/io';
import { WHITE, PRIMARY_COLOR } from './Colors';

function FileIcon({ size, color, ...rest }) {
    return (
        <div
            style={{
                backgroundColor: 'cornflowerblue',
                justifyContent: 'center',
                height: 40,
                width: 40,
                alignItems: 'center',
                marginRight: 8,
                display: 'flex',
                borderRadius: 50
            }}
        >
            <IoIosImage
                size={size ? size : 24}
                color={color ? color : WHITE}
                {...rest}
            ></IoIosImage>
        </div>
    );
}

export default FileIcon;
