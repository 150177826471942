import React, { useState, useEffect } from 'react';

import { FaRegCheckCircle, FaRegCircle, FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import { IoCheckmarkCircle, IoRadioButtonOffOutline } from 'react-icons/io5';

import { useQuery, useMutation } from '@apollo/react-hooks';

import * as COLOR from '../../../appearance/Colors';

import { QUERY, MUTATION } from '../../../gql-operations';
import styled, { css } from 'styled-components';

import { AuthContext } from '../../../util/context';
import useUpdateCheckboxes from '../../../hooks/useUpdateCheckboxes';
import customAnalytics from '../../../util/custom-analytics';

function CheckboxSection({ message, height, hide, disabled, customShowPlus }) {
    const [showPlus, setShowPlus] = useState(false);
    const [messageHeight, setMessageHeight] = useState(0);

    const { updateCheckboxes, spliceFromCheckboxes } = useUpdateCheckboxes();

    const {
        state: { holdingKeys, keys }
    } = React.useContext(AuthContext);

    // useEffect(() => {
    //     if (height.current) {
    //         setMessageHeight(height.current.offsetHeight);
    //     }
    //     return () => {};
    // }, [height]);
    // const MESSAGE_HEIGHT = height.current ? height.current.offsetHeight : 0;

    const metaActive = holdingKeys && (keys.Meta || keys.Control);

    const refetchMessage = message => {
        return [
            {
                query: QUERY.GET_MESSAGE,
                variables: {
                    data: {
                        id: message.id
                    }
                }
            }
        ];
    };

    const [addCheckbox] = useMutation(MUTATION.ADD_CHECKBOX, {
        variables: {
            data: {
                messageId: message.id
            }
        },
        update: async (store, { data }) => {
            updateCheckboxes([message]);
        },
        refetchQueries: refetchMessage(message),
        onCompleted: res => {
            customAnalytics.message_edit({
                "action": "Task"
            })
        }
    });

    const [checkMessage] = useMutation(MUTATION.CHECK_MESSAGE, {
        variables: {
            data: {
                messageId: message.id
            }
        },
        update: async (store, { data }) => {
            spliceFromCheckboxes([message]);
        },
        refetchQueries: refetchMessage(message),
        onCompleted: res => {
            customAnalytics.message_edit({
                "action": "Check"
            })
        }
    });
    const [uncheckMessage] = useMutation(MUTATION.UNCHECK_MESSAGE, {
        variables: {
            data: {
                messageId: message.id
            }
        },
        update: async (store, { data }) => {
            updateCheckboxes([message]);
        },
        refetchQueries: refetchMessage(message),
        onCompleted: res => {
            customAnalytics.message_edit({
                "action": "Uncheck"
            })
        }
    });

    const [removeCheckbox] = useMutation(MUTATION.REMOVE_CHECKBOX, {
        variables: {
            data: {
                messageId: message.id
            }
        },
        update: async (store, { data }) => {
            spliceFromCheckboxes([message]);
        },
        refetchQueries: refetchMessage(message),
        onCompleted: res => {
            customAnalytics.message_edit({
                "action": "Untask"
            })
        }
    });

    if (!hide) {
        if (message.isCheckbox) {
            if (message.isCheckbox && metaActive) {
                return (
                    <IconContainer
                        onClick={e => {
                            e.stopPropagation();
                            console.log('Removing checkbox');
                            removeCheckbox();
                        }}
                        disabled={disabled}
                        style={{ height: '100%' }}
                    >
                        <FaRegTimesCircle size={18} color={COLOR.WHITE} />
                    </IconContainer>
                );
            } else if (message.isChecked) {
                return (
                    <IconContainer
                        onClick={e => {
                            e.stopPropagation();
                            console.log('Clicking only on checkbox');
                            uncheckMessage();
                        }}
                        disabled={disabled}
                        style={{ height: '100%' }}
                    >
                        <IoCheckmarkCircle size={22} color={COLOR.WHITE} />
                    </IconContainer>
                );
            } else {
                return (
                    <IconContainer
                        onClick={e => {
                            e.stopPropagation();
                            console.log('Clicking only on checkbox');
                            checkMessage();
                        }}
                        disabled={disabled}
                        style={{ height: '100%' }}
                    >
                        <IoRadioButtonOffOutline size={22} color={COLOR.WHITE} />
                    </IconContainer>
                );
            }
        } else {
            return (
                <IconContainer
                    onMouseEnter={() => setShowPlus(true)}
                    onMouseLeave={() => {
                        setShowPlus(false);
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        addCheckbox();
                    }}
                    disabled={disabled}
                    style={{ height: '100%' }}
                >
                    {(customShowPlus ? customShowPlus : showPlus) ? (
                        <FaPlusCircle size={18} color={COLOR.WHITE}></FaPlusCircle>
                    ) : null}
                </IconContainer>
            );
        }
    } else {
        // empty container
        return <IconContainer />;
    }
}

const IconContainer = styled.div`
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    // height: ${props => `${props.height}px` || '0'};

    ${props =>
        props.disabled &&
        css`
                  pointer-events: none;
              `
    };
`;

export default CheckboxSection;
