import { gql } from 'apollo-boost';

// input TrashMessagesInput {
//     messageIds: [ID!]!
// }
export const TRASH_MESSAGES = gql`
    mutation($data: TrashMessagesInput!) {
        trashMessages(data: $data)
    }
`;
