import { gql } from 'apollo-boost';

// input SignS3Input {
//     fileName: String!
//     fileType: String!
//     fileSize: Number
// }

export const S3_SIGN = gql`
    mutation($data: SignS3Input!) {
        signS3(data: $data) {
            url
            signedRequest
        }
    }
`;
