import React from 'react';
import { AuthContext } from '../util/context';

function useUpdateFolderDataOnTree() {
    const {
        state: { treeData, selectedFolder },
        context: { setNewTree, setSelectedFolder }
    } = React.useContext(AuthContext);

    const runThis = folderObject => {
        let newTreeCopy = { ...treeData };

        console.log('updating on a tree', folderObject);

        const folderInTree = newTreeCopy.items[folderObject.id];
        if (folderInTree) {
            folderInTree.data = folderObject;

            setNewTree(newTreeCopy);
        }

        // update the selectedFolder
        if (selectedFolder.id === folderObject.id) {
            setSelectedFolder(folderObject);
        }
    };

    return [runThis];
}

export default useUpdateFolderDataOnTree;
