import { useQuery } from '@apollo/react-hooks';
import { QUERY } from '../gql-operations';

function useGetMaxFileSize() {
    const { data } = useQuery(QUERY.ME, {
        fetchPolicy: 'network-only'
    });
    let user_max_file_size;
    if (data && data.me) {
        user_max_file_size = data.me.quota.maxFileSize;
    }
    return { user_max_file_size };
}

export default useGetMaxFileSize;
