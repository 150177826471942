import { gql } from 'apollo-boost';

// input MoveMessagesInput {
//     toFolder: ID!
//     messageIds: [ID!]!
// }

export const MOVE_MESSAGES = gql`
    mutation($data: MoveMessagesInput!) {
        moveMessages(data: $data)
    }
`;
