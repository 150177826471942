import { gql } from 'apollo-boost';

// input LoginInput {
// 	email: String!
// 	password: String!
// }

export const LOGIN = gql`
    mutation($data: LoginInput!) {
        login(data: $data) {
            __typename
            ... on AuthPayload {
                token
                refreshToken
            }
            ... on LoginInvalidInputError {
                message
            }
            ... on UserNotFound {
                message
            }
            ... on PasswordsDoNotMatch {
                message
            }
            ... on UserNotVerified {
                message
            }
            ... on UserLocked {
                message
            }
        }
    }
`;
