import { QUERY } from '../../../gql-operations';

function loadMoreFilteredMessages(searchTerm, currentType, folder, cursor, isTrash, filterFetchMore, setLoadMoreCheck) {
    filterFetchMore({
        query: QUERY.FILTER_MESSAGES,
        variables: {
            data: {
                text: searchTerm,
                type: currentType,
                folderId: folder ? folder.id : '',
                cursor: cursor,
                isTrash: isTrash
            }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
            // console.log('This is previousRes', previousResult);
            // console.log('This is fetch more', fetchMoreResult);
            const previousEntry = previousResult.filterMessages.messages;

            // case of the first message in the folder
            if (!previousResult.filterMessages.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            // if there is no fetchMore, just return the previous data
            if (!fetchMoreResult.filterMessages.cursor) {
                setLoadMoreCheck(false);
                return previousResult;
            }

            // cursor is the same
            if (previousResult.filterMessages.cursor === fetchMoreResult.filterMessages.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            const newMessages = fetchMoreResult.filterMessages.messages;
            const newCursor = fetchMoreResult.filterMessages.cursor;
            
            setLoadMoreCheck(false);
            return {
                filterMessages: {
                    messages: [...(previousEntry && previousEntry.length > 0 ? previousEntry : []), ...newMessages],
                    cursor: newCursor,
                    __typename: previousResult.filterMessages.__typename
                }
            };
        }
    });
}

export default loadMoreFilteredMessages;
