import React, { useState, useRef, useEffect, useReducer } from 'react';
import loadMoreMessages from './loadMoreMessages';
import loadMoreFilteredMessages from './loadMoreFilteredMessages';
import loadMoreFindMessages from './loadMoreFindMessages';

import { Waypoint } from 'react-waypoint';

import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';

import DefaultMessage from '../../Messages/DefaultMessage';
import ImageCarousel from '../Components/ImageCarousel';
import ScrollToBottomButton from '../Components/ScrollToBottomButton';

import NewFolderCard from '../../../components/NewFolderCard';
import styled, { css } from 'styled-components';

import { AuthContext } from '../../../util/context';
import useCheckBrowser from '../../../util/useCheckBrowser';
import { useScrollToBottom } from '../../../hooks/useScrollToBottom';
import useDeleteMessagesMutation from '../../../hooks/useDeleteMessagesMutation';
import {
    PRIMARY_COLOR_4,
    SECONDARY_TEXT,
    PRIMARY_COLOR_6,
    RED,
    PALE_WHITE_6,
    PALE_WHITE_8,
    PALE_WHITE_2,
    PALE_WHITE,
    WHITE,
    RED_OP,
    GREEN_OP,
    PRIMARY_COLOR_8
} from '../../../appearance/Colors';

import CreateFolderModal from '../../Modals/CreateFolderModal';
import DeleteModal from '../../Modals/DeleteModal';
import SearchFoldersModal, { searchActions } from '../../Modals/SearchFoldersModal';

import { format, differenceInDays } from 'date-fns';

import _ from 'lodash';
import PathFolder from '../../PathFolder';
import { HIERARCHY } from '../../../util/specialFolderEnums';
import customAnalytics from '../../../util/custom-analytics';

function MessagesList({
    payload,
    filterMessages,
    filterMessagesCursor,
    fetchMore,
    fetchFindMore,
    filterFetchMore,
    searchFetchMore,
    selectMessage,
    enableShiftSelect,
    selectedMessagesMap,
    messageFeatures,
    isTrash,
    screenName
}) {
    const messageContainerRef = useRef();
    const [scrollToBottom, endRef] = useScrollToBottom();
    const [currentMessage, setCurrentMessage] = useState();
    const [dropdownMessage, setDropdownMessage] = useState(undefined);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const list = useRef();
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);
    const [loadMoreCheck, setLoadMoreCheck] = useState(true);
    const [filteredLoadMoreCheck, setfilteredLoadMoreCheck] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSearchFoldersModal, setShowSearchFoldersModal] = useState(false);

    const { messages, cursor, folders, parent, folder } = payload;

    const { Browser, Platform, PLATFORMS } = useCheckBrowser();
    const [deleteMessages] = useDeleteMessagesMutation();
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const [page, setPage] = useState(0);
    const [filteredPage, setFilteredPage] = useState(0);
    const [loader, setLoader] = useState(false);
    const [initialLoader, setInitialLoader] = useState(true);

    const {
        state: {
            selectedFolder,
            selectedMessage,
            folderFilterType,
            holdingKeys,
            keys,
            showMultiSelect,
            homeFolder,
            searchTerm,
            searchActive,
            searchFolderFilterType
        },
        context: {
            setSelectedFolder,
            setSelectedMessage,
            setFolderFilter,
            setMessagesToSend,
            setSelectedMessagesMap,
            setShowMultiSelect,
            setSearchFolderFilter
        }
    } = React.useContext(AuthContext);

    const loadMore = async () => {
        // this is in case that for Hierarchy folder because it's a different fetch
        // and we're searching for text and folder
        setLoader(true);
        if (searchActive && searchFetchMore) {
            loadMoreFilteredMessages(
                searchTerm,
                searchFolderFilterType,
                folder,
                cursor,
                isTrash,
                searchFetchMore,
                setLoadMoreCheck
            );
        } else if (fetchFindMore) {
            let text = ''; // searchTerm ? searchTerm : '';
            let folder = ''; // searchGlobal || selectedFolder.id === HIERARCHY ? '' : selectedFolder.id;
            loadMoreFindMessages(text, folder, cursor, isTrash, fetchFindMore, setLoadMoreCheck);
        } else {
            loadMoreMessages(folder, cursor, isTrash, fetchMore, setLoadMoreCheck);
        }
        setLoader(false);
    };

    useEffect(() => {
        if (Boolean(messages) && folderFilterType.length === 0) {
            setInitialLoader(false);
        }
        if (Boolean(filterMessages) && folderFilterType.length > 0) {
            setInitialLoader(false);
        }
        if (Boolean(messages) && searchFolderFilterType.length > 0) {
            setInitialLoader(false);
        }
    }, [messages, filterMessages, folderFilterType, searchFolderFilterType]);

    useEffect(() => {
        // with every folder switch these need to reset
        setInitialLoader(true);
        setLoadMoreCheck(true);
        setfilteredLoadMoreCheck(true);
    }, [selectedFolder, folderFilterType, searchFolderFilterType]);

    useEffect(() => {
        // just so the titleRef can rerender
        forceUpdate();
    }, [selectedMessage]);

    const loadMoreFiltered = () => {
        loadMoreFilteredMessages(
            searchActive ? searchTerm : '',
            searchActive ? searchFolderFilterType : folderFilterType,
            folder,
            searchActive ? cursor : filterMessagesCursor,
            isTrash,
            searchActive ? searchFetchMore : filterFetchMore,
            setfilteredLoadMoreCheck
        );
    };

    // const renderMultiSelectButtons = message => {
    //     if (Object.keys(selectedMessagesMap).length > 0 || showMultiSelect) {
    //         return (
    //             <div
    //                 style={{ cursor: 'pointer' }}
    //                 onClick={() => {
    //                     selectMessage(message);
    //                 }}
    //             >
    //                 {selectedMessagesMap[message.id] ? (
    //                     <FaRegCheckCircle style={{ height: 20, width: 20, color: 'white' }}></FaRegCheckCircle>
    //                 ) : (
    //                     <FaRegCircle style={{ height: 20, width: 20, color: 'white' }}></FaRegCircle>
    //                 )}
    //             </div>
    //         );
    //     }
    //     return null;
    // };

    const renderFolders = () => {
        let margin = 0;
        if (isTrash) {
            if (payload.path && payload.path.length === 0) {
                margin = 8;
            }
            if (folders && folder.length > 0) {
                margin = 8;
            }
        }
        if (folders) {
            const localSort = [...folders];
            localSort.sort((a, b) => a.title.localeCompare(b.title, 0, { caseFirst: 'lower' }));
            return (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: 40,
                            transform:
                                Browser === 'Firefox' && Platform === PLATFORMS.MACINTOSH ? 'scaleY(-1)' : 'scaleY(1)',
                            marginBottom: margin
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                                maxWidth: 500
                            }}
                        >
                            {localSort.map(folder => {
                                return (
                                    <div
                                        key={folder.id}
                                        style={{
                                            backgroundColor: PRIMARY_COLOR_8,
                                            borderRadius: 4,
                                            marginBottom: 4,
                                            marginRight: 8,
                                            maxWidth: 150,
                                            // transition: 'backdrop-filter 0.2s',
                                            // backdropFilter: 'saturate(180%) blur(20px)'
                                        }}
                                        onClick={() => {
                                            setSelectedFolder(folder);
                                            customAnalytics.subfolder_open();
                                        }}
                                    >
                                        <NewFolderCard item={{ data: folder }} disableRightIcon></NewFolderCard>
                                    </div>
                                );
                            })}
                            {!selectedFolder.deletedAt &&
                                !isTrash &&
                                selectedFolder &&
                                selectedFolder.id !== (homeFolder && homeFolder.id) ? (
                                <div
                                    key={selectedFolder.id}
                                    style={{
                                        backgroundColor: PRIMARY_COLOR_8,
                                        borderRadius: 4,
                                        marginBottom: 4,
                                        marginRight: 8,
                                        // transition: 'backdrop-filter 0.2s',
                                        // backdropFilter: 'saturate(180%) blur(20px)'
                                    }}
                                    onClick={() => {
                                        setShowCreateFolderModal(true);
                                    }}
                                >
                                    <NewFolderCard
                                        item={{ data: { id: selectedFolder.id, title: '+ Add folder' } }}
                                        disableRightIcon
                                        disableDrop
                                        disableDrag
                                    ></NewFolderCard>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderParentAndPath = () => {
        let margin = 0;
        if (isTrash) {
            if (payload.path && payload.path.length > 0) {
                margin = 8;
            }
        }
        if (payload.path && payload.path.length > 0) {
            return (
                <ParentFolderWrapper
                    style={{
                        transform:
                            Browser === 'Firefox' && Platform === PLATFORMS.MACINTOSH ? 'scaleY(-1)' : 'scaleY(1)',
                        marginBottom: margin
                    }}
                >
                    {payload.path.map((folder, index) => {
                        return (
                            <div key={folder.id} style={{ display: 'flex', alignItems: 'center' }}>
                                <PathFolder
                                    folder={folder}
                                    onClick={() => {
                                        // here we is a full parent object from the server
                                        setSelectedFolder(folder);
                                    }}
                                ></PathFolder>

                                {index < payload.path.length - 1 ? (
                                    <span style={{ marginLeft: 2, marginRight: 2, color: 'white' }}>/</span>
                                ) : null}
                                {index === payload.path.length - 1 ? (
                                    <div style={{ color: 'white' }}>
                                        <span>/</span>
                                        <CurrentFolder>{payload.folder.title}</CurrentFolder>
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </ParentFolderWrapper>
            );
        }
        return null;
    };

    const renderDateSeparator = (dateSeparator, i) => {
        return (
            <SeparatorContainer browser={Browser} platform={Platform} style={{ zIndex: -i }} key={i}>
                <div
                    style={{
                        backgroundColor: PRIMARY_COLOR_4,
                        borderRadius: 20,
                        padding: '2px 8px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 4,
                        backdropFilter: 'saturate(180%) blur(20px)'
                    }}
                >
                    <span style={{ color: PALE_WHITE_8, fontWeight: '500', fontSize: 12 }}>
                        {format(dateSeparator, 'dd MMM')}
                    </span>
                </div>
            </SeparatorContainer>
        );
    };

    const renderDateSection = (message, i) => {
        let date = format(new Date(message.createdAt), 'HH:mm');
        // if (messageContainerRef.current && messageContainerRef.current.offsetWidth > 500) {
        return (
            <div
                onClick={e => {
                    e.stopPropagation();
                    selectMessage(message, i);
                }}
                style={{
                    color: PALE_WHITE_6,
                    marginRight: 16,
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    // minWidth: 100,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span>{date}</span>
            </div>
        );
        // }
    };

    const renderMessages = (messages, filter) => {
        let tempMessages = [];
        if (Boolean(messages) && messages.length > 0) {
            let i = 0;
            let messageCount = messages.length;

            while (i < messageCount) {
                let previous = messages[i - 1];
                let current = messages[i];

                let currentMoment = new Date(current.createdAt);

                let currentMoment00 = new Date(
                    currentMoment.getFullYear(),
                    currentMoment.getMonth(),
                    currentMoment.getDay(),
                    0,
                    0,
                    0
                );

                if (previous) {
                    let previousMoment = new Date(previous.createdAt);
                    let previousMoment00 = new Date(
                        previousMoment.getFullYear(),
                        previousMoment.getMonth(),
                        previousMoment.getDay(),
                        23,
                        59,
                        0
                    );

                    let previousDuration = differenceInDays(previousMoment00, currentMoment00);

                    if (previousDuration !== 0) {
                        tempMessages.push(renderDateSeparator(previousMoment, i));
                    }
                }

                tempMessages.push(renderMessageContainer(current, i, filter));

                // Proceed to the next message.
                i += 1;
            }
            return tempMessages;
        } else {
            if (initialLoader) {
                return (
                    <LoadingMessages browser={Browser} platform={Platform}>
                        Loading messages...
                    </LoadingMessages>
                );
            } else {
                return renderNoMessages();
            }
        }
    };

    const renderMessageContainer = (message, i, filter) => {
        let openMenu = false;
        return (
            <div
                id={message.id}
                key={message.id}
                style={{
                    transform: Browser === 'Firefox' && Platform === PLATFORMS.MACINTOSH ? 'scaleY(-1)' : 'scaleY(1)',
                    zIndex: -i
                }}
            >
                <MessageContainer
                    ref={messageContainerRef}
                    key={message.id}
                    style={selectedMessagesMap[message.id] ? { backgroundColor: GREEN_OP, zIndex: -i } : null}
                    onClick={(e) => {
                        if (!holdingKeys && !keys.Meta) {
                            if (Object.keys(selectedMessagesMap).length > 0 || showMultiSelect) {
                                selectMessage(message, i, enableShiftSelect ? e.shiftKey : false);
                            } else {
                                setSelectedMessage(message);
                            }
                        }
                    }}
                >
                    <DefaultMessage
                        index={i}
                        screenName={screenName}
                        message={message}
                        // multipleSelect={() => {
                        //     selectMessage(message, i);
                        // }}
                        imagePreview={message => {
                            setLightboxIsOpen(true);
                            setCurrentMessage(message);
                        }}
                        messageFeatures={messageFeatures}
                        showDeleteModal={message => {
                            if (message) {
                                setDropdownMessage(message);
                                setShowDeleteModal(true);
                            }
                        }}
                        showMoveMessageModal={message => {
                            if (message) {
                                setDropdownMessage(message);
                                setShowSearchFoldersModal(true);
                            }
                        }}
                        openMenu={val => {
                            let element = document.getElementById(message.id);
                            if (val && element.style) {
                                element.style.zIndex = 50;
                            } else {
                                element.style.zIndex = null;
                            }
                        }}
                    // selected={selectedMessagesMap[message.id]}
                    // isDropped={isDropped('some crazy name')}
                    />

                    {/* <div style={{ flex: 1 }}></div> */}
                    {/* {renderMultiSelectButtons(message)} */}
                    {renderDateSection(message, i)}
                </MessageContainer>
                {filter ? renderCustomWaypoint(i) : renderWaypoint(i)}
            </div>
        );
    };

    // const renderDefaultMessage = message => {
    //     return (
    // <DefaultMessage
    //     key={message.id}
    //     message={message}
    //     multipleSelect={() => {
    //         selectMessage(message, i);
    //     }}
    //     imagePreview={message => {
    //         setLightboxIsOpen(true);
    //         setCurrentMessage(message);
    //     }}
    //     messageFeatures={messageFeatures}
    //     // selected={selectedMessagesMap[message.id]}
    //     // isDropped={isDropped('some crazy name')}
    // />;
    //     );
    // };

    const renderLoader = () => {
        if (loadMoreCheck) {
            if (messages && messages.length > 10) {
                return (
                    <LoadingMessages browser={Browser} platform={Platform}>
                        Loading messages...
                    </LoadingMessages>
                );
            }
        }
    };

    const renderFilterLoader = () => {
        if (filteredLoadMoreCheck) {
            if (filterMessages && filterMessages.length > 10) {
                return (
                    <LoadingMessages browser={Browser} platform={Platform}>
                        Loading messages...
                    </LoadingMessages>
                );
            }
        }
    };

    const renderNoMessages = () => {
        return (
            <TurnOffContainer
                browser={Browser}
                platform={Platform}
                style={{
                    marginLeft: 40,
                    fontWeight: '600',
                    color: SECONDARY_TEXT,
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                No messages here
                {searchActive && searchFolderFilterType.length > 0 ? (
                    <ClearFilters
                        onClick={() => {
                            setSearchFolderFilter([]);
                        }}
                    >
                        <span>Clear filters</span>
                        {/* <TurnOff>(turn off)</TurnOff> */}
                    </ClearFilters>
                ) : null}
                {!searchActive && folderFilterType.length > 0 ? (
                    <ClearFilters
                        onClick={() => {
                            setFolderFilter([]);
                        }}
                    >
                        <span>Clear filters</span>
                        {/* <TurnOff>(turn off)</TurnOff> */}
                    </ClearFilters>
                ) : null}
            </TurnOffContainer>
        );
    };

    const renderCustomContent = () => {
        if (searchActive) {
            return (
                <Messages id="messagesList" browser={Browser} platform={Platform} ref={list}>
                    {/* reference for the end of the list */}
                    <div ref={endRef} />
                    {renderParentAndPath()}
                    {renderFolders()}
                    {renderMessages(messages)}
                    {/* {filterMessages && filterMessages.length
                        ? filterMessages.map((message, i) => {
                              // check for aws as well
                              let filter = true;
                              return renderMessageContainer(message, i, filter);
                          })
                        : renderNoMessages()} */}
                    {renderLoader()}
                </Messages>
            );
        } else if (folderFilterType && folderFilterType.length > 0) {
            // sorting to be able to send the message to the bottom of the change
            // becase of firefox magic with transform
            // filterMessages.sort(function compare(a, b) {
            //     var dateA = new Date(a.createdAt);
            //     var dateB = new Date(b.createdAt);
            //     return dateB - dateA;
            // });
            return (
                <Messages id="messagesList" browser={Browser} platform={Platform} ref={list}>
                    {/* reference for the end of the list */}
                    <div ref={endRef} />
                    {renderParentAndPath()}
                    {renderFolders()}
                    {renderMessages(filterMessages, true)}
                    {/* {filterMessages && filterMessages.length
                        ? filterMessages.map((message, i) => {
                              // check for aws as well
                              let filter = true;
                              return renderMessageContainer(message, i, filter);
                          })
                        : renderNoMessages()} */}
                    {renderFilterLoader()}
                </Messages>
            );
        } else {
            // sorting to be able to send the message to the bottom of the change
            // becase of firefox magic with transform
            // messages.sort(function compare(a, b) {
            //     var dateA = new Date(a.createdAt);
            //     var dateB = new Date(b.createdAt);
            //     return dateB - dateA;
            // });
            return (
                <Messages id="messagesList" browser={Browser} platform={Platform} ref={list}>
                    {/* reference for the end of the list */}
                    <div ref={endRef} />
                    {renderParentAndPath()}
                    {renderFolders()}
                    {renderMessages(messages)}
                    {/* {messages && messages.length
                        ? messages.map((message, i) => {
                              // check for aws as well
                              return renderMessageContainer(message, i);
                          })
                        : renderNoMessages()} */}
                    {renderLoader()}
                </Messages>
            );
        }
    };

    const renderWaypoint = i => {
        // console.log('waypoint entered', i);
        // console.log('hello', data.getFolderContent.messages.messages.length);
        // console.log('hello 3', data.getFolderContent.messages.messages.length - 3);
        if (messages.length > 2) {
            // console.log('this is the size');
            if (i === messages.length - 3) {
                return (
                    <Waypoint
                        onEnter={() => {
                            if (!loader) {
                                setPage(page + 1);
                                loadMore();
                            }
                        }}
                    />
                );
            } else {
                return null;
            }
        }
    };

    const renderCustomWaypoint = i => {
        if (i === filterMessages.length - 3) {
            return (
                <Waypoint
                    onEnter={() => {
                        if (!loader) {
                            setFilteredPage(filteredPage + 1);
                            loadMoreFiltered();
                        }
                    }}
                />
            );
        } else {
            return null;
        }
    };

    const renderImageCarousel = () => {
        let carouselMessages;
        if (folderFilterType && folderFilterType.length > 0 && filterMessages) {
            carouselMessages = filterMessages;
        } else {
            carouselMessages = messages;
        }
        return (
            <ImageCarousel
                isVisible={lightboxIsOpen}
                data={carouselMessages}
                currentMessage={currentMessage}
                closeModal={() => {
                    setLightboxIsOpen(false);
                }}
            ></ImageCarousel>
        );
    };

    const removeSelectedMessages = () => {
        // setSelectedMessagesMap({});
        // setMessagesToSend([]);
        setShowMultiSelect(false);
    };

    const deletePermanently = () => {
        // onDeselect();
        removeSelectedMessages();
        deleteMessages([dropdownMessage]);
        if (selectedMessage && selectedMessage.id === dropdownMessage.id) {
            setSelectedMessage(undefined);
        }
    };

    const renderModals = () => {
        return (
            <>
                <CreateFolderModal
                    currentFolder={selectedFolder}
                    isOpen={showCreateFolderModal}
                    subfolder
                    closeModal={value => {
                        setShowCreateFolderModal(false);
                        // if (value){
                        //     setSelectedFolder(value);
                        //     if (document.getElementById(value.id)) {
                        //         document.getElementById(value.id).scrollIntoView();
                        //     }
                        // }
                    }}
                />
                {/* MODALS */}
                <DeleteModal
                    isOpen={showDeleteModal}
                    title={`This action can't be undone`}
                    description="This message will be deleted and you will not going to be able to access it's content any longer."
                    closeModal={response => {
                        setShowDeleteModal(false);
                        if (response) {
                            deletePermanently();
                        }
                        // setTimeout(async () => {
                        // }, 100);
                    }}
                ></DeleteModal>

                <SearchFoldersModal
                    isOpen={showSearchFoldersModal}
                    title={`Move message to...`}
                    addNewMoveMessage
                    // action={searchActions.MOVE_FOLDER}
                    dropdownMessage={dropdownMessage}
                    closeModal={toFolder => {
                        setShowSearchFoldersModal(false);
                        // if (toFolder) {
                        //     moveMessages([dropdownMessage], toFolder, selectedFolder);
                        // }
                    }}
                ></SearchFoldersModal>
            </>
        );
    };

    const _handleScroll = ev => {
        // console.log('Scrolling!', ev);
        // console.log('Scrolling!', ev.srcElement.scrollTop);
        if (Browser !== 'Firefox' || Platform !== PLATFORMS.MACINTOSH) {
            if (ev.srcElement.scrollTop < -200) {
                if (!showScrollToBottom) {
                    setShowScrollToBottom(true);
                }
            } else {
                setShowScrollToBottom(false);
            }
        } else {
            if (ev.srcElement.scrollTop > 200) {
                if (!showScrollToBottom) {
                    setShowScrollToBottom(true);
                }
            } else {
                setShowScrollToBottom(false);
            }
        }
        // console.log('Scrolling!', ev.scrollTop);
    };

    React.useEffect(() => {
        list.current.addEventListener('scroll', _handleScroll);
        console.log('added scroll listener');
        return () => {
            if (list.current) {
                list.current.removeEventListener('scroll', _handleScroll);
                console.log('removed scroll listener');
            }
        };
    }, []);

    const renderScrollToBottom = () => {
        // if (document.getElementById('messagesList')) {
        // console.log(document.getElementById('messagesList').scrollHeight);
        // console.log(document.getElementById('messagesList').offsetTop);
        // console.log(document.getElementById('messagesList').offsetHeight);
        // let elementHeight = document.getElementById('messagesList').offsetHeight;
        // let scrollHeight = document.getElementById('messagesList').scrollHeight;

        // console.log('ieiql', list.current.scrollTop);

        return (
            <ScrollToBottomButton
                show={showScrollToBottom}
                onClick={() => {
                    console.log('do you scroll');
                    scrollToBottom();
                }}
            />
        );

        // }
    };

    return (
        <>
            {renderCustomContent()}
            {renderImageCarousel()}
            {renderScrollToBottom()}

            {renderModals()}
        </>
    );
}

const Messages = styled.div`
    display: flex;
    flex-grow: 1;
    overflow-y: scroll;
    flex-direction: column-reverse;
    transform: scaleY(1);
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            flex-direction: column;
            transform: scaleY(-1);
        `}
    padding-left: 10px;
    padding-right: 10px;
`;

const SeparatorContainer = styled.div`
    display: flex;
    // flex: 1;
    justify-content: center;
    margin-bottom: 16px;
    transform: scaleY(1);
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
            margin-top: 16px;
            margin-bottom: 0px;
        `}
`;

const LoadingMessages = styled.div`
    margin-left: 40px;
    font-weight: 600;
    margin-bottom: 16px;
    color: ${SECONDARY_TEXT};
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
            margin-top: 16px;
            margin-bottom: 0px;
        `}
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    margin-bottom: 14px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
`;

const ParentFolderWrapper = styled.div`
    margin-left: 40px;
    display: flex;
    flex-direction: row;
`;

const CurrentFolder = styled.span`
    margin-left: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
`;

const TurnOffContainer = styled.div`
    margin-bottom: 16px;
    transform: scaleY(1);
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
            margin-top: 16px;
            margin-bottom: 0px;
        `}
`;

const TurnOff = styled.span`
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        color: ${RED}!important;
    }
`;

const ClearFilters = styled.div`
    margin-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 4px;
    border: 1px solid ${PALE_WHITE_2};
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 14px;
    color: ${PALE_WHITE_6};
    background-color: ${RED_OP};
    &:hover {
        background-color: ${PALE_WHITE};
        color: ${WHITE};
    }
`;

export default MessagesList;
