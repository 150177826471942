import { useRef } from 'react';

export function useScrollToBottom() {
    const endRef = useRef(null);

    const scrollToBottom = () => {
        if(endRef.current) {
            endRef.current.scrollIntoView();
        }
    };

    return [scrollToBottom, endRef];
}
