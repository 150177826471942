import { gql } from 'apollo-boost';

// input UpdateUserDetailsInput {
//     fullName: String!
//     profilePictureUrl: String
// }

export const UPDATE_USER_DETAILS = gql`
    mutation($data: UpdateUserDetailsInput!) {
        updateUserDetails(data: $data) {
            id
            fullName
            profilePictureUrl
        }
    }
`;
