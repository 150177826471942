import React from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { QUERY } from '../gql-operations';
import { AuthContext } from '../util/context';

function useUpdateStarred() {
    const client = useApolloClient();

    const updateStarred = messages => {
        // console.log('update', messages);
        try {
            const data = client.readQuery({
                query: QUERY.GET_STARRED_MESSAGES_GLOBAL
            });

            const dataClone = JSON.parse(JSON.stringify(data));

            let cloneData = [...dataClone.getStarredMessagesGlobal];

            messages.forEach(message => {
                let folderIndex = cloneData.findIndex(object => {
                    if (object.folder.id === message.folder.id) return true;
                });

                if (folderIndex > -1) {
                    let newMessages = [];

                    newMessages = [...messages, ...cloneData[folderIndex].messages];

                    cloneData[folderIndex].messages = newMessages;

                    const newData = {
                        getStarredMessagesGlobal: cloneData
                    };

                    client.writeQuery({
                        query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                        data: newData
                    });
                } else {
                    console.log('just update the one');
                    // custom creation of folder for the sake of speed
                    let newFolder = { __typename: 'FolderContentGlobal', folder: message.folder, messages: [message] };

                    // doesn't work with .push
                    let newCloneData = [newFolder, ...cloneData];

                    const newData = {
                        getStarredMessagesGlobal: newCloneData
                    };

                    client.writeQuery({
                        query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                        data: newData
                    });
                }
            });
        } catch (error) {
            console.log('Error while fetching checkboxes', error);
        }
    };

    const spliceStarred = messages => {
        try {
            const data = client.readQuery({
                query: QUERY.GET_STARRED_MESSAGES_GLOBAL
            });

            const dataClone = JSON.parse(JSON.stringify(data));

            let cloneData = [...dataClone.getStarredMessagesGlobal];

            messages.forEach(message => {
                let folderIndex = cloneData.findIndex(object => {
                    if (object.folder.id === message.folder.id) return true;
                });

                if (folderIndex > -1) {
                    let newMessages = [];

                    newMessages = [...cloneData[folderIndex].messages];

                    // finding the message in the apollo cache
                    let index = newMessages.findIndex(obj => obj.id === message.id);

                    // removing the message from the apollo cache
                    console.log('message is', index);
                    if (index > -1) {
                        if (newMessages.length - 1 === 0) {
                            // removing the whole folder
                            cloneData.splice(folderIndex, 1);
                        } else {
                            newMessages.splice(index, 1);
                            cloneData[folderIndex].messages = newMessages;
                        }
                    }

                    const newData = {
                        getStarredMessagesGlobal: cloneData
                    };

                    client.writeQuery({
                        query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                        data: newData
                    });
                }
            });
        } catch (error) {
            console.log('Error while splicing the checks', error);
        }
    };

    return { updateStarred, spliceStarred };
}

export default useUpdateStarred;
