import React, { useState } from 'react';
import { QUERY, MUTATION } from '../gql-operations';
import { formatS3Filename } from '../util/file-format-name';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { AuthContext } from '../util/context';

import useUpdateMessageMutation from './useUpdateMessageMutation';

import useUpdateCheckboxes from './useUpdateCheckboxes';

import useRedirectErrors from '../errors/useRedirectErrors';
import USER_TYPE from '../util/userType';
import USER_ROLE from '../util/userRole';
import useAddMetadata from '../hooks/useAddMetadata';

import { MAX_STORAGE_REACHED } from '../errors/errors';
import customAnalytics from '../util/custom-analytics';
import { isFileTypeImage } from '../constants/fileUtils';



function useS3Request() {
    // const [uploadProgress, setUploadProgress] = useState(undefined);
    const { updateCheckboxes } = useUpdateCheckboxes();
    const [createMessage] = useMutation(MUTATION.CREATE_MESSAGE, {
        onCompleted: res => {
            customAnalytics.message_sent(res.createMessage);
        },
        onError: error => {
            passingErrors(error);
            setClientError(error.graphQLErrors);
        }
    });
    const { updateFiles } = useUpdateMessageMutation();
    const [signS3] = useMutation(MUTATION.S3_SIGN);
    const { data } = useQuery(QUERY.ME);
    const { passingErrors } = useRedirectErrors();
    const { savedFromMeta, updatedFromMeta } = useAddMetadata()

    const {
        state: { uploadProgress },
        context: { setUploadProgress, setClientError }
    } = React.useContext(AuthContext);

    let controller = new AbortController();
    let signal = controller.signal;

    // let elem = document.querySelector('#status');

    const fileFormat = name => {
        return name.split('.').pop();
    };

    const makeS3RequestFile = (data, file, signal, message) => {
        console.log('making s3 req', data, file);
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            signal.addEventListener('abort', () => {
                // elem.textContent = 'Promise rejected';
                console.log('promise rejected started');
                reject('Promise aborted');
                xhr.abort();
            });

            // const error = new DOMException('aborted!', 'AbortError');

            // if (signal.aborted) {
            //     return reject(error); //[2]
            // }

            xhr.onabort = event => {
                console.log('do you even go here', event);
                reject('I just rejected you');
            };
            xhr.upload.onabort = event => {
                console.log('do you even go here', event);
                reject('I just rejected you');
            };

            xhr.upload.addEventListener('progress', event => {
                if (event.lengthComputable) {
                    const copy = { ...uploadProgress };
                    const id = message ? message.id : file.name;
                    copy[id] = {
                        name: file.name,
                        state: 'pending',
                        percentage: (event.loaded / event.total) * 100,
                        type: 'file'
                    };
                    setUploadProgress(copy);
                }
            });

            xhr.addEventListener('load', event => {
                console.log('how many times LOAD');
                const copy = { ...uploadProgress };
                const id = message ? message.id : file.name;
                copy[id] = {
                    name: file.name,
                    state: 'done',
                    percentage: 100,
                    type: 'file'
                };
                // here we can already clear the upload object
                // setUploadProgress(copy);
                setUploadProgress(undefined);
                // setFileToSend(undefined);
                resolve(xhr.response);
            });

            xhr.addEventListener('error', event => {
                const copy = { ...uploadProgress };
                const id = message ? message.id : file.name;
                copy[id] = {
                    name: file.name,
                    state: 'error',
                    percentage: 0,
                    type: 'file'
                };
                setUploadProgress(copy);
                reject(xhr.response);
            });

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status !== 200) {
                        console.error('Request Failed:', xhr.status, xhr.statusText, xhr.responseText);
                        reject({
                            status: xhr.status,
                            statusText: xhr.statusText,
                            responseText: xhr.responseText
                        });
                    } else {
                        resolve(data.url);
                    }
                }
            };

            xhr.open('PUT', data.signedRequest);
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.onerror = (event) => {
                console.error('Error:', xhr.statusText, xhr.responseText);
                reject(xhr.response);
            };

            xhr.send(file);

        });
    };

    function cancelPromise() {
        controller.abort();
        // xhr.abort();
        // console.log('this is abort controller', xhr);
    }

    const sendFileMutation = async (file, values, folder, autoCheckbox) => {
        try {
            // let convertedFileName = formatS3Filename(file.name);
            let typeFormat = file.type.split('/');
            let betterName = formatS3Filename(data.me.id, file.name);

            if (isFileTypeImage(file.type)) {
                console.log('this is the create update');
                try {
                    let reader = new FileReader();
                    var i = new Image();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        i.src = reader.result;

                        i.onload = async () => {
                            let aspectRatio = i.height / i.width;
                            let response = await signS3({
                                variables: {
                                    data: {
                                        fileName: betterName,
                                        fileType: file.type,
                                        fileSize: file.size
                                    }
                                }
                            });

                            const s3url = await makeS3RequestFile(response.data.signS3, file, signal);
                            await createMessage({
                                variables: {
                                    data: {
                                        folderId: folder.id,
                                        content: {
                                            title: values.title ? values.title : '',
                                            isCheckbox: autoCheckbox,
                                            file: {
                                                url: s3url,
                                                name: betterName,
                                                displayName: file.name,
                                                type: typeFormat[0],
                                                format: fileFormat(file.name),
                                                size: file.size,
                                                width: i.width,
                                                aspect_ratio: aspectRatio,
                                                mime: file.type
                                            },
                                            metadata: savedFromMeta()
                                        }
                                    }
                                },
                                update: async (store, { data: { createMessage } }) => {
                                    if (autoCheckbox) {
                                        updateCheckboxes([createMessage]);
                                    }
                                }
                            });
                        };

                        i.onerror = res => {
                            console.log('cant get', res);
                        };
                    };
                } catch (error) {
                    console.log('some error', error);
                }
            } else {
                let response = await signS3({
                    variables: {
                        data: {
                            fileName: betterName,
                            fileType: file.type,
                            fileSize: file.size
                        }
                    }
                });

                // const {url, signedRequest} = await response.data.signS3;
                const s3url = await makeS3RequestFile(response.data.signS3, file, signal);

                console.log('s3 still going', s3url);

                await createMessage({
                    variables: {
                        data: {
                            folderId: folder.id,
                            content: {
                                title: values.title ? values.title : '',
                                isCheckbox: autoCheckbox,
                                file: {
                                    url: s3url,
                                    name: betterName,
                                    displayName: file.name,
                                    type: typeFormat[0],
                                    format: fileFormat(file.name),
                                    size: file.size,
                                    mime: file.type
                                },
                                metadata: savedFromMeta()
                            }
                        }
                    },
                    update: async (store, { data: { createMessage } }) => {
                        if (autoCheckbox) {
                            updateCheckboxes([createMessage]);
                        }
                    }
                });
            }
        } catch (error) {
            console.log('incoming errors from sendFileMutation', error);
            passingErrors(error);
            setClientError(error.graphQLErrors);
            // console.log('incoming errors', error.extensions.code);
            // error.graphQLErrors.map(({ message }, i) => {
            //     alert(message);
            //     console.log('kdkdkd', message);
            // });
        }
    };

    const sendToS3Files = async (values, fileToSend, folder, autoCheckbox) => {
        console.log('what?', fileToSend);
        let files;
        if (!fileToSend.length) {
            files = [fileToSend];
        }
        files = fileToSend;
        console.log('list files', files);
        if (files) {
            let response = await files.forEach(async (file, index) => {
                if (data.me) {
                    // if (data.me.role === USER_ROLE.ADMIN) {
                    //     sendFileMutation(file, values, folder);
                    // } else if (data.me.planType === USER_TYPE.PRO) {
                    //     sendFileMutation(file, values, folder);
                    // } else if (data.me.planType === USER_TYPE.PERSONAL && file.size <= MAX_FILE_SIZE) {
                    //     sendFileMutation(file, values, folder);
                    // } else
                    if (file.size <= data.me.quota.maxFileSize) {
                        // this one should be deleted later on TODO PLAN
                        console.log('fetting the ', file);
                        if (index === files.length - 1) {
                            sendFileMutation(file, values, folder, autoCheckbox);
                        } else {
                            sendFileMutation(file, '', folder, autoCheckbox);
                        }
                    }
                }
            });
            // setUploadProgress(undefined);
            return response;
        }
        return undefined;
    };

    const updateMessageFiles = async (files, message) => {
        // for now only one file per message
        try {
            let file = files[0];
            let betterName = formatS3Filename(data.me.id, file.name);
            // let convertedFileName = formatS3Filename(file.name);
            let typeFormat = file.type.split('/');
            console.log('curr', file);

            if (isFileTypeImage(file.type)) {
                console.log('this is the image update');
                try {
                    let reader = new FileReader();
                    var i = new Image();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        i.src = reader.result;
                        console.log('dkdkkddk', i.width, i.height);

                        i.onload = async () => {
                            let aspectRatio = i.height / i.width;
                            let response = await signS3({
                                variables: {
                                    data: {
                                        fileName: betterName,
                                        fileType: file.type,
                                        fileSize: file.size
                                    }
                                }
                            });

                            const s3url = await makeS3RequestFile(response.data.signS3, file, signal, message);
                            const content = {
                                file: {
                                    url: s3url,
                                    name: betterName,
                                    displayName: file.name,
                                    type: typeFormat[0],
                                    format: fileFormat(file.name),
                                    size: file.size,
                                    width: i.width,
                                    aspect_ratio: aspectRatio,
                                    mime: file.type
                                }
                            };
                            updateFiles(message, content);
                        };

                        i.onerror = res => {
                            console.log('cant get', res);
                        };
                    };
                } catch (error) {
                    console.log('this is error,', error);
                }
            } else {
                let response = await signS3({
                    variables: {
                        data: {
                            fileName: betterName,
                            fileType: file.type,
                            fileSize: file.size
                        }
                    }
                });

                const s3url = await makeS3RequestFile(response.data.signS3, file, signal, message);
                const content = {
                    file: {
                        url: s3url,
                        name: betterName,
                        displayName: file.name,
                        type: typeFormat[0],
                        format: fileFormat(file.name),
                        size: file.size,
                        mime: file.type
                    }
                };
                updateFiles(message, content);
            }
        } catch (error) {
            console.log('incoming errors from updateMessageFiles', error);
        }
    };

    return { sendToS3Files, updateMessageFiles, cancelPromise };
}

export default useS3Request;

// Solve aborting
// https://codesandbox.io/s/elegant-lake-5jnh3?fontsize=14&hidenavigation=1&theme=dark&file=/src/index.js
// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequestEventTarget/onabort
// https://support.cloudinary.com/hc/en-us/articles/202519972-How-to-abort-an-in-progress-upload-
