import React from 'react';

import styled, { css } from 'styled-components';
import { MUTATION } from '../../../gql-operations';

import { useMutation } from '@apollo/react-hooks';

import { PRIMARY_COLOR, PALE_WHITE_4, WHITE } from '../../../appearance/Colors';

import useUpdateFolderDataOnTree from '../../../hooks/useUpdateFolderDataOnTree';
import customAnalytics from '../../../util/custom-analytics';

function FolderTitle({ folder, editable, titleSize, title }) {
    const titleRef = React.useRef(null);
    const [updateFolder] = useMutation(MUTATION.UPDATE_FOLDER, {
        onCompleted: res => {
            if (res) {
                customAnalytics.folder_edit({
                    'action': "Rename"
                })
            }
        }
    });
    const [updateFolderData] = useUpdateFolderDataOnTree();

    const updateFolderMutation = async (folder, event) => {
        if (folder.title !== event.target.innerText) {
            let response = await updateFolder({
                variables: {
                    data: {
                        folderId: folder.id,
                        title: event.target.innerText.trim()
                    }
                }
            });
            console.log('this is res from update', response);
            if (response.data) {
                let updatedFolder = response.data.updateFolder;
                updateFolderData(updatedFolder);
            }
            // folder.title = response.data.updateFolder.title;
        }
    };

    const addStyles = () => {
        titleRef.current.style.backgroundColor = 'white';
        titleRef.current.style.color = PRIMARY_COLOR;
    };

    const removeStyles = () => {
        titleRef.current.removeAttribute('style');
        document.activeElement.blur();
    };

    return (
        <Title
            ref={titleRef}
            id="folderTitle"
            suppressContentEditableWarning
            contentEditable={editable}
            titleSize={titleSize}
            onFocus={e => addStyles()}
            onBlur={event => {
                removeStyles();
                updateFolderMutation(folder, event);
            }}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    removeStyles();
                    // saved nonetheless through onBlur
                    // updateFolderMutation(folder, event);
                }
                if (event.key === 'Escape') {
                    event.preventDefault();
                    if (window.getSelection()) {
                        window.getSelection().removeAllRanges();
                    }
                    titleRef.current.innerText = folder.title;
                    titleRef.current.blur();
                }
            }}
        >
            {title ? title : folder.title}
        </Title>
    );
}

const Title = styled.div`
    color: ${WHITE};
    outline: none;
    padding: 8px;
    font-weight: 800;
    border-radius: 4px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    font-size: ${props => (props.titleSize ? props.titleSize + 'px' : '16px')};
    :focus {
        cursor: auto;
    }
    ${props =>
        props.contentEditable
            ? css`
                  &:hover {
                      background-color: ${PALE_WHITE_4};
                  }
              `
            : null};
`;

export default FolderTitle;
