import React, { useState, useEffect } from 'react';
import { prettySize } from '../../../util/fileSizeCalculator';
import openURLFile from '../../../util/openURLFile';

import FileIcon from '../../../appearance/FileIcon';
import ImageIcon from '../../../appearance/ImageIcon';

import {
    PRIMARY_COLOR,
    WHITE,
    SECONDARY_COLOR,
    GREEN,
    RED,
    ACTION_TAG,
    NOTE_BACKGROUND,
    PALE_WHITE_1,
    PALE_WHITE_4,
    PALE_WHITE_2
} from '../../../appearance/Colors';

import styled from 'styled-components';

import useS3Request from '../../../hooks/useS3Request';
import useCloudinaryRequest from '../../../hooks/useCloudinaryRequest';
import getImagePreviews from '../../../util/getImagePreviews';
import { imageExists } from '../../../util/imageExists';

import FileSaver from 'file-saver';

import { IoMdTrash, IoIosDocument } from 'react-icons/io';

import CarouselModal from '../../../components/Modals/CarouselModal';
import DeleteModal from '../../../components/Modals/DeleteModal';
import useUpdateMessageMutation from '../../../hooks/useUpdateMessageMutation';
import { useQuery } from '@apollo/react-hooks';

import { QUERY } from '../../../gql-operations';
import useGetMaxFileSize from '../../../util/max_file_size';
import USER_TYPE from '../../../util/userType';
import USER_ROLE from '../../../util/userRole';

import ActionTag from '../../Action/ActionTagButton';
import LinkTag from '../../Action/LinkTag';
import InactiveTag from '../../Action/InactiveTagButton';
import DangerTag from '../../Action/DangerTagButton';

// import FileViewer from 'react-file-viewer';
import { AuthContext } from '../../../util/context';
import useDownloadFile from '../../../hooks/useDownloadFile';
import customAnalytics from '../../../util/custom-analytics';

function FilePreviewPlugin({ message, style, dropzoneFiles }) {
    let maxTypeChar = 28;

    const [imagesPreview, setImagesPreview] = useState(undefined);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const { data } = useQuery(QUERY.ME, {
        fetchPolicy: 'network-only'
    });

    const { user_max_file_size } = useGetMaxFileSize();

    const inputFile = React.useRef(null);

    const {
        state: { uploadProgress }
    } = React.useContext(AuthContext);

    // custom hooks
    const { updateMessageFiles } = useS3Request();
    const { deleteFileMutation } = useUpdateMessageMutation();
    const downloadFile = useDownloadFile();

    const onChangeFile = async event => {
        event.stopPropagation();
        event.preventDefault();
        const fileListAsArray = Array.from(event.target.files);
        getPreviews(fileListAsArray);
        // this.setState({file}); /// if you want to upload latter
    };

    const onDropzoneFiles = async files => {
        const fileListAsArray = Array.from(files);
        getPreviews(fileListAsArray);
    };

    useEffect(() => {
        if (dropzoneFiles) {
            console.log('dropzoneFiles', dropzoneFiles);
            onDropzoneFiles(dropzoneFiles);
        }
        return () => {
            // cleanup
        };
    }, [dropzoneFiles]);

    const getPreviews = async files => {
        let res = await getImagePreviews(files);
        if (res) {
            setImagesPreview(res);
        }
    };

    const clearFileToSend = () => {
        setImagesPreview(undefined);
        // inputFile.current.value = '';
    };

    const publishFiles = async index => {
        let filesToSend = imagesPreview.map(previewItem => previewItem.file);
        // let imageFiles = [];
        let ordinaryFiles = [];
        filesToSend.forEach(file => {
            ordinaryFiles.push(file);
        });
        // somewhere here the successful upload
        clearFileToSend();
        if (ordinaryFiles.length) {
            const onlyFirst = ordinaryFiles[index];
            await updateMessageFiles([onlyFirst], message);
            customAnalytics.message_edit({
                "action": message.file ? "Replace" : "Add",
                "type": "File"
            }, message);
        }
    };

    const deleteFile = async () => {
        // dialog would be nice here
        await deleteFileMutation(message);
    };

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    // NOT USED NOW
    // const removeFromTheList = file => {
    //     let previewClone = [...imagesPreview];
    //     let previewIndex = previewClone.findIndex(listFile => listFile.file === file);

    //     if (previewIndex > -1) {
    //         previewClone.splice(previewIndex, 1);
    //         if (previewClone.length > 0) {
    //             setImagesPreview(previewClone);
    //         } else {
    //             setImagesPreview(undefined);
    //         }
    //     }
    // };

    const renderViewButton = (url, type) => {
        // not working for word ATM file.type.includes('word')
        if (type === 'application/pdf') {
            return (
                <LinkTag label="View" style={{ marginRight: 8 }} href={url} target="_blank" rel="noopener noreferrer" />
            );
        }
    };

    const copyToClipboard = async url => {
        await navigator.clipboard.writeText(url);
        setCopiedToClipboard(true);
        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 1500);
    };

    const renderActions = (url, format) => {
        return (
            <div style={{ display: 'flex' }}>
                {/* <LinkTag
                    label="Download"
                    style={{ marginRight: 8 }}
                    href={url}
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                /> */}
                <ActionTag
                    label="Download"
                    style={{ marginRight: 8 }}
                    onClick={e => {
                        e.stopPropagation();
                        downloadFile(message.file.name, message.file.size);
                    }}
                ></ActionTag>
                {renderViewButton(url, format)}
                <ActionTag
                    label={
                        uploadProgress && uploadProgress[message.id] && uploadProgress[message.id].type === 'file'
                            ? `Updating... ${parseInt(uploadProgress[message.id].percentage)}%`
                            : 'Replace'
                    }
                    style={
                        uploadProgress && uploadProgress[message.id] && uploadProgress[message.id].type === 'file'
                            ? { marginRight: 8, backgroundColor: GREEN }
                            : { marginRight: 8 }
                    }
                    onClick={e => {
                        e.stopPropagation();
                        onButtonClick();
                    }}
                ></ActionTag>
                <DangerTag
                    label="Remove"
                    onClick={() => setIsDeleteModalOpen(true)}
                    style={{ marginRight: 8 }}
                ></DangerTag>

                <div style={{ flex: 1 }}></div>
                {/* NOT DEVELOPED YET */}
                {/* <ActionTag
                    style={copiedToClipboard ? { backgroundColor: GREEN } : null}
                    label={!copiedToClipboard ? 'Share' : 'Copied!'}
                    onClick={e => {
                        e.stopPropagation();
                        copyToClipboard(message.file.url);
                    }}
                ></ActionTag> */}

                <DeleteModal
                    title="Delete file?"
                    description="You will not be able to get this file once deleted."
                    isOpen={isDeleteModalOpen}
                    closeModal={response => {
                        if (response) {
                            console.log('what response', response);
                            deleteFile();
                        }
                        setIsDeleteModalOpen(false);
                    }}
                ></DeleteModal>
            </div>
        );
    };

    const renderPublishAction = (index, size) => {
        if (data.me) {
            // if (data.me.role === USER_ROLE.ADMIN) {
            //     return (
            //         <ActionTag
            //             label={message.file ? 'Replace' : 'Publish'}
            //             style={{ backgroundColor: GREEN, marginRight: 8 }}
            //             onClick={() => publishFiles(index)}
            //         />
            //     );
            // } else if (data.me.planType === 'pro') {
            //     return (
            //         <ActionTag
            //             label={message.file ? 'Replace' : 'Publish'}
            //             style={{ backgroundColor: GREEN, marginRight: 8 }}
            //             onClick={() => publishFiles(index)}
            //         />
            //     );
            // } else if (data.me.planType === USER_TYPE.PERSONAL && size > user_max_file_size) {
            //     return (
            //         <InactiveTag
            //             label="File too large"
            //             style={{
            //                 marginRight: 8
            //             }}
            //         />
            //     );
            // } else
            if (size > user_max_file_size) {
                // TODO PLAN
                return (
                    <InactiveTag
                        label="File too large"
                        style={{
                            marginRight: 8
                        }}
                    />
                );
            } else {
                return (
                    <ActionTag
                        label={message.file ? 'Replace' : 'Publish'}
                        style={{ backgroundColor: GREEN, marginRight: 8 }}
                        onClick={e => {
                            e.stopPropagation();
                            publishFiles(index);
                        }}
                    />
                );
            }
        }
    };

    const renderPublishActions = (index, size) => {
        return (
            <div style={{ display: 'flex' }}>
                {/* <div style={{ flex: 1 }}></div> */}
                {renderPublishAction(index, size)}
                <ActionTag
                    label="Cancel"
                    onClick={e => {
                        e.stopPropagation();
                        clearFileToSend();
                    }}
                ></ActionTag>
            </div>
        );
    };

    const fileName = (url, name, displayName) => {
        let title;
        if (displayName) {
            title = displayName;
        } else if (name) {
            title = name;
        } else {
            // in case that we haven't saved the name of the file
            url.substr(url.lastIndexOf('/') + 1);
        }
        return title;
    };

    const renderFileType = (url, type, format) => {
        if (type.includes('image')) {
            if (!type.includes('image/heic') || (format && !format.includes('heic'))) {
                return (
                    <div
                        style={{
                            borderRadius: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: NOTE_BACKGROUND
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            setLightboxIsOpen(true);
                        }}
                    >
                        <img src={url} style={{ width: 100, borderRadius: 4, cursor: 'zoom-in' }} />
                    </div>
                );
            } else {
                return <ImageIcon />;
            }
        } else {
            return <FileIcon />;
        }
    };

    const getFormat = name => {
        return name.split('.').pop();
    };

    const renderContent = (url, name, type, size, displayName, format) => {
        return (
            <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 12 }}>
                <div style={{ marginRight: 8, display: 'flex' }}>{renderFileType(url, type, format)}</div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ marginBottom: 4, wordBreak: 'break-word', color: PRIMARY_COLOR, fontWeight: 500 }}>
                        {fileName(url, name, displayName)}
                    </div>
                    <div style={{ marginBottom: 4, color: SECONDARY_COLOR }}>{size ? prettySize(size) : null}</div>
                    <div style={{ color: SECONDARY_COLOR }}>Type: {getFormat(name)}</div>
                </div>
            </div>
        );
    };

    const renderCarousel = item => {
        return (
            <CarouselModal
                isOpen={lightboxIsOpen}
                closeModal={e => {
                    e.stopPropagation();
                    setLightboxIsOpen(false);
                }}
                items={[item]}
                fileType="File"
            ></CarouselModal>
        );
    };

    if (imagesPreview) {
        return imagesPreview.map(({ reader, file }, index) => {
            const { name, size, type, displayName, format } = file;
            const url = reader.result;

            return (
                <FileWrapper key={index} style={{ ...style }}>
                    {renderContent(url, name, type, size, displayName, format)}
                    {renderPublishActions(index, size)}
                    {/* {renderCarousel(reader)} */}
                </FileWrapper>
            );
        });
    } else if (message.file) {
        const { url, name, displayName, type, size, format } = message.file;
        return (
            <FileWrapper style={{ ...style }}>
                {renderContent(url, name, type, size, displayName, format)}
                {renderActions(url, format)}

                <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} />
                {renderCarousel(message.file)}
            </FileWrapper>
        );
    } else {
        return (
            <AddFileWrapper
                style={{ ...style }}
                uploading={uploadProgress && uploadProgress[message.id] && uploadProgress[message.id].type === 'file'}
                onClick={() => onButtonClick()}
            >
                <div style={{ color: 'white' }}>
                    {uploadProgress && uploadProgress[message.id] && uploadProgress[message.id].type === 'file'
                        ? 'Uploading...'
                        : 'Add file'}
                </div>
                <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} />
            </AddFileWrapper>
        );
    }
}

const FileWrapper = styled.div`
    background-color: ${WHITE};
    border-radius: 4px;
    padding: 8px;
`;

const AddFileWrapper = styled.div`
    border-radius: 4px;
    border: 1px solid ${PALE_WHITE_4};
    background-color: ${props => (props.uploading ? GREEN : PALE_WHITE_1)};
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    &:hover {
        background-color: ${PALE_WHITE_2};
    }
`;

export default FilePreviewPlugin;
