import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { IoMdMenu } from 'react-icons/io';

// MODALS
import DeleteModal from '../Modals/DeleteModal';
import CreateFolderModal from '../Modals/CreateFolderModal';
// import UpdateFolderModal from '../Modals/UpdateFolderModal';
import SearchFoldersModal, { searchActions } from '../Modals/SearchFoldersModal';

import useUpdateFoldersOnTree from '../../hooks/useUpdateFoldersOnTree';
import useDeleteFoldersMutation from '../../hooks/useDeleteFoldersMutation';
import useTrashFoldersMutation from '../../hooks/useTrashFoldersMutation';
import useUpdateUserMutation from '../../hooks/useUpdateUserMutation';
import useNotificationSystem from '../../components/Notifications/useNotificationSystem';

import { AuthContext } from '../../util/context';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { QUERY } from '../../gql-operations';
import { PRIMARY_COLOR_6 } from '../../appearance/Colors';

import { STARRED, CHECKBOXES, HIERARCHY } from '../../util/specialFolderEnums';
import customAnalytics from '../../util/custom-analytics';

function FolderOptionsMenu({ history, folder }) {
    const dropdownMenu = useRef(null);

    const [showDropMenu, setShowDropMenu] = useState(false);
    // const [showUpdateFolderModal, setShowUpdateFolderModal] = useState(false);
    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showFoldersSearch, setShowFoldersSearch] = useState(false);
    const { showDefaultNotification } = useNotificationSystem();

    const { updateFoldersOnTree } = useUpdateFoldersOnTree();
    const { deleteFolders } = useDeleteFoldersMutation();
    const { trashFolders } = useTrashFoldersMutation();
    const { updateHomeFolder, removeHomeFolder } = useUpdateUserMutation();

    const { data, loading, error } = useQuery(QUERY.ME);

    const {
        state: { selectedFolder, showDeletedMessages, showMultiSelect, homeFolder },
        context: { setSelectedFolder, setShowDeletedMessages, setShowMultiSelect, setShowBackgroundSelect }
    } = React.useContext(AuthContext);

    const showMenu = event => {
        event.preventDefault();
        if (!showDropMenu) {
            document.addEventListener('click', closeMenu);
        } else {
            document.removeEventListener('click', closeMenu);
        }
        setShowDropMenu(!showDropMenu);
    };

    const closeMenu = event => {
        // use contains only if you want not to close when clicking on an option
        // if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
        setShowDropMenu(false);
        document.removeEventListener('click', closeMenu);
        // }
    };

    const onEditFolder = () => {
        // focusing on title and selecting all of it
        let el = document.getElementById('folderTitle');
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    };

    const onDeleteFolder = () => {
        setShowDeleteFolderModal(true);
    };

    const onTrashFolder = async () => {
        await trashFolders([folder]);
        showDefaultNotification(folder.id, 'Folder moved to trash');
    };

    const shouldRender = () => {
        if (selectedFolder.id.includes(STARRED) || selectedFolder.id.includes(CHECKBOXES)) {
            return false;
        } else {
            return true;
        }
    };

    if (shouldRender()) {
        return (
            <React.Fragment>
                <div>
                    <DropDownElement onClick={showMenu}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {/* <div style={{ fontSize: 14, marginTop: 4 }}>Drops</div> */}
                            <IoMdMenu size={20}></IoMdMenu>
                        </div>
                    </DropDownElement>
                    {showDropMenu ? (
                        <DropDownMenu ref={dropdownMenu}>
                            <div role="menu" style={{ width: 200 }}>
                                <div role="content">
                                    {!selectedFolder.id.includes(homeFolder && homeFolder.id) &&
                                        !selectedFolder.id.includes(HIERARCHY) ? (
                                        <ul>
                                            <li>
                                                <Element
                                                    onClick={() => {
                                                        setShowFoldersSearch(true);
                                                    }}
                                                >
                                                    Move to...
                                                </Element>
                                            </li>
                                            <li>
                                                <Element onClick={onEditFolder}>Rename</Element>
                                            </li>
                                            <li>
                                                <Element onClick={() => setShowDeletedMessages(!showDeletedMessages)}>
                                                    {!showDeletedMessages ? 'Show' : 'Hide'} Trash
                                                </Element>
                                            </li>
                                            {/* <li>
                                        // Not using it any longer because you can start by selecting only one message
                                        <Element onClick={() => setShowMultiSelect(!showMultiSelect)}>
                                            {!showMultiSelect ? 'Select messages' : 'Dismiss selected'}
                                        </Element>
                                    </li> */}
                                            {/* <li>
                                        <Element onClick={() => setShowBackgroundSelect(true)}>
                                            Change background
                                        </Element>
                                    </li> */}

                                            {/* <li>
                                        <Element
                                            onClick={() => {
                                                setShowCreateFolderModal(true);
                                            }}
                                        >
                                            Create folder here
                                        </Element>
                                    </li> */}

                                            <SeparatorLI />
                                            {folder.deletedAt ? (
                                                <li>
                                                    <Element onClick={onDeleteFolder}>Delete permanently</Element>
                                                </li>
                                            ) : (
                                                <li>
                                                    <Element onClick={onTrashFolder}>Trash folder</Element>
                                                </li>
                                            )}
                                        </ul>
                                    ) : (
                                        // only Inbox folder
                                        <ul>
                                            <li>
                                                <Element onClick={() => setShowDeletedMessages(!showDeletedMessages)}>
                                                    {!showDeletedMessages ? 'Show' : 'Hide'} Trash
                                                </Element>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </DropDownMenu>
                    ) : null}
                </div>
                {/* <UpdateFolderModal
                folder={folder}
                isOpen={showUpdateFolderModal}
                closeModal={() => {
                    setShowUpdateFolderModal(false);
                }}
            /> */}
                <DeleteModal
                    isOpen={showDeleteFolderModal}
                    title={`${folder.title} will be deleted forever`}
                    description={[
                        `This folder will be `,
                        <b key={'title_1'}>deleted forever</b>,
                        ` with `,
                        <b key={'title_2'}>all subfolders and messages</b>,
                        ` and you will not going to be able to access it's content any longer.`
                    ]}
                    closeModal={async response => {
                        if (response) {
                            await deleteFolders([folder]);
                        }
                        setShowDeleteFolderModal(false);
                    }}
                ></DeleteModal>

                {/* <CreateFolderModal
                    currentFolder={selectedFolder}
                    isOpen={showCreateFolderModal}
                    closeModal={() => {
                        setShowCreateFolderModal(false);
                    }}
                /> */}
                <SearchFoldersModal
                    isOpen={showFoldersSearch}
                    currentFolder={selectedFolder}
                    title={`Move ${selectedFolder.title} to...`}
                    enableMoveToRoot
                    addNew
                    action={searchActions.MOVE_FOLDER}
                    closeModal={async toFolder => {
                        setShowFoldersSearch(false);
                        // console.log('are you fucking me', selectedFolder);
                        if (toFolder) {
                            // console.log('sending', selectedFolder, toFolder);
                            // let response = await moveFolder(folder, toFolder);
                            updateFoldersOnTree(selectedFolder, toFolder);
                            // analytics inside the upper function
                        }
                        // console.log('selected folder is', toFolder);
                    }}
                ></SearchFoldersModal>
            </React.Fragment>
        );
    } else {
        return null;
    }
}

const DropDownElement = styled.div`
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
    height: 40px;
    width: 44px;
    margin: 8px 0px;
    border-radius: 4px;
    background-color: ${PRIMARY_COLOR_6};
    backdrop-filter: saturate(180%) blur(20px);
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

const DropDownMenu = styled.div`
    position: absolute;
    right: 10px;
    // margin-top: 6px;
    // margin-left: 4px;
    background-color: white;
    min-height: 15px;
    min-width: 200px;
    max-width: 304px;
    z-index: 1500;
    color: #262626;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
`;

const SeparatorLI = styled.li`
    padding-bottom: 1px;
    margin-bottom: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const Element = styled.div`
    padding: 10px 12px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const InfoSection = styled.div`
    color: rgba(0, 0, 0, 0.4);
    padding: 10px 12px;
    user-select: none;
`;

export default FolderOptionsMenu;
