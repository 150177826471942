import React from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';

import { QUERY } from '../gql-operations';
import { AuthContext } from '../util/context';

function useUpdateGetFolderContent() {
    const client = useApolloClient();
    const [getContent] = useLazyQuery(QUERY.GET_FOLDER_CONTENT, {
        fetchPolicy: 'network-only'
    });

    const {
        state: { showDeletedMessages }
    } = React.useContext(AuthContext);

    const updateNewFolders = (folder, newFolders) => {
        console.log('da f', folder);
        console.log('da f', newFolders);
        try {
            const data = client.readQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: folder.id,
                        isTrash: showDeletedMessages
                    }
                }
            });

            console.log('this is data from current folder', data);
            console.log('incoming folders', newFolders);
            // const foldersToUpdate = [];
            // if (newFolders.length > 0) {
            //     foldersToUpdate = [...data.getFolderContent.folders, ...newFolders];
            // }

            const newData = {
                getFolderContent: {
                    folder: data.getFolderContent.folder,
                    parent: data.getFolderContent.parent,
                    path: data.getFolderContent.path,
                    folders: [...data.getFolderContent.folders, ...newFolders],
                    messages: {
                        messages: data.getFolderContent.messages.messages,
                        cursor: data.getFolderContent.messages.cursor,
                        __typename: data.getFolderContent.messages.__typename
                    },
                    __typename: data.getFolderContent.__typename
                }
            };

            client.writeQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: folder.id,
                        isTrash: showDeletedMessages
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('Folder was not oppened yet');
        }
    };

    const refreshFolder = folder => {
        getContent({
            variables: {
                data: {
                    folderId: folder.id,
                    isTrash: showDeletedMessages
                }
            }
        });
    };

    // folder - selectedFolder
    // folderToSplice - folder to splice from the selectedFolder
    const refreshFolderLocal = (folder, folderToSplice) => {
        const data = client.readQuery({
            query: QUERY.GET_FOLDER_CONTENT,
            variables: {
                data: {
                    folderId: folder.id,
                    isTrash: showDeletedMessages
                }
            }
        });

        let updatedFolders = [];
        if (data.getFolderContent.folders.length > 0) {
            updatedFolders = [...data.getFolderContent.folders];
        }

        let folderIndex = updatedFolders.findIndex(folder => folder.id === folderToSplice.id);
        updatedFolders.splice(folderIndex, 1);

        const newData = {
            getFolderContent: {
                folder: data.getFolderContent.folder,
                parent: data.getFolderContent.parent,
                path: data.getFolderContent.path,
                folders: updatedFolders,
                messages: {
                    messages: data.getFolderContent.messages.messages,
                    cursor: data.getFolderContent.messages.cursor,
                    __typename: data.getFolderContent.messages.__typename
                },
                __typename: data.getFolderContent.__typename
            }
        };

        client.writeQuery({
            query: QUERY.GET_FOLDER_CONTENT,
            variables: {
                data: {
                    folderId: folder.id,
                    isTrash: showDeletedMessages
                }
            },
            data: newData
        });
    };

    return { updateNewFolders, refreshFolder, refreshFolderLocal };
}

export default useUpdateGetFolderContent;

// const updateApolloCache = (folder, newFolders) => {
//     const data = client.readQuery({
//         query: QUERY.GET_FOLDER_CONTENT,
//         variables: {
//             data: {
//                 folderId: folder.id
//             }
//         }
//     });

//     console.log('this is data from current folder', data);
//     console.log('incoming folders', newFolders);
//     // const foldersToUpdate = [];
//     // if (newFolders.length > 0) {
//     //     foldersToUpdate = [...data.getFolderContent.folders, ...newFolders];
//     // }

//     const newData = {
//         getFolderContent: {
//             folder: data.getFolderContent.folder,
//             folders: [...newFolders],
//             messages: {
//                 messages: data.getFolderContent.messages.messages,
//                 cursor: data.getFolderContent.messages.cursor,
//                 __typename: data.getFolderContent.messages.__typename
//             },
//             __typename: data.getFolderContent.__typename
//         }
//     };

//     client.writeQuery({
//         query: QUERY.GET_FOLDER_CONTENT,
//         variables: {
//             data: {
//                 folderId: folder.id
//             }
//         },
//         data: newData
//     });
// };
