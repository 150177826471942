import React, { useContext, useState } from 'react';

import { SUBSCRIPTION } from '../gql-operations';

import { useApolloClient } from '@apollo/react-hooks';
import { AuthContext } from './context';
import useGetSubfolders from '../hooks/useGetSubfolders';

// NOT USING THIS useSubscribeToNewFolder works better!!!
function useSubscribeToNewFolder2() {
    const [getSubfolders] = useGetSubfolders();

    const createdSubscription = subscribeToMore => {
        console.log('INITIALIZING SUBSCRIPTION');
        let unsubscribe = subscribeToMore({
            document: SUBSCRIPTION.NEW_FOLDER_SUBSCRIPTION,
            variables: {},
            updateQuery: async (previousResult2, { subscriptionData }) => {
                // console.log('subsription previous res', previousResult2);
                // console.log('subsription New Data', subscriptionData);
                if (!subscriptionData.data) return previousResult2;
                const newFolder = subscriptionData.data.newFolder;
                // setting the folder to active
                // newFolder.active = true;
                // setSelectedFolder(newFolder);
                console.log('this is new', newFolder);

                if (newFolder) {
                    await getSubfolders(newFolder, true);
                }
            }
        });
        return unsubscribe;
    };
    return { createdSubscription };
}

export default useSubscribeToNewFolder2;
