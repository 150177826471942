import React from 'react';

import styled from 'styled-components';

import { GREEN, PALE_WHITE_2, WHITE, ACTION_TAG, ACTION_TAG_HOVER} from '../../appearance/Colors';

const LinkTag = ({ label, type, ...rest }) => {
    return <ActionTag {...rest}>{label}</ActionTag>;
};

const ActionTag = styled.a`
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${ACTION_TAG};
    border-radius: 4px;
    color: ${WHITE};
    font-size: 12px;
    cursor: pointer;
    font-weight: bold !important;
    align-items: center;
    justify-content: center;
    display: flex;
    &:hover {
        background-color: ${ACTION_TAG_HOVER};
    }
`;

export default LinkTag;
