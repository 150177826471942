import { gql } from 'apollo-boost';

export const GET_ACTIVE_PLANS = gql`
  query {
    getActivePlans {
      subscriptions {      
        id
        name
        description
        stripePriceId
        type
        price
        anchorPrice
        active
      }
      oneTimePurchases {
        id
        name
        description
        stripePriceId
        type
        price
        anchorPrice
        active
      }
      oneTimeDeals {
        id
        name
        description
        stripePriceId
        type
        price
        anchorPrice
        active
      }
    }
  }
`;

