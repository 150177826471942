import React from 'react';

import styled from 'styled-components';

import { RED } from '../../appearance/Colors';

const SimpleTag = ({ label, type, ...rest }) => {
    return <Tag {...rest}>{label}</Tag>;
};

const Tag = styled.div`
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.64);
    border-radius: 4px;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    cursor: default;
    align-items: center;
    justify-content: center;
    display: flex;
`;

export default SimpleTag;
