import { useMutation } from '@apollo/react-hooks';
import { MUTATION } from '../gql-operations';

import useAddMetadata from '../hooks/useAddMetadata';

import customAnalytics from "../util/custom-analytics";

function useUpdateMessageMutation() {
    const [updateMessageMutation] = useMutation(MUTATION.UPDATE_MESSAGE, {
        onCompleted: res => {
            customAnalytics.message_updated(res.updateMessage);
        }
    });
    const { updatedFromMeta } = useAddMetadata();

    const updateURL = (message, urlMeta) => {
        let cleanList = urlMeta.map(url => {
            if (url) {
                return {
                    author: url.author,
                    date: url.date,
                    description: url.description,
                    image: url.image,
                    logo: url.logo,
                    publisher: url.publisher,
                    title: url.title,
                    url: url.url,
                    public_id: url.public_id
                };
            } else {
                return {};
            }
        });
        updateMessageMutation({
            variables: {
                data: {
                    messageId: message.id,
                    content: {
                        urlMeta: cleanList,
                        metadata: updatedFromMeta()
                    }
                }
            }
        });
    };

    const updateMessage = (message, values) => {
        updateMessageMutation({
            variables: {
                data: {
                    messageId: message.id,
                    content: {
                        title: values.title,
                        body: values.body,
                        metadata: updatedFromMeta()
                    }
                }
            }
            // update is here done automatically because ti's a subscription
        });
        if ((!message.body || message.body && message.body.length === 0) && values.body.length > 0) {
            customAnalytics.message_edit({
                "action": "Add",
                "type": "Note",
                "note_length": values.body.length
            }, message);
        }
    };

    const updateFiles = (message, files) => {
        // console.log('files and images', files);
        updateMessageMutation({
            variables: {
                data: {
                    messageId: message.id,
                    content: {
                        ...files,
                        metadata: updatedFromMeta()
                    }
                }
            }
            // update is here done automatically because ti's a subscription
        });
    };

    const deleteFileMutation = message => {
        updateMessageMutation({
            variables: {
                data: {
                    messageId: message.id,
                    content: {
                        file: null,
                        metadata: updatedFromMeta()
                    }
                }
            }
            // update is here done automatically because ti's a subscription
        });
        customAnalytics.message_edit({
            "action": "Delete",
            "type": "File"
        }, message);
    };

    const deleteImageMutation = message => {
        updateMessageMutation({
            variables: {
                data: {
                    messageId: message.id,
                    content: {
                        image: null,
                        metadata: updatedFromMeta()
                    }
                }
            }
            // update is here done automatically because ti's a subscription
        });
        customAnalytics.message_edit({
            "action": "Delete",
            "type": "Image"
        }, message);
    };

    return { updateMessage, updateURL, updateFiles, deleteFileMutation, deleteImageMutation };
}

export default useUpdateMessageMutation;
