import React, { useState, useEffect } from 'react';
// import { useStripe } from '@stripe/react-stripe-js';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { MUTATION, QUERY } from '../../gql-operations';
import USER_TYPE from '../../util/userType';
import CancelSubscriptionModal from '../../components/Modals/CancelSubscriptionModal';
import useRedirectErrors from '../../errors/useRedirectErrors';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
// import PlanCard from './PlanCard';
import Purchase from './Purchase';
import { FILE_BLUE, INFO_BLUE_OP_2, DETAIL_TEXT, PLACEHOLDER, WHITE, PALE_WHITE, PALE_WHITE_6, PRIMARY_COLOR, SECONDARY_COLOR } from '../../appearance/Colors';
import { FiChevronLeft } from "react-icons/fi";
import { useMediaQuery } from 'react-responsive';


const Purchases = ({ hideNavigation }) => {
    // const stripe = useStripe();
    let history = useHistory();

    const { passingErrors } = useRedirectErrors();

    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [selectedPurchase, setSetselectedPurchase] = useState(undefined);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    let homeFolder = localStorage.getItem('homeFolder');

    useEffect(() => {
        if (!JSON.parse(homeFolder)) {
            history.push('/login');
        } else {
            getUserPurchases();
        }
    }, [])

    const { data: userData } = useQuery(QUERY.ME);



    const [cancelSubscriptionMutation] = useMutation(MUTATION.CANCEL_SUBSCRIPTION, {
        onCompleted: res => {
            console.log('successful cancellation', res)
            if (res.cancelSubscription) {
                getUserPurchases();
                setSetselectedPurchase(undefined);
            }
            // console.log('so you gave something in return ', res.cancelSubscription)
        },
        onError: err => {
            console.log('Some error', err)
        }
    });

    const [getUserPurchases, { data: userPurchases, loading, error }] = useLazyQuery(QUERY.GET_USER_PURCHASES, {
        variables: {
            data: {
                userId: homeFolder ? JSON.parse(homeFolder).owner : ''
            }
        },
        onCompleted: res => {
            // console.log('res', res)
        },
        onError: err => {
            console.log('error ', err)
            passingErrors(err);
            history.push('/login');
        },
        fetchPolicy: 'network-only',
    });

    const redirectToCancel = async (purchase) => {
        await cancelSubscriptionMutation({
            variables: {
                data: {
                    stripeId: purchase.stripeId.toString()
                }
            },
        });
    }



    const recentPurchases = () => {
        if (userPurchases && userPurchases.getUserPurchases && (userPurchases.getUserPurchases.purchases.length > 0)) {
            return (
                <div style={{ paddingBottom: 20 }}>
                    {userPurchases.getUserPurchases.purchases.map(purchase => {
                        if (purchase.subscriptionStatus === 'active') {
                            return (
                                <Purchase
                                    key={purchase.id}
                                    style={{ marginBottom: 8 }}
                                    purchase={purchase}
                                    activeSubscription={true}
                                    action={(value) => {
                                        if (value) {
                                            setShowCancellationModal(true)
                                            setSetselectedPurchase(purchase);
                                        }
                                    }}
                                ></Purchase>
                            );
                        } else {
                            return (
                                <Purchase
                                    style={{ marginBottom: 8 }}
                                    key={purchase.id}
                                    purchase={purchase}
                                ></Purchase>
                            );
                        }
                    })}
                </div>
            )
        } else {
            return (
                <div style={{ marginTop: 24 }}>
                    <ErrorText>No previous purchases</ErrorText>
                </div>
            )
        }
    }

    const renderDescription = () => {
        return (
            <div style={{ fontSize: 16, color: DETAIL_TEXT }}>
                {/* For single purchases in case of the refund, contact support@luckynote.io */}
                <span>Need more info? Try our <a href='https://billing.stripe.com/p/login/4gw2aKd7sbbl78AcMM' target='_blank'>billing page</a> for more details.</span>
            </div>
        )
    }

    const renderTitle = () => {
        return (
            <div style={{
                // paddingHorizontal: 20, 
                // paddingVertical: 16
                // padding: "20px 0px",
                paddingBottom: 20,
                display: 'flex',
                flexDirection: 'column'
            }}>
                {renderNavigation()}
                <span style={{ fontSize: 24, fontWeight: '600', color: WHITE, marginBottom: 4 }}>Purchases</span>
                {renderDescription()}
            </div>
        )
    }

    const renderNavigation = () => {
        if (!hideNavigation) {
            return (
                <div style={{ marginTop: 20 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 16,
                            cursor: "pointer",
                            alignItems: 'center'
                        }}
                        onClick={() => {
                            // #TODO bug here
                            history.push('/');
                        }}
                    >
                        <FiChevronLeft color={PALE_WHITE_6} size={16}></FiChevronLeft>
                        <span style={{
                            color: PALE_WHITE_6,
                            flex: 1,
                            fontSize: 14
                        }}>Back to Luckynote</span>
                    </div>
                </div>
            )
        }
    }

    if ((userPurchases && !userPurchases.getUserPurchases) || (userData && !userData.me)) return <div>Loading</div>

    if (homeFolder) {
        return (
            <div style={{ display: 'flex', flex: 1, overflow: 'auto', backgroundColor: PRIMARY_COLOR, justifyContent: 'center' }}>
                <div style={{ flex: 1, maxWidth: 700, padding: isMobile ? "0px 20px" : 0 }}>
                    {renderTitle()}
                    {recentPurchases()}
                    <CancelSubscriptionModal
                        isOpen={showCancellationModal}
                        closeModal={(result) => {
                            setShowCancellationModal(false);
                            if (result) {
                                redirectToCancel(selectedPurchase);
                            }
                        }}
                        purchase={selectedPurchase}
                    />

                </div>
            </div>
        );
    }
    return null;
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${WHITE};
    margin-bottom: 24px;
`;

export default Purchases;
