import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION } from '../../../gql-operations';
import LuckyIcon from '../../../appearance/LuckyIcon';
import { GREEN } from '../../../appearance/Colors';
import styled from 'styled-components';

const ResetPasswordPage = (props) => {
    // const [password, setPassword] = useState('');
    // const [repeatPassword, setRepeatPassword] = useState('');
    // const [token, setToken] = useState(props.match.params.token);

    // useEffect(() => {
    //     console.log(token);
    // }, [token]);


    const [resetPassword, { data, error }] = useMutation(MUTATION.RESET_PASSWORD_MAIL, {
        onCompleted: res => {
            if (res) {
                console.log('completed');
            }
        }
    });

    const renderMessage = () => {
        if (data && data.resetPassword) {
            return <ErrorText style={{ color: GREEN }}>Email sent successfully, please check your inbox</ErrorText>;
        } else if (error) {
            if (error.graphQLErrors.length > 0) {
                return <ErrorText>{error.graphQLErrors.map(x => x.message)}</ErrorText>;
            } else {
                return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
            }
        }
    };

    return (
        <div
            style={{
                backgroundColor: '#fafafa',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}
        >

            <Formik
                initialValues={{ token: props.match.params.token }}
                onSubmit={async (values, actions) => {
                    console.log(values);

                    await resetPassword({
                        variables: {
                            data: {
                                newPassword: values.password,
                                token: values.token
                            }
                        }
                    });
                    console.log(data);
                    props.history.push('/login');
                    actions.setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .min(6, 'Minimum password length is 6.')
                        .max(50, 'Maximum password length is 50.')
                        .required('You need to have a password'),
                    repeatPassword: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .min(6, 'Minimum password length is 6.')
                        .max(50, 'Maximum password length is 50.')
                        .required('Please repeat your password again')
                })}
            >
                {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                    <div>
                        <div
                            className="logo"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                props.history.push('/home');
                            }}
                        >
                            <LuckyIcon />
                            {renderMessage()}
                        </div>

                        <div className="card">
                            <form onSubmit={handleSubmit} className="auth-form">
                                <div className="row password">
                                    <input
                                        type="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        placeholder="Password"
                                        name="password"
                                    />
                                    {errors.password && (
                                        <div id="password" className="error">
                                            {errors.password}
                                        </div>
                                    )}
                                </div>

                                <div className="row password">
                                    <input
                                        type="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.repeatPassword}
                                        placeholder="Repeat password"
                                        name="repeatPassword"
                                    />
                                    {errors.repeatPassword && (
                                        <div id="repeatPassword" className="error">
                                            {errors.repeatPassword}
                                        </div>
                                    )}
                                </div>

                                <div className="row submit">
                                    <button type="submit" className="button big blue">
                                        Reset Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>

        </div>
    );
}

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
`;

const ForgotTitle = styled.div`
    text-align: center;
    font-size: 18px;
    margin: 20px auto 10px;
    color: #666;
`;

export default ResetPasswordPage;
