import React, { useState, useEffect } from 'react';
import { store } from 'react-notifications-component';
import ProgressRing from './ProgressRing';
import { IoIosClose, IoIosArrowForward, IoMdClipboard } from 'react-icons/io';
import { FiArchive, FiX } from 'react-icons/fi';

import {
    PRIMARY_COLOR,
    PALE_WHITE_4,
    SECONDARY_COLOR,
    PALE_WHITE_6,
    WHITE,
    PALE_WHITE_1,
    PALE_WHITE_3
} from '../../appearance/Colors';

function CopyToClipboardNotification({ notificationId, title }) {
    const [progress, setProgress] = useState(100);
    const [timeLeft, setTimeLeft] = useState(10);

    useEffect(() => {
        var downloadTimer = setInterval(function () {
            if (timeLeft <= 0) {
                clearInterval(downloadTimer);
            }
            // document.getElementById('progressBar').value = 10 - timeleft;
            setTimeLeft(timeLeft - 1);

            setProgress(progress - 10);
        }, 1000);
        return () => {
            // cleanup
        };
    }, [timeLeft]);

    // if (progress > 0) {
    //     setTimeout(() => {
    //         setProgress(progress - 20);
    //     }, 10);
    // } else {
    //     setProgress(100);
    // }

    return (
        <div
            style={{
                border: `1px solid ${PALE_WHITE_4}`,
                backgroundColor: SECONDARY_COLOR,
                shadowColor: PRIMARY_COLOR,
                borderRadius: 4,
                padding: 8,
                width: '100%'
            }}
            onClick={() => {
                store.removeNotification(notificationId);
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'white',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        marginRight: 12,
                        justifyContent: 'center',
                        display: 'flex',
                        padding: 4
                    }}
                >
                    <IoMdClipboard color={PALE_WHITE_6} size={18}></IoMdClipboard>
                    {/* <ProgressRing radius={60} stroke={4} progress={progress} /> */}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <div style={{ color: WHITE }}>
                        <div style={{ fontSize: 14, lineHeight: '18px' }}>{title}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    {/* <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                            backgroundColor: PALE_WHITE_1,
                            padding: 4,
                            borderRadius: 4
                        }}
                    >
                        <FiX color={PALE_WHITE_6} size={16}></FiX>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default CopyToClipboardNotification;
