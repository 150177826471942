import React from 'react';

import { MUTATION } from '../../../gql-operations';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';

import styled from 'styled-components';

import LuckyIcon from '../../../appearance/LuckyIcon';

import './ForgotPassword.scss';
import { GREEN } from '../../../appearance/Colors';
import useRedirectErrors from '../../../errors/useRedirectErrors';

const ForgotPasswordPage = ({ history }) => {
    const { passingErrors } = useRedirectErrors();
    const [forgotPasswordMail, { data, error, loading }] = useMutation(MUTATION.FORGOT_PASSWORD_SEND_EMAIL, {
        onCompleted: res => {
            // console.log('this is forgotPassword response', res.forgotPasswordMail);
            if (res.forgotPasswordMail) {
                setTimeout(() => {
                    history.push('/login', {
                        forgotResponse: res.forgotPasswordMail
                    });
                }, 5000);
                // <Link to={'/login'} params={{forgotResponse}}>Create Account</Link>
            }
        },
        onError: res => {
            passingErrors(res);
        },
    });

    const renderMessage = () => {
        if (data && data.forgotPasswordMail) {
            return <ErrorText style={{ color: GREEN }}>Email sent successfully, please check your inbox</ErrorText>;
        } else if (error) {
            if (error.graphQLErrors.length > 0) {
                return <ErrorText>{error.graphQLErrors.map(x => x.message)}</ErrorText>;
            } else {
                return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
            }
        }
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: '#fafafa',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    onSubmit={(values, actions) => {
                        console.log(values);
                        // TODO nothing here for now
                        forgotPasswordMail({
                            variables: {
                                data: {
                                    email: values.email
                                }
                            }
                        });

                        actions.setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email().required('You need to put a proper email')
                    })}
                >
                    {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                        <div>
                            <div
                                className="logo"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    history.push('/home');
                                }}
                            >
                                <LuckyIcon />
                                {/* <h4 style={{ color: '#333333' }}>Luckynote</h4> */}
                                {renderMessage()}
                            </div>

                            <div className="card">
                                <form onSubmit={handleSubmit} className="auth-form forgot-password">
                                    <div className="row labels">
                                        <ForgotTitle>Forgot your password?</ForgotTitle>
                                        <span>
                                            Don't worry. Just enter your email address below and we'll send you
                                            instructions.
                                        </span>
                                    </div>
                                    <div className="row email">
                                        <input
                                            name="email"
                                            type="email"
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                            value={values.email}
                                            placeholder="Email"
                                            autoCorrect="off"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            render={msg => {
                                                console.log('msg', msg);
                                                // return <Text style={styles.errorMsg}>{msg}</Text>;
                                                return (
                                                    <div id="email" className="error">
                                                        {msg}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="row submit">
                                        <button type="submit" className="button big blue">
                                            Reset Password
                                        </button>
                                    </div>
                                </form>

                                <span className="link signup">
                                    Back to
                                    <span> </span>
                                    <Link to={'/login'}>Sign In</Link>
                                </span>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
`;

const ForgotTitle = styled.div`
    text-align: center;
    font-size: 18px;
    margin: 20px auto 10px;
    color: #666;
`;

export default ForgotPasswordPage;
