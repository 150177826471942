import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION, QUERY } from '../gql-operations';

import { AuthContext } from '../util/context';

import { HIERARCHY, SEARCH_MESSAGES } from '../util/specialFolderEnums';

import { store } from 'react-notifications-component';
// import GoToFolderNotification from '../components/Notifications/GoToFolderNotification';
import useNotificationSystem from '../components/Notifications/useNotificationSystem';
// import customAnalytics from '../util/custom-analytics';
// import { update } from 'lodash';

function useMoveMessagesMutation() {
    const { showGoToFolderNotification } = useNotificationSystem();

    const {
        state: {
            selectedFolder,
            folderFilterType,
            showDeletedMessages,
            searchTerm,
            searchGlobal,
            searchDeletedMessages,
            searchFolderFilterType,
            searchActive,
            selectedMessage
        },
        context: { setMessagesToSend, setSelectedMessagesMap }
    } = React.useContext(AuthContext);

    const [moveMessages, { data: moveSuccess, error: moveError, loading: moveLoading }] = useMutation(
        MUTATION.MOVE_MESSAGES
        // {
        //     refetchQueries: [
        //         {
        //             query: QUERY.GET_STARRED_MESSAGES_GLOBAL
        //         },
        //         {
        //             query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL
        //         }
        //     ]
        // }
    );

    const updateFilteredCustomFolder = (messages, toFolder, currentFolder, store) => {
        console.log('you are the filtered hierarcy with thype');
        const filterData = store.readQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: '',
                    isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                }
            }
        });

        // console.log('got the filterData,', filterData);

        const newMessages = [...filterData.filterMessages.messages];

        messages.forEach(message => {
            // finding the message in the apollo cache
            let index = newMessages.findIndex(obj => obj.id === message.id);

            // attaching the new folder to existing message object
            newMessages[index].folder = toFolder;
        });

        const newData = {
            filterMessages: {
                messages: [...newMessages],
                cursor: filterData.filterMessages.cursor,
                __typename: filterData.filterMessages.__typename
            }
        };

        store.writeQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: '',
                    isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                }
            },
            data: newData
        });
    };

    const updateSearchFolder = (messages, toFolder, fromFolder, store) => {
        console.log('trash from search', fromFolder, messages);
        try {
            const data = store.readQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || selectedFolder.id === HIERARCHY ? '' : fromFolder.id,
                        isTrash: searchDeletedMessages ? true : false
                    }
                }
            });

            console.log('this is the data from hy', data);

            // you need to spread so you get the new object, not the reference
            const newMessages = [...data.filterMessages.messages];

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // attaching the new folder to existing message object
                newMessages[index].folder = toFolder;
            });

            const newData = {
                filterMessages: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    cursor: data.filterMessages.cursor,

                    messages: [...newMessages],

                    __typename: data.filterMessages.__typename
                }
            };

            console.log('old data', data);
            console.log('new data', newData);

            store.writeQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || selectedFolder.id === HIERARCHY ? '' : fromFolder.id,
                        isTrash: searchDeletedMessages ? true : false
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('error from custom folder', error);
        }
    };

    const updateCustomFolder = ({ messages, toFolder, currentFolder, store }) => {
        console.log('you are the hierarcy');
        let thisFolderId = selectedFolder.id === currentFolder.id ? currentFolder.id : toFolder.id;
        const data = store.readQuery({
            query: QUERY.FIND_MESSAGES,
            variables: {
                data: {
                    text: searchTerm,
                    folderId: searchGlobal || currentFolder.id === HIERARCHY ? '' : thisFolderId,
                    isTrash: currentFolder.deletedAt
                        ? true
                        : showDeletedMessages || searchDeletedMessages
                        ? true
                        : false
                }
            }
        });

        console.log('this is the data from hy', data);

        // you need to spread so you get the new object, not the reference
        const newMessages = [...data.findMessages.messages];

        messages.forEach(message => {
            // finding the message in the apollo cache
            let index = newMessages.findIndex(obj => obj.id === message.id);

            // attaching the new folder to existing message object
            newMessages[index].folder = toFolder;
        });

        const newData = {
            findMessages: {
                // we are not cursoring the folders, that's why it's only previousResult2
                cursor: data.findMessages.cursor,
                messages: [...newMessages],
                __typename: data.findMessages.__typename
            }
        };

        console.log('old data', data);
        console.log('new data', newData);

        store.writeQuery({
            query: QUERY.FIND_MESSAGES,
            variables: {
                data: {
                    text: searchTerm,
                    folderId: searchGlobal || currentFolder.id === HIERARCHY ? '' : thisFolderId,
                    isTrash: currentFolder.deletedAt
                        ? true
                        : showDeletedMessages || searchDeletedMessages
                        ? true
                        : false
                }
            },
            data: newData
        });
    };

    const updateFilteredFolder = ({ messages, toFolder, currentFolder, store }) => {
        console.log('updating filtered folder');
        // check if it's in filtered messages
        const filterData = store.readQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: currentFolder.id,
                    isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                }
            }
        });
        const newMessages = [...filterData.filterMessages.messages];

        messages.forEach(message => {
            // finding the message in the apollo cache
            let index = newMessages.findIndex(obj => obj.id === message.id);

            // removing the message from the apollo cache
            if (index > -1) {
                newMessages.splice(index, 1);
            }
        });

        const newData = {
            filterMessages: {
                messages: [...newMessages],
                cursor: filterData.filterMessages.cursor,
                __typename: filterData.filterMessages.__typename
            }
        };

        store.writeQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: currentFolder.id,
                    isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                }
            },
            data: newData
        });
    };

    const updatingToFolder = ({ messages, toFolder, currentFolder, store }) => {
        console.log('updating toFolder');
        try {
            // UPDATING "TO FOLDER" IF SELECTED
            const secondData = store.readQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: toFolder.id,
                        isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                    }
                }
            });

            let newMessagesArr = [...messages];

            const withDateMessages = newMessagesArr.map(message => {
                message.createdAt = new Date().toISOString();
                return message;
            });

            console.log('just as message', withDateMessages);

            const newMessages2 = [...withDateMessages, ...secondData.getFolderContent.messages.messages];

            console.log('fixed ones', newMessages2);
            console.log('original ones', secondData.getFolderContent.messages.messages);

            const newSecondData = {
                getFolderContent: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    folder: secondData.getFolderContent.folder,
                    parent: secondData.getFolderContent.parent,
                    path: secondData.getFolderContent.path,
                    folders: [...secondData.getFolderContent.folders],
                    messages: {
                        messages: [...newMessages2],
                        cursor: secondData.getFolderContent.messages.cursor,
                        __typename: secondData.getFolderContent.messages.__typename
                    },
                    __typename: secondData.getFolderContent.__typename
                }
            };

            store.writeQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: toFolder.id,
                        isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                    }
                },
                data: newSecondData
            });
        } catch (error) {
            console.log('Folder not selected');
        }
    };

    const updateCurrentFolder = ({ messages, toFolder, currentFolder, store }) => {
        console.log('updating current folder', currentFolder, messages);
        try {
            // UPDATING "FROM FOLDER" IF SELECTED
            const data = store.readQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: currentFolder.id,
                        isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                    }
                }
            });
            // you need to spread so you get the new object, not the reference
            const newMessages = [...data.getFolderContent.messages.messages];
            // finding the message in the apollo cache
            // let index = newMessages.indexOf(messages[0]);
            messages.forEach(message => {
                let index = newMessages.findIndex(obj => obj.id === message.id);
                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }
            });
            const newData = {
                getFolderContent: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    folder: data.getFolderContent.folder,
                    parent: data.getFolderContent.parent,
                    path: data.getFolderContent.path,
                    folders: [...data.getFolderContent.folders],
                    messages: {
                        messages: [...newMessages],
                        cursor: data.getFolderContent.messages.cursor,
                        __typename: data.getFolderContent.messages.__typename
                    },
                    __typename: data.getFolderContent.__typename
                }
            };
            store.writeQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: currentFolder.id,
                        isTrash: currentFolder.deletedAt ? true : showDeletedMessages
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('None folders not selected');
        }
    };

    const updateStarredFolder = ({ messages, toFolder, currentFolder, store }) => {
        try {
            const data = store.readQuery({
                query: QUERY.GET_STARRED_MESSAGES_GLOBAL
            });

            let dataClone = JSON.parse(JSON.stringify(data));

            if (dataClone) {
                messages.forEach(message => {
                    if (message.isStarred) {
                        let folderFound = false;
                        for (let index = 0; index < dataClone.getStarredMessagesGlobal.length; index++) {
                            const { folder, messages } = dataClone.getStarredMessagesGlobal[index];

                            if (folder.id === message.folder.id) {
                                console.log('splicing the starred');
                                // if the message exists in the current starred folder, then splice it
                                if (messages) {
                                    let messageIndex = messages.findIndex(obj => obj.id === message.id);
                                    if (messageIndex > -1) {
                                        messages.splice(messageIndex, 1);
                                        if (messages.length === 0) {
                                            // deleting the whole folder if nothing is left
                                            dataClone.getStarredMessagesGlobal.splice(index, 1);
                                        }
                                    }
                                }
                            }

                            if (folder.id === toFolder.id) {
                                // check if the tofolder exists just move it here
                                console.log('found the same folder');
                                if (messages) {
                                    let messageToMove = { ...message };

                                    messageToMove.folder = toFolder;
                                    messageToMove.createdAt = new Date().toISOString();

                                    let newMessages = [messageToMove, ...messages];

                                    newMessages.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

                                    dataClone.getStarredMessagesGlobal[index].messages = newMessages;
                                    folderFound = true;
                                }
                            }
                        }

                        if (!folderFound) {
                            // if not create new folder and messages
                            console.log('do you create new one?');
                            let messageToMove = { ...message };
                            messageToMove.folder = toFolder;
                            messageToMove.createdAt = new Date().toISOString();
                            let newFolder = {
                                __typename: 'FolderContentGlobal',
                                folder: messageToMove.folder,
                                messages: [messageToMove]
                            };
                            let newCloneData = [newFolder, ...dataClone.getStarredMessagesGlobal];
                            dataClone.getStarredMessagesGlobal = newCloneData;
                        }

                        store.writeQuery({
                            query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                            data: dataClone
                        });
                    }
                });
            }
        } catch (error) {
            console.log('error from starred', error);
        }
    };

    const updateCheckedFolder = ({ messages, toFolder, currentFolder, store }) => {
        try {
            const data = store.readQuery({
                query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL
            });

            let dataClone = JSON.parse(JSON.stringify(data));

            if (dataClone) {
                messages.forEach(message => {
                    if (message.isCheckbox) {
                        let folderFound = false;
                        for (let index = 0; index < dataClone.getCheckboxMessagesGlobal.length; index++) {
                            const { folder, messages } = dataClone.getCheckboxMessagesGlobal[index];

                            if (folder.id === message.folder.id) {
                                // if the message exists in the current checked folder, then splice it
                                if (messages) {
                                    let messageIndex = messages.findIndex(obj => obj.id === message.id);
                                    if (messageIndex > -1) {
                                        messages.splice(messageIndex, 1);
                                        if (messages.length === 0) {
                                            // deleting the whole folder if nothing is left
                                            dataClone.getCheckboxMessagesGlobal.splice(index, 1);
                                        }
                                    }
                                }
                            }

                            if (folder.id === toFolder.id) {
                                // check if the tofolder exists just move it here
                                if (messages) {
                                    let messageToMove = { ...message };

                                    messageToMove.folder = toFolder;
                                    messageToMove.createdAt = new Date().toISOString();

                                    let newMessages = [messageToMove, ...messages];

                                    newMessages.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

                                    dataClone.getCheckboxMessagesGlobal[index].messages = newMessages;
                                }
                            }
                        }

                        if (!folderFound) {
                            // if not create new folder and messages
                            let messageToMove = { ...message };
                            messageToMove.folder = toFolder;
                            messageToMove.createdAt = new Date().toISOString();
                            let newFolder = {
                                __typename: 'FolderContentGlobal',
                                folder: messageToMove.folder,
                                messages: [messageToMove]
                            };
                            let newCloneData = [newFolder, ...dataClone.getCheckboxMessagesGlobal];

                            dataClone.getCheckboxMessagesGlobal = newCloneData;
                        }

                        store.writeQuery({
                            query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL,
                            data: dataClone
                        });
                    }
                });
            }
        } catch (error) {
            console.log('error from checked', error);
        }
    };

    const updateMessage = ({ messages, toFolder, currentFolder, store }) => {
        console.log('updating message', toFolder, messages, selectedMessage);
        try {
            if (selectedMessage) {
                messages.forEach(message => {
                    if (selectedMessage.id === message.id) {
                        // GET CURRENT OBJECT
                        const data = store.readQuery({
                            query: QUERY.GET_MESSAGE,
                            variables: {
                                data: {
                                    id: message.id
                                }
                            }
                        });

                        const newData = {
                            getMessage: {
                                ...data.getMessage,
                                folder: toFolder
                            }
                        };

                        // // UPDATE WITH DATA
                        store.writeQuery({
                            query: QUERY.GET_MESSAGE,
                            variables: {
                                data: {
                                    id: message.id
                                }
                            },
                            data: newData
                        });
                    }
                });
            }
        } catch (error) {
            console.log('error with updating message', error);
        }
    };

    const prepareMessages = messages => {
        let better = [...messages];
        // console.log('orig', better);
        better.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        // console.log('after sort', better);
        let onlyId = better.map(message => message.id);
        // onlyId.reverse();

        // console.log('this is better', onlyId);
        return onlyId;
    };

    const runThis = async (messages, toFolder, currentFolder) => {
        console.log('tis is toFolder', toFolder);
        console.log('tis is currentFolder', currentFolder);
        // Math.floor(Math.random() * 16) + 5

        if (selectedFolder.id !== toFolder.id) {
            showGoToFolderNotification(toFolder);
        }

        if (messages && toFolder && currentFolder) {
            moveMessages({
                variables: {
                    data: {
                        messageIds: prepareMessages(messages),
                        toFolder: toFolder.id
                    }
                },
                update: async (store, { data: { moveMessages } }) => {
                    try {
                        let props = {
                            messages,
                            toFolder,
                            currentFolder,
                            store
                        };
                        if (searchActive) {
                            updateSearchFolder(props);
                        } else if (currentFolder.id === HIERARCHY && folderFilterType.length > 0) {
                            updateFilteredCustomFolder(props);
                            // updating the not filtered behind
                            updateCustomFolder(props);
                        } else if (currentFolder.id === HIERARCHY) {
                            updateCustomFolder(props);
                        } else if (currentFolder && folderFilterType.length > 0) {
                            updateFilteredFolder(props);
                            // updating the not filtered behind
                            updateCurrentFolder(props);
                        } else if (toFolder.id === currentFolder.id) {
                            updatingToFolder(props);
                        } else {
                            updateCurrentFolder(props);
                        }
                        updateStarredFolder(props);
                        updateCheckedFolder(props);
                        updateMessage(props);
                    } catch (error) {
                        console.log('general error', error);
                    }
                }
            });
            setMessagesToSend([]);
            setSelectedMessagesMap({});
            // IT'S BETTER PUTTING ANALYTICS THROUGHOUT THE APP
            // customAnalytics.message_edit({
            //     "action": "Move",
            //     "count": messages.length,
            //     "from": currentFolder.title === "Inbox" ? "Inbox" : currentFolder.parent ? "Subfolder" : "HomeFolder",
            //     "destination": toFolder.title === "Inbox" ? "Inbox" : toFolder.parent ? "Subfolder" : "HomeFolder",
            // })
        }
    };

    return [runThis, moveSuccess];
}

export default useMoveMessagesMutation;
