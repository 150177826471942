import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { QUERY, MUTATION } from '../gql-operations';

// import { RESTORE_MESSAGES_MUTATION, GET_TRASH_MESSAGES_GLOBAL_QUERY } from '../gql-operations/';

import { AuthContext } from '../util/context';

import { STARRED, CHECKBOXES, HIERARCHY, TRASH, SEARCH_MESSAGES } from '../util/specialFolderEnums';

import useNotificationSystem from '../components/Notifications/useNotificationSystem';

import { useApolloClient } from '@apollo/react-hooks';
import customAnalytics from '../util/custom-analytics';
// import _ from 'lodash';

function useRestoreMessagesMutation() {
    // const { showGoToFolderNotification } = useNotificationSystem();

    const [restoreMessage] = useMutation(MUTATION.RESTORE_MESSAGES, {
        onCompleted: res => {
            // analytics at the bottom
        }
    });
    const client = useApolloClient();

    const {
        state: {
            folderFilterType,
            selectedFolder,
            showDeletedMessages,
            searchTerm,
            searchGlobal,
            searchDeletedMessages,
            searchFolderFilterType,
            searchActive
        }
    } = React.useContext(AuthContext);

    const deleteFromFilteredCustomFolder = (messages, fromFolder, store) => {
        console.log('you are the hierarcy with thype');
        console.log('you go into filtered');
        const filterData = store.readQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: '',
                    isTrash: showDeletedMessages
                }
            }
        });

        console.log('got the filterData,', filterData);

        const newMessages = [...filterData.filterMessages.messages];

        messages.forEach(message => {
            // finding the message in the apollo cache
            let index = newMessages.findIndex(obj => obj.id === message.id);

            // removing the message from the apollo cache
            if (index > -1) {
                newMessages.splice(index, 1);
            }
        });

        const newData = {
            filterMessages: {
                messages: [...newMessages],
                cursor: filterData.filterMessages.cursor,
                __typename: filterData.filterMessages.__typename
            }
        };

        store.writeQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: '',
                    isTrash: showDeletedMessages
                }
            },
            data: newData
        });
    };

    const deleteFromSearchFolder = (messages, fromFolder, store) => {
        console.log('trash from search', fromFolder, messages);
        try {
            const data = store.readQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || selectedFolder.id === HIERARCHY ? '' : fromFolder.id,
                        isTrash: searchDeletedMessages ? true : false
                    }
                }
            });

            console.log('this is the data from hy', data);

            // you need to spread so you get the new object, not the reference
            const newMessages = [...data.filterMessages.messages];

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }
            });

            const newData = {
                filterMessages: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    cursor: data.filterMessages.cursor,

                    messages: [...newMessages],

                    __typename: data.filterMessages.__typename
                }
            };

            console.log('old data', data);
            console.log('new data', newData);

            store.writeQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || selectedFolder.id === HIERARCHY ? '' : fromFolder.id,
                        isTrash: searchDeletedMessages ? true : false
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('error from custom folder', error);
        }
    };

    const deleteFromCustomFolder = (messages, fromFolder, store) => {
        console.log('you are the hierarcy');
        const data = store.readQuery({
            query: QUERY.FIND_MESSAGES,
            variables: {
                data: {
                    text: searchTerm,
                    folderId: searchGlobal || selectedFolder.id === HIERARCHY ? '' : fromFolder.id,
                    isTrash: showDeletedMessages || searchDeletedMessages ? true : false
                }
            }
        });

        console.log('this is the data from hy', data);

        // you need to spread so you get the new object, not the reference
        const newMessages = [...data.findMessages.messages];

        messages.forEach(message => {
            // finding the message in the apollo cache
            let index = newMessages.findIndex(obj => obj.id === message.id);

            // removing the message from the apollo cache
            if (index > -1) {
                newMessages.splice(index, 1);
            }
        });

        const newData = {
            findMessages: {
                // we are not cursoring the folders, that's why it's only previousResult2
                cursor: data.findMessages.cursor,

                messages: [...newMessages],

                __typename: data.findMessages.__typename
            }
        };

        console.log('old data', data);
        console.log('new data', newData);

        store.writeQuery({
            query: QUERY.FIND_MESSAGES,
            variables: {
                data: {
                    text: searchTerm,
                    folderId: searchGlobal || selectedFolder.id === HIERARCHY ? '' : fromFolder.id,
                    isTrash: showDeletedMessages || searchDeletedMessages ? true : false
                }
            },
            data: newData
        });
    };

    const deleteFromTrash = (messages, fromFolder, store) => {
        console.log('this is trash stuff');
        const data = store.readQuery({
            query: QUERY.GET_TRASH_MESSAGES_GLOBAL
        });

        console.log('this is the data from hy', data);

        // you need to spread so you get the new object, not the reference
        const newMessages = [...data.getTrashMessagesGlobal.messages];

        messages.forEach(message => {
            // finding the message in the apollo cache
            let index = newMessages.findIndex(obj => obj.id === message.id);

            // removing the message from the apollo cache
            if (index > -1) {
                newMessages.splice(index, 1);
            }
        });

        const newData = {
            getTrashMessagesGlobal: {
                // we are not cursoring the folders, that's why it's only previousResult2
                cursor: data.getTrashMessagesGlobal.cursor,

                messages: [...newMessages],

                __typename: data.getTrashMessagesGlobal.__typename
            }
        };

        console.log('old data', data);
        console.log('new data', newData);

        store.writeQuery({
            query: QUERY.GET_TRASH_MESSAGES_GLOBAL,
            data: newData
        });
    };

    const deleteFromFilteredFolder = (messages, fromFolder, store) => {
        console.log('you go into filtered');
        const filterData = store.readQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: fromFolder.id,
                    isTrash: fromFolder.deletedAt ? true : showDeletedMessages
                }
            }
        });

        console.log('got the filterData,', filterData);

        const newMessages = [...filterData.filterMessages.messages];

        messages.forEach(message => {
            // finding the message in the apollo cache
            let index = newMessages.findIndex(obj => obj.id === message.id);

            // removing the message from the apollo cache
            if (index > -1) {
                newMessages.splice(index, 1);
            }
        });

        const newData = {
            filterMessages: {
                messages: [...newMessages],
                cursor: filterData.filterMessages.cursor,
                __typename: filterData.filterMessages.__typename
            }
        };

        store.writeQuery({
            query: QUERY.FILTER_MESSAGES,
            variables: {
                data: {
                    text: '',
                    type: folderFilterType,
                    folderId: fromFolder.id === HIERARCHY ? '' : fromFolder.id,
                    isTrash: fromFolder.deletedAt ? true : showDeletedMessages
                }
            },
            data: newData
        });
    };

    const deleteFromCurrentFolder = (messages, fromFolder, store) => {
        // DELETE FROM ORIGINAL DATA
        console.log('you go into original', fromFolder, showDeletedMessages);
        if (showDeletedMessages) {
            const data = store.readQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: fromFolder.id,
                        isTrash: fromFolder.deletedAt ? true : showDeletedMessages
                    }
                }
            });

            // you need to spread so you get the new object, not the reference
            const newMessages = [...data.getFolderContent.messages.messages];

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }
            });

            const newData = {
                getFolderContent: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    folder: data.getFolderContent.folder,
                    parent: data.getFolderContent.parent,
                    folders: [...data.getFolderContent.folders],
                    path: [...data.getFolderContent.path],
                    messages: {
                        messages: [...newMessages],
                        cursor: data.getFolderContent.messages.cursor,
                        __typename: data.getFolderContent.messages.__typename
                    },
                    __typename: data.getFolderContent.__typename
                }
            };

            console.log('old data', data);
            console.log('new data', newData);

            store.writeQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: fromFolder.id,
                        isTrash: fromFolder.deletedAt ? true : showDeletedMessages
                    }
                },
                data: newData
            });
        } else {
            // TODO reload the folder where it's going to be restored
            // client.query({
            //     query: QUERY.GET_FOLDER_CONTENT,
            //     variables: {
            //         data: {
            //             folderId: fromFolder.id,
            //             isTrash: showDeletedMessages
            //         }
            //     }
            // })
        }
    };

    const deleteFromStarred = (messages, store) => {
        const data = store.readQuery({
            query: QUERY.GET_STARRED_MESSAGES_GLOBAL
        });

        const dataClone = JSON.parse(JSON.stringify(data));

        if (dataClone) {
            messages.forEach(message => {
                for (let index = 0; index < dataClone.getStarredMessagesGlobal.length; index++) {
                    const { folder, messages } = dataClone.getStarredMessagesGlobal[index];
                    if (messages) {
                        let messageIndex = messages.findIndex(obj => obj.id === message.id);

                        // removing the message from the apollo cache
                        if (messageIndex > -1) {
                            messages.splice(messageIndex, 1);
                            if (messages.length === 0) {
                                // deleting the whole folder if nothing is left
                                dataClone.getStarredMessagesGlobal.splice(index, 1);
                            }
                            break;
                        }
                    }
                }
            });
        }

        store.writeQuery({
            query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
            data: dataClone
        });
    };

    const deleteFromChecked = (messages, store) => {
        console.log('dleete from checked');
        const data = store.readQuery({
            query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL
        });

        const dataClone = JSON.parse(JSON.stringify(data));

        let cloneData = [...dataClone.getCheckboxMessagesGlobal];

        if (messages) {
            messages.forEach(message => {
                try {
                    let folderIndex = cloneData.findIndex(object => {
                        if (object.folder.id === message.folder.id) return true;
                    });

                    if (folderIndex > -1) {
                        let newMessages = [];

                        newMessages = [...cloneData[folderIndex].messages];

                        // finding the message in the apollo cache
                        let index = newMessages.findIndex(obj => obj.id === message.id);

                        // removing the message from the apollo cache
                        console.log('message is', index);
                        if (index > -1) {
                            if (newMessages.length - 1 === 0) {
                                // removing the whole folder
                                cloneData.splice(folderIndex, 1);
                            } else {
                                newMessages.splice(index, 1);
                                cloneData[folderIndex].messages = newMessages;
                            }
                        }

                        const newData = {
                            getCheckboxMessagesGlobal: cloneData
                        };

                        store.writeQuery({
                            query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL,
                            data: newData
                        });
                    }
                } catch (error) {
                    console.log('Error while splicing the checks', error);
                }
            });
        }
    };

    const prepareMessages = messages => {
        console.log('incoming messagews', messages);
        let better = [...messages];
        let clean = better.map(message => message.id);
        console.log('better', clean);
        return clean;
    };

    const restoreMessagesMutation = (messages, fromFolder) => {
        restoreMessage({
            variables: {
                data: {
                    messageIds: prepareMessages(messages)
                }
            },
            update: async (store, { data: { trashMessages } }) => {
                try {
                    console.log('you go altogether', selectedFolder);
                    if (searchActive) {
                        deleteFromSearchFolder(messages, selectedFolder, store);
                    } else if (selectedFolder.id === HIERARCHY && folderFilterType.length > 0) {
                        deleteFromFilteredCustomFolder(messages, selectedFolder, store);
                        // updating the not filtered behind
                        deleteFromCustomFolder(messages, selectedFolder, store);
                    } else if (selectedFolder.id === TRASH) {
                        console.log('what is wrong with you');
                        deleteFromTrash(messages, selectedFolder, store);
                    } else if (selectedFolder.id === HIERARCHY) {
                        deleteFromCustomFolder(messages, selectedFolder, store);
                    } else if (selectedFolder && folderFilterType.length > 0) {
                        deleteFromFilteredFolder(messages, selectedFolder, store);
                        // updating the not filtered behind
                        deleteFromCurrentFolder(messages, selectedFolder, store);
                        // } else if (selectedFolder.id === STARRED) {
                        //     deleteFromStarred(messages, selectedFolder, store);
                        // } else if (selectedFolder.id === CHECKBOXES) {
                        //     deleteFromChecked(messages, selectedFolder, store);
                    } else if (selectedFolder) {
                        deleteFromCurrentFolder(messages, selectedFolder, store);
                    }
                    deleteFromStarred(messages, store);
                    deleteFromChecked(messages, store);
                } catch (error) {
                    console.log('there is no current folder for this message', error);
                }
            }
        });
        customAnalytics.restore_messages(messages);
    };

    return { restoreMessagesMutation };
}

export default useRestoreMessagesMutation;
