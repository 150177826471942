import { setContext } from 'apollo-link-context';
import * as jwt from 'jsonwebtoken';

export const accessTokenLink = setContext((request, previousContext) => {
    const token = localStorage.getItem('token');
    // Checking if client has an access token
    if (token && token !== null) {
        const payload = jwt.decode(token);
        // Checking if the token has expired
        if (payload.exp * 1000 < new Date().getTime()) {
            // If it has, it will need to be refreshed, so the refresh token is also sent to the server
            return {
                headers: {
                    'x-token': localStorage.getItem('token'),
                    'x-refresh-token': localStorage.getItem('refreshToken')
                }
            };
        } else {
            // If the token is still valid, there is no need to refresh it, so the refresh token is not sent
            const headers = {
                headers: {
                    'x-token': localStorage.getItem('token')
                }
            };
            return headers;
        }
    } else {
        // The value for x token in this case will be 'null'
        return {
            headers: {
                'x-token': localStorage.getItem('token')
            }
        };
    }
});
