import { gql } from 'apollo-boost';

// input TrashFoldersInput {
//     folders: [ID!]!
// }
export const TRASH_FOLDERS = gql`
    mutation($data: TrashFoldersInput!) {
        trashFolders(data: $data)
    }
`;
