import React from 'react';
import styled from 'styled-components';

const SettingsTabButton = ({ active, title, ...rest }) => {
    return (
        <div
            style={{
                padding: 16,
                color: 'white',
                backgroundColor: active
                    ? 'rgba(255,255,255,0.1)'
                    : 'rgba(255,255,255,0)',
                borderRadius: 6,
                cursor: 'pointer'
            }}
            {...rest}
        >
            {title}
        </div>
    );
};

export default SettingsTabButton;
