import { QUERY } from '../gql-operations';

function loadMoreMessages(cursor, textValue, sort, fetchMore, setLoadMoreCheck) {
    fetchMore({
        query: QUERY.FIND_USERS,
        variables: {
            data: {
                text: textValue,
                sortBy: sort,
                cursor: cursor
            }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
            // console.log('This is previousRes', previousResult);
            // console.log('This is fetch more', fetchMoreResult);
            const previousEntry = previousResult.findUsers.users;

            // case of the first message in the folder
            if (!previousResult.findUsers.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            // if there is no fetchMore, just return the previous data
            if (!fetchMoreResult.findUsers.cursor) {
                setLoadMoreCheck(false);
                return previousResult;
            }

            // cursor is the same
            if (previousResult.findUsers.cursor === fetchMoreResult.findUsers.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            // const newMessages = fetchMoreResult.findUsers.messages;
            // const newCursor = fetchMoreResult.findUsers.cursor;
            const newUsers = fetchMoreResult.findUsers.users;
            const newCursor = fetchMoreResult.findUsers.cursor;
            // console.log('new cursor', fetchMoreResult.findUsers.cursor);

            setLoadMoreCheck(false);
            return {
                findUsers: {
                    cursor: newCursor,
                    users: [...(previousEntry && previousEntry.length > 0 ? previousEntry : []), ...newUsers],
                    __typename: previousResult.findUsers.__typename
                }
            };
        }
    });
}

export default loadMoreMessages;
