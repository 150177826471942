import { gql } from '@apollo/client';

// input PurchaseInput {
// 	 userId: ID!
// 	 planId: ID!
//   quantity: Int
//   coupon: String
// }

// mutation CreateCheckoutSession($data: PurchaseInput!) {
export const CREATE_CHECKOUT_SESSION = gql`
  mutation ($data: PurchaseInput!) {
    createCheckoutSession(data: $data) {
      __typename
      ... on CheckoutSession {
        id
        url
      }
      ... on Error {
        message
      }
    }
  }
`;
