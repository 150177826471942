import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { cloudinaryCore } from '../../util/cloudinary';
import customAnalytics from '../../util/custom-analytics';

// working nicely
const CustomView = ({ data }) => (
    <TransformWrapper
        initialScale={1}
        minScale={0.5}
        maxScale={16}
        centerOnInit={true}
        wheel={{
            step: 0.4, // Controls zoom step size (smaller = slower zoom)
            smoothStep: 0.005, // For smoother zooming
            wheelEnabled: true, // Enable wheel/touchpad zooming
            touchPadEnabled: true, // Enable touchpad pinch
            pinch: true // Enable pinch gestures
        }}
        pinch={{
            step: 5, // Controls pinch zoom sensitivity
            enabled: true,
            scalePadding: 0.2, // Adds padding to scale operations
            velocityDisabled: false, // Enable/disable velocity on pinch
            velocityScale: 1 // Control velocity scaling (lower = slower)
        }}
    >
        <TransformComponent
            wrapperStyle={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            contentStyle={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <img
                src={data.src}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100vh',
                    objectFit: 'contain',
                    width: 'auto',
                    height: 'auto'
                }}
                alt=""
            />
        </TransformComponent>
    </TransformWrapper>
);


function CarouselModal({ isOpen, closeModal, image, items, currentMessage, fileType }) {
    const checkIfBlob = object => {
        if (object) {
            if (object.src) {
                return { src: object.src };
            } else if (object.format === 'heic') {
                let url = cloudinaryCore.url(object.public_id, {
                    quality: 50,
                    fetchFormat: 'auto',
                    crop: 'fit',
                    width: 1080
                });
                return { src: url };
            } else if (object.image) {
                let url = cloudinaryCore.url(object.image);
                return { src: url };
            } else {
                let url2 = cloudinaryCore.url(object.url);
                return { src: url2 };
            }
        }
    };

    const mapItems = () => {
        return items.map(obj => (obj = checkIfBlob(obj)));
    };

    return (
        <ModalGateway>
            {isOpen ? (
                <Modal
                    closeOnBackdropClick={true}  // Ensures clicking backdrop closes modal
                    onClose={e => {
                        customAnalytics.message_action({
                            'action': 'View Image',
                            "type": fileType
                        })
                        closeModal(e);
                    }}
                    styles={{
                        blanket: base => ({
                            ...base,
                            zIndex: 9999999
                        }),
                        positioner: base => ({
                            ...base,
                            zIndex: 9999999,
                            width: '100vw',
                            height: '100vh',
                            overflow: 'hidden',
                            margin: 0,
                            padding: 0
                        }),
                        dialog: base => ({
                            ...base,
                            width: '100vw',
                            height: '100vh',
                            margin: 0,
                            padding: 0,
                            overflow: 'hidden'
                        }),
                        view: base => ({
                            ...base,
                            width: '100vw',
                            height: '100vh'
                        })
                    }}
                >
                    <Carousel
                        components={{ View: CustomView }}
                        // currentIndex={
                        // 	items.find((obj) => obj.messageId === currentMessage.id)
                        // 		.currentIndex
                        // }
                        // formatters={{}}
                        // styles={{
                        // 	container: base => ({
                        // 		...base,
                        // 		backgroundColor: 'rgba(255,255,255,0.85)'
                        // 	})
                        // }}
                        frameProps={{}}
                        views={mapItems()}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
    );
}

export default CarouselModal;
