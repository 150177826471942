import React from 'react';
import { format, compareAsc } from 'date-fns';
import styled, { css } from 'styled-components';

import URLPreviewSectionMini from './Sections/URLPreviewSectionMini';
import ImageSection from './Sections/ImageSection';
import FileSection from './Sections/FileSection';
// import ImageFileSection from './Sections/ImageFileSection';
import VideoSection from './Sections/VideoSection';
import AudioSection from './Sections/AudioSection/AudioSection';
// import CheckboxSection from './Sections/CheckboxSection';

import Linkify from 'react-simple-linkify';

import { NOTE_BACKGROUND, WHITE, STARRED } from '../../appearance/Colors';

import { FaRegStickyNote } from 'react-icons/fa';
import customAnalytics from '../../util/custom-analytics';
import { isFileTypeImage } from '../../constants/fileUtils';

function SearchMessage({ message }) {
    const renderImageContent = () => {
        const margin = message.file ? MARGIN : 0;
        if (message.image)
            return (
                <div style={{ marginBottom: margin }}>
                    <ImageSection url={message.image.url} image={message.image} width={100} />
                </div>
            );
    };

    const renderAWSContent = () => {
        const margin = message.title ? MARGIN : 0;
        if (message.file) {
            if (
                isFileTypeImage(message.file.type)
                // message.file.type === 'image/png' ||
                // message.file.type === 'image/jpg' ||
                // message.file.type === 'image/jpeg' ||
                // message.file.type === 'image/svg' ||
                // message.file.type === 'image/svg+xml' ||
                // message.file.type === 'image/heic' ||
                // message.file.type === 'application/zip' ||
                // message.file.type === 'application/pdf'
            ) {
                return (
                    <div style={{ marginBottom: margin }}>
                        <FileSection file={message.file}></FileSection>
                    </div>
                );
            } else if (
                message.file.type.includes('audio')
                // message.file.type === 'audio/wav' ||
                // message.file.type === 'audio/aac' ||
                // message.file.type === 'audio/mpeg' ||
                // message.file.type === 'audio/mp3'
            ) {
                return (
                    <div style={{ marginBottom: margin }}>
                        <AudioSection file={message.file}></AudioSection>
                    </div>
                );
            } else if (message.file.type.includes('video')) {
                return (
                    <div style={{ marginBottom: margin }}>
                        <VideoSection url={message.file.url}></VideoSection>
                    </div>
                );
            } else {
                // just displaying it as an ordinary file
                return (
                    <div style={{ marginBottom: margin }}>
                        <FileSection file={message.file}></FileSection>
                    </div>
                );
            }
        }
        return null;
    };

    const renderTitleContent = () => {
        const margin = message.urlMeta.length ? MARGIN : 0;
        if (message.title)
            return (
                <div style={{ marginBottom: margin }}>
                    <PreMessage onClick={() => {
                        customAnalytics.message_action({
                            'action': "Open",
                            "type": "Link"
                        }, message);
                    }}>
                        <Linkify target="_blank" rel="noopener noreferrer">
                            {message.title}
                        </Linkify>
                    </PreMessage>
                </div>
            );
    };

    const renderUrlMeta = () => {
        const margin = message.body ? MARGIN : 0;
        if (message.urlMeta) {
            return message.urlMeta.map((metaData, index) => {
                if (message.urlMeta[index]) {
                    return (
                        <div key={index} style={{ marginBottom: margin, display: 'flex' }}>
                            <URLPreviewSectionMini key={index} index={index} metaData={metaData} message={message} />
                        </div>
                    );
                }
            });
        }
    };

    const renderBodyContent = () => {
        if (message.body)
            return (
                <div
                    style={{
                        color: 'rgba(0,0,0,0.6)',
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 4,
                        backgroundColor: NOTE_BACKGROUND,
                        borderRadius: 4,
                        padding: 6
                    }}
                >
                    <TruncateWrapper>
                        <div>{message.body}</div>
                    </TruncateWrapper>
                    <div
                    // style={{
                    //     margin: 6
                    // }}
                    >
                        <FaRegStickyNote size={14} style={{ marginLeft: 8, marginTop: 2 }}></FaRegStickyNote>
                    </div>
                </div>
            );
    };

    const renderInFolder = () => {
        if (message.folder && message.folder.title) {
            return (
                <div
                    style={{
                        marginTop: 8,
                        color: 'rgba(0,0,0,0.6)'
                    }}
                >
                    {message.folder.title}
                </div>
            );
        }
    };

    return (
        <div key={message.id} style={{ display: 'flex', flexDirection: 'row' }}>
            <MessageDiv
                style={{
                    padding: 8,
                    backgroundColor: message.isStarred ? STARRED : WHITE
                }}
            >
                <MessageContent>
                    {renderImageContent()}
                    {renderAWSContent()}
                    {renderTitleContent()}
                    {renderBodyContent()}
                    {renderUrlMeta()}
                    {renderInFolder()}
                </MessageContent>
            </MessageDiv>
            <div style={{ flex: 1 }}></div>
            <div style={{ color: 'rgba(255,255,255,.4)' }}>{format(new Date(message.createdAt), 'dd MMM, HH:mm')}</div>
        </div>
    );
}

const MARGIN = 8;

const MessageDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: white;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    max-width: 600px;
    word-break: break-word;
    user-select: text;

    // padding-top: 12px;
    // padding-bottom: 12px;
    // padding-right: 12px;
    // padding-left: 12px;
    // white-space: pre-wrap;
    // line-height: 150%;
`;

const PreMessage = styled.div`
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 150%;
    color: black;
    a {
        font-weight: bold;
        color: #2b88d9;
        text-decoration: none;
    }
`;

const MessageContent = styled.div`
    flex-direction: column;
`;

const TruncateWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
    white-space: pre-wrap;
    padding: 1px;
`;

export default SearchMessage;
