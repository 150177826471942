import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function PaymentSuccess() {
    const history = useHistory();
    const redirect = () => {
        history.push('/login');
    };

    useEffect(() => {
        setTimeout(() => {
            redirect();
        }, 2000);
    }, []);

    return (
        <div
            style={{
                backgroundColor: '#fafafa',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ fontSize: 18, fontWeight: 500 }}>Successfully purchased! 🎉</div>
                <div style={{ marginTop: 16 }}>You will be redirected to Luckynote in a couple of seconds</div>
                 {/* <div onClick={() => redirect()}>Redirect now</div> */}
            </div>
        </div>
    );
}

export default PaymentSuccess;
