import { useQuery } from '@apollo/react-hooks';
import { QUERY } from '../gql-operations';
import customAnalytics from '../util/custom-analytics';

function useDownloadFile() {
    const { refetch } = useQuery(QUERY.DOWNLOAD_FILE, { skip: true });

    const downloadFile = async (fileName, fileSize) => {
        let { data, error } = await refetch({
            data: {
                fileName: fileName
            }
        });

        if (data) {
            console.log('data is', data);
            switch (data.downloadFile.__typename) {
                case 'S3File':
                    window.open(data.downloadFile.url, '_blank');
                    break;
                case 'FileDoesNotBelongToUserError':
                    alert('File does not belong to this user');
                    break;
                default:
                    alert('Server error');
                    break;
            }
        }

        if (error) {
            console.error('Error with download query', error);
        }

        customAnalytics.ui_action({
            'action': "Download File",
            "size": fileSize
        })
    };

    return downloadFile;
}

export default useDownloadFile;

// Without returning the whole result
// function useDownloadFile() {
//     const { refetch } = useQuery(QUERY.DOWNLOAD_FILE, { skip: true });

//     const downloadFile = async fileName => {
//         return refetch({
//             data: {
//                 fileName: fileName
//             }
//         });
//     };

//     return downloadFile;
// }

// export default useDownloadFile;
