import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import { QUERY } from '../gql-operations';
import { AuthContext } from '../util/context';

function useGetFolderContent() {
    const client = useApolloClient();

    const {state: {showDeletedMessages}} = React.useContext(AuthContext)

    const getFolderParent = folderId => {
        const data = client.readQuery({
            query: QUERY.GET_FOLDER_CONTENT,
            variables: {
                data: {
                    folderId: folderId,
                    isTrash: showDeletedMessages
                }
            }
        });

        let parentObject = data.getFolderContent.parent;

        return parentObject;
    };

    return { getFolderParent };
}

export default useGetFolderContent;
