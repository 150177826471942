import { gql } from 'apollo-boost';

// input VerifyAccountInput {
// 	email: String!
// }

export const VERIFY_ACCOUNT = gql`
    mutation($data: VerifyAccountInput!) {
        verifyAccount(data: $data)
    }
`;
