import React from 'react';
const lucky_icon = require('../assets/lucky_icon.svg');

function LuckyIcon({ sizeInPx }) {
    return (
        <div style={{
            height: sizeInPx ? sizeInPx : 50,
            width: sizeInPx ? sizeInPx : 50
        }}>
            <img
                style={{
                    height: 'auto',
                    width: '100%'
                }}
                src={lucky_icon}
            />
        </div>
    );
}

export default LuckyIcon;
