import { gql } from 'apollo-boost';

export const MOVE_FOLDER = gql`
    mutation($data: MoveFolderInput!) {
        moveFolder(data: $data) {
            id
            title
            parent
            owner
            before
            deletedAt
        }
    }
`;