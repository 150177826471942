import React, { useState } from 'react';
import styled from 'styled-components';
import CarouselModal from '../../Modals/CarouselModal';
import DeleteModal from '../../Modals/DeleteModal';

import useUpdateMessageMutation from '../../../hooks/useUpdateMessageMutation';

import { AuthContext } from '../../../util/context';
import environment from '../../../env-config';
import { Image, Transformation } from 'cloudinary-react';

import { IoIosClose, IoMdLink, IoMdTrash } from 'react-icons/io';
import useCheckValidity from '../../../util/useCheckValidity';

import {
    PRIMARY_COLOR,
    WHITE,
    NOTE_BACKGROUND,
    FILE_BLUE,
    PRIMARY_COLOR_4,
    PRIMARY_COLOR_2,
    PRIMARY_COLOR_1,
    PRIMARY_COLOR_6
} from '../../../appearance/Colors';
import ImageNAIcon from '../../../appearance/ImageNAIcon';

import domain_from_url from "../../../util/domain-from-url";
import customAnalytics from '../../../util/custom-analytics';

function URLPreviewSection({ index, metaData, message }) {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { checkImageValidity } = useCheckValidity();

    const { updateURL } = useUpdateMessageMutation();

    const { size, aspect_ratio, width } = message.urlMeta[index];

    // const imageWidth = width ? message.urlMeta[index].width < 100 ? message.urlMeta[index].width : 200 : 200;

    const imageWidth = (message.urlMeta[index] && message.urlMeta[index].width) < 100 ? 50 : 200;

    let tempHeight = Math.floor(aspect_ratio * imageWidth);

    const {
        state: { holdingKeys, keys }
    } = React.useContext(AuthContext);

    // super annoying on the message itself, only showing the plugin
    const metaActive = holdingKeys && (keys.Meta || keys.Control) && keys.Shift;

    // const checkIfEmpty = async () => {
    //     let urlMeta = message.urlMeta[index];
    //     if (!urlMeta.description && !urlMeta.image && !urlMeta.publisher && !urlMeta.title) {
    //         await message.urlMeta.splice(index, 1);
    //     }
    // };

    const deleteUrl = async () => {
        await message.urlMeta.splice(index, 1, { url: message.urlMeta[index].url });
        updateURL(message, message.urlMeta);
    };

    const deleteUrlImage = () => {
        message.urlMeta[index].image = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deletePublisher = () => {
        message.urlMeta[index].publisher = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deleteTitle = () => {
        message.urlMeta[index].title = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deleteDescription = () => {
        message.urlMeta[index].description = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const removeButton = callback => {
        if (metaActive) {
            return (
                <div onClick={callback} style={{ cursor: 'pointer' }}>
                    <IoIosClose color={PRIMARY_COLOR} size={16}></IoIosClose>
                </div>
            );
        }
        return null;
    };

    const removeSection = () => {
        if (metaActive) {
            return (
                <div style={{ marginTop: 4, marginLeft: 16 }} onClick={deleteUrl}>
                    Remove preview
                </div>
            );
        }
        return null;
    };

    if (metaData && (metaData.title || metaData.image)) {
        return (
            <>
                <UrlWrapper onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    {/* {url.publisher ? (
                        <UrlSection>
                            {removeButton(deletePublisher)}
                            <div style={{ color: '#333', fontWeight: 600 }}>{url.publisher}</div>
                        </UrlSection>
                    ) : null} */}

                    {metaData.image ? (
                        <ImageUrlSection>
                            {/* {removeButton(deleteUrlImage)} */}
                            <div
                                style={{
                                    position: 'relative',
                                    marginRight: metaData.title ? 8 : 0
                                }}
                            >
                                <div
                                    style={{
                                        // flex: 1,
                                        // display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setLightboxIsOpen(true);
                                    }}
                                >
                                    {checkImageValidity(metaData.image) ? (
                                        <Image
                                            cloudName={environment.REACT_APP_CLOUD_NAME}
                                            publicId={metaData.image}
                                            width={'1.0'}
                                            crop={'pad'}
                                            style={{
                                                borderRadius: 4,
                                                maxHeight: 350,
                                                objectFit: 'cover',
                                                width: '100%',
                                                minWidth: imageWidth,
                                                minHeight: tempHeight
                                            }}
                                        >
                                            <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                                        </Image>
                                    ) : (
                                        <div
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                minWidth: 100,
                                                minHeight: 50,
                                                backgroundColor: PRIMARY_COLOR_1,
                                                borderRadius: 4,
                                                display: 'flex',
                                                marginRight: 8
                                            }}
                                        >
                                            <ImageNAIcon />
                                        </div>
                                    )}
                                    {/* <span
                                    style={{
                                        color: WHITE,
                                        fontSize: 12,
                                        position: 'absolute',
                                        bottom: 4,
                                        left: 4,
                                        backgroundColor: 'rgba(0,0,0,0.2)',
                                        padding: '2px 4px',
                                        borderRadius: 4,
                                        opacity: 0.8
                                    }}
                                >
                                    {metaData.publisher ? metaData.publisher : null}
                                </span> */}
                                </div>
                            </div>
                        </ImageUrlSection>
                    ) : null}
                    {metaData.title ? (
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 40 }}>
                            <UrlSection>
                                {/* {removeButton(deleteTitle)} */}
                                <div
                                    style={{
                                        // flex: 1,
                                        fontWeight: 500,
                                        color: 'black'
                                    }}
                                >
                                    {metaData.title}
                                </div>
                                {metaData.description ? (
                                    <TruncateWrapper style={{ color: PRIMARY_COLOR_6 }}>{metaData.description}</TruncateWrapper>
                                ) : null}
                            </UrlSection>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12, alignItems: 'center' }}>
                                {metaData.logo ?
                                    <div
                                        style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                                    // onClick={ }
                                    >
                                        <img src={metaData.logo} style={{ height: 16, width: 16, objectFit: 'contain', marginRight: 6 }}></img>
                                        <span style={{ flex: 1, color: PRIMARY_COLOR_4 }}>{metaData.publisher && metaData.publisher.length > 0 ? metaData.publisher : domain_from_url(metaData && metaData.url)}</span>
                                    </div>
                                    :
                                    <div
                                        style={{ flex: 1 }}
                                    // onClick={ }
                                    >
                                        <span style={{ flex: 1, color: PRIMARY_COLOR_4 }}>{domain_from_url(metaData && metaData.url)}</span>
                                    </div>}
                            </div>
                        </div>
                    ) : null}
                    {hovered ? (
                        <IconWrapper
                            onClick={e => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                customAnalytics.message_edit({
                                    "action": 'Delete',
                                    "type": "Link",
                                    "quick": true
                                }, message);
                            }}
                        >
                            <IoMdTrash size={16} />
                        </IconWrapper>
                    ) : null}
                </UrlWrapper>
                {/* {removeSection()} */}

                <CarouselModal
                    isOpen={lightboxIsOpen}
                    closeModal={e => {
                        e.stopPropagation();
                        setLightboxIsOpen(false);
                    }}
                    items={[metaData]}
                    fileType="Link"
                ></CarouselModal>
                <DeleteModal
                    isOpen={showDeleteModal}
                    title="Remove Preview?"
                    actionButtonText="Remove"
                    closeModal={response => {
                        if (response) {
                            deleteUrl();
                        }
                        setShowDeleteModal(false);
                    }}
                ></DeleteModal>
            </>
        );
    } else if (metaData) {
        return (
            <UrlWrapper style={{ justifyContent: 'flex-start' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        // padding: 8,
                        // backgroundColor: FILE_BLUE,
                        alignItems: 'center',
                        borderRadius: 4,
                    }}
                >
                    {metaData.logo ?
                        <img src={metaData.logo} style={{ height: 32, width: 32, objectFit: 'contain', borderRadius: 4, marginRight: 6 }}></img>
                        :
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 8,
                                backgroundColor: FILE_BLUE,
                                borderRadius: 4,
                                marginRight: 6
                            }}
                        >
                            <IoMdLink color={WHITE} size={16}></IoMdLink>
                        </div>
                    }
                    <span style={{ flex: 1, color: PRIMARY_COLOR_4 }}>
                        {domain_from_url(metaData.url)}
                    </span>
                </div>
            </UrlWrapper>
        )
    } else {
        return null;
    }
}

const UrlWrapper = styled.div`
    // margin-top: 4px;
    // flex-direction: column;
    // border-left: black;
    // border-width: 4px;
    // border-style: solid;
    // padding-left: metaActive ? 0px : 0px;
    // width: 450px;
    display: flex;
    flex-direction: row;
    padding: 8px;
    background-color: ${NOTE_BACKGROUND};
    border-radius: 4px;
    flex: 1;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
`;

const IconWrapper = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: ${PRIMARY_COLOR_2};
    &:hover {
        color: ${PRIMARY_COLOR_4};
    }
`;

const TruncateWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // flex: 1;
    color: rgba(0, 0, 0, 0.8);
`;

const UrlSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    // margin-top: 4px;
`;

const ImageUrlSection = styled.div`
    display: flex;
    flex-direction: row;
    // margin-top: 8px;
    overflow: hidden;
    // min-width: 100px;
    max-width: 200px;
    justify-content: center;
    // flex: 1;
`;

export default URLPreviewSection;
