import { QUERY, MUTATION } from '../gql-operations';

import { useMutation, useQuery } from '@apollo/react-hooks';

import useCloudinaryRequest from './useCloudinaryRequest';

import FILE_TYPE from '../util/fileType';
import mixpanel from 'mixpanel-browser';
import customAnalytics from '../util/custom-analytics';

function useUpdateUserMutation() {
    const { makeCloudinaryReqest } = useCloudinaryRequest();
    const [updateUser] = useMutation(MUTATION.UPDATE_USER_DETAILS, {
        onCompleted: res => {
            customAnalytics.user_edit()
        }
    });

    const updateBackgroundPicture = async image => {
        const cloudResponse = await makeCloudinaryReqest(image, undefined, FILE_TYPE.BACKGROUND);

        let res = await updateUser({
            variables: {
                data: {
                    backgroundImageUrl: cloudResponse.public_id
                }
            },
            refetchQueries: [
                {
                    query: QUERY.ME
                }
            ]
        });

        customAnalytics.addBackgroundPic(cloudResponse.secure_url)
    };

    const updateProfilePicture = async image => {
        const cloudResponse = await makeCloudinaryReqest(image, undefined, FILE_TYPE.PROFILE_PICTURE);

        let res = await updateUser({
            variables: {
                data: {
                    profilePictureUrl: cloudResponse.public_id
                }
            }
        });

        customAnalytics.addProfilePic(cloudResponse.secure_url)
    };

    const deleteProfilePicture = async () => {
        updateUser({
            variables: {
                data: {
                    profilePictureUrl: null
                }
            }
        });

        customAnalytics.removeProfilePic();
    };

    return { updateBackgroundPicture, updateProfilePicture, deleteProfilePicture };
}

export default useUpdateUserMutation;
