import React from 'react';

import { MUTATION } from '../../../gql-operations';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';

import styled from 'styled-components';

import LuckyIcon from '../../../appearance/LuckyIcon';

import './VerifyAccountPage.scss';
import { GREEN } from '../../../appearance/Colors';

const VerifyAccountPage = ({ history }) => {
    const [sendVerificationEmail, { data, error, loading }] = useMutation(MUTATION.SEND_VERIFICATION_EMAIL, {
        onCompleted: res => {
            // console.log('response is', res);
            if (res.sendVerificationEmail.__typename === 'SendVerificationEmailSuccess') {
                setTimeout(() => {
                    history.push('/login', {
                        verificationResponse: res
                    });
                }, 5000);
                // <Link to={'/login'} params={{forgotResponse}}>Create Account</Link>
            }
        }
    });

    const renderErrorMessage = () => {
        if (data && data.sendVerificationEmail) {
            switch (data.sendVerificationEmail.__typename) {
                case 'SendVerificationEmailSuccess':
                    return <ErrorText style={{color: GREEN}}>Email sent successfully, please check your inbox</ErrorText>;
                case 'UserNotFound':
                    return <ErrorText>User not found</ErrorText>;

                case 'UserAlreadyVerified':
                    return <ErrorText>User already verified</ErrorText>;

                case 'InternalServerError':
                    return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;

                default:
                    return <ErrorText>Unknown error</ErrorText>;
            }
        }
    };

    return (
        <div
            style={{
                backgroundColor: '#fafafa',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <Formik
                initialValues={{
                    email: ''
                }}
                onSubmit={(values, actions) => {
                    console.log(values);
                    // TODO nothing here for now
                    sendVerificationEmail({
                        variables: {
                            data: {
                                email: values.email
                            }
                        }
                    });
                    // no update here necessary, we should just redirect somewhere
                    // no return value?
                    actions.setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email().required('You need to put a proper email')
                })}
            >
                {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                    <div>
                        <div className="logo">
                            <LuckyIcon />
                            {/* <h4 style={{ color: '#333333' }}>Luckynote</h4> */}
                            {renderErrorMessage()}
                        </div>

                        <div className="card">
                            <form onSubmit={handleSubmit} className="auth-form forgot-password">
                                <div className="row labels">
                                    <ForgotTitle>Verify account</ForgotTitle>
                                    <span>
                                        Enter your email address below and we'll send you instructions.
                                    </span>
                                </div>
                                <div className="row email">
                                    <input
                                        name="email"
                                        type="email"
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                        value={values.email}
                                        placeholder="Email"
                                        autoCorrect="off"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        render={msg => {
                                            console.log('msg', msg);
                                            // return <Text style={styles.errorMsg}>{msg}</Text>;
                                            return (
                                                <div id="email" className="error">
                                                    {msg}
                                                </div>
                                            );
                                        }}
                                    />
                                </div>

                                <div className="row submit">
                                    <button type="submit" className="button big blue">
                                        Verify account
                                    </button>
                                </div>
                            </form>

                            <span className="link signup">
                                Back to
                                <span> </span>
                                <Link to={'/login'}>Sign In</Link>
                            </span>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    );
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
`;

const ForgotTitle = styled.div`
    text-align: center;
    font-size: 18px;
    margin: 20px auto 10px;
    color: #666;
`;

export default VerifyAccountPage;
