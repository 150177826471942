import { gql } from 'apollo-boost';

// input UserPurchasesInput {
// 	 userId: ID!
// }

export const GET_USER_PURCHASES = gql`
  query ($data: UserPurchasesInput!) {
    getUserPurchases (data: $data) {
      purchases {
        id
        user {
          id
          planType
        }
        stripeId
        finalPrice
        plan {
          name
          stripePriceId
          type
          price
          stripeProductId
          currency
        }
        quantity
        subscriptionStatus
        currentPeriodStart
        currentPeriodEnd
        purchaseDate
      }
    }
  }
`;
