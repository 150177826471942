export const PRIMARY_COLOR = 'rgba(35, 39, 42, 1)';
export const PRIMARY_COLOR_8 = 'rgba(35, 39, 42, .8)';
export const PRIMARY_COLOR_7 = 'rgba(35, 39, 42, .7)';
export const PRIMARY_COLOR_6 = 'rgba(35, 39, 42, .6)';
export const PRIMARY_COLOR_5 = 'rgba(35, 39, 42, .5)';
export const PRIMARY_COLOR_4 = 'rgba(35, 39, 42, .4)';
export const PRIMARY_COLOR_2 = 'rgba(35, 39, 42, .2)';
export const PRIMARY_COLOR_1 = 'rgba(35, 39, 42, .1)';
export const SECONDARY_COLOR = '#484C4D';
export const PRIMARY_TEXT = '#ffffff';
export const SECONDARY_TEXT = 'rgba(255,255,255,0.8)';
export const DETAIL_TEXT = 'rgba(255,255,255,.6)';
export const PALE_WHITE = 'rgba(255,255,255,.1)';
export const PALE_WHITE_1 = 'rgba(255,255,255,.1)';
export const PALE_WHITE_2 = 'rgba(255,255,255,.2)';
export const PALE_WHITE_3 = 'rgba(255,255,255,.3)';
export const PALE_WHITE_4 = 'rgba(255,255,255,.4)';
export const PALE_WHITE_5 = 'rgba(255,255,255,.5)';
export const PALE_WHITE_6 = 'rgba(255,255,255,.6)';
export const PALE_WHITE_8 = 'rgba(255,255,255,.8)';
export const WHITE = '#ffffff';
export const PLACEHOLDER = '#606060';
export const NOTE_BACKGROUND = '#F2F2F2';
export const IMAGE_BACKGROUND = '#E2E2E2';
export const GREEN = '#00896F';
export const GREEN_OP = 'rgba(0,137,111,.6)';
export const GREEN_OP_4 = 'rgba(0,137,111,.4)';
export const GREEN_OP_2 = 'rgba(0,137,111,.2)';
export const GREEN_OP_1 = 'rgba(0,137,111,.1)';
export const RED = '#CF3D2A';
export const RED_OP = 'rgba(207, 61, 42, .6)';
export const HOVER_RED = '#B23221';
export const STARRED = 'rgba(250,230,164,1)';
export const ACTION_TAG = 'rgba(0, 0, 0, 0.64)';
export const ACTION_TAG_HOVER = 'rgba(120, 120, 120, 1)';
export const LINK = '#0E7AFE';
export const BLUE_HIGHLIGHT = '#e2f0ff';
export const FILE_BLUE = 'cornflowerblue';
export const INFO_BLUE = 'rgba(0, 133, 202, 1)';
export const INFO_BLUE_OP = 'rgba(0, 133, 202, .6)';
export const INFO_BLUE_OP_4 = 'rgba(0, 133, 202, .4)';
export const INFO_BLUE_OP_2 = 'rgba(0, 133, 202, .2)';
