const FILTER_TYPE = {
    STAR: 'STAR',
    CHECK: 'CHECK',
    IMAGE: 'IMAGE',
    FILE: 'FILE',
    NOTE: "NOTE",
    META_URL: "META_URL",
    TEXT: "TEXT",
    TEXT_SOLO: "TEXT_SOLO",
    FILE_SOLO: 'FILE_SOLO',
    IMAGE_SOLO: 'IMAGE_SOLO',
};

export default FILTER_TYPE;
