import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';
import FileSaver from 'file-saver';

import { prettySize } from '../../../util/fileSizeCalculator';
import { NOTE_BACKGROUND } from '../../../appearance/Colors';

function VideoPlayerPlugin({ url, width, height, name, size, style }) {
    const wrapperRef = React.useRef();
    const [wrapperWidth, setWrapperWidth] = useState(0);

    useEffect(() => {
        if (wrapperRef.current) {
            // console.log('wrapperRef', wrapperRef.current.offsetWidth);
            setWrapperWidth(wrapperRef.current.offsetWidth);
        }
        return () => {};
    }, [wrapperRef]);

    if (url) {
        return (
            <div style={{ ...style }}>
                <VideoWrapper>
                    <div
                        ref={wrapperRef}
                        style={{
                            position: 'relative',
                            width: '100%',
                            // maxHeight: 300,
                            // minHeight: 100,
                            backgroundColor: NOTE_BACKGROUND,
                            borderRadius: 4
                        }}
                    >
                        <ReactPlayer
                            url={url}
                            width={width ? width : wrapperWidth}
                            // height={height ? height : '100%'}
                            controls={true}
                            pip={true}
                            style={{
                                display: 'flex',
                                maxHeight: 300,
                                minHeight: 100,
                                overflow: 'hidden'
                            }}
                        ></ReactPlayer>
                    </div>
                </VideoWrapper>
                {/* <Button
                    onClick={async () => {
                        FileSaver.saveAs(url, name);
                    }}
                >
                    Download {prettySize(size)}
                </Button> */}
            </div>
        );
    }
    return null;
}

const MARGIN = 10;
const BORDER = 1;

const VideoWrapper = styled.div`
    // margin: ${MARGIN}px;
`;

const Button = styled.div`
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    padding: 10px 0;
    // color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }
`;

export default VideoPlayerPlugin;
