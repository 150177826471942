import React from 'react';

import styled from 'styled-components';

import { RED } from '../../appearance/Colors';

const DangerTagButton = ({ label, type, ...rest }) => {
    return <DangerTag {...rest}>{label}</DangerTag>;
};

const DangerTag = styled.div`
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.64);
    border-radius: 4px;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    &:hover {
        background-color: ${RED};
        color: rgba(255, 255, 255, 1);
    }
`;

export default DangerTagButton;
