import React from 'react';

import { MUTATION, QUERY } from '../gql-operations';

import { useMutation } from '@apollo/react-hooks';

import useMoveFolderMutation from './useMoveFolderMutation';

import { AuthContext } from '../util/context';
import customAnalytics from '../util/custom-analytics';

function useReorderFoldersMutation(props) {
    const [reorderFolders] = useMutation(MUTATION.REODER_FOLDERS, {
        onCompleted: res => {
            customAnalytics.folder_edit({
                'action': 'Reorder'
            });
        }
    });
    const [moveFolder] = useMoveFolderMutation();

    const {
        state: { showDeletedMessages, selectedFolder }
    } = React.useContext(AuthContext);

    const runThis = (treeData, source, destination) => {
        if (source && destination && treeData) {
            const getFolderObject = (parentId, index) => {
                var sourceParent = treeData.items[parentId];
                var newSourceChildren = [...sourceParent.children];
                let realObjId = newSourceChildren[index];
                var realObject = treeData.items[realObjId];

                if (realObjId) {
                    realObject = treeData.items[realObjId].data;
                }

                return realObject;
            };

            let newSrouce = getFolderObject(source.parentId, source.index);
            let newDestination;

            if (source.parentId === destination.parentId && source.index === destination.index) {
                console.log('nothing happened, was put to the same place');
            } else if (source.parentId === destination.parentId && source.index !== destination.index) {
                console.log('moving in the root or children');

                if (source.index > destination.index) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    // src is larger index than destination
                    newSrouce.before = newDestination.before;
                    newDestination.before = newSrouce.id;
                } else {
                    if (getFolderObject(destination.parentId, destination.index + 1)) {
                        newDestination = getFolderObject(destination.parentId, destination.index + 1);
                        // src is smaller index than destination
                        newSrouce.before = newDestination.before;
                        newDestination.before = newSrouce.id;
                    } else {
                        newDestination = getFolderObject(destination.parentId, destination.index);
                        // case when placing the src element on the bottom of the list
                        // TODO it needs to be fixed if you take the children
                        // and drag and drop it on it's own parent. Current case is that the tree
                        // puts the element on the bottom of the list, but here we're not
                        // sending anything to the server
                        if (newDestination) {
                            newSrouce.before = newDestination.id;
                            newDestination = null;
                        } else {
                            return;
                        }
                    }
                }

                console.log('moving folder to 2', newSrouce, newDestination);

                return commitFolders(newSrouce, newDestination);
            } else if (destination.parentId === 'root') {
                console.log('destination is s aroot');

                // it's added to the end
                if (getFolderObject(destination.parentId, destination.index)) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    console.log('i exist somewhere in the middle', newDestination);
                    // we need to update the destination follower
                    newSrouce.parent = null;
                    newSrouce.before = newDestination.before;
                    newDestination.before = newSrouce.id;
                } else {
                    // because we put it to the last element we take the one folder before

                    newDestination = getFolderObject(destination.parentId, destination.index - 1);
                    console.log('i exist somwhere at the end', newDestination);
                    newSrouce.parent = null;
                    newSrouce.before = newDestination.id;
                }

                console.log('moving folder to 2', newSrouce, newDestination);

                return commitFolders(newSrouce, newDestination);
            } else if (source.parentId === 'root') {
                console.log('source is s aroot');

                if (getFolderObject(destination.parentId, destination.index)) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    console.log('i exist somewhere in the middle', newDestination);
                    // we need to update the destination follower
                    newSrouce.parent = newDestination.parent;
                    newSrouce.before = newDestination.before;
                    newDestination.before = newSrouce.id;
                } else {
                    // because we put it to the last element we take the one folder before
                    newDestination = getFolderObject(destination.parentId, destination.index - 1);
                    console.log('i exist somwhere at the end', newDestination);
                    if (newDestination) {
                        newSrouce.parent = newDestination.parent;
                        newSrouce.before = newDestination.id;
                    } else {
                        console.log('using moveFolder function', newSrouce, newDestination);
                        let folderToMove = { id: newSrouce.id };
                        let newParent = { id: destination.parentId };
                        return moveFolderResponse(folderToMove, newParent);
                    }
                }

                console.log('moving folder to 2', newSrouce, newDestination);

                return commitFolders(newSrouce, newDestination);
            } else {
                console.log('SOMETHING UNEXPECTED');

                if (getFolderObject(destination.parentId, destination.index)) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    console.log('i exist somewhere in the middle', newDestination);
                    // we need to update the destination follower
                    newSrouce.parent = newDestination.parent;
                    newSrouce.before = newDestination.before;
                    newDestination.before = newSrouce.id;
                } else {
                    // because we put it to the last element we take the one folder before
                    newDestination = getFolderObject(destination.parentId, destination.index - 1);
                    console.log('i exist somwhere at the end', newDestination);
                    if (newDestination) {
                        newSrouce.parent = newDestination.parent;
                        newSrouce.before = newDestination.id;
                    } else {
                        console.log('using moveFolder function', newSrouce, newDestination);
                        let folderToMove = { id: newSrouce.id };
                        let newParent = { id: destination.parentId };
                        return moveFolderResponse(folderToMove, newParent);
                    }
                }

                console.log('moving folder to 2', newSrouce, newDestination);

                return commitFolders(newSrouce, newDestination);
            }
        } else {
            alert('some case is MISSING for reordering');
            console.log('something is MISSING');
        }
    };

    const moveFolderResponse = async (newFolder, newParent) => {
        let response = await moveFolder(newFolder, newParent);
        let foldersToUpdate = response.data.moveFolder;
        return foldersToUpdate;
    };

    const commitFolders = async (sourceFolder, destinationFolder) => {
        let constructData;

        if (!destinationFolder) {
            constructData = {
                folderPrimary: {
                    id: sourceFolder.id,
                    before: sourceFolder.before,
                    parent: sourceFolder.parent
                }
            };
        } else {
            constructData = {
                folderPrimary: {
                    id: sourceFolder.id,
                    before: sourceFolder.before,
                    parent: sourceFolder.parent
                },
                folderFollower: null,

                folderDestination: {
                    id: destinationFolder.id,
                    before: destinationFolder.before
                }
            };
        }

        console.log('the contructed data', constructData);

        let response = await reorderFolders({
            variables: {
                data: constructData
            },
            refetchQueries: await createRefetchQuery(constructData)
        });

        // console.log('I WOULD SEND THIS ONLY ONCE', response)
        let foldersToUpdate = response.data.reorderFolders;
        return foldersToUpdate;
    };

    const createRefetchQuery = constructData => {
        let queries = [];
        queries.push({
            query: QUERY.GET_FOLDER_CONTENT,
            variables: {
                data: {
                    folderId: selectedFolder.id,
                    isTrash: showDeletedMessages
                }
            }
        });
        return queries;
    };

    return [runThis];
}

export default useReorderFoldersMutation;
