import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

function VideoSection({ url }) {
    const wrapperRef = React.useRef();
    const [wrapperWidth, setWrapperWidth] = useState(0);

    useEffect(() => {
        if (wrapperRef.current) {
            // console.log('wrapperRef', wrapperRef.current.offsetWidth);
            setWrapperWidth(wrapperRef.current.offsetWidth);
        }
        return () => {};
    }, [wrapperRef]);

    if (url) {
        return (
            <div
                ref={wrapperRef}
                style={{ position: 'relative', width: '100%' }}
            >
                <ReactPlayer
                    url={url}
                    width={300}
                    height="100%"
                    controls={true}
                    pip={true}
                    style={{
                        display: 'flex',
                        borderRadius: 4,
                        overflow: 'hidden'
                    }}
                ></ReactPlayer>
            </div>
        );
    }
    return null;
}

export default VideoSection;
