import { gql } from 'apollo-boost';

// input RegisterInput {
// 	email: String!
// 	password: String!
// 	fullName: String!
// }

export const REGISTER = gql`
    mutation($data: RegisterInput!) {
        register(data: $data) {
            __typename
            ... on AuthPayload {
                token
                refreshToken
            }
            ... on Error {
                message
            }
            ... on RegisterInvalidInputError {
                message
            }
        }
    }
`;
