import { CREATE_FOLDER } from './folder/create-folder';
import { DELETE_FOLDERS } from './folder/delete-folders';
import { MOVE_FOLDER } from './folder/move-folder';
import { REODER_FOLDERS } from './folder/reorder-folders';
import { RESTORE_FOLDER } from './folder/restore-folder';
import { TRASH_FOLDERS } from './folder/trash-folders';
import { UPDATE_FOLDER } from './folder/update-folder';

import { RESTORE_MESSAGES } from './message/restore-messages';
import { TRASH_MESSAGES } from './message/trash-messages';
import { UNSTAR_MESSAGE } from './message/unstar-message';
import { STAR_MESSAGE } from './message/star-message';
import { CHECK_MESSAGE } from './message/check-message';
import { UNCHECK_MESSAGE } from './message/uncheck-message';
import { REMOVE_CHECKBOX } from './message/remove-checkbox';
import { ADD_CHECKBOX } from './message/add-checkbox';
import { UPDATE_MESSAGE } from './message/update-message';
import { DELETE_MESSAGES } from './message/delete-messages';
import { MOVE_MESSAGES } from './message/move-messages';
import { CREATE_MESSAGE } from './message/create-message';
import { S3_SIGN } from './message/sign-s3';

import { DELETE_USER } from './user/delete-user';
import { DELETE_USER_ADMIN } from './user/delete-user-admin';
import { UPDATE_STORAGE } from './user/update-storage';
import { VERIFY_ACCOUNT } from './user/verify-account';
import { SEND_VERIFICATION_EMAIL } from './user/send-verification-email';
import { UPDATE_USER_DETAILS } from './user/update-user-details';
import { RESET_PASSWORD_MANUAL } from './user/reset-password-manual';
import { RESET_PASSWORD_MAIL } from './user/reset-password-mail';
import { REGISTER } from './user/register';
import { LOGIN } from './user/login';
import { LOCK_ACCOUNT_MANUAL } from './user/lock-account-manual';
import { FORGOT_PASSWORD_SEND_EMAIL } from './user/forgot-password-send-email';
import { DELETE_ACCOUNT } from './user/delete-account';
import { CHANGE_MESSAGE_QUOTA } from './user/change-message-quota';
import { CHANGE_USER_PLAN } from './user/change-user-plan';
import { GENERATE_REFERRAL_LINK } from './user/generate-referral-link';

import { CREATE_CHECKOUT_SESSION } from './purchase/create-checkout-session'
import { CANCEL_SUBSCRIPTION } from './purchase/cancel-subscription'

const MUTATION = {
    CREATE_FOLDER,
    DELETE_FOLDERS,
    MOVE_FOLDER,
    REODER_FOLDERS,
    RESTORE_FOLDER,
    TRASH_FOLDERS,
    UPDATE_FOLDER,
    RESTORE_MESSAGES,
    TRASH_MESSAGES,
    UNSTAR_MESSAGE,
    STAR_MESSAGE,
    CHECK_MESSAGE,
    UNCHECK_MESSAGE,
    REMOVE_CHECKBOX,
    ADD_CHECKBOX,
    UPDATE_MESSAGE,
    DELETE_MESSAGES,
    MOVE_MESSAGES,
    CREATE_MESSAGE,
    S3_SIGN,
    UPDATE_STORAGE,
    VERIFY_ACCOUNT,
    SEND_VERIFICATION_EMAIL,
    UPDATE_USER_DETAILS,
    RESET_PASSWORD_MANUAL,
    RESET_PASSWORD_MAIL,
    REGISTER,
    LOGIN,
    LOCK_ACCOUNT_MANUAL,
    FORGOT_PASSWORD_SEND_EMAIL,
    DELETE_ACCOUNT,
    CHANGE_MESSAGE_QUOTA,
    CHANGE_USER_PLAN,
    DELETE_USER,
    DELETE_USER_ADMIN,
    GENERATE_REFERRAL_LINK,
    CREATE_CHECKOUT_SESSION,
    CANCEL_SUBSCRIPTION
};

export default MUTATION;
