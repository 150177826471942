import { gql } from 'apollo-boost';

// input DeleteFoldersInput {
// 	folders: [ID!]!
// }

export const DELETE_FOLDERS = gql`
    mutation($data: DeleteFoldersInput!) {
        deleteFolders(data: $data)
    }
`;
