import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { FiMoreVertical } from 'react-icons/fi';

import { PALE_WHITE_2, PALE_WHITE_6, PALE_WHITE_8 } from '../appearance/Colors';

import useTrashFoldersMutation from '../hooks/useTrashFoldersMutation';
// import useUpdateUserMutation from '../hooks/useUpdateUserMutation';

import useNotificationSystem from '../components/Notifications/useNotificationSystem';

function FolderCardDropdown({
    folder,
    hovering,
    setShowDeleteFolderModal,
    setShowCreateFolderModal,
    setShowUpdateFolderModal,
    setShowFoldersSearch
}) {
    const dropdownMenu = useRef(null);

    const [showDropMenu, setShowDropMenu] = useState(hovering);

    // const [editFolder, setEditFolder] = useState(false);

    const { showDefaultNotification } = useNotificationSystem();

    const { trashFolders } = useTrashFoldersMutation();
    // const { updateHomeFolder } = useUpdateUserMutation();

    const closeOnEscape = event => {
        if (event.key === 'Escape') {
            setShowDropMenu(false);
            // setEditFolder(false);
            document.removeEventListener('click', closeMenu);
        }
    };

    const showMenu = event => {
        event.preventDefault();
        event.stopPropagation();
        if (!showDropMenu) {
            document.addEventListener('click', closeMenu);
            document.addEventListener('keydown', closeOnEscape, true);
        } else {
            document.removeEventListener('click', closeMenu);
        }
        setShowDropMenu(!showDropMenu);
    };

    const closeMenu = event => {
        // use contains only if you want not to close when clicking on an option
        // if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
        setShowDropMenu(false);
        document.removeEventListener('click', closeMenu);
        document.removeEventListener('keydown', closeOnEscape, true);
        // }
    };

    const onDeleteFolder = () => {
        setShowDeleteFolderModal(true);
    };

    const onTrashFolder = async () => {
        await trashFolders([folder]);
        showDefaultNotification(folder.id, 'Folder moved to trash');
    };

    if (hovering || showDropMenu) {
        return (
            <React.Fragment>
                <DropDownElement onClick={showMenu}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <FiMoreVertical size={20}></FiMoreVertical>
                    </div>
                </DropDownElement>
                {showDropMenu ? (
                    <DropDownMenu ref={dropdownMenu}>
                        <div role="menu" style={{ width: 200 }}>
                            <div role="content">
                                <ul>
                                    <li>
                                        <Element
                                            onClick={() => {
                                                closeMenu();
                                                setShowFoldersSearch(true);
                                            }}
                                        >
                                            Move to...
                                        </Element>
                                    </li>
                                    <li>
                                        <Element
                                            onClick={() => {
                                                setShowUpdateFolderModal(true);
                                            }}
                                        >
                                            Rename
                                        </Element>
                                    </li>

                                    {/* <li>
                                        <Element
                                            onClick={() => {
                                                setShowCreateFolderModal(true);
                                            }}
                                        >
                                            Create folder here
                                        </Element>
                                    </li> */}
                                    <SeparatorLI />
                                    {folder.deletedAt ? (
                                        <li>
                                            <Element onClick={onDeleteFolder}>Delete permanently</Element>
                                        </li>
                                    ) : (
                                        <li>
                                            <Element onClick={onTrashFolder}>Trash folder</Element>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </DropDownMenu>
                ) : null}
                {/* {editFolder ? (
                        <DropDownMenu ref={dropdownMenu}>
                            <div role="menu" style={{ width: 200 }}>
                                <div role="content">
                                    <FolderTitle
                                        folder={folder}
                                        editable={true}
                                        autoFocus
                                        style={{ color: 'black' }}
                                        onComplete={() => {
                                            setEditFolder(false);
                                        }}
                                    ></FolderTitle>
                                </div>
                            </div>
                        </DropDownMenu>
                    ) : null} */}
            </React.Fragment>
        );
    } else {
        return null;
    }
}

const DropDownElement = styled.div`
    color: ${PALE_WHITE_6};
    cursor: pointer;
    border-radius: 4px;
    // padding: 2px;
    margin-left: 4px;
    &:hover {
        color: ${PALE_WHITE_8};
        background-color: ${PALE_WHITE_2};
    }
`;

const DropDownMenu = styled.div`
    position: absolute;
    right: 10px;
    top: 30px;
    // margin-top: 6px;
    // margin-left: 4px;
    background-color: white;
    min-height: 15px;
    min-width: 200px;
    max-width: 304px;
    z-index: 1500;
    color: #262626;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
`;

const SeparatorLI = styled.li`
    padding-bottom: 1px;
    margin-bottom: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const Element = styled.div`
    padding: 10px 12px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const InfoSection = styled.div`
    color: rgba(0, 0, 0, 0.4);
    padding: 10px 12px;
    user-select: none;
`;

export default FolderCardDropdown;
