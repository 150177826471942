import React from 'react';
import { useDrop } from 'react-dnd';
import ItemTypes from './ItemTypes';
import styled from 'styled-components';

const FolderCard = ({
    onDrop,
    inputFolder,
    onFolderClick,
    active,
    ...props
}) => {
    // DROPPING MESSAGE
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [ItemTypes.MESSAGE],
        drop: onDrop,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });
    const isActive = isOver && canDrop;
    let backgroundColor = 'transparent';
    if (isActive) {
        backgroundColor = 'darkgreen';
    } else if (canDrop) {
        backgroundColor = 'darkkhaki';
    }
    // DROPPING MESSAGE END

    if (inputFolder) {
        return (
            <React.Fragment>
                <FolderCardContainer
                    id={inputFolder.id}
                    ref={drop}
                    {...props}
                    style={{
                        backgroundColor:
                            active && !isActive ? '#0081CF' : backgroundColor,
                        ...props.style
                    }}
                    onClick={event => {
                        onFolderClick(inputFolder);
                    }}
                >
                    <div style={{ marginLeft: 8 }}>
                        <h4>{inputFolder.title}</h4>
                        <h5>{inputFolder.desc}</h5>
                    </div>
                    <div style={{ flex: 1 }} />
                </FolderCardContainer>
            </React.Fragment>
        );
    }
};

const FolderCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    color: white;
    // border-radius: 3px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    min-height: 40px;
    align-items: center;
`;

export default FolderCard;
