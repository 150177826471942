import React, { useState } from 'react';

import { QUERY, MUTATION } from '../gql-operations';

import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { format, compareAsc, formatDistance } from 'date-fns';

import styled from 'styled-components';
import ScrollableComponent from '../components/ScrollableComponent';
import { Waypoint } from 'react-waypoint';
// import messages from '../assets/MOCK_MESSAGES.json';

import DeleteUserModalAdmin from './Modals/DeleteUserModalAdmin';

import useRedirectErrors from '../errors/useRedirectErrors';

import loadMoreUsers from '../util/loadMoreUsers';
import loadMoreFindUsers from '../util/loadMoreFindUsers';

import { prettySize } from '../util/fileSizeCalculator';
import { GREEN, PALE_WHITE_6, PRIMARY_COLOR_6, RED, SECONDARY_TEXT, PRIMARY_COLOR } from '../appearance/Colors';
import { useHistory } from 'react-router-dom';

import { IoMdImage, IoMdCheckbox, IoMdStar, IoMdDocument, IoMdLink } from "react-icons/io";
import { FaRegStickyNote, FaRegCircle, FaTrash } from "react-icons/fa";
import USER_TYPE from '../util/userType';

function SettingsAdminComponent() {
    const [loadMoreCheck, setLoadMoreCheck] = useState(true);
    const [userSearchInput, setUserSearchInput] = useState('');
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const { passingErrors } = useRedirectErrors();
    const [sortCustom, setSortCustom] = useState(true);
    const [totalUsers, setTotalUsers] = useState(0);
    const [showExactDate, setShowExactDate] = useState(false);

    let history = useHistory();

    // const { data, loading, error, fetchMore } = useQuery(QUERY.GET_ALL_USERS, {
    //     variables: {
    //         data: {}
    //     },
    //     onError: res => {
    //         passingErrors(res);
    //         history.push('/login');
    //     }
    // });

    const { data: userData } = useQuery(QUERY.GET_ALL_ANALYTICS, {
        onError: res => {
            // passingErrors(res);
            // history.push('/login');
        },
        onCompleted: res => {
            if (res) {
                setTotalUsers(res.getAnalytics.length);
            }
        }
    });

    const [findUsers, { data: findData, fetchMore: findFetchMore }] = useLazyQuery(QUERY.FIND_USERS, {
        onError: res => {
            passingErrors(res);
            history.push('/login');
        },
        fetchPolicy: 'network-only'
    });

    const [changeMessageQuota] = useMutation(MUTATION.CHANGE_MESSAGE_QUOTA);
    const [changeUserPlan] = useMutation(MUTATION.CHANGE_USER_PLAN);

    React.useEffect(() => {
        handleUserSearchChange('');
    }, []);

    // #TODO fix the user search
    const handleUserSearchChange = (value, sort) => {
        setUserSearchInput(value);
        // console.log('avlue', value);
        // console.log('here is going to be commenced search');
        findUsers({
            variables: {
                data: {
                    text: value,
                    sortBy: sortCustom
                }
            }
        });
    };

    const changeOrder = async () => {
        await setSortCustom(!sortCustom);
        findUsers({
            variables: {
                data: {
                    text: userSearchInput,
                    sortBy: !sortCustom
                }
            }
        });
    };

    // const _loadOlder = user => {
    //     loadMoreUsers(data.getAllUsers.cursor, fetchMore, setLoadMoreCheck);
    // };

    const loadMoreFiltered = () => {
        loadMoreFindUsers(findData.findUsers.cursor, userSearchInput, sortCustom, findFetchMore, setLoadMoreCheck);
    };

    const onDeleteUser = user => {
        setCurrentUser(user);
        setShowDeleteUserModal(true);
    };

    // const renderWaypoint = i => {
    //     if (data.getAllUsers.users.length > 2) {
    //         if (i === data.getAllUsers.users.length - 2) {
    //             return <Waypoint onEnter={() => _loadOlder()} />;
    //         } else {
    //             return null;
    //         }
    //     }
    // };

    const addMessages = async user => {
        let amount = prompt("Enter amount");
        if (amount != null) {
            await changeMessageQuota({
                variables: {
                    data: {
                        amount: parseInt(amount),
                        userId: user.id
                    }
                },
                update: async (store, { data: { changeMessageQuota } }) => {
                    const customData = store.readQuery({
                        query: QUERY.FIND_USERS,
                        variables: {
                            data: {
                                text: userSearchInput,
                                sortBy: sortCustom
                            }
                        }
                    });

                    const newUsers = [...customData.findUsers.users];

                    let index = newUsers.indexOf(user);

                    if (index > -1) {
                        newUsers[index].quota.maxMessages = newUsers[index].quota.maxMessages + parseInt(amount)
                    }

                    const newData = {
                        findUsers: {
                            users: [...newUsers],
                            cursor: customData.findUsers.cursor,
                            __typename: customData.findUsers.__typename
                        }
                    };

                    store.writeQuery({
                        query: QUERY.FIND_USERS,
                        variables: {
                            data: {
                                text: userSearchInput,
                                sortBy: sortCustom
                            }
                        },
                        data: newData
                    });
                }
            });
        }
    }

    const changePlan = async (user) => {
        let amount = prompt(`Enter plan type ${USER_TYPE.PERSONAL}, ${USER_TYPE.PRO}, ${USER_TYPE.BETA}`);
        if (amount != null) {
            if (
                amount.includes(USER_TYPE.PERSONAL)
                || amount.includes(USER_TYPE.PRO)
                || amount.includes(USER_TYPE.BETA)
            ) {
                await changeUserPlan({
                    variables: {
                        data: {
                            userId: user.id,
                            planType: amount
                        }
                    },
                    update: async (store, { data: { changeUserPlan } }) => {
                        const customData = store.readQuery({
                            query: QUERY.FIND_USERS,
                            variables: {
                                data: {
                                    text: userSearchInput,
                                    sortBy: sortCustom
                                }
                            }
                        });

                        const newUsers = [...customData.findUsers.users];

                        let index = newUsers.indexOf(user);

                        if (index > -1) {
                            newUsers[index].planType = amount
                        }

                        const newData = {
                            findUsers: {
                                users: [...newUsers],
                                cursor: customData.findUsers.cursor,
                                __typename: customData.findUsers.__typename
                            }
                        };

                        store.writeQuery({
                            query: QUERY.FIND_USERS,
                            variables: {
                                data: {
                                    text: userSearchInput,
                                    sortBy: sortCustom
                                }
                            },
                            data: newData
                        });
                    }
                });
            } else {
                changePlan(user)
            }
        }
    }

    const renderCustomWaypoint = i => {
        if (findData.findUsers.users.length > 2) {
            if (i === findData.findUsers.users.length - 3) {
                return <Waypoint onEnter={() => loadMoreFiltered()} />;
            } else {
                return null;
            }
        }
    };

    const timeDistance = date => {
        if (date) {
            return (
                <div style={{ fontWeight: 600, color: RED, marginRight: 4 }} onClick={() => setShowExactDate(!showExactDate)}>
                    {!showExactDate ? formatDistance(new Date(date), new Date()) : format(new Date(date), 'dd MMM yyyy, HH:mm')}
                </div>
            );
        } else {
            return <div style={{ fontWeight: 600, color: PRIMARY_COLOR, marginRight: 4 }}>-</div>;
        }
    };

    const numberBold = (number, total) => {
        if (number == 0) {
            return <div style={{ fontWeight: 600, color: PRIMARY_COLOR, marginRight: 4 }}>0</div>;
        } else if (number && total) {
            return <div style={{ fontWeight: 600, color: PRIMARY_COLOR, marginRight: 4 }}>{((number / total) * 100).toFixed(1)}% <span style={{ fontWeight: 500, color: PRIMARY_COLOR_6 }}>({number})</span></div>;

        } else if (number) {
            return <div style={{ fontWeight: 600, color: PRIMARY_COLOR, marginRight: 4 }}>{number}</div>;
        } else {
            return <div style={{ fontWeight: 600, color: PRIMARY_COLOR, marginRight: 4 }}>-</div>;
        }
    };

    const renderUserItem = user => {
        return (
            <div
                style={{
                    marginTop: 10,
                    padding: 16,
                    backgroundColor: 'rgba(255,255,255,0.10)',
                    border: '1px solid black',
                    borderRadius: 4,
                    marginRight: 16,
                    marginLeft: 16
                }}
            >
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex'
                    }}
                >
                    <div style={{ fontSize: 16, flex: 1 }}>
                        <div style={{ fontSize: 12 }}>{format(new Date(user.createdAt), 'dd MMM yyyy, HH:mm')}, {formatDistance(new Date(user.createdAt), new Date())}</div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                marginTop: 8
                            }}
                        >
                            {user.fullName}
                        </div>
                        <div style={{ marginTop: 8 }}>{user.email}</div>
                        <div style={{ marginTop: 8 }}>{user.id}</div>
                        <div style={{ marginTop: 8 }}>
                            Verified: {user.isVerified ? <span style={{ color: GREEN }}>Yes</span> : 'No'}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            Avatar: {user.profilePictureUrl ? <span style={{ color: GREEN }}>Yes</span> : 'No'}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            Subscribed:{' '}
                            {user.isSubscribedToNewsletter ? <span style={{ color: GREEN }}>Yes</span> : 'No'}
                        </div>
                        <div style={{ marginTop: 8 }}>
                            Referral Count:{' '}
                            {user.referralCount ? <span style={{ }}>{user.referralCount}</span> : 'No'}
                        </div>
                        {user.quota ? (
                            <>
                                <div style={{ marginTop: 8, fontWeight: 600, flexDirection: 'row', display: 'flex' }}>
                                    <div>Plan: {user.planType}</div>
                                    <button
                                        style={{ marginLeft: 8, fontSize: 12 }}
                                        onClick={() => {
                                            changePlan(user)
                                        }}
                                    >
                                        Change
                                    </button>
                                </div>
                                <div style={{ marginTop: 8, fontWeight: 600 }}>
                                    <div>
                                        Storage: {prettySize(user.quota.totalStorage)}{' '}
                                        {`(${prettySize(user.quota.maxStorage)} total)`}
                                    </div>
                                </div>
                                <div style={{ marginTop: 8, color: PRIMARY_COLOR_6 }}>
                                    <div>Max file size: {prettySize(user.quota.maxFileSize)}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
                                    <div style={{ fontWeight: 600 }}>
                                        {user.quota.maxMessages}/{user.quota.totalMessages} (
                                        {user.quota.maxMessages - user.quota.totalMessages} messages)
                                    </div>
                                    <button
                                        style={{
                                            marginLeft: 8,
                                            fontSize: 12
                                        }}
                                        onClick={() => {
                                            addMessages(user)
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </>
                        ) : null}

                        {user.analytics ? (
                            <div
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    border: `1px solid ${PRIMARY_COLOR_6}`,
                                    marginTop: 8
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <div style={{ fontWeight: 600 }}>Folders</div>
                                    <div style={{ marginTop: 8 }}>{user.analytics.homeFolderCount} home</div>
                                    <div style={{ marginTop: 8 }}>{user.analytics.subfolderCount} subfolders</div>
                                    <div style={{ marginTop: 8, color: PRIMARY_COLOR_6 }}>
                                        {user.analytics.trashedFolderCount} trashed
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ fontWeight: 600 }}>Messages</div>
                                    <Usage style={{ marginTop: 8 }}>{numberBold(user.analytics.totalInboxMessages, user.quota.totalMessages)} inbox</Usage>
                                    <div style={{ marginTop: 8 }}>
                                        {user.analytics.totalInboxTrashedMessages} inbox trashed
                                    </div>
                                    <div style={{ marginTop: 8, color: PRIMARY_COLOR_6 }}>
                                        {user.analytics.totalTrashedMessages} trashed
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {user.usage ? (
                            <>
                                <div style={{ border: `1px solid ${PRIMARY_COLOR_6}`, marginTop: 8 }}>
                                    <div style={{ fontWeight: 600 }}>Last</div>

                                    <Usage>{timeDistance(user.usage.lastActive)} Active</Usage>
                                    <Usage>{timeDistance(user.usage.lastActiveDesktop)} Desktop</Usage>
                                    <Usage>{timeDistance(user.usage.lastActiveMobile)} Mobile</Usage>

                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ fontWeight: 600, marginTop: 16 }}>Views</div>
                                            <Usage>{numberBold(user.usage.totalFolderViews)} Folder</Usage>
                                            <Usage>{numberBold(user.usage.totalStarViews)} Star</Usage>
                                            <Usage>{numberBold(user.usage.totalTaskViews)} Task</Usage>
                                            <Usage>{numberBold(user.usage.totalMessageViews)} Message</Usage>
                                            <Usage>{numberBold(user.usage.totalSearchViews)} Search</Usage>
                                            <Usage>{numberBold(user.usage.totalFilterViews)} Filter</Usage>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ fontWeight: 600, marginTop: 16 }}>Stats</div>
                                            <Usage>
                                                <IoMdCheckbox style={{ marginRight: 4 }}></IoMdCheckbox>
                                                {numberBold(user.usage.totalTaskMessages, user.quota.totalMessages)}Tasks
                                            </Usage>
                                            <Usage>
                                                <IoMdStar style={{ marginRight: 4 }}></IoMdStar>
                                                {numberBold(user.usage.totalStarMessages, user.quota.totalMessages)}Stars
                                            </Usage>
                                            <Usage>
                                                <IoMdImage style={{ marginRight: 4 }}></IoMdImage>
                                                {numberBold(user.usage.totalImageMessages, user.quota.totalMessages)}Images
                                            </Usage>
                                            <Usage>
                                                <IoMdDocument style={{ marginRight: 4 }}></IoMdDocument>
                                                {numberBold(user.usage.totalFileMessages, user.quota.totalMessages)}Files
                                            </Usage>
                                            <Usage>
                                                <IoMdLink style={{ marginRight: 4 }}></IoMdLink>
                                                {numberBold(user.usage.totalUrlMessages, user.quota.totalMessages)}Urls
                                            </Usage>
                                            <Usage>
                                                <FaTrash style={{ marginRight: 4 }}></FaTrash>
                                                {numberBold(user.usage.totalPermanentlyDeletedMessages, user.quota.totalPermanentlyDeletedMessages)}Deleted
                                            </Usage>

                                        </div>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div style={{ marginRight: 16, flex: 1 }}>
                                            <div style={{ fontWeight: 600, marginTop: 16 }}>Folder</div>
                                            <Usage>{timeDistance(user.usage.lastFolderCreation)} Create</Usage>
                                            <Usage>{timeDistance(user.usage.lastFolderUpdate)} Update</Usage>
                                            <Usage>{timeDistance(user.usage.lastFolderMove)} Move</Usage>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ fontWeight: 600, marginTop: 16 }}>Messages</div>
                                            <Usage>{timeDistance(user.usage.lastMessageCreation)} Create</Usage>
                                            <Usage>{timeDistance(user.usage.lastMessageUpdate)} Update</Usage>
                                            <Usage>{timeDistance(user.usage.lastMessageMove)} Move</Usage>
                                            <div style={{ fontWeight: 600, marginTop: 16 }}>Web</div>
                                            <Usage>{numberBold(user.usage.totalInboxCreatedMessages, user.quota.totalMessages)}Inbox</Usage>
                                            <Usage>
                                                {numberBold(user.usage.totalExtensionCreatedMessages, user.quota.totalMessages)}Extension
                                            </Usage>
                                            <div style={{ fontWeight: 600, marginTop: 16 }}>Mobile</div>
                                            <Usage>
                                                {numberBold(user.usage.totalInboxMobileCreatedMessages, user.quota.totalMessages)}Inbox
                                            </Usage>
                                            <Usage>
                                                {numberBold(user.usage.totalMobileExtensionCreatedMessages, user.quota.totalMessages)}
                                                Extension
                                            </Usage>

                                        </div>
                                    </div>



                                    <div style={{ fontWeight: 600, marginTop: 16 }}>Login</div>
                                    <Usage>{numberBold(user.usage.totalLoginsDesktop)} Desktop</Usage>
                                    <Usage>{numberBold(user.usage.totalLoginsMobile)} Mobile</Usage>
                                    <Usage>{timeDistance(user.usage.lastLogin)} Last Login</Usage>
                                </div>
                            </>
                        ) : null}

                        {user.settings ? (
                            <div style={{ border: '1px solid black', marginTop: 8 }}>
                                <div style={{ fontWeight: 600 }}>Devices</div>
                                {user.settings.usedDesktopWebApp ? <Device>DESKTOP</Device> : null}
                                {user.settings.usedMacApp ? <Device>MAC</Device> : null}
                                {user.settings.usedWindowsApp ? <Device>WINDOWS</Device> : null}
                                {user.settings.usedAndroidApp ? <Device>ANDROID</Device> : null}
                                {user.settings.usedLinuxApp ? <Device>LINUX</Device> : null}
                                {user.settings.usedIosApp ? <Device>IOS</Device> : null}
                                {user.settings.usedClipper ? <Device>WEB CLIPPER</Device> : null}
                            </div>
                        ) : null}
                    </div>
                    {/* <div style={{ flex: 1 }}></div> */}
                    {/* <div style={{ fontSize: 12 }}>{format(new Date(user.createdAt), 'dd MMM yyyy, HH:mm')} </div> */}
                </div>
                <div
                    style={{
                        marginTop: 24,
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            marginRight: 8,
                            cursor: 'pointer'
                        }}
                    >
                        reset password
                    </div>
                    <div
                        style={{
                            marginRight: 8,
                            cursor: 'pointer'
                        }}
                    >
                        lock account
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div
                        style={{
                            color: 'red',
                            cursor: 'pointer'
                        }}
                        onClick={() => onDeleteUser(user)}
                    >
                        delete account
                    </div>
                </div>
            </div>
        );
    };

    // const renderLoader = () => {
    //     console.log('this ss', loadMoreCheck, data.getAllUsers.users);
    //     if (loadMoreCheck) {
    //         if (data.getAllUsers.users && data.getAllUsers.users.length > 10) {
    //             return (
    //                 <div
    //                     style={{
    //                         marginLeft: 40,
    //                         fontWeight: '600',
    //                         marginTop: 16,
    //                         marginBottom: 16,
    //                         color: PRIMARY_COLOR_6
    //                     }}
    //                 >
    //                     Loading users...
    //                 </div>
    //             );
    //         }
    //     }
    // };

    const renderCustomContent = () => {
        return (
            <Messages>
                {/* reference for the end of the list */}

                {findData
                    ? findData.findUsers.users.map((user, i) => {
                        // check for aws as well
                        return (
                            <div key={user.id}>
                                {/* {i === 1 && <Waypoint onEnter={() => _loadingLatest(message)} />} */}
                                {renderUserItem(user)}

                                {/* LoadMore Waypoint */}
                                {renderCustomWaypoint(i)}
                            </div>
                        );
                    })
                    : null}
                {/* {!loadMoreCheck ? <div onClick={loadMore}>click for more</div> : null} */}
            </Messages>
        );
        // if (userSearchInput.length > 0) {
        //     return (
        //         <Messages>
        //             {/* reference for the end of the list */}

        //             {findData
        //                 ? findData.findUsers.users.map((user, i) => {
        //                       // check for aws as well
        //                       return (
        //                           <div key={user.id}>
        //                               {/* {i === 1 && <Waypoint onEnter={() => _loadingLatest(message)} />} */}
        //                               {renderUserItem(user)}

        //                               {/* LoadMore Waypoint */}
        //                               {renderCustomWaypoint(i)}
        //                           </div>
        //                       );
        //                   })
        //                 : null}
        //             {/* {!loadMoreCheck ? <div onClick={loadMore}>click for more</div> : null} */}
        //         </Messages>
        //     );
        // } else {
        //     return (
        //         <Messages>
        //             {data
        //                 ? data.getAllUsers.users.map((user, i) => {
        //                       return (
        //                           <div key={user.id}>
        //                               {/* {i === 1 && <Waypoint onEnter={() => _loadingLatest(user)} />} */}
        //                               {renderUserItem(user)}

        //                               {renderWaypoint(i)}
        //                           </div>
        //                       );
        //                   })
        //                 : null}
        //             {renderLoader()}
        //             {/* {!loadMoreCheck ? <div onClick={loadMore}>click for more</div> : null} */}
        //         </Messages>
        //     );
        // }
    };

    // if (loading) {
    //     return <div>{loading}</div>;
    // }

    // if (data) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}
        >
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 20px'
                }}
            >
                <div
                    style={{
                        backgroundColor: 'rgba(255,255,255,.1)',
                        padding: 10,
                        borderRadius: 3,
                        marginBottom: 8,
                        flex: 1
                    }}
                >
                    <input
                        style={{
                            backgroundColor: 'transparent',
                            backgroundColor: 'transparent',
                            border: 0,
                            fontSize: 18,

                            width: '100%'
                        }}
                        autoFocus
                        onKeyDown={e => {
                            if (e.keyCode === 40 || e.keyCode === 38) {
                                e.preventDefault();
                            }
                        }}
                        value={userSearchInput}
                        onChange={e => handleUserSearchChange(e.target.value)}
                        type="text"
                        placeholder={`Search ${totalUsers}`}
                    ></input>
                </div>
                <button onClick={() => changeOrder()}>{sortCustom ? 'Showing by active' : 'Showing by created'}</button>
            </div>
            {renderCustomContent()}
            <DeleteUserModalAdmin
                user={currentUser}
                isOpen={showDeleteUserModal}
                closeModal={() => {
                    setShowDeleteUserModal(false);
                }}
            />
        </div>
    );
    // }
    return null;
}

const Messages = styled.div`
    grid-column: 3;
    grid-row: 2;
    // padding-left: 20px;
    // padding-right: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 8px;
`;

const Device = styled.div`
    margin-top: 8px;
    color: ${RED};
`;

const Usage = styled.div`
    margin-top: 8px;
    flex-direction: row;
    display: flex;
`;

export default SettingsAdminComponent;
