import { QUERY } from '../../../gql-operations';

function loadMoreFilteredFolders(value, cursor, fetchMore, setLoadMoreCheck) {
    fetchMore({
        query: QUERY.FIND_FOLDERS,
        variables: {
            data: {
                text: value,
                cursor: cursor
            }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
            // console.log('This is previousRes', previousResult);
            // console.log('This is fetch more', fetchMoreResult);
            const previousEntry = previousResult.findFolders.folders;

            // case of the first message in the folder
            if (!previousResult.findFolders.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            // if there is no fetchMore, just return the previous data
            if (!fetchMoreResult.findFolders.cursor) {
                setLoadMoreCheck(false);
                return previousResult;
            }

            // cursor is the same
            if (previousResult.findFolders.cursor === fetchMoreResult.findFolders.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            const newFolders = fetchMoreResult.findFolders.folders;
            const newCursor = fetchMoreResult.findFolders.cursor;

            setLoadMoreCheck(false);
            return {
                findFolders: {
                    // we are not cursoring the folders, that's why it's only previousResult
                    cursor: newCursor,
                    folders: [...(previousEntry && previousEntry.length > 0 ? previousEntry : []), ...newFolders],
                    __typename: previousResult.findFolders.__typename
                }
            };
        }
    });
}

export default loadMoreFilteredFolders;
