// fileUtils.js

// Define a list of supported image MIME types
const imageMimeTypes = [
    'image/jpg',          // JPEG
    'image/jpeg',          // JPEG
    'image/png',           // PNG
    'image/gif',           // GIF
    'image/webp',          // WebP
    'image/svg+xml',       // SVG
    'image/bmp',           // BMP
    'image/tiff',          // TIFF
    'image/x-icon',        // ICO
    'image/heic',          // HEIC (iOS-specific)
    'image/heif',          // HEIF
    'image/avif',          // AVIF
    'image/jp2',           // JPEG 2000
    'image/jpx',           // JPEG 2000
    'image/jpm',           // JPEG 2000
    'image/x-citrix-jpeg', // Citrix JPEG
    'image/x-citrix-png',  // Citrix PNG
    // Add any additional formats as needed
];

/**
 * Checks if a given file type is an image.
 * @param {string} fileType - The MIME type of the file to check.
 * @returns {boolean} - True if the file type is an image, otherwise false.
 */
export const isFileTypeImage = (fileType) => {
    return imageMimeTypes.includes(fileType);
};
