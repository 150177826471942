import React, { useState } from 'react';

import { MUTATION, QUERY } from '../gql-operations';

import environment from '../env-config';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { AuthContext } from '../util/context';
import useUpdateMessageMutation from './useUpdateMessageMutation';
import USER_TYPE from '../util/userType';
import USER_ROLE from '../util/userRole';
import FILE_TYPE from '../util/fileType';

import useUpdateCheckboxes from './useUpdateCheckboxes';

import {
    getCloudinaryBackgroundsFolder,
    getCloudinaryProfilePictureFolder,
    getCloudinaryFileFolder
} from '../util/file-format-name';
import useAddMetadata from '../hooks/useAddMetadata';
import customAnalytics from '../util/custom-analytics';
import useRedirectErrors from '../errors/useRedirectErrors';


// import axios from 'axios';

function useCloudinaryRequest() {
    // const [uploadProgress, setUploadProgress] = useState(undefined);
    const { updateCheckboxes } = useUpdateCheckboxes();
    const { passingErrors } = useRedirectErrors();
    const [createMessage] = useMutation(MUTATION.CREATE_MESSAGE, {
        onCompleted: res => {
            customAnalytics.message_sent(res.createMessage);
        },
        onError: error => {
            passingErrors(error);
            setClientError(error.graphQLErrors);
        }
    });
    const { data } = useQuery(QUERY.ME);
    const { updateFiles } = useUpdateMessageMutation();
    const { savedFromMeta } = useAddMetadata();

    const {
        state: { uploadProgress },
        context: { setUploadProgress, setClientError }
    } = React.useContext(AuthContext);

    const makeCloudinaryReqest = (file, message, type) => {
        // console.log('making s3 req', data, file);
        return new Promise(async (resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', event => {
                if (event.lengthComputable) {
                    const copy = { ...uploadProgress };
                    const id = message ? message.id : file.name;
                    copy[id] = {
                        name: file.name,
                        state: 'pending',
                        percentage: (event.loaded / event.total) * 100,
                        type: 'image'
                    };
                    setUploadProgress(copy);
                }
            });

            xhr.addEventListener('load', event => {
                console.log('how many times LOAD');
                const copy = { ...uploadProgress };
                const id = message ? message.id : file.name;
                copy[id] = {
                    name: file.name,
                    state: 'done',
                    percentage: 100,
                    type: 'image'
                };
                // here we can already clear the upload object
                // setUploadProgress(copy);
                setUploadProgress(undefined);
                // setFileToSend(undefined);
                resolve(xhr.response);
            });

            xhr.addEventListener('error', event => {
                const copy = { ...uploadProgress };
                const id = message ? message.id : file.name;
                copy[id] = {
                    name: file.name,
                    state: 'error',
                    percentage: 0,
                    type: 'image'
                };
                setUploadProgress(copy);
                reject(xhr.response);
            });

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // console.log('you get a resolution', xhr.response);
                        let parsedResponse = JSON.parse(xhr.response);
                        resolve(parsedResponse);
                    } else {
                        reject({
                            status: xhr.status,
                            statusText: xhr.statusText
                        });
                    }
                }
            };

            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', environment.REACT_APP_UPLOAD_PRESET);
            if (type === FILE_TYPE.BACKGROUND) {
                formData.append('folder', getCloudinaryBackgroundsFolder(data.me.id));
            } else if (type === FILE_TYPE.PROFILE_PICTURE) {
                formData.append('folder', getCloudinaryProfilePictureFolder(data.me.id));
            } else {
                formData.append('folder', getCloudinaryFileFolder(data.me.id));
            }

            xhr.open('PUT', `https://api.cloudinary.com/v1_1/${environment.REACT_APP_CLOUD_NAME}/image/upload`);

            xhr.send(formData);
        });
    };

    const sendImageMutation = async (file, values, folder, autoCheckbox) => {
        const cloudResponse = await makeCloudinaryReqest(file);
        console.log('this is the cloudinary response', cloudResponse);

        let aspectRatio = cloudResponse.height / cloudResponse.width;
        let type = cloudResponse.resource_type + '/' + cloudResponse.format;

        await createMessage({
            variables: {
                data: {
                    folderId: folder.id,
                    content: {
                        title: values.title ? values.title : '',
                        isCheckbox: autoCheckbox,
                        image: {
                            public_id: cloudResponse.public_id,
                            url: cloudResponse.secure_url,
                            name: cloudResponse.original_filename,
                            displayName: cloudResponse.original_filename,
                            type: cloudResponse.resource_type,
                            format: cloudResponse.format,
                            size: cloudResponse.bytes,
                            aspect_ratio: aspectRatio,
                            width: cloudResponse.width
                        },
                        metadata: savedFromMeta()
                    }
                }
            },
            update: async (store, { data: { createMessage } }) => {
                if (autoCheckbox) {
                    updateCheckboxes([createMessage]);
                }
            }
        });
    };

    const sendCloudinaryFiles = async (values, files, folder, autoCheckbox) => {
        if (files) {
            let response = files.forEach(async file => {
                // console.log(response);
                // if (data.me.role === USER_ROLE.ADMIN) {
                //     sendImageMutation(file, values, folder);
                // } else if (data.me.planType === USER_TYPE.PRO) {
                //     sendImageMutation(file, values, folder);
                // } else if (data.me.planType === USER_TYPE.PERSONAL && file.size <= data.me.quota.maxFileSize) {
                //     sendImageMutation(file, values, folder);
                // } else
                if (data.me) {
                    if (file.size <= data.me.quota.maxFileSize) {
                        // this one should be deleted later on TODO PLAN
                        sendImageMutation(file, values, folder, autoCheckbox);
                    }
                }
            });
            return response;
        }
    };

    const updateMessageImages = async (images, message) => {
        // for now only one image per message
        let currentFile = images[0];

        const cloudResponse = await makeCloudinaryReqest(currentFile, message);
        // console.log('this is the response', cloudResponse);
        let aspectRatio = cloudResponse.height / cloudResponse.width;
        let type = cloudResponse.resource_type + '/' + cloudResponse.format;

        const content = {
            image: {
                public_id: cloudResponse.public_id,
                url: cloudResponse.secure_url,
                name: cloudResponse.original_filename,
                displayName: cloudResponse.original_filename,
                type: cloudResponse.resource_type,
                format: cloudResponse.format,
                size: cloudResponse.bytes,
                aspect_ratio: aspectRatio,
                width: cloudResponse.width
            }
        };

        updateFiles(message, content);
    };

    return {
        makeCloudinaryReqest,
        sendCloudinaryFiles,
        updateMessageImages
    };
}

export default useCloudinaryRequest;

// const axiosSending = async (file) => {
// 	const formData = new FormData();
// 	formData.append('file', file);
// 	formData.append('upload_preset', REACT_APP_UPLOAD_PRESET);

// 	const config = {
// 		onUploadProgress: (progressEvent) =>
// 			console.log('cloudinary progress', progressEvent),
// 	};

// 	const response = await axios.put(
// 		`https://api.cloudinary.com/v1_1/${REACT_APP_CLOUD_NAME}/image/upload`,
// 		formData,
// 		config
// 	);

// 	console.log('axios resp', response);
// };
