import { gql } from 'apollo-boost';

// input SendVerificationEmailInput {
//     email: String!
// }

// global error catch
// ... on Error {
//     message
// }

export const SEND_VERIFICATION_EMAIL = gql`
    mutation($data: SendVerificationEmailInput!) {
        sendVerificationEmail(data: $data) {
            __typename
            ... on SendVerificationEmailSuccess {
                success
            }
            ... on UserNotFound {
                message
            }
            ... on UserAlreadyVerified {
                message
            }
            ... on InternalServerError {
                message
            }
        }
    }
`;
