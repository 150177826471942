import React from 'react';

// function useGetImagePreviews() {
// return readerObject + fileObject
function asyncFunction(file, resolve) {
    let reader = new FileReader();
    reader.onloadend = () => {
        // console.log('are you so fast', reader);
        let folderAndPreview = {
            reader: reader,
            file: file
        };
        resolve(folderAndPreview);
    };

    reader.readAsDataURL(file);
}

const getImagePreviews = async files => {
    let requests = files.map(item => {
        return new Promise(resolve => {
            asyncFunction(item, resolve);
        });
    });

    return Promise.all(requests).then(res => {
        // console.log('done', res);
        return res;
    });
};

//     return [getImagePreviews]
// }

export default getImagePreviews;
