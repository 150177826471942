import { gql } from '@apollo/client';

// input CancelSubscriptionInput {
// 	 stripeId: ID!
// }

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($data: CancelSubscriptionInput!) {
    cancelSubscription(data: $data) {
      stripeId
    }
  }
`;
