import { format } from 'date-fns';

export const formatS3Filename = (userId, filename) => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = length => {
        return Math.random()
            .toString(36)
            .substring(2, length + 2);
    };

    const cleanFileName = filename ? filename.replace(/ /g, '_') : randomString(8);
    const newFilename = `users/${userId}/${date}/${randomString(8)}-${randomString(4)}-${randomString(
        4
    )}-${randomString(4)}-${randomString(12)}/${cleanFileName}`;

    return newFilename;
};

export const getCloudinaryFileFolder = userId => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = length => {
        return Math.random()
            .toString(36)
            .substring(2, length + 2);
    };

    const folder = `users/${userId}/files/${date}/${randomString(8)}-${randomString(4)}-${randomString(
        4
    )}-${randomString(4)}-${randomString(12)}`;

    return folder;
};

export const getCloudinaryBackgroundsFolder = userId => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = length => {
        return Math.random()
            .toString(36)
            .substring(2, length + 2);
    };

    const folder = `users/${userId}/backgrounds/${date}/${randomString(8)}-${randomString(4)}-${randomString(
        4
    )}-${randomString(4)}-${randomString(12)}`;

    return folder;
};

export const getCloudinaryProfilePictureFolder = userId => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = length => {
        return Math.random()
            .toString(36)
            .substring(2, length + 2);
    };

    const folder = `users/${userId}/avatars/${date}/${randomString(8)}-${randomString(4)}-${randomString(
        4
    )}-${randomString(4)}-${randomString(12)}`;

    return folder;
};