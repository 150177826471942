import React from 'react';
import { IoCloudOffline } from 'react-icons/io5';
import { WHITE, PRIMARY_COLOR_6 } from './Colors';

function ImageNAIcon({ size, color, title, ...rest }) {
    return (
        <div
            style={{
                // backgroundColor: 'cornflowerblue',
                justifyContent: 'center',
                // height: 40,
                // width: 40,
                alignItems: 'center',
                // marginRight: 8,
                // display: 'flex',
                // borderRadius: 50
            }}
        >
            <IoCloudOffline
                size={size ? size : 20}
                color={color ? color : PRIMARY_COLOR_6}
                title={title ? title : 'Image not available'}
                {...rest}
            />
        </div>
    );
}

export default ImageNAIcon;
