import React from 'react';

import Modal from './Modal';

import { useApolloClient, useMutation } from '@apollo/react-hooks';

import { MUTATION, QUERY } from '../../gql-operations';

import styled from 'styled-components';
import { PALE_WHITE_6, RED, WHITE } from '../../appearance/Colors';
import customAnalytics from "../../util/custom-analytics";
import DangerTagButton from '../Action/DangerTagButton';
import DefaultTag from '../Action/DefaultTag';
import { useHistory } from 'react-router-dom';
import { format, compareAsc, formatDistance } from 'date-fns';

function CancelSubscriptionModal({ isOpen, closeModal, user, purchase }) {
    // const [deleteUser, { loading }] = useMutation(MUTATION.DELETE_USER, {
    //     onCompleted: res => {
    //         console.log('completed', res)
    //         responseToDelete(res);
    //     },
    //     onError: res => {
    //         console.log('error', res)
    //     }
    // })

    const prettifyDate = (date) => {
        return format(new Date(date), 'dd MMM yyyy, HH:mm')
    }


    // const responseToDelete = (regResponse) => {
    //     if (regResponse) {
    //         switch (regResponse.deleteUser.__typename) {
    //             case 'User':
    //                 signOut();
    //             case 'DbStartTransactionError':
    //                 return <ErrorText>{regResponse.deleteUser.message}</ErrorText>;
    //             case 'InternalServerError':
    //                 return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
    //             default:
    //                 return <ErrorText>Unknown error</ErrorText>;
    //         }
    //     }
    // }

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    const renderDates = () => {
        if (purchase) {
            // { formatDistance(new Date(purchase.currentPeriodEnd), new Date()) } { prettifyDate(purchase.currentPeriodEnd) }
            return (
                <span style={{fontWeight: '600', color: RED}}>
                    ({formatDistance(new Date(purchase.currentPeriodEnd), new Date())})
                    <span> </span>
                    {prettifyDate(purchase.currentPeriodEnd)}
                </span>
            )
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={ModalStyle}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Cancel Subscription Modal"
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1, color: WHITE, fontSize: 18, fontWeight: '600' }}>Your subscription will be cancelled</div>
                <p style={{ color: PALE_WHITE_6, fontSize: 16, marginTop: 8 }}>By cancelling your subscription you will loose access to Luckynote Pro benefits, such as saving unlimited messages as well as expanded storage. Your messages will be saved, but you might not be able to add new ones. The subscription will remain active until the end of the billing month, which is in this case in {renderDates()}</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}></div>
                    <DefaultTag
                        label="Cancel"
                        onClick={() => closeModal(false)}
                        style={{ marginRight: 8 }}
                    ></DefaultTag>
                    <DangerTagButton
                        label='Cancel Subscription'
                        onClick={() => {
                            closeModal(true);
                        }}
                    ></DangerTagButton>
                </div>
            </div>
        </Modal>
    );
}

const ModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // color: 'black',
        borderRadius: 10,
        border: 0,
        maxWidth: 640,
        backgroundColor: '#222729'
    }
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;


export default CancelSubscriptionModal;
