import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import customAnalytics from './util/custom-analytics';
import environment from './env-config.js'

class App extends Component {
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            // console.log('on route change');
            if (environment.REACT_NODE_ENV === 'production') {
                customAnalytics.pageview(window.location.pathname + window.location.search + window.location.hash, window.location.pathname + window.location.search + window.location.hash)
            }
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }
    render() {
        return this.props.children;
    }
}
export default withRouter(App);
