import React from 'react';
import styled from 'styled-components';
import MessagePreviewComponent from './MessagePreviewComponent';
import Hotkeys from 'react-hot-keys';

import { AuthContext } from '../util/context';

function FolderOptionsComponent({ }) {
    const {
        state: { selectedMessage }
    } = React.useContext(AuthContext);

    if (selectedMessage) {
        return (
            <MessageBodyContainer>
                <MessagePreviewComponent message={selectedMessage}></MessagePreviewComponent>
            </MessageBodyContainer>
        );
    }
    return null;
}

const MessageBodyContainer = styled.div`
    width: 330px;
    // background-color: moccasin;
    background-color: #393d3f;
    // padding: 16px;
    // justify-content: space-between;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // right: 0;
    // bottom: 0;
    // height: 100%;
`;

export default FolderOptionsComponent;
