import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { AuthContext } from '../util/context';

function MyDropzone({ title, children, filesSelected, disabled }) {
    const [files, setFiles] = useState(null);

    const {
        state: { selectedFolder }
    } = React.useContext(AuthContext);

    const onDrop = useCallback(async acceptedFiles => {
        // Do something with the files
        // console.log('should be sent to this folder', folder);
        // console.log('i got some files', acceptedFiles);
        if (!disabled) {
            await setFiles(acceptedFiles);
            await filesSelected(acceptedFiles);
        }
        //resize automatski
    }, [disabled]);

    useEffect(() => {
        // if (file) {
        // 	(async function() {
        // 		// await submit();
        // 		// scrollToEnd();
        // 	})();
        // }
        return () => {
            setFiles(null);
        };
    }, [files]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    const renderTitle = () => {
        console.log('seelcted', selectedFolder);
        if (title) {
            return (
                <div style={{ color: 'white' }}>
                    <div style={{ opacity: 0.8 }}>{title}</div>
                </div>
            );
        } else {
            return (
                <div style={{ color: 'white' }}>
                    <div style={{ opacity: 0.8, marginBottom: 4 }}>Drop files here to send them to</div>
                    <div>{selectedFolder.title}</div>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            <section
                // onDrag={e => {
                // 	console.log('please fix');
                // }}
                // onDragOverCapture={e => {
                // 	console.log('please fix');
                // }}
                {...getRootProps()}
                style={{
                    // pointerEvents: 'none',
                    // zIndex: 9999,
                    // position: 'absolute',
                    // top: 0,
                    // left: 0,
                    // right: 0,
                    // bottom: 0,
                    gridColumn: 3,
                    gridRow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    flex: 1,
                    // backgroundColor: isDragActive
                    // 	? 'rgba(255,255,255,.3)'
                    // 	: 'transparent',
                    position: 'relative',
                    outline: 'none'
                    // flex: 1
                }}
            >
                {/* <input {...getInputProps()} /> */}
                {/* {isDragActive ? (
					<p>Drop the files here ...</p>
				) : ( */}
                {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
                {isDragActive && !disabled ? (
                    <div
                        style={{
                            background: 'red',
                            flex: 1,
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            bottom: 8,
                            left: 8,
                            zIndex: 99999999,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            border: 'rgba(255,255,255,.8)',
                            backgroundColor: 'rgb(57, 61, 63)',
                            borderRadius: 4,
                            borderStyle: 'solid',
                            borderWidth: 2
                        }}
                    >
                        {renderTitle()}
                    </div>
                ) : null}
                {children}
                {/* )} */}
            </section>
            {/* <button onClick={submit}>Submit</button> */}
        </React.Fragment>
    );
}

export default MyDropzone;
