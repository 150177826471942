import { gql } from 'apollo-boost';

// input CheckboxInput {
// 	messageId: ID!
// }

export const REMOVE_CHECKBOX = gql`
    mutation($data: CheckboxInput!) {
        removeCheckbox(data: $data) {
            id
            title
            body
            createdAt
            updatedAt
            deletedAt
            image {
                url
                name
                displayName
                type
                format
                size
                width
                aspect_ratio
                public_id
            }
            file {
                url
                name
                displayName
                type
                format
                size
                mime
            }
            isCheckbox
            isChecked
            isStarred
            folder {
                id
                title
                owner
                parent
                deletedAt
            }
            urlMeta {
                author
                date
                description
                image
                logo
                publisher
                title
                url
                public_id
                width
                format
                aspect_ratio
                size
            }
        }
    }
`;
