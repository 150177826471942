const environment = {
REACT_APP_CLOUD_NAME: 'luckyimagecloud',
REACT_APP_UPLOAD_PRESET: 'uyxj6esi',
REACT_MIXPANEL_TOKEN: '2203fce756d1e5fe8a681e80bd9d5867',
REACT_GOOGLE_ANALYTICS: 'UA-171693503-1',
REACT_FACEBOOK_PIXEL: '3049294228689093',
REACT_NODE_ENV: 'production',
REACT_BACKEND_URL: 'https://luckynote.io/graphql',
REACT_SUBSCRIPTION_URL: 'wss://luckynote.io/graphql',
REACT_STRIPE_PUBLISHABLE_KEY: 'pk_live_lPOM4HA03WMOKWp2qiOS0Dlh007p3yAdIt',
}
export default environment
