import { gql } from 'apollo-boost';

// input UpdateFolderInput {
// 	folderId: ID!
// 	title: String!
// }

export const UPDATE_FOLDER = gql`
    mutation($data: UpdateFolderInput!) {
        updateFolder(data: $data) {
            id
            title
            parent
            owner
            before
            deletedAt
        }
    }
`;