import React from 'react';
import * as ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const Modal = ({ isOpen, onAfterOpen, onRequestClose, style = {}, children }) => {
  if(!isOpen) return null;

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={style}
      //className={className || 'Modal'}
      overlayClassName="Overlay"
      contentLabel="Example Modal"
    >
      { children }
    </ReactModal>
  );
};

export default Modal;
