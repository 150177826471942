import React, { useState, useEffect, useReducer } from 'react';

import Modal from './Modal';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { QUERY, MUTATION } from '../../gql-operations';

import styled, { css } from 'styled-components';

import SelectableMessagesList from '../SelectableMessagesList';

import loadMoreFindMessages from '../../components/Folder/Components/loadMoreFindMessages';

import useRedirectErrors from '../../errors/useRedirectErrors';
import {
    PALE_WHITE_4,
    WHITE,
    PALE_WHITE_6,
    GREEN,
    RED,
    PALE_WHITE_2,
    PALE_WHITE,
    PALE_WHITE_1
} from '../../appearance/Colors';

import { IoIosClose, IoMdOpen } from 'react-icons/io';

import { AuthContext } from '../../util/context';
import { SEARCH_MESSAGES, STARRED, CHECKBOXES, HIERARCHY } from '../../util/specialFolderEnums';

import customAnalytics from '../../util/custom-analytics';

const SHOW_TYPE = {
    DELETED: 'DELETED',
    GLOBAL: 'GLOBAL'
};

function SearchMessagesModal({ isOpen, closeModal, message }) {
    const [messagesSearchInput, setMessagesSearchInput] = useState('');
    // const [searchGlobal, setSearchGlobal] = useState(false);
    const { passingErrors } = useRedirectErrors();
    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [loadMoreCheck, setLoadMoreCheck] = useState(true);
    // const [deleted, setDeleted] = useState(false);
    const [
        findMessages,
        { data: findMessagesData, error: findMessagesError, loading: findMessagesLoading, fetchMore }
    ] = useLazyQuery(QUERY.FIND_MESSAGES, {
        onError: res => {
            passingErrors(res);
        },
        fetchPolicy: 'network-only'
    });

    const {
        state: { selectedFolder: folder, searchGlobal, searchDeletedMessages, searchTerm },
        context: {
            setSelectedFolder,
            setSearchGlobal,
            setShowDeletedMessages,
            setSearchTerm,
            setSearchDeletedMessages,
            setSearchActive
        }
    } = React.useContext(AuthContext);

    // const [
    // 	findFolders,
    // 	{
    // 		data: findFolderData,
    // 		error: findFolderError,
    // 		loading: findFolderLoading,
    // 		client
    // 	}
    // ] = useLazyQuery(FIND_FOLDERS_QUERY);

    // const [
    // 	moveMessages,
    // 	{ data: moveSuccess, error: moveError, loading: moveLoading }
    // ] = useMutation(MOVE_MESSAGES_MUTATION, {
    // 	onCompleted(data) {
    // 		console.log('i completed', data);
    // 		closeModal();
    // 	}
    // });

    useEffect(() => {
        // loading the first batch
        // handleMessagesSearchChange('');
        if (isOpen) {
            findMessages({
                variables: {
                    data: {
                        text: searchTerm,
                        folderId: searchGlobal || (folder && folder.id === HIERARCHY) ? '' : folder.id,
                        isTrash: searchDeletedMessages
                    }
                }
            });
            customAnalytics.modalview('Search Messages');
            customAnalytics.pageview('/modal/search','SearchMessagesModal');
            customAnalytics.search_active();
            customAnalytics.search_action({
                "search_global": searchGlobal
            });
        }

        return () => {
            // setShowDeletedMessages(false)
            // cleanup
            // if (messagesSearchInput.length === 0) {
            //     setMessagesSearchInput('');
            // }
        };
    }, [isOpen]);

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    const handleMessagesSearchChange = (value, type) => {
        setMessagesSearchInput(value);
        setSearchTerm(value);
        // here we ask if you want to search global or local
        // console.log('you should reload it', value, deleted);

        if (type === SHOW_TYPE.DELETED) {
            let tempDeleted = !searchDeletedMessages;
            customAnalytics.search_action({
                'search_trashed_messages': tempDeleted
            });
            findMessages({
                variables: {
                    data: {
                        text: value,
                        folderId: searchGlobal || folder.id === HIERARCHY ? '' : folder.id,
                        isTrash: tempDeleted
                    }
                }
            });
            setSearchDeletedMessages(tempDeleted);
            // setDeleted(tempDeleted);
        } else if (type === SHOW_TYPE.GLOBAL) {
            let tempGlobal = !searchGlobal;
            customAnalytics.search_action({
                'search_global': tempGlobal
            });
            findMessages({
                variables: {
                    data: {
                        text: value,
                        folderId: tempGlobal || folder.id === HIERARCHY ? '' : folder.id,
                        isTrash: searchDeletedMessages
                    }
                }
            });
            setSearchGlobal(tempGlobal);
        } else {
            findMessages({
                variables: {
                    data: {
                        text: value,
                        folderId: searchGlobal || folder.id === HIERARCHY ? '' : folder.id,
                        isTrash: searchDeletedMessages
                    }
                }
            });
        }

        if (!loadMoreCheck) {
            // console.log('load', loadMoreCheck);
            setLoadMoreCheck(true);
        }
    };

    // if (findMessagesData) {
    //     // console.log('data', findMessagesData);
    // }

    const showMessagesList = () => {
        if (findMessagesData && findMessagesData.findMessages.messages.length === 0 && messagesSearchInput.length > 0) {
            return [];
        } else if (findMessagesData && findMessagesData.findMessages.messages.length > 0) {
            // console.log(
            // 	'listing out my messages',
            // 	findMessagesData.findMessages.messages
            // );
            return findMessagesData.findMessages.messages;
        }
        return undefined;
    };

    const renderFooter = () => {
        if (loadMoreCheck) {
            if (findMessagesData && findMessagesData.findMessages.messages.length >= 10) {
                return (
                    <div
                        style={{
                            // justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 16,
                            color: PALE_WHITE_6
                        }}
                    >
                        Loading...
                    </div>
                );
            }
        }
    };

    const loadContent = () => {
        if (findMessagesLoading) {
            return <LoadingText>Loading...</LoadingText>;
        } else {
            return (
                <>
                    <SelectableMessagesList
                        items={showMessagesList()}
                        showDeleted={searchDeletedMessages}
                        showGlobal={searchGlobal}
                        selectedItem={value => {
                            // function to jump to message and to selected folder
                            console.log('selected message', value);
                            closeModal(value);
                        }}
                        loadMore={loadMore}
                        actionDeleted={showDeleted}
                        actionGlobal={showGlobal}
                        currentfolder={folder}
                    ></SelectableMessagesList>
                    {renderFooter()}
                </>
            );
        }
    };

    const showDeleted = () => {
        handleMessagesSearchChange(messagesSearchInput, SHOW_TYPE.DELETED);
    };

    const showGlobal = () => {
        handleMessagesSearchChange(messagesSearchInput, SHOW_TYPE.GLOBAL);
    };

    const loadMore = () => {
        loadMoreFindMessages(
            messagesSearchInput,
            searchGlobal || folder.id === HIERARCHY ? '' : folder.id,
            findMessagesData.findMessages.cursor,
            searchDeletedMessages,
            fetchMore,
            setLoadMoreCheck
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div style={{ display: 'flex', marginBottom: 8 }}>
                    <div
                        style={{
                            color: 'rgba(255,255,255,.6)',
                            fontWeight: 500,
                            fontSize: 16,
                            flex: 1
                        }}
                    >
                        Search messages {!searchGlobal ? 'in ' + folder.title : 'in All'}
                    </div>
                    <Fullscreen
                        onClick={() => {
                            console.log('why', folder, searchDeletedMessages);
                            // setShowDeletedMessages(deleted);
                            // setSelectedFolder(selectedFolder);
                            setSearchActive(true);
                            closeModal(true);
                        }}
                    >
                        <IoMdOpen size={16} style={{ marginRight: 4 }} />
                        Add filters
                    </Fullscreen>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
                    <div
                        style={{
                            backgroundColor: 'rgba(255,255,255,.1)',
                            padding: 10,
                            borderRadius: 3,
                            flex: 1
                        }}
                    >
                        <input
                            id="searchInput"
                            style={{
                                color: 'rgba(255,255,255,.8)',
                                backgroundColor: 'transparent',
                                backgroundColor: 'transparent',
                                border: 0,
                                fontSize: 18,
                                color: 'white',
                                width: '100%'
                            }}
                            autoFocus
                            onKeyDown={e => {
                                if (e.keyCode === 40 || e.keyCode === 38) {
                                    e.preventDefault();
                                }
                            }}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            onFocus={e => {
                                e.target.select();
                            }}
                            value={messagesSearchInput}
                            onChange={e => handleMessagesSearchChange(e.target.value)}
                            type="text"
                            placeholder={`Search`}
                        ></input>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: WHITE }}>
                        {/* <div style={{ flex: 1 }}></div> */}
                        {folder &&
                            (folder.id === STARRED || folder.id === CHECKBOXES || folder.id === HIERARCHY) ? null : (
                            <GlobalButton active={searchGlobal} onClick={showGlobal}>
                                <span style={{ lineHeight: '16px' }}>Search all</span>
                                {searchGlobal ? <IoIosClose size={18}></IoIosClose> : null}
                            </GlobalButton>
                        )}

                        <DeletedButton
                            style={{ lineHeight: '16px' }}
                            active={searchDeletedMessages}
                            onClick={showDeleted}
                        >
                            <span>Search trash</span>
                            {searchDeletedMessages ? <IoIosClose size={18}></IoIosClose> : null}
                        </DeletedButton>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        height: 400,
                        flexDirection: 'column',
                        overflowY: 'auto',
                        marginTop: 4
                    }}
                >
                    {loadContent()}
                </div>
                {/* {loadMoreCheck ? <LoadMore onClick={() => loadMore()}>Load more</LoadMore> : null} */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ textAlign: 'center', color: PALE_WHITE_4 }}>
                        <small>Use up down keys and hit enter to select, or use the mouse</small>
                    </span>
                </div>
            </div>
        </Modal>
    );
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 10,
        border: 0,
        // borderColor: 'red',
        // maxWidth: 640,
        width: 840,
        // maxWidth: '80vh',
        // minHeight: '80%',
        maxHeight: '80%',
        backgroundColor: '#222729'
        // overflow: 'none'
    }
};

const LoadMore = styled.div`
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }
`;

const LoadingText = styled.div`
    display: flex;
    // justify-content: center;
    color: ${PALE_WHITE_6};
    margin-top: 16px;
`;

const GlobalButton = styled.div`
    // margin-right: 16px;
    background-color: ${PALE_WHITE_1};
    ${props =>
        props.active &&
        css`
            background-color: ${GREEN};
        `}
    border-radius: 20px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    // justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    cursor: pointer;
    height: 30px;
    margin-left: 8px;
    padding-right: ${props => (props.active ? '8px' : '12px')};
`;

const Fullscreen = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 4px;
    border: 1px solid ${PALE_WHITE_2};
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 14px;
    color: ${PALE_WHITE_6};
    &:hover {
        background-color: ${PALE_WHITE};
        color: ${WHITE};
    }
`;

const DeletedButton = styled.div`
    // margin-right: 16px;
    background-color: ${PALE_WHITE_1};
    ${props =>
        props.active &&
        css`
            background-color: ${RED};
        `}
    border-radius: 20px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    // justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    cursor: pointer;
    height: 30px;
    padding-right: ${props => (props.active ? '8px' : '12px')};
    margin-left: 8px;
`;

export default SearchMessagesModal;
