import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useMutation } from '@apollo/react-hooks';

import { MUTATION } from '../gql-operations';

import styled from 'styled-components';

import ActionTagButton from '../components/Action/ActionTagButton';

import { GREEN, PALE_WHITE, WHITE, DETAIL_TEXT, RED, GREEN_OP, PRIMARY_TEXT, PALE_WHITE_6 } from '../appearance/Colors';
import { useHistory } from 'react-router-dom';
import { prettySize } from '../util/fileSizeCalculator';
import ProfilePicture from '../components/Folder/Components/ProfilePicture';
import customAnalytics from "../util/custom-analytics";
import USER_TYPE from '../util/userType';
import DeleteUserModal from './Modals/DeleteUserModal';
import { DESCRIPTION } from '../constants';
import { IoArrowUpCircle } from "react-icons/io5";

const SettingsAccountComponent = ({ user, closeModal, changeTab }) => {
    let history = useHistory();

    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [referralLinkUrl, setReferralLinkUrl] = useState('');
    const [updateUserDetails] = useMutation(MUTATION.UPDATE_USER_DETAILS, {
        onCompleted: res => {
            if (res) {
                customAnalytics.user_edit();
            }
        }
    });
    const [verificationSent, setVerificationSent] = useState(false);
    const [sendVerificationEmail] = useMutation(MUTATION.SEND_VERIFICATION_EMAIL, {
        onCompleted: res => {
            if (res.sendVerificationEmail && res.sendVerificationEmail.__typename === 'SendVerificationEmailSuccess') {
                setVerificationSent(true);
            }
        }
    });
    const [generateReferralLink] = useMutation(MUTATION.GENERATE_REFERRAL_LINK, {
        onCompleted: res => {
            if (res) {
                setReferralLinkUrl(res.generateReferralLink)
            }
        },
        onError: err => {
            console.log('err', err)
        }
    })

    const updateUserMutation = values => {
        updateUserDetails({
            variables: {
                data: {
                    fullName: values.fullName
                }
            }
        });
        // closeModal();
    };

    const generateReferralLinkMutation = values => {
        console.log('first', user)
        generateReferralLink({
            variables: {
                data: {
                    referralId: user.me.referralId
                }
            }
        });
    }

    useEffect(() => {
        if (user && user.me) {
            generateReferralLinkMutation()
        }
    }, [user && user.me])


    const verifiedAccount = () => {
        if (!user.me.isVerified && !verificationSent) {
            return (
                <div
                    style={{ color: RED, marginTop: 8, fontSize: 16, cursor: 'pointer' }}
                    onClick={() => {
                        sendVerificationEmail({
                            variables: {
                                data: {
                                    email: user.me.email
                                }
                            }
                        });
                    }}
                >
                    Verify account
                </div>
            );
        } else if (!user.me.isVerified && verificationSent) {
            return <div style={{ color: GREEN, marginTop: 8, fontSize: 16 }}>The email has been sent</div>;
        } else {
            return null;
        }
    };

    const copyToClip = async () => {
        await navigator.clipboard.writeText(referralLinkUrl);
        setCopiedToClipboard(true);
        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 1500);
        customAnalytics.message_action({
            "action": "Copy",
            "type": "Referral"
        })
    }

    const renderReferralLink = () => {
        if (referralLinkUrl) {
            return (
                <>
                    <div
                        style={{
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            color: 'white',
                            padding: 16,
                            borderRadius: 6,
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(255,255,255,.8)'
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', color: DETAIL_TEXT, fontWeight: '600' }}>
                            <span style={{ flex: 1 }}>Invite a friend</span>
                            <span style={{}}>
                                {user.me.referralCount} / {user.me.referralCountMax}
                            </span>{' '}
                        </div>
                        <div style={{ flexDirection: 'row', display: 'flex', marginTop: 8, fontWeight: '600' }}>

                            <span style={{ marginRight: 8 }} onClick={() => copyToClip()}>
                                <div>{referralLinkUrl}</div>
                            </span>
                            {copiedToClipboard ?
                                <div style={{ color: GREEN }}>Copied!</div>
                                : <div onClick={() => copyToClip()} style={{ color: GREEN, cursor: 'pointer' }}>Copy</div>}
                        </div>

                        <div style={{ marginTop: 8, maxWidth: 600 }}>
                            <span style={{ color: PALE_WHITE_6 }}>
                                {DESCRIPTION.REFERRAL_DESCRIPTION}
                            </span>
                        </div>

                        {/* <div style={{ marginTop: 4 }}>
                            <span style={{ fontWeight: '600' }}>
                                {user.me.referralCount} / {user.me.referralCountMax}
                            </span>{' '}
                            referrals left
                        </div> */}
                    </div>

                </>
            )
        }
    }

    const renderUpgradeSectionOld = () => {
        if (user.me.planType === USER_TYPE.PRO) {
            return null
        } else {
            return (
                <div
                    style={{
                        backgroundColor: GREEN_OP,
                        padding: 16,
                        borderRadius: 6,
                        marginTop: 16,
                        fontSize: 16,
                        color: 'rgba(255,255,255,.8)'
                    }}
                >
                    <span style={{ color: PRIMARY_TEXT, fontWeight: '600' }}>Luckynote PRO 🎉</span>

                    <div style={{ marginTop: 8 }}>
                        <span style={{ fontWeight: '600' }}>
                            Unlimited
                        </span>{' '}
                        messages
                    </div>

                    <div style={{ marginTop: 4 }}>
                        <span style={{ fontWeight: '600' }}>
                            5GB
                        </span>{' '}
                        storage
                    </div>
                    <ActionTagButton
                        label="Upgrade"
                        type="submit"
                        style={{ backgroundColor: GREEN, marginTop: 12 }}
                        onClick={() =>
                            window.open(
                                `https://buy.stripe.com/28obMG34RarF6Pu3cf`
                            )
                        }
                    ></ActionTagButton>
                </div>
            )
        }
    }

    const renderUpgradeSection = () => {
        if (user.me.planType === USER_TYPE.PRO) {
            return (
                <div style={{ display: "flex", flexDirection: 'row' }}>
                    <ActionTagButton
                        style={{ backgroundColor: PALE_WHITE, marginTop: 12, flex: 1 }}
                        label="Previous purchases"
                        onClick={async () => {
                            await closeModal();
                            history.push('/purchases')
                        }}
                    >
                    </ActionTagButton>
                    <div style={{ width: 8 }}></div>
                    <ActionTagButton
                        icon={<IoArrowUpCircle color={WHITE} size={16} style={{ marginRight: 4 }}></IoArrowUpCircle>}
                        style={{ backgroundColor: GREEN, marginTop: 12, flex: 1 }}
                        label="Upgrade"
                        onClick={async () => {
                            changeTab(1)
                        }}
                    >
                    </ActionTagButton>
                </div>
            )
        } else {
            return (
                <ActionTagButton
                    icon={<IoArrowUpCircle color={WHITE} size={16} style={{ marginRight: 4 }}></IoArrowUpCircle>}
                    style={{ backgroundColor: GREEN, marginTop: 12, flex: 1 }}
                    label="Upgrade"
                    onClick={async () => {
                        changeTab(1)
                    }}
                >
                </ActionTagButton>
            )
        }
    }

    if (user) {
        // console.log('user', user);
        let leftStorage = user.me.quota.maxStorage - user.me.quota.totalStorage;
        return (
            <div style={{ flex: 1, overflow: 'auto' }}>
                <div>
                    <div id="picture">
                        <ProfilePicture url={user.me.profilePictureUrl} name={user.me.fullName}></ProfilePicture>
                    </div>
                    <div
                        style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)', marginTop: 16, marginBottom: 16 }}
                    />
                    <div>
                        <div style={{ fontSize: 18, fontWeight: 600 }}>Your current plan</div>
                        <div
                            style={{
                                backgroundColor: 'rgba(255,255,255,0.1)',
                                padding: 16,
                                borderRadius: 6,
                                marginTop: 16,
                                fontSize: 16,
                                color: 'rgba(255,255,255,.8)'
                            }}
                        >
                            <span style={{ color: DETAIL_TEXT, fontWeight: '600' }}>{user.me.planType}</span>
                            {/* 10000 is a PRO quota */}
                            {user.me.planType === USER_TYPE.PRO && user.me.quota.maxMessages >= 10000 ?
                                <div style={{ marginTop: 8 }}>
                                    <span style={{ fontWeight: '600' }}>
                                        {user.me.quota.totalMessages}
                                    </span>{' '}
                                    total (unlimited)
                                </div>
                                :
                                <div style={{ marginTop: 8 }}>
                                    <span style={{ fontWeight: '600' }}>
                                        {user.me.quota.maxMessages - user.me.quota.totalMessages} /{' '}
                                        {user.me.quota.maxMessages}
                                    </span>{' '}
                                    messages left
                                </div>
                            }
                            <div style={{ marginTop: 4 }}>
                                <span style={{ fontWeight: '600' }}>
                                    {prettySize(leftStorage)} / {prettySize(user.me.quota.maxStorage)}
                                </span>{' '}
                                storage left
                            </div>
                            {renderUpgradeSection()}
                        </div>
                        {renderReferralLink()}
                        {/* {renderUpgradeSectionOld()} */}
                    </div>
                    <div
                        style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)', marginTop: 16, marginBottom: 16 }}
                    />
                    <div style={{ fontSize: 18, fontWeight: 600 }}>Personal information</div>
                    <Formik
                        initialValues={{
                            fullName: user.me.fullName
                            // email: user.me.email
                        }}
                        onSubmit={(values, actions) => {
                            updateUserMutation(values);
                        }}
                        validationSchema={Yup.object().shape({
                            fullName: Yup.string()
                        })}
                    >
                        {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                            <div style={{ marginTop: 16 }}>
                                <span>Name</span>
                                <div style={{ display: 'flex', marginTop: 8, alignItems: 'center' }}>
                                    <input
                                        style={{
                                            minWidth: 200,
                                            backgroundColor: 'rgba(255,255,255,0.1)',
                                            border: 'none',
                                            borderRadius: 4,
                                            fontSize: 16,
                                            color: 'white',
                                            marginRight: 16,
                                            padding: 8,
                                            width: '100%'
                                        }}
                                        type="text"
                                        name="fullName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.fullName}
                                        placeholder="Your name"
                                        autoComplete="off"
                                    />
                                </div>
                                <div style={{ marginTop: 16 }}>
                                    <span>Email</span>
                                    <div style={{ fontSize: 16, color: 'rgba(255,255,255,.8)', marginTop: 8 }}>
                                        {user.me.email}
                                    </div>
                                    {verifiedAccount()}
                                </div>
                                <ActionTagButton
                                    label="Update"
                                    type="submit"
                                    style={{ backgroundColor: GREEN, marginTop: 24, maxWidth: 100 }}
                                    onClick={() => handleSubmit()}
                                ></ActionTagButton>
                                <div
                                    style={{ marginTop: 24, color: RED, cursor: "pointer" }}
                                    onClick={() => { setShowDeleteUserModal(true) }}>
                                    <span>Delete account</span>
                                </div>
                            </div>
                        )}
                    </Formik>
                </div>
                <DeleteUserModal
                    isOpen={showDeleteUserModal}
                    closeModal={() => {
                        setShowDeleteUserModal(false);
                    }}
                    user={user.me}
                />
            </div>
        );
    }
    return null;
}

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;

export default SettingsAccountComponent;
