import SAVED_TYPE from './saved-from-enum';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import mixpanel from 'mixpanel-browser';
import * as jwt from 'jsonwebtoken'
import { differenceInSeconds, differenceInDays } from 'date-fns';

function pageview(path, trackerNames, viewObject) {
    console.log('why', path, trackerNames);
    mixpanel.track('Page View', {
        'path': path,
        'screen_name': trackerNames,
        ...viewObject
    });
    ReactGA.pageview(path, trackerNames);
}

function messageview(actionObject, message) {
    let { messageContent } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB);

    mixpanel.track('Message View', {
        ...actionObject,
        ...messageContent
    });
}

function modalview(name) {
    mixpanel.track('Modal Open', {
        "modal_name": name
    });
    ReactGA.modalview('Modal Open', {
        "modal_name": name
    });
}

function addAnalytics(path, user) {
    console.log('what 2', user);
    mixpanel.identify(user.id);
    mixpanel.people.set({
        "$email": user.email,
        "$name": user.fullName,
        "$created": user.createdAt,
        "User ID": user.id,
        "Role": user.role,
        "Plan": user.planType
        // "$avatar": user.profilePictureUrl,
        // 'Background Picture': user.backgroundImageUrl
    })
    ReactGA.set({ userId: user.id });
}

function addProfilePic(url) {
    mixpanel.track('Add Profile Picture');
    // console.log('url', url);
    // no need for userId
    mixpanel.people.set({
        "$avatar": url
    })
}

function addBackgroundPic(url) {
    mixpanel.track('Add Background Picture');
    mixpanel.people.set({
        "Background Picture": url
    })
}

function removeProfilePic() {
    mixpanel.track('Remove Profile Picture');
}

function event(args) {
    mixpanel.track(args)
    ReactGA.event(args);
}

function login(payload) {
    const { token } = payload;
    const decoded = jwt.decode(token)
    if (decoded) {
        const { _id: userId, role } = decoded;
        console.log('decode', decoded);
        mixpanel.identify(userId);
        mixpanel.track('Login');
        ReactGA.event({
            category: 'User',
            action: 'Login'
        });
        // mixpanel.people.set({
        //     plan: "Personal"
        // })
        // mixpanel.people.set({
        //     "$name": name,
        //     "$email": email
        // });
    }
}

function sign_out() {
    mixpanel.track('Sign Out');
    mixpanel.reset()
    ReactGA.event({
        category: 'User',
        action: 'Sign Out'
    });
    // mixpanel.reset()
    // mixpanel.unregister()
}

function delete_account() {
    mixpanel.track('Delete Account');
    mixpanel.reset()
    ReactGA.event({
        category: 'User',
        action: 'Delete Account'
    });
    // mixpanel.reset()
    // mixpanel.unregister()
}

async function sign_up(payload) {
    const { token } = payload;
    const decoded = jwt.decode(token)
    if (decoded) {
        const { _id: userId, role } = decoded;
        mixpanel.track('Sign Up', {
            // 'source': "Pat's affiliate site",
            'opted_out_of_email': false,
            'user_id': userId
        });
        const distinctId = await mixpanel.get_distinct_id();
        // To create a user profile, you must call identify()
        mixpanel.alias(userId, distinctId)
        mixpanel.identify(userId);
        mixpanel.people.set({
            "Plan": "Personal"
        })

        // this one doesn't work, or didn't set it up correctly
        // ReactGA.ga('event', 'conversion', {
        //     'send_to': 'AW-10818851857/VdMYCPDT7I4DEJGo6qYo',
        //     //    'value': 25.0,
        //     //    'currency': 'EUR'
        // });

        ReactGA.event({
            category: 'User',
            action: 'Created an Account'
        });

        ReactPixel.fbq('track', 'CompleteRegistration');
        ReactPixel.fbq('track', 'Lead');
        // ReactPixel.fbq('track', 'Purchase', { currency: 'USD', value: 1.0 });
    }
}

function sign_up_pro() {
    mixpanel.track('Sign Up', {
        "pro": true
    })
    ReactGA.event({
        category: "User",
        action: 'Sign Up Pro'
    });
    ReactPixel.fbq('track', 'Purchase', { currency: 'USD', value: 10.0 });
}

const extractMessageInfo = (message, justCreated) => {
    const messageContent = {};
    const urlMatches = message.title && message.title.match(/\bhttps?:\/\/\S+/gi);

    if (message.folder.title === 'Inbox') {
        if (message.taskFolderMessage) {
            messageContent["screen"] = "TasksFolder";
        } else {
            messageContent["screen"] = "Inbox";
        }
    } else if (message.folder.parent) {
        messageContent["screen"] = "Subfolder";
    } else {
        messageContent["screen"] = "HomeFolder";
    }
    if (message.title) {
        messageContent["has_title"] = true
        messageContent["title_length"] = message.title.length
    };
    if (message.isCheckbox) {
        messageContent["has_checkbox"] = true;
        messageContent["is_checked"] = message.isChecked;
    };
    if (message.isStarred) {
        messageContent["has_star"] = true;
    };
    if (message.body) {
        messageContent["has_note"] = true
        messageContent["note_length"] = message.body.length
    };
    if (message.image) {
        messageContent["has_image"] = true
        messageContent["image_size"] = message.image.size;
    };
    if (message.file) {
        messageContent["has_file"] = true
        messageContent["file_size"] = message.file.size;
    };
    if (message.file && message.file.type.includes('audio')) {
        messageContent["has_audio"] = true
    };
    if (urlMatches && urlMatches.length > 0) {
        messageContent["has_link"] = true;
        messageContent["total_links"] = urlMatches.length;
    }
    if (message.deletedAt) {
        messageContent["is_deleted"] = true;
        messageContent["delete_to_now_days"] = differenceInDays(new Date(), new Date(message.deletedAt));
    }
    if (!justCreated) {
        if (message.createdAt) {
            messageContent["create_to_now_days"] = differenceInDays(new Date(), new Date(message.createdAt));
            messageContent["create_to_now_seconds"] = differenceInSeconds(new Date(), new Date(message.createdAt));
            messageContent["creation_date"] = message.createdAt;
        }

        if (message.updatedAt) {
            messageContent["update_to_now_days"] = differenceInDays(new Date(), new Date(message.updatedAt));
            messageContent["update_to_now_seconds"] = differenceInSeconds(new Date(), new Date(message.updatedAt));
            messageContent["updated_date"] = message.updatedAt;
        }
    }
    messageContent["msg_id"] = message.id;

    console.log('extracted message info', messageContent)

    return messageContent;
}

const extractFolderInfo = (folder, justCreated) => {
    const folderContent = {};

    if (!justCreated) {
        if (folder.createdAt) {
            folderContent["create_to_now_days"] = differenceInDays(new Date(), new Date(folder.createdAt));
            folderContent["create_to_now_seconds"] = differenceInSeconds(new Date(), new Date(folder.createdAt));
            folderContent["creation_date"] = folder.createdAt;
        }

        if (folder.updatedAt) {
            folderContent["update_to_now_days"] = differenceInDays(new Date(), new Date(folder.updatedAt));
            folderContent["update_to_now_seconds"] = differenceInSeconds(new Date(), new Date(folder.updatedAt));
            folderContent["updated_date"] = folder.updatedAt;
        }
    }
    folderContent["folder_id"] = folder.id;

    console.log('extracted folder info', folderContent)

    return folderContent;
}

const extractMixpanelObject = (message) => {
    const mixpanelObject = {};
    const urlMatches = message.title.match(/\bhttps?:\/\/\S+/gi);

    if (message.folder.title === 'Inbox') {
        mixpanelObject['Inbox Messages'] = 1;
    } else {
        mixpanelObject['Folder Messages'] = 1;
    }
    if (message.title) {
        mixpanelObject['Title Messages'] = 1;
    };
    if (message.isCheckbox) {
        mixpanelObject['Task Messages'] = 1;
    };
    if (message.isStarred) {
        mixpanelObject['Star Messages'] = 1;
    };
    if (message.body) {
        mixpanelObject['Note Messages'] = 1;
    };
    if (message.image) {
        mixpanelObject['Image Messages'] = 1;
    };
    if (message.file) {
        mixpanelObject['File Messages'] = 1;
    };
    if (message.file && message.file.type.includes('audio')) {
        mixpanelObject['Audio Messages'] = 1;
    };
    if (urlMatches && urlMatches.length > 0) {
        mixpanelObject['Link Messages'] = 1;
    }
    mixpanelObject['Total Messages'] = 1;

    console.log('extracted mixpanel object', mixpanelObject)

    return mixpanelObject;
}

function createMessageAnalyticsObj(message, messageType, justCreated) {
    // console.log('message', message);
    const userId = message.folder.owner;

    const mixpanelObject = extractMixpanelObject(message);
    const messageContent = extractMessageInfo(message, justCreated);

    messageContent["msg_type"] = messageType;

    return { messageContent, mixpanelObject, userId };
}

function message_sent(message, actionObject) {
    let { messageContent, mixpanelObject, userId } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB, true);

    mixpanel.track('Message Sent', {
        ...messageContent,
        ...actionObject
    });

    mixpanel.identify(userId);
    mixpanel.people.increment(mixpanelObject);

    ReactGA.event({
        category: 'Message',
        action: 'Sent Message'
    });
}

function message_updated(message) {
    let { messageContent } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB);

    mixpanel.track('Message Updated', messageContent);

    ReactGA.event({
        category: 'Message',
        action: 'Updated Message'
    });
}

function ui_action(actionObject) {
    // console.log('action', action);
    // ReactGA.event({
    //     category: 'UI Action',
    //     action: action
    // });
    mixpanel.track(`UI Action`, actionObject);
}

function create_subfolder_from_folder() {
    mixpanel.track('Folder Created', {
        "type": "Subfolder",
        "from": "HomeFolder"
    });
    mixpanel.people.increment("Total Folders", 1)
    ReactGA.event({
        category: 'Product',
        action: 'Create Subfolder from HomeFolder'
    });
}


function show_deleted_messages() {
    mixpanel.track('Show Trashed Messages');
    ReactGA.event({
        category: 'Messages',
        action: 'Show Trashed Messages Click'
    });
}

function search_active(obj) {
    mixpanel.track('Search Active', obj);
    ReactGA.event({
        category: 'Search',
        action: 'Search View Open'
    });
}

function search_action(obj) {
    mixpanel.track('Search Action', obj);
}

function subfolder_open() {
    mixpanel.track('Folder View', {
        "type": "Subfolder"
    });
    ReactGA.event({
        category: 'Subfolder',
        action: 'Subfolder View Open'
    });
}

function filter_actived(activeFilters) {
    console.log('at', activeFilters);
    // const res = activeFilters.reduce((acc, curr) => ((acc[curr] = ''), acc), {});
    // var myobj = JSON.parse(JSON.stringify(res));
    // console.log(res);
    // console.log(myobj);
    let reee = activeFilters.reduce((a, v) => ({ ...a, [v]: v }), {});
    console.log('rr', reee);
    if (activeFilters && activeFilters.length > 0) {
        mixpanel.track('Folder Filters Active', {
            "filters": activeFilters
        });
        ReactGA.event({
            category: 'Filters',
            action: 'Filter Active Click',
            value: activeFilters.length
        });
    } else {
        mixpanel.track('Folder Filters Cleared');

        ReactGA.event({
            category: 'Filters',
            action: 'Filter Cleared Click'
        });
    }
}

function search_filter_actived(activeFilters) {
    console.log('at', activeFilters);
    // const res = activeFilters.reduce((acc, curr) => ((acc[curr] = ''), acc), {});
    // var myobj = JSON.parse(JSON.stringify(res));
    // console.log(res);
    // console.log(myobj);
    let reee = activeFilters.reduce((a, v) => ({ ...a, [v]: v }), {});
    console.log('rr', reee);
    if (activeFilters && activeFilters.length > 0) {
        mixpanel.track('Search Filters Active', {
            "filters": activeFilters
        });
        ReactGA.event({
            category: 'Search Filters',
            action: 'Filter Active Click',
            value: activeFilters.length
        });
    } else {
        mixpanel.track('Search Filters Cleared');
        ReactGA.event({
            category: 'Search Filters',
            action: 'Filter Cleared Click'
        });
    }
}

function mobile_download(mobilePlatform) {
    ReactGA.event(mobilePlatform);
    if (mobilePlatform === SAVED_TYPE.IOS) {
        mixpanel.track('App Download', {
            "download_device": 'Download iOS'
        });
        ReactPixel.fbq('trackCustom', 'iOSInstall', { platform: SAVED_TYPE.IOS });
        ReactPixel.fbq('track', 'Lead');
        ReactGA.event({
            category: 'User',
            action: 'Download iOS'
        });
    } else {
        mixpanel.track('App Download', {
            "download_device": 'Download Android'
        });
        ReactPixel.fbq('trackCustom', 'AndroidInstall', { platform: SAVED_TYPE.ANDROID });
        ReactPixel.fbq('track', 'Lead');
        ReactGA.event({
            category: 'User',
            action: 'Download Android'
        });
    }
}

function trash_messages(messages) {
    let count = messages.length
    messages.forEach(message => {
        let { messageContent } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB);
        mixpanel.track('Message Action', {
            "action": "Trash",
            "multi_select": count,
            ...messageContent
        });
    });

    mixpanel.people.increment({
        "Total Messages": -count,
        "Total Trash Messages": count
    })

    ReactGA.event({
        category: 'Message',
        action: 'Trash Messages',
        value: count
    });
}

function restore_messages(messages) {
    let count = messages.length
    messages.forEach(message => {
        let { messageContent } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB);
        mixpanel.track('Message Action', {
            "action": "Restore",
            "multi_select": count,
            ...messageContent
        });
    });

    mixpanel.people.increment({
        "Total Messages": count,
        "Total Trash Messages": -count
    })

    ReactGA.event({
        category: 'Message',
        action: 'Restore',
        value: count
    });
}

function delete_messages(messages) {
    let count = messages.length
    messages.forEach(message => {
        let { messageContent } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB);
        mixpanel.track('Message Action', {
            "action": "Delete",
            "multi_select": count,
            ...messageContent
        });
    });

    mixpanel.people.increment({
        "Total Trash Messages": -count,
        "Total Deleted Messages": count
    })

    ReactGA.event({
        category: 'Message',
        action: 'Delete',
        value: count
    });
}

function trash_folder(count) {
    mixpanel.track('Folder Action', {
        "action": "Trash"
    });
    mixpanel.people.increment({
        "Total Folders": -1,
        "Total Trash Folders": 1
    })
    ReactGA.event({
        category: 'Folder',
        action: 'Trash'
    });
}

function restore_folder(count) {
    mixpanel.track('Folder Action', {
        "action": "Restore"
    });
    mixpanel.people.increment({
        "Total Folders": 1,
        "Total Trash Folders": -1
    })
    ReactGA.event({
        category: 'Folder',
        action: 'Restore'
    });
}

async function delete_folder(count) {
    mixpanel.track('Folder Action', {
        "action": "Delete"
    });
    mixpanel.people.increment({
        "Total Trash Folders": -1,
        "Total Deleted Folders": 1
    })
    ReactGA.event({
        category: 'Folder',
        action: 'Delete'
    });
}

// not active on desktop yet START
// async function share_message_click() {
//     ReactGA.event({
//         category: 'Message View',
//         action: 'Share Message Click'
//     });
//     mixpanel.track('Message Action', {
//         "action": "Share"
//     });
// }

// async function copy_message_body_click() {
//     ReactGA.event({
//         category: 'Message View',
//         action: 'Copy Message Body Click'
//     });
//     mixpanel.track('Copy Message Body Click');
// }
// not active on desktop yet END

async function message_action(actionObject, message) {
    let mesageInfo = {}
    if (message) {
        let { messageContent } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB);
        mesageInfo = messageContent;
    }

    mixpanel.track('Message Action', {
        ...actionObject,
        ...mesageInfo
    });
}

async function message_edit(actionObject, message, justCreated) {
    let mesageInfo = {}
    if (message) {
        let { messageContent } = createMessageAnalyticsObj(message, SAVED_TYPE.WEB, justCreated);
        mesageInfo = messageContent;
    }
    mixpanel.track('Message Edit', {
        ...actionObject,
        ...mesageInfo
    });
}

async function dash_task() {
    mixpanel.track('Message Edit', {
        'action': "Task",
        "quick": true
    });
    mixpanel.people.set('Task Messages', 1)
}

async function help_center_open() {
    mixpanel.track('Help Center Open');
    ReactGA.event({
        category: 'Product',
        action: 'Help Center Open'
    });
}


async function task_from_tasks_folder() {
    mixpanel.track('Task from Tasks Folder');
}

function create_folder_from_move_message() {
    mixpanel.track('Folder Created', {
        'type': "HomeFolder",
        'from': 'Move Message'
    });
    mixpanel.people.increment("Total Folders", 1)
    ReactGA.event({
        category: 'Product',
        action: 'Create HomeFolder - From Move Message'
    });
}

function testimonial_open() {
    ReactGA.event({
        category: 'Homepage',
        action: 'Testimonial Open'
    });
}

function landingpage_action(actionObject) {
    mixpanel.track('Landing Page', actionObject);
}

function create_home_folder(fromLocation) {
    mixpanel.track('Folder Created', {
        'type': "HomeFolder",
        'from': fromLocation ? fromLocation : 'Default'
    });
    mixpanel.people.increment("Total Folders", 1)
    ReactGA.event({
        category: 'Product',
        action: 'Create HomeFolder - From Folders list'
    });
}

// not used
async function folder_action(actionObject) {
    mixpanel.track('Folder Action', actionObject);
}

async function folder_edit(actionObject, folder) {
    let folderInfo = {}
    if (folder) {
        folderInfo = extractFolderInfo(folder)
    }
    mixpanel.track('Folder Edit', {
        ...actionObject,
        ...folderInfo
    });
}

async function user_action(actionObject) {
    mixpanel.track('User Action', actionObject);
}

async function user_edit(actionObject) {
    mixpanel.track('User Edit', actionObject);
}

export default {
    pageview,
    messageview,
    addAnalytics,
    addProfilePic,
    addBackgroundPic,
    removeProfilePic,
    landingpage_action,
    search_filter_actived,
    help_center_open,
    dash_task,
    message_action,
    message_edit,
    task_from_tasks_folder,
    event,
    modalview,
    sign_up,
    sign_up_pro,
    login,
    sign_out,
    delete_account,
    message_sent,
    message_updated,
    ui_action,
    create_home_folder,
    create_subfolder_from_folder,
    show_deleted_messages,
    search_active,
    search_action,
    subfolder_open,
    filter_actived,
    mobile_download,
    trash_messages,
    delete_messages,
    trash_folder,
    delete_folder,
    create_folder_from_move_message,
    testimonial_open,
    folder_action,
    folder_edit,
    restore_messages,
    restore_folder,
    user_action,
    user_edit
};
