import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION } from '../gql-operations';

import { AuthContext } from '../util/context';

import useGetFolderContent from './useGetFolderContent';
import { QUERY } from '../gql-operations';
import customAnalytics from '../util/custom-analytics';

function useDeleteFoldersMutation() {
    const {
        state: { treeData },
        context: { setNewTree, setSelectedFolder }
    } = React.useContext(AuthContext);

    const [deleteFoldersMutation, { data: successResponse }] = useMutation(MUTATION.DELETE_FOLDERS, {
        onCompleted: res => {
            // analytics on the bottom
        }
    });
    const { getFolderParent } = useGetFolderContent();

    // const [updateFolder, { data }] = useMutation(UPDATE_FOLDER_MUTATION);

    const deleteChildFromTree = folders => {
        let cloneTree = { ...treeData };
        const lastFolder = folders[folders.length - 1];
        // going to the parent when deleting
        if (lastFolder.parent) {
            let parent = getFolderParent(lastFolder.id);
            setSelectedFolder(parent);
        } else {
            if (cloneTree.items[lastFolder.before]) {
                setSelectedFolder(cloneTree.items[lastFolder.before].data);
            } else {
                setSelectedFolder(null);
            }
        }
        folders.forEach(folder => {
            let currentFolder = cloneTree.items[folder.parent ? folder.parent : 'root'];
            console.log('currentFolder', currentFolder);
            if (currentFolder) {
                let newSourceChildren = currentFolder.children;
                console.log('his kids', newSourceChildren);

                if (newSourceChildren) {
                    let currentFolderIndex = newSourceChildren.findIndex(obj => obj === folder.id);
                    if (currentFolderIndex > -1) {
                        console.log('index is', currentFolderIndex);
                        var itemRemoved = newSourceChildren.splice(currentFolderIndex, 1)[0];

                        console.log('item was removeted', itemRemoved);

                        let newItems = { ...newSourceChildren };

                        cloneTree.items[currentFolder.id].items = newItems;

                        console.log('new tree', cloneTree);

                        setNewTree(cloneTree);
                        // return cloneTree;
                    }
                }
            }
        });
    };

    const deleteFolders = async foldersArray => {
        let response = await deleteFoldersMutation({
            variables: {
                data: {
                    folderIds: foldersArray.map(folder => folder.id)
                }
            },
            refetchQueries: [
                {
                    query: QUERY.GET_TRASH_FOLDERS_GLOBAL
                }
            ]
            // update: async (store, { data: { deleteFolders } }) => {
            // 	const data = store.readQuery({
            // 		query: GET_HOME_FOLDERS_QUERY,
            // 	});

            // 	const newFolders = [...data.getHomeFolders.folders];

            // 	foldersArray.forEach((folder) => {
            // 		// finding the message in the apollo cache
            // 		let index = newFolders.findIndex((obj) => obj.id === folder.id);

            // 		// finding the before and updating it localy, so we can sort it
            // 		// In folder resolver we are upading this folder, not here
            // 		if (newFolders[index + 1]) {
            // 			newFolders[index + 1].before = folder.before;
            // 		}
            // 		// let beforeFolder = newFolders.find(obj => obj.before === folder.id);

            // 		// removing the message from the apollo cache
            // 		if (index > -1) {
            // 			newFolders.splice(index, 1);
            // 		}
            // 	});

            // 	const newData = {
            // 		getHomeFolders: {
            // 			folders: [...newFolders],
            // 			cursor: data.getHomeFolders.cursor,
            // 			__typename: data.getHomeFolders.__typename,
            // 		},
            // 	};

            // 	store.writeQuery({
            // 		query: GET_HOME_FOLDERS_QUERY,
            // 		data: newData,
            // 	});
            // },
        });
        if (response) {
            // checking if it's true from the server so we can delete from the tree
            deleteChildFromTree(foldersArray);
            console.log('folders array', foldersArray);
            customAnalytics.delete_folder();
        }
    };

    return { deleteFolders, deleteChildFromTree };
}

export default useDeleteFoldersMutation;
