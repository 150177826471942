import { gql } from 'apollo-boost';

// input RestoreMessagesInput {
//     messageIds: [ID]!
// }
export const RESTORE_MESSAGES = gql`
    mutation($data: RestoreMessagesInput!) {
        restoreMessages(data: $data)
    }
`;
