import React, { useState, useRef } from 'react';
import { QUERY } from '../gql-operations';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';

import styled from 'styled-components';

import DefaultMessage from './Messages/DefaultMessage';
import FolderHeader from '../components/Folder/Components/FolderHeader';
import SearchMessagesModal from '../components/Modals/SearchMessagesModal';

import { AuthContext } from '../util/context';

import useKeyboardListener from '../hooks/useKeyboardListener';

import ImageCarousel from '../components/Folder/Components/ImageCarousel';
import { GREEN_OP, PALE_WHITE_6, PRIMARY_COLOR, PRIMARY_COLOR_6, SECONDARY_TEXT } from '../appearance/Colors';
import FILTER_TYPE from '../util/messageType';

import useRedirectErrors from '../errors/useRedirectErrors';
import Loader from 'react-loader-spinner';

import MessageInput from '../components/MessageInput';
import MultiSelectOptions from '../components/Folder/Components/MultiSelectOptions';

import SearchFoldersModal from '../components/Modals/SearchFoldersModal';

import { pluralHelper } from '../util/pluralHelper';

import MyDropzone from '../components/MyDropzone';

import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';

import { format, compareAsc } from 'date-fns';
import customAnalytics from '../util/custom-analytics';

function StarredFolderContainer({ }) {
    const messageContainerRef = useRef();
    const { passingErrors } = useRedirectErrors();
    const [showFoldersModal, setShowFoldersModal] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(undefined);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [files, setFiles] = useState(null);

    const [getStarredMessagesGlobal, { data, error, loading }] = useLazyQuery(QUERY.GET_STARRED_MESSAGES_GLOBAL, {
        onError: error => {
            passingErrors(error);
        },
        fetchPolicy: 'cache-and-network'
    });

    // const {} = useKeyboardListener();

    const {
        state: { holdingKeys, keys, homeFolder, messagesToSend, selectedMessagesMap, showMultiSelect },
        context: { setSelectedMessage, setMessagesToSend, setSelectedMessagesMap, setSelectedFolder, setFolderFilter }
    } = React.useContext(AuthContext);

    React.useEffect(() => {
        getStarredMessagesGlobal();
        customAnalytics.pageview('/star_view', 'FolderStars');
        return () => {
            // cleanup
        };
    }, []);

    const onMessageClick = message => {
        console.log('you should show me the message', message);
        setSelectedMessage(message);
    };

    const sendMessageAction = (action, message, index, folderIndex) => {
        let actionObject = {}

        actionObject = {
            "action": action,
            "screen": "FolderStars",
            "index_position": index,
            "folder_index_position": folderIndex,
            ...actionObject
        }
        customAnalytics.message_action(actionObject, message);
    }

    const selectMessage = (message, listIndex, folderIndex) => {
        if (selectedMessagesMap[message.id]) {
            let newSpread = [...messagesToSend];
            let index = newSpread.findIndex(obj => obj.id === message.id);

            if (index > -1) {
                newSpread.splice(index, 1);
            }

            setMessagesToSend([...newSpread]);

            delete selectedMessagesMap[message.id];
            setSelectedMessagesMap({
                ...selectedMessagesMap
            });
            sendMessageAction('Deselect', message, listIndex, folderIndex);
        } else {
            setSelectedMessagesMap({
                ...selectedMessagesMap,
                [message.id]: true
            });
            setMessagesToSend([...messagesToSend, message]);
            sendMessageAction('Select', message, listIndex, folderIndex)
        }
    };

    const renderImageCarousel = () => {
        let caroImages = [];
        if (data.getStarredMessagesGlobal) {
            data.getStarredMessagesGlobal.forEach(({ folder, messages }) => {
                caroImages = [...caroImages, ...messages];
            });
            // console.log('messages', data);
            return (
                <ImageCarousel
                    isVisible={lightboxIsOpen}
                    data={caroImages}
                    currentMessage={currentMessage}
                    closeModal={() => {
                        setLightboxIsOpen(false);
                    }}
                ></ImageCarousel>
            );
        }
        return null;
    };

    const renderDateSection = (message, i, folderIndex) => {
        let date = format(new Date(message.createdAt), 'dd MMM, HH:mm');
        if (messageContainerRef.current && messageContainerRef.current.offsetWidth > 500) {
            return (
                <div
                    onClick={e => {
                        e.stopPropagation();
                        selectMessage(message, i, folderIndex);
                    }}
                    style={{
                        color: 'rgba(255,255,255,.6)',
                        marginRight: 4,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        minWidth: 100,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <span>{date}</span>
                </div>
            );
        }
    };

    const constructFoldersAndMessages = () => {
        if (data.getStarredMessagesGlobal.length > 0) {
            let starredFolders = [...data && data.getStarredMessagesGlobal];
            starredFolders.sort((a, b) => a.folder.title.localeCompare(b.folder.title))
            if (starredFolders && starredFolders.length > 0) {
                let index = starredFolders.findIndex(object => {
                    if (object.folder.id === homeFolder.id) return true;
                });

                if (index > -1) {
                    starredFolders.unshift(starredFolders.splice(index, 1)[0]);
                }
            }
            return starredFolders.map((object, i) => {
                return (
                    <div
                        key={i}
                        style={{
                            marginBottom: 24
                        }}
                    >
                        <div key={object.folder.id} style={{ display: 'flex' }}>
                            <FolderTitle
                                onClick={() => {
                                    setFolderFilter([FILTER_TYPE.STAR]);
                                    setSelectedFolder(object.folder);
                                }}
                            >
                                {object.folder.title}
                            </FolderTitle>
                        </div>
                        {object.messages.length > 0 ? (
                            object.messages.map((message, index) => {
                                return (
                                    <MessageContainer
                                        ref={messageContainerRef}
                                        key={message.id}
                                        style={
                                            selectedMessagesMap[message.id]
                                                ? { backgroundColor: GREEN_OP, zIndex: -i }
                                                : null
                                        }
                                        onClick={() => {
                                            if (!holdingKeys && !keys.Meta) {
                                                if (Object.keys(selectedMessagesMap).length > 0 || showMultiSelect) {
                                                    selectMessage(message, index, i);
                                                } else {
                                                    setSelectedMessage(message);
                                                }
                                            }
                                        }}
                                    >
                                        <DefaultMessage
                                            index={index}
                                            screenName='FolderStars'
                                            message={message}
                                            multipleSelect={() => {
                                                selectMessage(message, index, i);
                                            }}
                                            imagePreview={message => {
                                                setLightboxIsOpen(true);
                                                setCurrentMessage(message);
                                            }}
                                            openMenu={val => {
                                                let element = document.getElementById(message.id);
                                                if (val) {
                                                    element.style.zIndex = 50;
                                                } else {
                                                    element.style.zIndex = null;
                                                }
                                            }}
                                        />
                                        {renderDateSection(message, index, i)}
                                    </MessageContainer>
                                );
                            })
                        ) : (
                            <div
                                style={{
                                    fontWeight: '600',
                                    marginBottom: 16,
                                    color: SECONDARY_TEXT
                                }}
                            >
                                No starred yet
                            </div>
                        )}
                    </div>
                );
            });
        } else {
            return (
                <div
                    style={{
                        fontWeight: '600',
                        marginBottom: 16,
                        color: SECONDARY_TEXT
                    }}
                >
                    No starred yet
                </div>
            );
        }
    };

    const renderInputOrMultiSelect = folder => {
        if (Object.keys(selectedMessagesMap).length > 0) {
            return (
                <MultiSelectOptions
                    onSelectMove={bool => {
                        setShowFoldersModal(bool);
                    }}
                    specialFolder
                ></MultiSelectOptions>
            );
        } else {
            return null;
        }
        // return null;
    };

    const renderLoadingScreen = title => {
        return (
            <div style={{ color: 'white', backgroundColor: PRIMARY_COLOR_6, flex: 1, padding: 24 }}>
                <Loader
                    type="Puff"
                    // color="#00BFFF"
                    color={PALE_WHITE_6}
                    height={50}
                    width={50}
                // timeout={3000} //3 secs
                />
                <div style={{ fontWeight: '600', color: PALE_WHITE_6, marginTop: 8 }}>{title}</div>
            </div>
        );
    };

    if (data) {
        // ordering the homeFolder first
        return (
            <>
                <FolderWrapper
                    style={
                        {
                            // background: `url(${background}) no-repeat center center fixed`,
                            // backgroundSize: 'cover',
                            // backgroundRepeat: 'no-repeat',
                            // backgroundPosition: 'center'
                        }
                    }
                >
                    <FolderHeader
                        customTitle={'Stars'}
                        selectedMessage={onMessageClick}
                        titleSize={20}
                        titleEditable={false}
                        hideFilters
                        loading={loading}
                    ></FolderHeader>

                    <MyDropzone
                        filesSelected={files => {
                            // console.log('received file from dropzone', files);
                            if (files) {
                                setFiles(files);
                            }
                        }}
                        disabled={true}
                    >
                        <Messages>{constructFoldersAndMessages()}</Messages>
                    </MyDropzone>
                    {renderInputOrMultiSelect(homeFolder)}
                </FolderWrapper>
                {renderImageCarousel()}
                <SearchFoldersModal
                    isOpen={showFoldersModal}
                    title={`Move ${messagesToSend.length} ${pluralHelper(messagesToSend.length, 'message')} to...`}
                    addNewMoveMessage
                    closeModal={() => {
                        setShowFoldersModal(false);
                    }}
                ></SearchFoldersModal>
            </>
        );
    } else {
        if (loading) {
            return renderLoadingScreen('Loading Stars...');
        } else {
            return null;
        }
    }
}

const FolderWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: ${PRIMARY_COLOR};
    position: relative;
`;

const FolderTitleWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const FolderTitle = styled.div`
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 4px;
    margin-left: 40px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.6);
    }
`;

const H3 = styled.h3`
    color: white;
`;

const Messages = styled.div`
    grid-column: 3;
    grid-row: 2;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    margin-bottom: 14px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
`;

export default StarredFolderContainer;
