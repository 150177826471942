import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PALE_WHITE_6 } from '../appearance/Colors';

import { Waypoint } from 'react-waypoint';

const useKeyPress = function (targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);

    function downHandler(event) {
        if (event.key === targetKey) {
            event.preventDefault();
            setKeyPressed(true);
        }
    }

    const upHandler = event => {
        if (event.key === targetKey) {
            event.preventDefault();
            setKeyPressed(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    });

    return keyPressed;
};

// const items = [
// 	{ id: 1, name: 'Josh Weir' },
// 	{ id: 2, name: 'Sarah Weir' },
// 	{ id: 3, name: 'Alicia Weir' },
// 	{ id: 4, name: 'Doo Weir' },
// 	{ id: 5, name: 'Grooft Weir' }
// ];

const renderWaypoint = (i, items, loadMore) => {
    // console.log('waypoint entered', i);
    // console.log('hello', data.getFolderContent.messages.messages.length);
    // console.log('hello 3', data.getFolderContent.messages.messages.length - 3);
    if (items.length > 2) {
        // console.log('this is the size');
        if (i === items.length - 3) {
            return <Waypoint onEnter={() => loadMore()} />;
        } else {
            return null;
        }
    }
};

const ListItem = ({ item, items, active, index, setSelected, setHovered, currentItem, loadMore }) => (
    <div
        key={index}
        className={`item ${active ? 'active' : ''}`}
        onClick={() => setSelected(item)}
        onMouseEnter={() => setHovered(item)}
        onMouseLeave={() => setHovered(undefined)}
        style={{
            color: 'white',
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 8,
            paddingBottom: 8,
            marginBottom: 2,
            marginTop: 2,
            borderRadius: 4,
            backgroundColor: active ? 'rgba(255,255,255,.2)' : 'transparent',
            minHeight: 44
        }}
    >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <span style={{ marginRight: 8 }}>{item.title}</span>
                <span style={{ marginRight: 8, marginTop: 8, color: 'rgba(255,255,255,.4)' }}>{item.path}</span>
            </div>
            {currentItem && item.id === currentItem.id ? (
                <div>
                    <span style={{ fontSize: 12, opacity: 0.6 }}>Current</span>
                </div>
            ) : null}
        </div>
        {renderWaypoint(index, items, loadMore)}
    </div>
);

const SelectableList = ({ items, selectedItem, currentItem, loadMore }) => {
    const [selected, setSelected] = useState(undefined);
    const downPress = useKeyPress('ArrowDown');
    const upPress = useKeyPress('ArrowUp');
    const enterPress = useKeyPress('Enter');
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState(undefined);

    useEffect(() => {
        setCursor(0);
    }, [items]);

    useEffect(() => {
        if (items.length && downPress) {
            setCursor(prevState => (prevState < items.length - 1 ? prevState + 1 : prevState));
            if (items.length - 1 === cursor) {
                setCursor(0);
            }
        }
    }, [downPress]);

    useEffect(() => {
        if (items.length && upPress) {
            setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
            if (cursor === 0) {
                setCursor(items.length - 1);
            }
        }
    }, [upPress]);

    useEffect(() => {
        if (items.length && enterPress) {
            setSelected(items[cursor]);
            selectedItem(items[cursor]);
        }
    }, [cursor, enterPress]);

    useEffect(() => {
        if (items.length && hovered) {
            setCursor(items.indexOf(hovered));
        }
    }, [hovered]);

    return (
        <div>
            {/* <span>Selected: {selected ? selected.title : 'none'}</span> */}
            {items.map((item, i) => (
                <ListItem
                    currentItem={currentItem}
                    key={item.id}
                    index={i}
                    active={i === cursor}
                    item={item}
                    items={items}
                    setSelected={value => {
                        setSelected(value);
                        selectedItem(value);
                    }}
                    setHovered={setHovered}
                    loadMore={loadMore}
                />
            ))}
            {items.length === 0 ? <NoResults>No such folder found</NoResults> : null}
            {/* <p>
				<small>
					Use up down keys and hit enter to select, or use the mouse
				</small>
			</p> */}
        </div>
    );
};

const NoResults = styled.div`
    display: flex;
    justify-content: center;
    color: ${PALE_WHITE_6};
    margin-top: 16px;
`;

export default SelectableList;
