import { gql } from 'apollo-boost';

// input DeleteMessagesInput {
//     messageIds: [ID!]!
// }

export const DELETE_MESSAGES = gql`
    mutation($data: DeleteMessagesInput!) {
        deleteMessages(data: $data)
    }
`;
