import React from 'react';

import { useMutation } from '@apollo/react-hooks';

import { QUERY, MUTATION } from '../gql-operations';

import { AuthContext } from '../util/context';

import { STARRED, CHECKBOXES, HIERARCHY, TRASH } from '../util/specialFolderEnums';

import useNotificationSystem from '../components/Notifications/useNotificationSystem';

import { useApolloClient } from '@apollo/react-hooks';
import useGetSubfolders from './useGetSubfolders';
import customAnalytics from '../util/custom-analytics';
// import _ from 'lodash';

function useRestoreFoldersMutation() {
    // const { showGoToFolderNotification } = useNotificationSystem();

    const [restoreFolder] = useMutation(MUTATION.RESTORE_FOLDER, {
        onCompleted: res => {
            // analytics at the bottom of the screen
        }
    });
    const [getSubfolders] = useGetSubfolders();
    const client = useApolloClient();

    const {
        state: { folderFilterType, selectedFolder, showDeletedMessages },
        context: { setSelectedFolder }
    } = React.useContext(AuthContext);

    const restoreFoldersMutation = (messages, fromFolder) => {
        restoreFolder({
            variables: {
                data: {
                    folderId: selectedFolder.id
                }
            },
            update: async (store, { data }) => {
                let restoredFolder = data.restoreFolder;
                getSubfolders(restoredFolder, true);
                setSelectedFolder(restoredFolder);
            },
            refetchQueries: [
                {
                    query: QUERY.GET_FOLDER_CONTENT,
                    variables: {
                        data: {
                            folderId: selectedFolder.id,
                            isTrash: false
                        }
                    }
                },
                {
                    query: QUERY.GET_TRASH_FOLDERS_GLOBAL
                },
                {
                    query: QUERY.GET_STARRED_MESSAGES_GLOBAL
                },
                {
                    query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL
                }
            ]
        });
        customAnalytics.restore_folder();
    };

    return { restoreFoldersMutation };
}

export default useRestoreFoldersMutation;
