//@flow

import React, { useState, useEffect } from 'react';

import Tree, { mutateTree, moveItemOnTree } from '@atlaskit/tree';

import useReorderFoldersMutation from '../hooks/useReorderFoldersMutation';

import { AuthContext } from '../util/context';

import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import './Antd.css';

import NewFolderCard from '../components/NewFolderCard';
import SpecialFolder from '../components/SpecialFolder';

import './ContextMenuStyle.scss';

import useGetSubfolders from '../hooks/useGetSubfolders';
// import useSubscribeToNewFolder from '../util/useSubscribeToNewFolder';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { QUERY } from '../gql-operations';

import styled, { css } from 'styled-components';

import { IoMdTrash, IoIosRefresh } from 'react-icons/io';

import subscribeToNewFolder from '../util/subscribeToNewFolder';
import useCheckBrowser from '../util/useCheckBrowser';

import { STARRED, CHECKBOXES, HIERARCHY, INBOX } from '../util/specialFolderEnums';
import Loader from 'react-loader-spinner';
import { PALE_WHITE_1, PALE_WHITE_6, WHITE, INFO_BLUE, PALE_WHITE_4, INFO_BLUE_OP_4 } from '../appearance/Colors';
import customAnalytics from '../util/custom-analytics';

import ActionTagButton from "../components/Action/ActionTagButton";
import SettingsModal from './Modals/SettingsModal';

// import { Link } from 'react-router-dom';

function PureTree({ subscribeToMore }) {
    const {
        state: { selectedFolder, treeData, homeFolder },
        context: { setNewTree, setSelectedFolder, setShowDeletedMessages }
    } = React.useContext(AuthContext);

    const [reorderServerFolders] = useReorderFoldersMutation(treeData);
    const [hoveringOver, setHoveringOver] = useState(undefined);
    const [isDragging, setIsDragging] = useState(false);
    const [getSubfolders] = useGetSubfolders();
    const [getTrashFoldersQuery, { data, error, loading, fetchMore }] = useLazyQuery(QUERY.GET_TRASH_FOLDERS_GLOBAL, {
        fetchPolicy: 'network-only'
    });
    const [showTrashFolders, setShowTrashFolders] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const { data: meData } = useQuery(QUERY.ME);
    const { createdSubscription } = subscribeToNewFolder();
    // let anchorRef = React.useRef();

    // setting up global subscriptino for folders only here because
    // he needs folders initialised the fist time and since this is a hook,
    // it cannot be put in some conditional function

    useEffect(() => {
        console.log('this is it once');

        let unsubscribe2 = createdSubscription(subscribeToMore);
        return () => {
            unsubscribe2();
        };
    }, [selectedFolder, treeData]);

    const { Platform, PLATFORMS } = useCheckBrowser();

    // const hoverFolder = (folder) => {
    // 	if (selectedFoldersMap[folder.id]) {
    // 		let newSpread = { ...treeData.items };
    // 		console.log('newSpread', newSpread);

    // 		delete selectedFoldersMap[folder.id];
    // 		setSelectedFoldersMap({
    // 			...selectedFoldersMap,
    // 		});
    // 	} else {
    // 		setSelectedFoldersMap({
    // 			...selectedFoldersMap,
    // 			[folder.id]: true,
    // 		});
    // 	}
    // };

    // useEffect(() => {
    // 	if (hoveringOver) {
    // 		const overItem = document.getElementById(hoveringOver);
    // 		if (overItem) {
    // 			overItem.style.backgroundColor = 'red';
    // 		}
    // 	}
    // 	return () => {
    // 		if (hoveringOver) {
    // 			const overItem = document.getElementById(hoveringOver);
    // 			overItem.style.backgroundColor = 'transparent';
    // 			setHoveringOver(undefined);
    // 		}
    // 	};
    // }, [hoveringOver]);

    const onFolderClick = itemId => {
        // we do this because maybe it's on the root folder
        let item = treeData.items[itemId];
        setSelectedFolder(item.data);
    };

    // const renderContextMenuFolderCard = (folder) => {
    // 	return (
    // 		<>
    // 			<ContextMenuTrigger id={folder.id}>
    // 				<NewFolderCard
    // 					item={item}
    // 					isDragging={snapshot.isDragging}
    // 					selected={item.id === selectedFolderId}
    // 					onExpand={onExpand}
    // 					onCollapse={onCollapse}
    // 					onClick={() => {
    // 						onFolderClick(item.id);
    // 					}}
    // 				/>
    // 			</ContextMenuTrigger>
    // 			<ContextMenu id={folder.id}>
    // 				<MenuItem
    // 					data={{ foo: 'bar' }}
    // 					onClick={() => {
    // 						console.log('im here1 ', folder.title);
    // 					}}
    // 				>
    // 					ContextMenu {folder.title}
    // 				</MenuItem>
    // 				<MenuItem
    // 					data={{ foo: 'bar' }}
    // 					onClick={() => {
    // 						console.log('im here 2', folder.title);
    // 					}}
    // 				>
    // 					ContextMenu Item 2
    // 				</MenuItem>
    // 				<MenuItem divider />
    // 				<MenuItem
    // 					data={{ foo: 'bar' }}
    // 					onClick={() => {
    // 						console.log('im here 3', folder.title);
    // 					}}
    // 				>
    // 					ContextMenu Item 3
    // 				</MenuItem>
    // 			</ContextMenu>
    // 		</>
    // 	);
    // };

    const getFolderObject = itemId => {
        let item = treeData.items[itemId];
        return item.data;
    };

    const renderItem = props => {
        // console.log('all the props', props.snapshot);
        const { item, depth, onExpand, onCollapse, provided, snapshot } = props;

        if (snapshot.draggingOver) {
            // console.log('dragging over', snapshot.draggingOver)
        }

        if (snapshot.combineWith) {
            // console.log('these rare props general', snapshot.combineWith);
            // setHoveringOver(snapshot.combineWith);
        }
        // else {
        // 	setHoveringOver(undefined);
        // }

        const folder = item.data;

        return (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
                {/* <Link key={selectedFolder.id} to={`/home/${selectedFolder.id}`} innerRef={anchorRef}> */}
                <NewFolderCard
                    item={item}
                    isDraggingActive={snapshot.isDragging}
                    homeFolder={item.id === (meData && meData.me.homeFolder)}
                    selected={item.id === (selectedFolder && selectedFolder.id)}
                    onExpand={onExpand}
                    onCollapse={onCollapse}
                    onClick={() => {
                        onFolderClick(item.id);
                    }}
                />
                {/* </Link> */}
            </div>
        );
    };

    const onExpand = async itemId => {
        // console.log('this is dragging', isDragging);
        if (!isDragging) {
            setTimeout(async () => {
                // console.log('how many times', itemId);
                let newTree = await mutateTree(treeData, itemId, { isExpanded: true });
                await setNewTree(newTree);
                let folder = getFolderObject(itemId);
                getSubfolders(folder);
            }, 250);
        } else {
            // setTimeout(async () => {
            //     // console.log('how many times', itemId);
            //     let newTree = await mutateTree(treeData, itemId, { isExpanded: true });
            //     await setNewTree(newTree);
            //     let folder = getFolderObject(itemId);
            //     getSubfolders(folder);
            // }, 3000);
        }
        customAnalytics.ui_action({
            "action": 'Expand Folder',
        })
    };

    const onCollapse = async itemId => {
        // console.log('how many times again', itemId);
        let newTree = await mutateTree(treeData, itemId, { isExpanded: false });
        await setNewTree(newTree);
        customAnalytics.ui_action({
            "action": 'Collapse Folder',
        })
    };

    const onDragEnd = async (source, destination) => {
        setIsDragging(false);
        if (source && destination) {
            let treeDataCopy = { ...treeData };
            const newTree = moveItemOnTree(treeData, source, destination);

            setNewTree(newTree);

            let newTreeCopy = { ...newTree };
            let foldersToUpdate = await reorderServerFolders(treeDataCopy, source, destination);

            if (foldersToUpdate) {
                await foldersToUpdate.forEach(incomingFolder => {
                    if (incomingFolder && newTreeCopy.items[incomingFolder.id]) {
                        newTreeCopy.items[incomingFolder.id].data = {
                            ...incomingFolder
                        };
                    }
                });
                setNewTree(newTreeCopy);
            }

            // loading all subfolders for the folder when dropped on it
            let folderToReload = getFolderObject(destination.parentId);
            getSubfolders(folderToReload);
        }
    };

    const renderSpecialFolders = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginBottom: 8 }}>
                <SpecialFolder
                    inputFolder={{
                        id: meData && meData.me.homeFolder
                    }}
                    onClick={() => {
                        setSelectedFolder(homeFolder);
                    }}
                    shortcutTitle={Platform === PLATFORMS.MACINTOSH ? '⌘1' : 'Ctr+1'}
                ></SpecialFolder>

                <SpecialFolder
                    inputFolder={{
                        id: HIERARCHY
                    }}
                    onClick={() => {
                        setSelectedFolder({ id: HIERARCHY, title: 'All' });
                    }}
                    shortcutTitle={Platform === PLATFORMS.MACINTOSH ? '⌘2' : 'Ctr+2'}
                ></SpecialFolder>

                <SpecialFolder
                    inputFolder={{
                        id: STARRED
                    }}
                    onClick={() => {
                        setSelectedFolder({ id: STARRED, title: 'Stars' });
                    }}
                    shortcutTitle={Platform === PLATFORMS.MACINTOSH ? '⌘3' : 'Ctr+3'}
                ></SpecialFolder>

                <SpecialFolder
                    inputFolder={{
                        id: CHECKBOXES
                    }}
                    onClick={() => {
                        setSelectedFolder({ id: CHECKBOXES, title: 'Tasks' });
                    }}
                    shortcutTitle={Platform === PLATFORMS.MACINTOSH ? '⌘4' : 'Ctr+4'}
                ></SpecialFolder>
            </div>
        );
    };

    const renderTrashFolders = () => {
        if (showTrashFolders) {
            return (
                <>
                    <div
                        style={{
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                            paddingBottom: 8,
                            marginTop: 8,
                            color: 'white',
                            borderTop: 1,
                            borderTopColor: PALE_WHITE_1,
                            borderStyle: 'solid',
                            fontWeight: 600
                        }}
                    >
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                            <IoMdTrash size={16} color={WHITE}></IoMdTrash>
                            <span style={{ marginRight: 4 }}>Trash</span>
                            <div
                                onClick={e => {
                                    console.log('reload them');
                                    e.stopPropagation();
                                    getTrashFoldersQuery();
                                }}
                            >
                                {!loading ? (
                                    <IoIosRefresh size={16} color={PALE_WHITE_6} style={{ cursor: 'pointer' }}></IoIosRefresh>
                                ) : (
                                    <Loader
                                        type="Puff"
                                        // color="#00BFFF"
                                        color={PALE_WHITE_6}
                                        height={20}
                                        width={20}
                                    // timeout={3000} //3 secs
                                    />
                                )}
                            </div>
                            <div style={{ flex: 1 }}></div>
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                    setShowTrashFolders(false);
                                }}
                            >
                                Hide
                            </span>
                        </div>
                    </div>
                    <div>
                        <CustomItem
                            key={'DELETED_MESSAGES'}
                            style={{ flexDirection: 'row', display: 'flex', color: WHITE }}
                            onClick={() => {
                                setSelectedFolder({ id: HIERARCHY, title: 'All' });
                                setShowDeletedMessages(true);
                                customAnalytics.ui_action({
                                    'action': "Show Trash Messages",
                                    "scope": "All"
                                })
                            }}
                        >
                            All trash messages
                        </CustomItem>

                        <div style={{ display: 'flex', flex: 1 }}>
                            <span
                                style={{
                                    marginTop: 8,
                                    padding: '8px 6px',
                                    color: PALE_WHITE_6,
                                    fontWeight: '500',
                                    borderTop: `1px solid ${PALE_WHITE_1}`,
                                    flex: 1
                                }}
                            >
                                Trashed folders
                            </span>
                        </div>
                    </div>
                    {data && data.getTrashFoldersGlobal.folders.length
                        ? data.getTrashFoldersGlobal.folders.map(folder => {
                            return (
                                <CustomItem
                                    key={folder.id}
                                    style={{ flexDirection: 'row', display: 'flex', color: WHITE }}
                                    selected={folder.id === (selectedFolder && selectedFolder.id)}
                                    onClick={() => {
                                        setSelectedFolder(folder);
                                    }}
                                >
                                    {folder.title}
                                </CustomItem>
                            );
                        })
                        : null}
                </>
            );
        } else {
            return (
                <CustomItem
                    style={{ marginTop: isDragging ? 50 : 16, display: 'flex' }}
                    onClick={() => {
                        console.log('show the trashed');
                        setShowTrashFolders(true);
                        getTrashFoldersQuery();
                        customAnalytics.ui_action({
                            'action': "Show Trash Folders",
                            "scope": "All"
                        })
                    }}
                >
                    <IoMdTrash size={16} color={WHITE}></IoMdTrash>
                    <span>Trash</span>
                </CustomItem>
            );
        }
    };

    const renderMessagesLeftNotification = () => {
        if (meData && meData.me.emergencyBuffer && (meData.me.quota.maxMessages - meData.me.quota.totalMessages <= 1)) {
            return (
                <div style={{
                    backgroundColor: INFO_BLUE_OP_4,
                    marginBottom: 8,
                    marginTop: -8,
                    borderRadius: 4,
                    padding: "6px 8px",
                    paddingLeft: 12,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: PALE_WHITE_4,
                    color: WHITE,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <div style={{ flex: 1, flexDirection: 'column' }}>
                        <div style={{ flex: 1, fontWeight: '600' }}>
                            {meData.me.emergencyBuffer} messages left
                        </div>
                        <div style={{ fontSize: 12, cursor: 'pointer', marginTop: 4 }} onClick={() => {
                            setSelectedFolder({ id: HIERARCHY, title: 'All' });
                            setShowDeletedMessages(true);
                            customAnalytics.ui_action({
                                'action': "Show Trash Messages",
                                "scope": "All"
                            })
                        }}>Click here to delete Trash</div>
                    </div>
                    <ActionTagButton
                        label='Upgrade'
                        onClick={() => {
                            setShowSettingsModal(true);
                        }}
                    >
                    </ActionTagButton>
                </div>
            )
        } else {
            if (meData && (meData.me.quota.maxMessages - meData.me.quota.totalMessages <= 0))
                return (
                    <div style={{
                        backgroundColor: INFO_BLUE_OP_4,
                        marginBottom: 8,
                        marginTop: -8,
                        borderRadius: 4,
                        padding: "6px 8px",
                        paddingLeft: 12,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: PALE_WHITE_4,
                        color: WHITE,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <div style={{ flex: 1, flexDirection: 'column' }}>
                            <div style={{ flex: 1, fontWeight: '600' }}>
                                No messages left
                            </div>
                            <div style={{ fontSize: 12, cursor: 'pointer', marginTop: 4 }} onClick={() => {
                                setSelectedFolder({ id: HIERARCHY, title: 'All' });
                                setShowDeletedMessages(true);
                                customAnalytics.ui_action({
                                    'action': "Show Trash Messages",
                                    "scope": "All"
                                })
                            }}>Click here to delete Trash</div>
                        </div>
                        <ActionTagButton
                            label='Upgrade'
                            onClick={() => {
                                setShowSettingsModal(true);
                            }}
                        >
                        </ActionTagButton>
                    </div>
                )

        }
    }

    return (
        <div style={{ flex: 1, overflow: 'auto', padding: 8 }}>
            {renderMessagesLeftNotification()}
            {renderSpecialFolders()}
            <Tree
                tree={treeData}
                renderItem={renderItem}
                onExpand={onExpand}
                onCollapse={onCollapse}
                onDragEnd={onDragEnd}
                offsetPerLevel={PADDING_PER_LEVEL}
                onDragStart={res => {
                    setIsDragging(true);
                }}
                isDragEnabled
                isNestingEnabled
            />
            <SettingsModal
                // user={data}
                isOpen={showSettingsModal}
                tab={1}
                closeModal={() => {
                    setShowSettingsModal(false);
                }}
            ></SettingsModal>
            {renderTrashFolders()}
        </div>
    );
}

const PADDING_PER_LEVEL = 16;

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, itemId) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: 'none',
    // paddingLeft: 20,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    // backgroundColor: isDragging ? 'lightgreen' : 'transparent',
    // backgroundColor:  itemId === hoveringOver ? 'purple' : 'transparent',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250
});

const CustomItem = styled.div`
    color: white;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: default;
    border-radius: 4px;
    border: none;
    min-width: 150px;
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
    ${props =>
        props.selected &&
        css`
            background-color: rgb(255, 255, 255, 0.13);
        `}
`;

export default PureTree;
