import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({ className, ...props }) => {
    const classes = classNames('brand', className);

    return (
        <div {...props} className={classes}>
            <h1 className="m-0">
                <Link to="/">
                    {/* <div style={{ height: 40 }}> */}
                        <Image
                            src={require('../../../../../assets/lucky_icon_title_small.svg')}
                            alt="Luckynote logo"
                            // width={32}
                            // style={{height: 40}}
                        />
                    {/* </div> */}
                </Link>
            </h1>
        </div>
    );
};

export default Logo;
