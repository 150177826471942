import React, { useEffect, useRef } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import classNames from 'classnames';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';
import { useLocation, Switch } from 'react-router-dom';
import styled from 'styled-components';
// import ScrollReveal from '../utils/ScrollReveal';

import '../assets/scss/style.scss';
import Pricing from '../components/sections/Pricing';
import { Helmet } from 'react-helmet';

const Contact = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        // const page = location.pathname;
        // document.body.classList.add('is-loaded');
        // childRef.current.init();
        // trackPage(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
        title: 'Contact us'
        // paragraph: "Modularity and functionality. That's what we love the most."
    };

    return (
        <CustomStyle>
            <Helmet>
                <title>Contact</title>
                <description>
                    You may at any point contact us by writing us the email at support@luckynote.io
                </description>
            </Helmet>
            <Header navPosition="right" className="reveal-from-bottom" />
            <main className="site-content">
                <section {...props} className={outerClasses}>
                    <div className="container-sm">
                        <div className={innerClasses} style={{ textAlign: 'left' }}>
                            <SectionHeader data={sectionHeader} className="center-content" />
                            <p className="m-0 text-sm">
                                You may at any point contact us by writing us the email at <b>support@luckynote.io</b>.
                                {/* <a href="mailto: support@luckynote.io" style={{ color: '#2b88d9' }}>
                                    support@luckynote.io
                                </a> */}
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </CustomStyle>
    );
};

const CustomStyle = styled.div`
    body {
        font-size: 1rem;
    }
    // color: #9ca9b3;
    a {
        // font-weight: 400 !important;
        // text-decoration: none;
        //     &:not(.button) {
        //         color: #9ca9b3;
        //     }
        //     &.button-link {
        //         color: #9ca9b3;
        //     }

        //     &.func-link {
        //         color: #9ca9b3;
        //     }
        //     &:hover {
        //         color: #eceded;
        //     }
    }
    // h2,
    // .h2 {
    //     font-size: 44px !important;
    //     line-height: 54px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // h3,
    // .h3 {
    //     font-weight: 700 !important;
    //     font-size: 32px !important;
    //     line-height: 42px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // p {
    //     font-size: 1rem;
    //     line-height: 30px;
    // }
`;

export default Contact;
