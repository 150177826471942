import React from 'react';

import { IoIosArrowDown } from 'react-icons/io';

function ScrollToBottomButton({ i, show, ...rest }) {
    let papercups = localStorage.getItem('PAPERCUPS');
    return (
        <div
            style={{
                position: 'absolute',
                height: 40,
                width: 40,
                backgroundColor: 'rgba(255,255,255,.6)',
                bottom: !papercups ? 64 : 8,
                borderRadius: 50,
                display: show ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                right: 16,
                backdropFilter: 'saturate(180%) blur(20px)'
            }}
            {...rest}
        >
            <IoIosArrowDown style={{ height: 20, width: 20 }}></IoIosArrowDown>
        </div>
    );
}

export default ScrollToBottomButton;
