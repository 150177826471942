import React, { useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import ItemTypes from './ItemTypes';
import styled, { css } from 'styled-components';

import useMoveMessagesMutation from '../hooks/useMoveMessagesMutation';
import useMoveFolderMutation from '../hooks/useMoveFolderMutation';
import useUpdateGetFolderContent from '../hooks/useUpdateGetFolderContent';

import { AuthContext } from '../util/context';

import { WHITE } from '../appearance/Colors';
import customAnalytics from '../util/custom-analytics';

const PathFolder = ({
    name,
    folder,
    isDraggingActive,
    onDrop,
    onExpand,
    onCollapse,
    selected,
    onClick,
    disableDrop,
    disableRightIcon,
    homeFolder,
    ...props
}) => {
    const [moveMessages] = useMoveMessagesMutation();
    const [moveFolder] = useMoveFolderMutation();

    const { refreshFolderLocal } = useUpdateGetFolderContent();

    const {
        state: { messagesToSend, selectedFolder }
    } = React.useContext(AuthContext);

    // DRAGGING FOLDER
    const [{ isDragging }, drag] = useDrag({
        item: { name, type: ItemTypes.FOLDER, folder },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            // console.log(dropResult);
            // console.log(item);
            // alert(`You dropped ${message.id} into ${dropResult.name}!`);

            // just to refresh a message when getting dropped
            if (item && dropResult) {
                // console.log('do you go in?', item, dropResult, folder);
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: () => {
            return false;
        }
    });

    const moveAndRefresh = async (drop, toFolder) => {
        await moveFolder(drop.folder, toFolder);
        await refreshFolderLocal(selectedFolder, drop.folder);
        customAnalytics.folder_edit({
            "action": "Move",
            "type": "Drag and Drop on Path",
            "destination": toFolder && toFolder.parent ? "Subfolder" : "HomeFolder"
        })
    };

    // DROPPING MESSAGE
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [ItemTypes.MESSAGE, ItemTypes.FOLDER],
        drop: drop => {
            if (drop.type === ItemTypes.FOLDER) {
                // dropping to folder
                const toFolder = folder;
                if (toFolder.id !== drop.folder.id) {
                    moveAndRefresh(drop, toFolder);
                }
            } else if (drop.type === ItemTypes.MESSAGE) {
                // console.log('messagesToSend', messagesToSend);
                if (messagesToSend && messagesToSend.length > 0) {
                    // console.log('item and drop', folder, drop);
                    onDropMutation(messagesToSend, folder);
                } else {
                    let message = drop.message;
                    onDropMutation([message], folder);
                }
            }
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        canDrop: () => {
            if (disableDrop) {
                return false;
            } else {
                return true;
            }
        }
    });
    // DROPPING MESSAGE END

    const onDropMutation = (messages, toFolder) => {
        // const listMessages = [item.message];
        // custom hook
        moveMessages(messages, toFolder, selectedFolder);
        messages.forEach(message => {
            customAnalytics.message_edit({
                "action": "Move",
                "type": "Drag and Drop on Path",
                "multi_select": messages.length,
                "from": selectedFolder.title === "Inbox" ? "Inbox" : selectedFolder.parent ? "Subfolder" : "HomeFolder",
                "destination": toFolder.title === "Inbox" ? "Inbox" : toFolder.parent ? "Subfolder" : "HomeFolder",
            }, message)
        });
    };

    return (
        <>
            <CustomItem
                id={folder.id}
                // not working like this
                // isDragging={!disableDrag ? isDragging : false}
                selected={selected}
                ref={drag}
                {...props}
                isOver={isOver}
                canDrop={!disableDrop ? canDrop : false}
            // onMouseEnter={() => setHoveringContainer(true)}
            // onMouseLeave={() => {
            //     setHoveringContainer(false);
            // }}
            >
                <div ref={drop} style={{ alignItems: 'center' }}>
                    <ParentFolder onClick={onClick}>{folder ? folder.title : ''}</ParentFolder>
                </div>
            </CustomItem>
        </>
    );
};

const CustomItem = styled.div`
    ${props =>
        props.isDraggingActive &&
        css`
            // transition: opacity 0.2s cubic-bezier(0.2,0,0,1) 0s;
            // transform: translate(9px, -23px);
            box-sizing: border-box;
            background-color: rgb(235, 236, 240);
            fill: rgb(235, 236, 240);
            box-shadow: rgba(9, 30, 66, 0.31) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
        `}
    ${props =>
        props.canDrop &&
        css`
            // background-color: rgb(235, 136, 200);
        `}
	${props =>
        props.isOver &&
        props.canDrop &&
        css`
            background-color: rgb(135, 236, 240);
        `}
	
	${props =>
        props.selected &&
        css`
            background-color: rgb(255, 255, 255, 0.13);
        `}
    border-radius: 4px;
    cursor: default;
    position: relative;
`;

const ParentFolder = styled.div`
    display: flex;
    // justify-content: center;
    // align-items: center;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 2px;
    // border: 1px solid transparent;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    color: ${WHITE};
    &:hover {
        background: rgba(35, 39, 42, 0.3);
        // background: rgba(35, 39, 42, 0.4);
        // border: 1px solid rgba(255, 255, 255, 0.4);
    }
`;

export default PathFolder;
