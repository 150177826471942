import React, { useEffect, useRef } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import classNames from 'classnames';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';
import { useLocation, Switch } from 'react-router-dom';
import styled from 'styled-components';
// import ScrollReveal from '../utils/ScrollReveal';

import '../assets/scss/style.scss';
import Pricing from '../components/sections/Pricing';
import { Helmet } from 'react-helmet';

const Terms = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        // const page = location.pathname;
        // document.body.classList.add('is-loaded');
        // childRef.current.init();
        // trackPage(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
        title: 'Terms and Conditions'
        // paragraph: "Modularity and functionality. That's what we love the most."
    };

    return (
        <CustomStyle>
            <Helmet>
                <title>Terms</title>
                <description>Luckynote Terms and Conditions</description>
            </Helmet>
            <Header navPosition="right" className="reveal-from-bottom" />
            <main className="site-content">
                <section {...props} className={outerClasses}>
                    <div className="container-sm">
                        <div className={innerClasses} style={{ textAlign: 'left' }}>
                            <SectionHeader data={sectionHeader} className="center-content" />
                            <p className="m-0 text-sm">
                                Please carefully read these Terms and Conditions before using the Luckynote application. Your access and use of the Service depend on your acceptance and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Service.
                            </p>

                            <h4 className="mt-32 mb-8">Subscription</h4>
                            <p className="m-0 text-sm">
                                You may subscribe to the pro version of the app through our website. You will be billed in advance on a recurring and periodic basis, known as the "Billing Cycle." Billing cycles are set on either a monthly or annual basis, depending on the subscription plan you choose when purchasing a Subscription. Unless you cancel it, your Subscription will automatically renew under the same conditions at the end of each Billing Cycle. You can cancel your subscription through our website or by emailing us at support@luckynote.io.
                            </p>
                            <h4 className="mt-32 mb-8">Fee Changes</h4>
                            <p className="m-0 text-sm">
                                We may modify the Subscription fees for the Subscriptions. Any Subscription fee changes will become effective at the end of the current Billing Cycle. We will provide reasonable prior notice of any changes in Subscription fees, giving you the opportunity to terminate your Subscription before the change becomes effective. Your continued use of the Service after the Subscription fee change takes effect signifies your agreement to pay the modified Subscription fee.
                            </p>
                            <h4 className="mt-32 mb-8">Refunds</h4>
                            <p className="m-0 text-sm">
                                Paid Subscription fees are non-refundable, except when required by law.
                            </p>
                            <h4 className="mt-32 mb-8">Accounts</h4>
                            <p className="m-0 text-sm">
                                When creating an account with us, you must provide accurate, complete, and current information. Failure to do so constitutes a breach of the Terms, which may result in the immediate termination of your account on our Service. You are responsible for safeguarding the password used to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any security breach or unauthorized use of your account.
                            </p>
                            <h4 className="mt-32 mb-8">Disclaimer</h4>
                            <p className="m-0 text-sm">
                                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis, without warranties of any kind, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance. Luckynote does not warrant that a) the Service will function uninterrupted, securely, or be available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
                            </p>
                            <h4 className="mt-32 mb-8">Changes</h4>
                            <p className="m-0 text-sm">
                                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will attempt to provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
                            </p>
                            <h4 className="mt-32 mb-8">Contact Us</h4>
                            <p className="m-0 text-sm">You can always email us at support@luckynote.io.</p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </CustomStyle>
    );
};

const CustomStyle = styled.div`
    body {
        font-size: 1rem;
    }
    // color: #9ca9b3;
    a {
        // font-weight: 400 !important;
        // text-decoration: none;
        //     &:not(.button) {
        //         color: #9ca9b3;
        //     }
        //     &.button-link {
        //         color: #9ca9b3;
        //     }

        //     &.func-link {
        //         color: #9ca9b3;
        //     }
        //     &:hover {
        //         color: #eceded;
        //     }
    }
    // h2,
    // .h2 {
    //     font-size: 44px !important;
    //     line-height: 54px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // h3,
    // .h3 {
    //     font-weight: 700 !important;
    //     font-size: 32px !important;
    //     line-height: 42px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // p {
    //     font-size: 1rem;
    //     line-height: 30px;
    // }
`;

export default Terms;
