import React from 'react';

import styled from 'styled-components';

import { PRIMARY_COLOR_4 } from '../appearance/Colors';

function SlashIcon({ ...rest }) {
    return (
        <IconWrapper>
            <div
                style={{
                    height: 24,
                    width: 24,
                    // padding: 8,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: PRIMARY_COLOR_4,
                    color: PRIMARY_COLOR_4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                {...rest}
            >
                <span style={{}}>/</span>
            </div>
        </IconWrapper>
    );
}

const IconWrapper = styled.div`
    // width: 40px;
    // height: 40px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 4px;
    cursor: default;
`;

export default SlashIcon;
