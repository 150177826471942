import { gql } from 'apollo-boost';

// input FindUsersInput {
// 	text: String!
// }

export const FIND_USERS = gql`
    query ($data: FindUsersInput!) {
        findUsers(data: $data) {
            users {
                id
                email
                fullName
                isVerified
                role
                createdAt
                planType
                quota {
                    maxMessages
                    totalMessages
                    maxStorage
                    totalStorage
                    maxFileSize
                }
                profilePictureUrl
                isSubscribedToNewsletter
                referralId
                referralCount
                analytics {
                    homeFolderCount
                    subfolderCount
                    trashedFolderCount
                    totalInboxMessages
                    totalInboxTrashedMessages
                    totalTrashedMessages
                }
                settings {
                    usedMacApp
                    usedWindowsApp
                    usedAndroidApp
                    usedLinuxApp
                    usedIosApp
                    usedDesktopWebApp
                    usedClipper
                }
                usage {
                    lastLogin
                    lastActive
                    lastActiveDesktop
                    lastActiveMobile
                    lastFolderCreation
                    lastFolderUpdate
                    lastFolderMove
                    lastMessageCreation
                    lastMessageUpdate
                    lastMessageMove
                    totalLoginsDesktop
                    totalLoginsMobile
                    totalFolderViews
                    totalStarViews
                    totalTaskViews
                    totalMessageViews
                    totalSearchViews
                    totalFilterViews
                    totalInboxCreatedMessages
                    totalInboxMobileCreatedMessages
                    totalImageMessages
                    totalFileMessages
                    totalUrlMessages
                    totalNoteMessages
                    totalTaskMessages
                    totalStarMessages
                    totalExtensionCreatedMessages
                    totalMobileExtensionCreatedMessages
                    totalPermanentlyDeletedMessages
                }
            }
            cursor
        }
    }
`;
