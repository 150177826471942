const setupInitialTree = folders => {
    let result = serializeArray(folders);

    let justArrayOfChildren = mapArray(folders);

    let setUpRootObject = () => {
        return {
            id: 'root',
            children: justArrayOfChildren,
            hasChildren: true,
            isExpanded: true,
            isChildrenLoading: false,
            data: {
                title: 'root'
            }
        };
    };

    let setupItems = () => {
        let root = setUpRootObject();
        return {
            root,
            ...result
        };
    };

    const treeData = {
        rootId: 'root',
        items: setupItems()
    };

    return treeData;
};

const serializeArray = folders => {
    let result = folders.reduce(function (map, folder) {
        map[folder.id] = {
            id: folder.id,
            children: [],
            hasChildren: true,
            isExpanded: false,
            isChildrenLoading: false,
            data: folder
        };
        return map;
    }, {});
    return result;
};

const mapArray = folders => {
    let result = folders.map(folder => {
        return folder.id;
    });
    return result;
};

export { setupInitialTree, serializeArray, mapArray };
