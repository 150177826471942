import React from 'react';
import SettingsAdminComponent from "../components/SettingsAdminComponent";
import {  } from "../errors/errors";

function Admin() {
    return (
        // <div>
            <SettingsAdminComponent></SettingsAdminComponent>
        // </div>
    );
}

export default Admin;
