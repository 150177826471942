import React, { useState } from 'react';
import styled from 'styled-components';
import CarouselModal from '../../Modals/CarouselModal';

import useUpdateMessageMutation from '../../../hooks/useUpdateMessageMutation';

import { AuthContext } from '../../../util/context';
import environment from '../../../env-config';
import { Image, Transformation } from 'cloudinary-react';

import { IoIosClose } from 'react-icons/io';
import { IMAGE_BACKGROUND, PRIMARY_COLOR, PRIMARY_COLOR_1 } from '../../../appearance/Colors';
import useCheckValidity from '../../../util/useCheckValidity';
import ImageNAIcon from '../../../appearance/ImageNAIcon';

function URLPreviewSectionMini({ index, metaData, message }) {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const { checkImageValidity } = useCheckValidity();

    const { updateURL } = useUpdateMessageMutation();

    const { size, aspect_ratio, width } = message.urlMeta;

    const imageWidth = (message.urlMeta && message.urlMeta.width < 100) ? 20 : 100;

    let tempHeight = Math.floor(aspect_ratio * imageWidth);

    const {
        state: { holdingKeys, keys }
    } = React.useContext(AuthContext);

    // super annoying on the message itself, only showing the plugin
    const metaActive = holdingKeys && (keys.Meta || keys.Control) && keys.Shift;

    // const checkIfEmpty = async () => {
    //     let urlMeta = message.urlMeta[index];
    //     if (!urlMeta.description && !urlMeta.image && !urlMeta.publisher && !urlMeta.title) {
    //         await message.urlMeta.splice(index, 1);
    //     }
    // };

    const deleteUrl = async () => {
        await message.urlMeta.splice(index, 1, { url: message.urlMeta[index].url });
        updateURL(message, message.urlMeta);
    };

    const deleteUrlImage = () => {
        message.urlMeta[index].image = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deletePublisher = () => {
        message.urlMeta[index].publisher = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deleteTitle = () => {
        message.urlMeta[index].title = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deleteDescription = () => {
        message.urlMeta[index].description = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const removeButton = callback => {
        if (metaActive) {
            return <IoIosClose color={PRIMARY_COLOR} size={16} onClick={callback}></IoIosClose>;
        }
        return null;
    };

    const removeSection = () => {
        if (metaActive) {
            return (
                <div style={{ marginTop: 4, marginLeft: 16 }} onClick={deleteUrl}>
                    Remove preview
                </div>
            );
        }
        return null;
    };

    if (metaData) {
        return (
            <>
                <div
                    style={{
                        marginTop: 4,
                        display: 'flex',
                        // flexDirection: 'row',
                        borderLeft: 'black',
                        borderLeftwidth: 4,
                        borderStyle: 'solid',
                        paddingLeft: metaActive ? 0 : 8,
                        maxWidth: 450
                    }}
                >
                    {checkImageValidity(metaData.image) ? (
                        <ImageUrlSection>
                            <Image
                                cloudName={environment.REACT_APP_CLOUD_NAME}
                                publicId={metaData.image}
                                // width={'300'}
                                crop={'pad'}
                                style={{
                                    borderRadius: 4,
                                    width: imageWidth,
                                    objectFit: 'scale-down',
                                    height: tempHeight
                                }}
                            >
                                <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                            </Image>
                        </ImageUrlSection>
                    ) : (
                        <div
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                minWidth: 100,
                                minHeight: tempHeight,
                                backgroundColor: PRIMARY_COLOR_1,
                                borderRadius: 4,
                                display: 'flex'
                            }}
                        >
                            <ImageNAIcon />
                        </div>
                    )}
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        {metaData.publisher ? (
                            <UrlSection>
                                <div style={{ color: '#333', fontWeight: 600 }}>{metaData.publisher}</div>
                            </UrlSection>
                        ) : null}
                        {metaData.title ? (
                            <UrlSection>
                                <div>{metaData.title}</div>
                            </UrlSection>
                        ) : null}
                        {/* {metaData.description ? (
                        <UrlSection>
                            {removeButton(deleteDescription)}
                            <TruncateWrapper>{metaData.description}</TruncateWrapper>
                        </UrlSection>
                    ) : null} */}
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}

const TruncateWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
    color: rgba(0, 0, 0, 0.8);
`;

const UrlSection = styled.div`
    // display: flex;
    // flex-direction: row;
    // margin-bottom: 4px;
`;

const ImageUrlSection = styled.div`
    // display: flex;
    // flex-direction: row;
    margin-right: 8px;
    background-color: ${IMAGE_BACKGROUND};
    border-radius: 4px;
`;

export default URLPreviewSectionMini;
