import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { BLUE_HIGHLIGHT, PRIMARY_COLOR } from '../../../../appearance/Colors';
import { useMediaQuery } from 'react-responsive';
import ButtonGroup from '../elements/ButtonGroup';
import { Link } from 'react-router-dom';
import customAnalytics from '../../../../util/custom-analytics';
import useCheckBrowser from '../../../../util/useCheckBrowser';

const propTypes = {
    ...SectionSplitProps.types
};

const defaultProps = {
    ...SectionSplitProps.defaults
};

const FeaturesSplit = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {
    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Your practical mental space',
        paragraph: 'From simple journaling to complex document management. Save all your content and have a peace of mind because you will always find it later.'
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { Browser } = useCheckBrowser();

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>
                        <div className="split-item">
                            <div
                                className="split-item-content center-content-mobile reveal-from-left"
                                data-reveal-container=".split-item"
                            >
                                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                    Lightning fast workflow
                                </div> */}
                                <h3 className="mt-0 mb-12">Always find your notes</h3>
                                <p className="m-0">
                                    Folders help you organize messages by topics, such as{' '}
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Movies, Recipes</span> and{' '}
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Workout</span>.
                                    <br />
                                    <br />
                                    Find everything with <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Search</span> and <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Filters</span> regardless of where your messages are.
                                    <br />
                                    <br />
                                    There is no limit how many folders you can make.
                                    {/* <span style={{ borderBottom: '1px solid #000' }}>To Watch</span> and{' '}
                                    <span style={{ borderBottom: '1px solid #000' }}>Watched</span>  */}
                                </p>
                            </div>
                            <div
                                className={classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item"
                            >
                                {isMobile ? (
                                    <video
                                        src="https://res.cloudinary.com/luckyimagecloud/video/upload/v1635850732/Luckynote%20General/Screen_Recording_2021-11-02_at_10.39.09_qtonjs.mp4"
                                        width="528"
                                        height="396"
                                        playsInline
                                        autoPlay
                                        muted
                                        loop
                                    />
                                ) : (
                                    <Image
                                        src={require('./../../assets/images/features-split-folders.webp')}
                                        alt="folders"
                                    // width={528}
                                    // height={396}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="split-item">
                            <div
                                className="split-item-content center-content-mobile reveal-from-right"
                                data-reveal-container=".split-item"
                            >
                                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                    Lightning fast workflow
                                </div> */}
                                <h3 className="mt-0 mb-12">Task anything</h3>
                                <p className="m-0">
                                    Image, Document, Bookmark, anything can be a Task in Luckynote.
                                    <br />
                                    <br />
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Star</span> your important notes to make them more prominent.
                                </p>
                            </div>
                            <div
                                className={classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item"
                            >
                                {isMobile ? (
                                    <Image
                                        src={require('./../../assets/images/features-split-image-star-check-mobile.webp')}
                                        alt="star-check-feature"
                                    // width={528}
                                    // height={396}
                                    />
                                ) : (
                                    <Image
                                        src={require('./../../assets/images/features-split-image-star-check.webp')}
                                        alt="star-check-feature"
                                    // width={528}
                                    // height={396}
                                    />
                                )}
                            </div>
                        </div>

                        {/* <div className="split-item">
                            <div
                                className="split-item-content center-content-mobile reveal-from-left"
                                data-reveal-container=".split-item"
                            >
                            
                                <h3 className="mt-0 mb-12">Shortcuts</h3>
                                <p className="m-0">
                                    If you're a fast navigator, you'll love this. From fast folder switching to quickly
                                    moving the messages. We're giving our best to bring you the speed.
                                </p>
                            </div>
                            <div
                                className={classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item"
                            >
                                <Image
                                    src={require('./../../assets/images/features-split-image-03.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396}
                                />
                            </div>
                        </div> */}

                        <div className="split-item">
                            <div
                                className="split-item-content center-content-mobile reveal-from-left"
                                data-reveal-container=".split-item"
                            >
                                <h3 className="mt-0 mb-12">Save from the Web</h3>
                                <p className="m-0">
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Bookmark anything </span>and save{' '}
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Images and Text </span>from web pages directly to Luckynote.
                                    <br />
                                    <br />
                                    Downloading images and copy-pasting text are things of the past.
                                    <br />
                                    <br />
                                    <span className="testimonial-item-link">
                                        <a
                                            href={Browser === 'Firefox' ?
                                                'https://addons.mozilla.org/en-US/firefox/addon/luckynote/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search'
                                                : "https://chrome.google.com/webstore/detail/luckynote/dgnamkhpffokcdhjnlebokmcgkkecncl"}
                                            target="_blank"
                                            onClick={() => {
                                                customAnalytics.landingpage_action({
                                                    "action": "Get Extension"
                                                })
                                            }}
                                        >
                                            Get Extension
                                        </a>
                                    </span>
                                </p>
                                {/* <p className="m-0">
                                    With our Web Clipper you can save any web page as well as images and text on the
                                    page. It's super fast.
                                </p> */}
                            </div>
                            <div
                                className={classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item"
                            >
                                <Image
                                    src={require('./../../assets/images/features-split-extension.webp')}
                                    alt="extension"
                                // width={528}
                                // height={396}
                                />
                            </div>
                        </div>

                        <div className="split-item">
                            <div
                                className="split-item-content center-content-mobile reveal-from-right"
                                data-reveal-container=".split-item"
                            >
                                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                    Lightning fast workflow
                                </div> */}
                                <h3 className="mt-0 mb-12">Works Everywhere</h3>
                                <p className="m-0">
                                    Your notes and documents stay synced across all your devices, automatically and in real time.
                                    <br />
                                    <br />
                                    Available on{' '}
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Android, </span>
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>iOS</span>
                                    {' '}and{' '}
                                    <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>Web.</span>
                                    <br />
                                    <br />
                                    <span className="testimonial-item-link">
                                        <a
                                            href="/#download"
                                            onClick={() => {
                                                customAnalytics.landingpage_action({
                                                    "action": "Download Now"
                                                })
                                            }}
                                        >
                                            Download Now
                                        </a>
                                    </span>
                                </p>
                            </div>
                            <div
                                className={classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item"
                            >
                                <Image
                                    src={require('./../../assets/images/features-split-devices.webp')}
                                    alt="devices"
                                // width={528}
                                // height={396}
                                />
                            </div>
                        </div>

                        <div style={{
                            flex: 1, justifyContent: 'center', display: 'flex',
                            marginTop: 50, flexDirection: 'column', alignItems: 'center',
                            paddingBottom: 32,
                            // paddingLeft: 20,
                            // paddingRight: 20,
                            // marginLeft: -24,
                            // marginRight: -24,
                            background: BLUE_HIGHLIGHT,
                            borderRadius: 24

                        }}>
                            <div className="center-content" style={{ padding: '0 20px' }}>
                                {/* <div className="container-xs mt-0 m-0 mb-16 ml-20 mr-20 center-content"> */}
                                <h3>Start messaging with Luckynote for free</h3>
                                {/* <p>Get Luckynote today</p> */}
                            </div>
                            <ButtonGroup>
                                {/* <Button tag="a" color="primary" wideMobile href="register">
                                        Get started
                                    </Button> */}

                                <Link
                                    to={isMobile ? 'mobile' : 'register'}
                                    className="button button-primary button-wide-mobile button-md"
                                >
                                    {isMobile ? "Download" : "Create Account"}
                                </Link>

                                {/* <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button> */}
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
