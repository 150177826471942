import cloudinary from 'cloudinary-core';
import axios from 'axios';

import environment from '../env-config';

export const send_cludinary_image = async image => {
    const formData = new FormData();
    // uzmi pravo ime slike
    formData.append('file', {
        uri: image.uri,
        type: image.type,
        name: image.fileName,
        timestamp: image.timestamp,
        height: image.height,
        fileSize: image.fileSize,
        latitude: image.latitude,
        longitude: image.longitude
    });
    // formData.append('timestamp', timestamp);
    // formData.append('api_key', api_key);
    // formData.append('signature', signature);
    formData.append('upload_preset', environment.REACT_APP_UPLOAD_PRESET);

    const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${environment.REACT_APP_CLOUD_NAME}/image/upload`,
        formData
    );

    console.log(response);
    return response;
};

export const cloudinaryCore = new cloudinary.Cloudinary({
    cloud_name: environment.REACT_APP_CLOUD_NAME
});
