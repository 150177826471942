import { gql } from 'apollo-boost';

// input LockAccountManualInput {
// 	email: String!
// }

export const LOCK_ACCOUNT_MANUAL = gql`
    mutation($data: LockAccountManualInput!) {
        lockAccountManual(data: $data)
    }
`;
