import React, { useState, useEffect, useRef, useReducer } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import CreateFolderModal from './Modals/CreateFolderModal';
import FolderCard from './FolderCard';
import SpecialFolder from './SpecialFolder';

import ScrollableComponent from './ScrollableComponent';
import ProfileDropdownMenu from './Menus/ProfileDropdownMenu';
import SearchFoldersModal, { searchActions } from '../components/Modals/SearchFoldersModal';

import { STARRED, CHECKBOXES, HIERARCHY } from '../util/specialFolderEnums';

import TreeFolder3 from '../components/TreeFolder3';

import SearchInputButton from './SearchInputButton';

import { AuthContext } from '../util/context';

import useCheckBrowser from '../util/useCheckBrowser';
import { PRIMARY_COLOR_8 } from '../appearance/Colors';
import customAnalytics from '../util/custom-analytics';

function FolderListComponent({ subscribeToMore }) {
    const [showAddFolderModal, setShowAddFolderModal] = useState(false);
    const [showFoldersSearch, setShowFoldersSearch] = useState(false);

    const { Platform, PLATFORMS } = useCheckBrowser();

    const {
        state: { selectedFolder },
        context: { setSelectedFolder }
    } = React.useContext(AuthContext);

    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const onFolderClick = async folder => {
        setSelectedFolder(folder);
    };

    const onSearchClick = () => {
        setShowFoldersSearch(true);
    };

    return (
        <React.Fragment>
            <FoldersContainer onBlur={() => console.log('something should happen')}>
                <ProfileDropdownMenu />

                <SearchInputButton
                    placeholder="Jump to folder"
                    tip={`${Platform === PLATFORMS.MACINTOSH ? '⌘K' : 'Ctr+K'}`}
                    onClick={() => {
                        onSearchClick();
                    }}
                    hideFullScreen
                    hideSearch
                ></SearchInputButton>

                {/* scrollable list of folders */}

                {/* </ScrollableComponent> */}
                <TreeFolder3 subscribeToMore={subscribeToMore}></TreeFolder3>

                <div
                    style={{
                        padding: 14,
                        color: 'white',
                        backgroundColor: '#393D3F',
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowAddFolderModal(true)}
                >
                    + Create folder
                    {/* <AddFolderButton onClick={() => setShowAddFolderModal(true)}>
						Add
					</AddFolderButton> */}
                </div>
                {/* end of scrollable list of folders */}
            </FoldersContainer>
            <CreateFolderModal
                isOpen={showAddFolderModal}
                closeModal={createFolder => {
                    setShowAddFolderModal(false);
                    if (createFolder.data) {
                        console.log('this is created folder', createFolder);
                        let folder = createFolder.data.createFolder;
                        onFolderClick(folder);
                        if (document.getElementById(folder.id)) {
                            document.getElementById(folder.id).scrollIntoView();
                        }
                    }
                }}
            />
            <SearchFoldersModal
                isOpen={showFoldersSearch}
                title="Jump to folder"
                action={searchActions.JUMP}
                closeModal={value => {
                    setShowFoldersSearch(false);
                    if (value) {
                        onFolderClick(value);
                        if (document.getElementById(value.id)) {
                            document.getElementById(value.id).scrollIntoView();
                        }
                    }
                    console.log('selected folder is', value);
                }}
            ></SearchFoldersModal>
        </React.Fragment>
    );
}

const FoldersContainer = styled.div`
    width: 300px;
    background-color: ${PRIMARY_COLOR_8};
    // padding: 16px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    backdrop-filter: saturate(180%) blur(20px);
`;

// const FolderWrapper = styled.div`
//     display: flex;
//     flex-direction: row;
//     padding: 10px 0;
//     align-items: center;
// `;

export default FolderListComponent;

// const { subscribeToMore } = useQuery(
// 	GET_HOME_FOLDERS_QUERY,{
// 		fetchPolicy: 'cache-and-network'
// 	}
// );

// const [getHomeFolders, { loading, error, data }] = useLazyQuery(
// 	GET_HOME_FOLDERS_QUERY,{
// 		fetchPolicy: 'cache-and-network'
// 	}
// );

// const [
// 	reorderFolders,
// 	{ loading: mutationLoading, error: mutationError }
// ] = useMutation(REORDER_FOLDERS_MUTATION);

// useEffect(() => {
// 	getHomeFolders();
// 	console.log('something', data)
// 	if (data){
// 		data.getHomeFolders.folders.map(folder => folder.subfolders = []);
// 	}
// }, [data])

// useEffect(() => {
// 	// setting up subscription for folders
// 	console.log('did you do it?')
// 	subscribeToMore({
// 		document: NEW_FOLDER_SUBSCRIPTION,
// 		variables: {},
// 		updateQuery: (previousResult2, { subscriptionData }) => {
// 			console.log('subsription previous res', previousResult2);
// 			console.log('subsription New Data', subscriptionData);
// 			if (!subscriptionData.data) return previousResult2;
// 			const newFolder = subscriptionData.data.newFolder;
// 			// setting the folder to active
// 			newFolder.active = true;
// 			console.log(newFolder);

// 			return {
// 				getHomeFolders: {
// 					// we are not cursoring the folders, that's why it's only previousResult2
// 					folders: [...previousResult2.getHomeFolders.folders, newFolder],
// 					cursor: previousResult2.getHomeFolders.cursor,
// 					__typename: previousResult2.getHomeFolders.__typename
// 				}
// 			};
// 		}
// 	});
// 	console.log('did you do it 2?')
// 	return value => {
// 		console.log('some cleanup value', value);
// 	};
// }, []);

// const getFolders = async newFolder => {
// 	const { loading, error, data } = await client.query({
// 		query: GET_HOME_FOLDERS_QUERY
// 	});
// 	if (loading) return null;

// 	if (data) {
// 		let { getUserFolders } = await data;
// 		console.log('did you go in', getUserFolders);
// 		// setting the first folder from the list
// 		console.log('fuck you', newFolder);
// 		let firstFolder;
// 		if (!newFolder) {
// 			firstFolder = getUserFolders[0];
// 		} else {
// 			console.log('are you fucking me ', newFolder);
// 			firstFolder = newFolder;
// 			getUserFolders.push(newFolder);
// 		}
// 		firstFolder.active = true;
// 		setCurrentFolder(firstFolder);
// 		selectedFolder(firstFolder);
// 		// setting all folders
// 		setFolders(getUserFolders);
// 	}
// };
