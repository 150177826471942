import { QUERY } from '../../../gql-operations';

function loadMoreMessages(folder, cursor, isTrash, fetchMore, setLoadMoreCheck) {
    fetchMore({
        query: QUERY.GET_FOLDER_CONTENT,
        variables: {
            data: {
                folderId: folder.id,
                messageCursor: cursor,
                isTrash: isTrash
            }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
            // console.log('This is previousRes', previousResult);
            // console.log('This is fetch more', fetchMoreResult);
            const previousEntry = previousResult.getFolderContent.messages.messages;

            // case of the first message in the folder
            if (!previousResult.getFolderContent.messages.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            // if there is no fetchMore, just return the previous data
            if (!fetchMoreResult.getFolderContent.messages.cursor) {
                setLoadMoreCheck(false);
                return previousResult;
            }

            // cursor is the same
            if (previousResult.getFolderContent.messages.cursor === fetchMoreResult.getFolderContent.messages.cursor) {
                setLoadMoreCheck(false);
                return;
            }

            const newMessages = fetchMoreResult.getFolderContent.messages.messages;
            const newCursor = fetchMoreResult.getFolderContent.messages.cursor;

            setLoadMoreCheck(false);
            return {
                getFolderContent: {
                    // we are not cursoring the folders, that's why it's only previousResult
                    folder: previousResult.getFolderContent.folder,
                    parent: previousResult.getFolderContent.parent,
                    path: previousResult.getFolderContent.path,
                    folders: [...previousResult.getFolderContent.folders],
                    messages: {
                        messages: [...(previousEntry && previousEntry.length > 0 ? previousEntry : []), ...newMessages],
                        cursor: newCursor,
                        __typename: previousResult.getFolderContent.messages.__typename
                    },
                    __typename: previousResult.getFolderContent.__typename
                }
            };
        }
    });
}

export default loadMoreMessages;
