import { gql } from 'apollo-boost';

// input DeleteAccountInput {
// 	email: String!
// }

export const CHANGE_MESSAGE_QUOTA = gql`
    mutation($data: ChangeMessageQuotaInput!) {
        changeMessageQuota(data: $data)
    }
`;
