import { gql } from 'apollo-boost';

export const NEW_FOLDER_SUBSCRIPTION = gql`
    subscription newFolder {
        newFolder {
            id
            title
            parent
            owner
            before
            deletedAt
        }
    }
`;