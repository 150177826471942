import React, { useState } from 'react';
import { AuthContext } from '../util/context';
import { moveItemOnTree } from '@atlaskit/tree';

import { MUTATION } from '../gql-operations';

import { useMutation } from '@apollo/react-hooks';

import useMoveFolderMutation from '../hooks/useMoveFolderMutation';
import useAddFoldersToTree from './useAddFoldersToTree';
import useDeleteFoldersMutation from './useDeleteFoldersMutation';
import useGetSubfolders from './useGetSubfolders';
import customAnalytics from '../util/custom-analytics';

function useUpdateFoldersOnTree() {
    const [createFolder] = useMutation(MUTATION.CREATE_FOLDER, {
        onCompleted: res => {
            // analytics below
        }
    });
    const [moveFolder] = useMoveFolderMutation();
    const [addChildrenToFolder] = useAddFoldersToTree();
    const { deleteChildFromTree } = useDeleteFoldersMutation();
    const [getSubfolders] = useGetSubfolders();

    const {
        state: { treeData },
        context: { setNewTree, setSelectedFolder }
    } = React.useContext(AuthContext);

    const moveFolderToRoot = async folder => {
        // console.log('this is the folder item', folder);
        // ovde treba da stigne update
        let response = await moveFolder(folder, null);
        // console.log('dkdkdkkdd', response);
        // since it's moving to the root, only the first folder should be updated
        let updatedFolder = response.data.moveFolder[0];
        removeFolderFromTreeManually(folder, updatedFolder);
        addChildrenToFolder([updatedFolder]);
        setSelectedFolder(updatedFolder);
        customAnalytics.folder_edit({
            'action': "Move",
            "destination": "Root"
        })
        return updatedFolder;
    };

    const createAndMoveFolder = async (newTitle, folderToMove) => {
        let response = await createFolder({
            variables: {
                data: {
                    title: newTitle,
                    parent: folderToMove.parent
                }
            }
        });
        if (folderToMove.parent) {
            customAnalytics.create_subfolder_from_folder('Create and Move');
        } else {
            customAnalytics.create_home_folder('Create and Move');
        }
        // console.log('created', response);
        if (response) {
            let createdFolder = response.data.createFolder;
            // console.log('created folder', createdFolder);
            let response = await moveFolder(folderToMove, createdFolder);
            // console.log('response from moving', response);
            let updatedFolder = response.data.moveFolder[0];
            updateTreeAfterCreated(folderToMove);
            // selecting the updated moved folder
            setSelectedFolder(updatedFolder);
            // getting the subfolder for the newly created
            getSubfolders(createdFolder, true);
            // returning created so we can scroll to him
            customAnalytics.folder_edit({
                'action': "Move",
                "destination": createdFolder && createdFolder.parent ? "Subfolder" : "HomeFolder"
            }, folderToMove)
            return createdFolder;
        }
    };

    const updateTreeAfterCreated = async folder => {
        if (folder) {
            let newTreeCopy = { ...treeData };

            // updating the folder to move with updated one
            const origFolder = newTreeCopy.items[folder.id];

            if (origFolder) {
                const parent = newTreeCopy.items[folder.parent ? folder.parent : 'root'];
                if (parent) {
                    let newSourceChildren = [...parent.children];
                    const folderIndex = newSourceChildren.findIndex(obj => obj === origFolder.id);
                    if (folderIndex > -1) {
                        newSourceChildren.splice(folderIndex, 1);
                    }

                    // updating the new parent
                    parent.children = newSourceChildren;
                    newTreeCopy.items[parent.id] = parent;
                    // updated automatically on tree

                    // setNewTree(newTreeCopy);
                }
            }
        }
    };

    // removing the folder from the treeData manually
    const removeFolderFromTreeManually = async (folder, updatedFolder) => {
        if (folder) {
            let newTreeCopy = { ...treeData };

            // updating the folder to move with updated one
            const origFolder = newTreeCopy.items[folder.id];
            if (origFolder) {
                origFolder.data = updatedFolder;

                // getting the parent to remove it from children
                const parent = newTreeCopy.items[folder.parent];
                if (parent) {
                    let newSourceChildren = [...parent.children];
                    const folderIndex = newSourceChildren.findIndex(obj => obj === origFolder.id);
                    if (folderIndex > -1) {
                        newSourceChildren.splice(folderIndex, 1);
                    }

                    // updating the new parent
                    parent.children = newSourceChildren;
                    newTreeCopy.items[parent.id] = parent;

                    setNewTree(newTreeCopy);
                }
            }
        }
    };

    const updateFoldersOnTree = async (source, destination) => {
        console.log('foldersToUpdate', source, destination);
        console.log('treeData', treeData);

        let folderToMove = { id: source.id };
        let newParent;
        if (destination) {
            newParent = { id: destination.id };
        }
        let response = await moveFolder(folderToMove, newParent);
        let foldersToUpdate = response.data.moveFolder;
        // console.log('response for moving the folder', foldersToUpdate);
        // const findSource = treeData.items[source.id];
        // const findDestination = treeData.items[destination.id];
        let parent = source.parent;

        const sourceParent = treeData.items[parent ? parent : 'root'];
        // console.log('i found parent', sourceParent);
        if (sourceParent) {
            let newSourceChildren = [...sourceParent.children];
            // console.log('i found children', newSourceChildren);
            const folderIndex = newSourceChildren.findIndex(obj => obj === source.id);
            // console.log('i found the index', folderIndex);

            let constructSourceFolder = null;
            if (folderIndex > -1) {
                constructSourceFolder = {
                    parentId: parent ? parent : 'root',
                    index: folderIndex
                };

                const constructDestinationFolder = {
                    parentId: foldersToUpdate[0].parent
                };

                // console.log('constructed ones', constructSourceFolder, constructDestinationFolder);

                if (treeData.items[constructDestinationFolder.parentId]) {
                    const newTree = moveItemOnTree(treeData, constructSourceFolder, constructDestinationFolder);
                    setNewTree(newTree);

                    let newTreeCopy = { ...newTree };

                    if (foldersToUpdate) {
                        await foldersToUpdate.forEach(incomingFolder => {
                            if (incomingFolder && newTreeCopy.items[incomingFolder.id]) {
                                newTreeCopy.items[incomingFolder.id].data = {
                                    ...incomingFolder
                                };
                            }
                        });
                        setNewTree(newTreeCopy);
                    }
                } else {
                    const currentFolderId = sourceParent.children[folderIndex];
                    // sending folder as a data object from treeItem
                    const currentFolderObject = treeData.items[currentFolderId].data;
                    deleteChildFromTree([currentFolderObject]);
                }
            } else {
                let movingFolder = foldersToUpdate[0];
                addChildrenToFolder([movingFolder]);
            }
        }
        customAnalytics.folder_edit({
            'action': "Move",
            "destination": destination && destination.parent ? "Subfolder" : "HomeFolder"
        }, source)
    };

    return { updateFoldersOnTree, moveFolderToRoot, createAndMoveFolder };
}

export default useUpdateFoldersOnTree;
