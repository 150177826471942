import React from 'react';

import styled from 'styled-components';

import { GREEN, PRIMARY_COLOR, WHITE } from '../../appearance/Colors';

const InactiveTagButton = ({ label, type, ...rest }) => {
    return <InactiveTag {...rest}>{label}</InactiveTag>;
};

const InactiveTag = styled.div`
    border-color: ${PRIMARY_COLOR};
    border-style: solid;
    border-width: 1px;
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${WHITE};
    border-radius: 4px;
    color: ${PRIMARY_COLOR};
    font-size: 12px;
    // cursor: pointer;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
`;

export default InactiveTagButton;
