import React from 'react';
import GoToFolderNotification from './GoToFolderNotification';
import CreatedFolderNotification from './CreatedFolderNotification';
import DefaultNotification from './DefaultNotification';
import { store } from 'react-notifications-component';

import { AuthContext } from '../../util/context';
import CopyToClipboardNotification from './CopyToClipboardNotification';
import { FiCheck, FiX } from 'react-icons/fi';
import { PALE_WHITE_6 } from '../../appearance/Colors';

function useNotificationSystem() {
    const {
        state: { treeData },
        context: { setSelectedFolder }
    } = React.useContext(AuthContext);

    const createNotificationId = () => {
        let notificationId = Math.floor(Math.random() * 100000000);
        return notificationId;
    };

    const showGoToFolderNotification = toFolder => {
        console.log('this is the folder', toFolder);
        let notificationId = createNotificationId();
        store.addNotification({
            // just because we're showing the notification for creating the folder at the same
            id: notificationId,
            content: (
                <GoToFolderNotification
                    notificationId={notificationId}
                    toFolder={toFolder}
                    title={`Message moved to ${toFolder.title}`}
                    actionTitle={`View folder`}
                    actionClick={() => {
                        setSelectedFolder(toFolder);
                    }}
                    icon={<FiCheck color={PALE_WHITE_6} size={18}></FiCheck>}
                />
            ),
            // title: 'Wonderful!',
            // message: 'teodosii@react-notifications-component',
            // type: 'success',
            width: 300,
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 5000,
                // onScreen: true,
                pauseOnHover: true,
                click: false
            }
        });
    };

    const movedFolderNotification = toFolder => {
        if (!toFolder.title) {
            let newFold = treeData.items[toFolder.id];
            if (newFold && newFold.data) {
                toFolder = newFold.data;
            }
        }
        let notificationId = createNotificationId();
        store.addNotification({
            // just because we're showing the notification for creating the folder at the same
            id: notificationId,
            content: (
                <GoToFolderNotification
                    notificationId={notificationId}
                    toFolder={toFolder}
                    title={`Folder moved to ${toFolder.title}`}
                    actionTitle={`View folder`}
                    actionClick={() => {
                        setSelectedFolder(toFolder);
                    }}
                    icon={<FiCheck color={PALE_WHITE_6} size={18}></FiCheck>}
                />
            ),
            // title: 'Wonderful!',
            // message: 'teodosii@react-notifications-component',
            // type: 'success',
            width: 300,
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 4000,
                // onScreen: true,
                pauseOnHover: true,
                click: false
            }
        });
    };

    const showCreatedFolderNotification = folder => {
        console.log('this is the folder', folder);
        let notificationId = createNotificationId();
        store.addNotification({
            id: notificationId,
            content: (
                <CreatedFolderNotification
                    notificationId={notificationId}
                    toFolder={folder}
                    setSelectedFolder={setSelectedFolder}
                    title="Created"
                />
            ),
            // title: 'Wonderful!',
            // message: 'teodosii@react-notifications-component',
            // type: 'success',
            width: 300,
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 1500,
                // onScreen: true,
                pauseOnHover: true,
                click: false
            }
        });
    };

    const showDefaultNotification = (id, title) => {
        console.log('this is the folder', id, title);
        store.addNotification({
            id: id,
            content: <DefaultNotification id={id} title={title} />,
            // title: 'Wonderful!',
            // message: 'teodosii@react-notifications-component',
            // type: 'success',
            width: 300,
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 1500,
                // onScreen: true,
                pauseOnHover: false,
                click: false
            }
        });
    };

    const showCopyToClipboardNotification = () => {
        // console.log('this is the folder', id, title);
        let notificationId = createNotificationId();
        store.addNotification({
            id: notificationId,
            content: <CopyToClipboardNotification notificationId={notificationId} title={'Copied to clipboard!'} />,
            // title: 'Wonderful!',
            // message: 'teodosii@react-notifications-component',
            // type: 'success',
            width: 300,
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 1500,
                // onScreen: true,
                pauseOnHover: false,
                click: false
            }
        });
    };

    return {
        showGoToFolderNotification,
        showDefaultNotification,
        showCopyToClipboardNotification,
        showCreatedFolderNotification,
        movedFolderNotification
    };
}

export default useNotificationSystem;
