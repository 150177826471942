import React from 'react';
import { REDIRECT_TO_HOME, REDIRECT_TO_LOGIN } from './errors';
// import { useNavigation } from "@react-navigation/native";

import { AuthContext } from '../util/context';

import { useHistory } from 'react-router-dom';

function useRedirectErrors() {
    // const {
    //     context: { setClientError }
    // } = React.useContext(AuthContext);

    let history = useHistory();

    const signOut = async () => {
        await localStorage.removeItem('token');
        await localStorage.removeItem('refreshToken');
        history.push('/login');
    };

    // const showClientError = (error) => {
    //     setClientError(error.graphQLErrors)
    // }

    const passingErrors = async (error, navigate) => {
        // const navigation = useNavigation();
        console.log('useRedirectErrors: ', error);
        // console.log('incoming error', error.graphQLErrors.e);
        if (Array.isArray(error.graphQLErrors)) {
            if (error.graphQLErrors.length > 0) {
                error.graphQLErrors.forEach(async error => {
                    console.log('incoming errors', error);
                    console.log('incoming errors', error.extensions.code);
                    console.log('incoming errorssss', error.extensions.code === REDIRECT_TO_LOGIN);
                    if (error.extensions.code === REDIRECT_TO_HOME) {
                        // do something
                    } else if (error.extensions.code === REDIRECT_TO_LOGIN) {
                        signOut();
                        // await AsyncStorage.removeItem("userData");
                        // navigate("SignIn");
                        // navigation.navigate("SignIn")
                    } else {
                        // history.push('/login');
                        // showClientError(error);
                    }
                });
            } else {
                if (error.message.includes('Network error: Failed to fetch')) {
                    history.push('/error');
                }
            }
        } else {
            if (error.graphQLErrors.code === REDIRECT_TO_HOME) {
                // do something
            } else if (error.graphQLErrors.code === REDIRECT_TO_LOGIN) {
                signOut();
                // await AsyncStorage.removeItem("userData");
                // navigate("SignIn");
                // navigation.navigate("SignIn")
            } else {
                // signOut();
                // history.push('/login');
                // showClientError(error);
            }
        }
    };

    return { passingErrors };
}

export default useRedirectErrors;
