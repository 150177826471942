import { gql } from 'apollo-boost';

// input DownloadFileInput {
//     fileName: String!
// }

export const DOWNLOAD_FILE = gql`
    query($data: DownloadFileInput!) {
        downloadFile(data: $data) {
            __typename
            ... on S3File {
                url
            }
            ... on FileDoesNotBelongToUserError {
                message
            }
        }
    }
`;
