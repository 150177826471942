import React from 'react';
import AudioMessage from './AudioMessage';

function AudioSection({ file }) {
    let title;
    if (file.displayName) {
        title = file.displayName;
    } else if (file.name) {
        title = file.name;
    } else {
        title = new Date().toISOString();
    }
    return (
        <div style={{ position: 'relative' }}>
            <AudioMessage url={file.url} title={title}></AudioMessage>
        </div>
    );
}

export default AudioSection;
