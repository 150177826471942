import React, { useEffect, useState } from 'react';

import { MUTATION } from '../../../gql-operations';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withRouter, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { changeSubscriptionToken } from '../../../App';

import styled from 'styled-components';

import './Register.scss';

import LuckyIcon from '../../../appearance/LuckyIcon';
import * as COLOR from '../../../appearance/Colors';

import ReactPixel from 'react-facebook-pixel';
import customAnalytics from '../../../util/custom-analytics';
import Loader from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';

const Register = ({ history }) => {
    const location = useLocation();
    const [referralId, setReferralId] = useState('');
    const [regResponse, setRegResponse] = useState(undefined);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [registerMutation, { loading }] = useMutation(MUTATION.REGISTER, {
        onCompleted: res => {
            setRegResponse(res);
            customAnalytics.sign_up(res.register);
        }
    });

    useEffect(() => {
        ReactPixel.fbq('track', 'PageView', 'Register');
        let referralIdLocal = localStorage.getItem('referralId');
        if (referralIdLocal) {
            setReferralId(referralIdLocal)
        } else {
            // getting the ID from the params
            const referralIdParam = new URLSearchParams(location.search).get('referral');
            if (referralIdParam) {
                localStorage.setItem("referralId", referralIdParam);
                setReferralId(referralIdParam)
            }
        }
    }, []);

    const _setLocalStorage = response => {
        const { register } = response;

        if (register && register.token && register.refreshToken) {
            console.log('SETTING LOCAL STORAGE');
            const { token, refreshToken, userData } = register;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            changeSubscriptionToken(token);
            localStorage.removeItem('referralId');
            // localStorage.setItem('userData', JSON.stringify(userData))
        }
    };

    const loadingMessage = () => {
        if (loading) {
            return (
                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <ErrorText>{`Creating account... \n Please wait, it might take a moment.`}</ErrorText>
                    <div style={{ marginTop: 8 }}>
                        <Loader
                            type="Puff"
                            // color="#00BFFF"
                            color={COLOR.SECONDARY_COLOR}
                            height={50}
                            width={50}
                        // timeout={3000} //3 secs
                        />
                    </div>
                </div>
            )
        }
    };

    const renderResponseMessage = () => {
        if (regResponse) {
            switch (regResponse.register.__typename) {
                case 'AuthPayload':
                    _setLocalStorage(regResponse);
                    setRegResponse(undefined);
                    history.push('/home');
                    return true;
                case 'DbStartTransactionError':
                case 'EmailAlreadyTakenError':
                case 'RegisterInvalidInputError':
                case 'CustomRegistrationError':
                    return <ErrorText>{regResponse.register.message}</ErrorText>;
                case 'InternalServerError':
                    return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
                default:
                    return <ErrorText>Unknown error</ErrorText>;
            }
            // if (error.graphQLErrors.length > 0) {
            //     return <ErrorText>{error.graphQLErrors.map(x => x.message)}</ErrorText>;
            // } else {
            //     return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
            // }
        }
    };

    const renderReferralMessage = () => {
        if (referralId && !loading) {
            return <InfoText>Referral code <strong>{referralId}</strong> applied</InfoText>;
        }
    }

    return (
        <>
            <div
                style={{
                    backgroundColor: '#fafafa',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        fullName: '',
                        email: '',
                        password: '',
                        referral: referralId ? referralId : ""
                    }}
                    onSubmit={async (values, actions) => {
                        setRegResponse(undefined);
                        // TODO nothing here for now
                        // console.log('values are', values);
                        registerMutation({
                            variables: {
                                data: {
                                    fullName: values.fullName,
                                    email: values.email,
                                    password: values.password,
                                    settings: {
                                        usedDesktopWebApp: true
                                    },
                                    referralId: values.referral
                                }
                            }
                        });

                        actions.setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                        fullName: Yup.string().min(2, 'Too short').max(70, 'Too long.  Maximum length is 70.').required('Required'),
                        email: Yup.string().email('Invalid email').required('Required'),
                        password: Yup.string().min(6, 'Minimum password length is 6.').max(50, 'Maximum password length is 50.').required('Required'),
                        referral: Yup.string()
                    })}
                >
                    {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                        <div>
                            {isMobile ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        textAlign: 'center',
                                        backgroundColor: COLOR.GREEN_OP,
                                        padding: 16,
                                        flexDirection: 'column'
                                    }}
                                    onClick={() => {
                                        history.push('/mobile');
                                    }}
                                >
                                    <div style={{ fontSize: 14, lineHeight: '20px' }}>
                                        Trying to access Luckynote over your phone browser? It works better as a
                                        standalone Android and iOS app.
                                    </div>
                                    <div style={{ marginTop: 16, fontWeight: 600, fontSize: 16 }}>Download app</div>
                                </div>
                            ) : null}
                            <div
                                className="logo"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (!loading) history.push('/home');
                                }}
                            >
                                <LuckyIcon />
                                {/* <h4 style={{ color: '#333333' }}>Luckynote</h4> */}
                            </div>
                            {renderResponseMessage()}
                            {loadingMessage()}

                            {!loading ?
                                <div className="card">
                                    <form onSubmit={handleSubmit} className="auth-form">
                                        <div className="row fullName">
                                            <input
                                                name="fullName"
                                                type="text"
                                                onChange={handleChange}
                                                // onBlur={handleBlur}
                                                value={values.fullName}
                                                placeholder="Your name"
                                            />
                                            <ErrorMessage
                                                name="fullName"
                                                render={msg => {
                                                    return (
                                                        <div id="fullName" className="error">
                                                            {msg}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div className="row email">
                                            <input
                                                name="email"
                                                type="email"
                                                onChange={handleChange}
                                                // onBlur={handleBlur}
                                                value={values.email}
                                                placeholder="Email"
                                                spellCheck="off"
                                                autoCapitalize="off"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                render={msg => {
                                                    console.log('msg', msg);
                                                    // return <Text style={styles.errorMsg}>{msg}</Text>;
                                                    return (
                                                        <div id="email" className="error">
                                                            {msg}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div className="row password">
                                            <input
                                                name="password"
                                                type="password"
                                                onChange={handleChange}
                                                // onBlur={handleBlur}
                                                value={values.password}
                                                placeholder="Password"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                render={msg => {
                                                    return (
                                                        <div id="password" className="error">
                                                            {msg}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div className="row fullName">
                                            <input
                                                name="referral"
                                                type="referral"
                                                onChange={handleChange}
                                                // onBlur={handleBlur}
                                                value={values.referral}
                                                placeholder="Referral Code (Optional)"
                                                spellCheck="off"
                                                autoCapitalize="off"
                                            />
                                        </div>

                                        <div className="row submit">
                                            <button type="submit" className="button big blue" disabled={loading}>
                                                {loading ? 'Registering...' : 'Register'}
                                            </button>
                                        </div>
                                    </form>

                                    <span className="link signup">
                                        Already have an account?
                                        <span> </span>
                                        <Link to={'/login'}>Sign In</Link>
                                    </span>
                                </div>
                                : null}

                            {renderReferralMessage()}
                            {/* {loading && <p>Loading...</p>} */}
                            {/** {object.error && <p>Error :( Please try again</p>}  */}
                        </div>
                    )}
                </Formik>
            </div>
        </>
    );
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;

const InfoText = styled.div`
    color: ${COLOR.GREEN};
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;

export default withRouter(Register);
