import React from 'react';

import Modal from './Modal';

import { useApolloClient, useMutation } from '@apollo/react-hooks';

import { MUTATION, QUERY } from '../../gql-operations';

import styled from 'styled-components';
import { PALE_WHITE_6, WHITE } from '../../appearance/Colors';
import customAnalytics from "../../util/custom-analytics";
import DangerTagButton from '../Action/DangerTagButton';
import DefaultTag from '../Action/DefaultTag';
import { useHistory } from 'react-router-dom';

function DeleteUserModal({ isOpen, closeModal, user }) {
    const client = useApolloClient();
    let history = useHistory();
    const [deleteUser, { loading }] = useMutation(MUTATION.DELETE_USER, {
        onCompleted: res => {
            console.log('completed', res)
            responseToDelete(res);
        },
        onError: res => {
            console.log('error', res)
        }
    })

    const signOut = async () => {
        localStorage.clear();
        // await localStorage.removeItem('token');
        // await localStorage.removeItem('refreshToken');
        // await localStorage.removeItem('userData');
        client.resetStore();
        history.push('/login');
        customAnalytics.delete_account();
    };

    const responseToDelete = (regResponse) => {
        if (regResponse) {
            switch (regResponse.deleteUser.__typename) {
                case 'User':
                    signOut();
                case 'DbStartTransactionError':
                    return <ErrorText>{regResponse.deleteUser.message}</ErrorText>;
                case 'InternalServerError':
                    return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
                default:
                    return <ErrorText>Unknown error</ErrorText>;
            }
        }
    }


    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    const deleteAccountMutation = () => {
        deleteUser({
            variables: {
                data: {
                    userId: user.id,
                    email: user.email
                }
            }
        });
        closeModal();
        // console.log('he deleted the account', response);
    };

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={deleteUserModalStyle}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1, color: WHITE, fontSize: 18, fontWeight: '600' }}>Your account will be permanently deleted</div>
                <p style={{ color: PALE_WHITE_6, fontSize: 16, marginTop: 8 }}>By deleting account all your data will be deleted forever. This includes folders, messages as well as any type of document
                    you have saved such as images, files and notes. This process is irreversible. Are you sure you want to continue?</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}></div>
                    <DefaultTag
                        label="Cancel"
                        onClick={closeModal}
                        style={{ marginRight: 8 }}
                    ></DefaultTag>
                    <DangerTagButton
                        label='Delete Account'
                        onClick={deleteAccountMutation}
                    ></DangerTagButton>
                </div>
            </div>
        </Modal>
    );
}

const deleteUserModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // color: 'black',
        borderRadius: 10,
        border: 0,
        maxWidth: 640,
        backgroundColor: '#222729'
    }
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;


export default DeleteUserModal;
