import { gql } from 'apollo-boost';

export const GET_STARRED_MESSAGES_GLOBAL = gql`
    query {
        getStarredMessagesGlobal {
            folder {
                id
                title
                owner
                parent
            }

            messages {
                id
                title
                body
                createdAt
                updatedAt
                deletedAt
                image {
                    url
                    name
                    displayName
                    type
                    format
                    size
                    width
                    aspect_ratio
                    public_id
                }
                file {
                    url
                    name
                    displayName
                    type
                    format
                    size
                }
                isCheckbox
                isChecked
                isStarred
                folder {
                    id
                    title
                    owner
                    parent
                    deletedAt
                }
                urlMeta {
                    author
                    date
                    description
                    image
                    logo
                    publisher
                    title
                    url
                    public_id
                    width
                    format
                    aspect_ratio
                    size
                }
            }
        }
    }
`;
