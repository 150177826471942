import { gql } from 'apollo-boost';

export const GET_FOLDER_CONTENT = gql`
    query($data: GetFolderContentInput!) {
        getFolderContent(data: $data) {
            folder {
                id
                title
                parent
                before
                owner
                deletedAt
            }
            parent {
                id
                title
                parent
                before
                owner
                deletedAt
            }
            folders {
                id
                title
                parent
                before
                owner
                deletedAt
            }
            path {
                id
                title
                parent
                before
                owner
                deletedAt
            }
            messages {
                messages {
                    id
                    title
                    body
                    createdAt
                    updatedAt
                    deletedAt
                    image {
                        url
                        name
                        displayName
                        type
                        format
                        size
                        width
                        aspect_ratio
                        public_id
                    }
                    file {
                        url
                        name
                        displayName
                        type
                        format
                        size
                        mime
                    }
                    isCheckbox
                    isChecked
                    isStarred
                    folder {
                        id
                        title
                        parent
                        owner
                        deletedAt
                    }
                    urlMeta {
                        author
                        date
                        description
                        image
                        logo
                        publisher
                        title
                        url
                        public_id
                        width
                        format
                        aspect_ratio
                        size
                    }
                }
                cursor
            }
        }
    }
`;
