import React, { useState, useEffect } from 'react';

// import background from '../assets/background.jpg';

import styled from 'styled-components';

import { QUERY } from '../gql-operations';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import { cloudinaryCore } from '../util/cloudinary';

import BackgroundPreviewModal from '../components/Modals/BackgroundPreviewModal';
import { PRIMARY_COLOR } from '../appearance/Colors';
import { AuthContext } from '../util/context';

import { Image, Transformation } from 'cloudinary-react';

import getImagePreviews from '../util/getImagePreviews';

import useWindowDimensions from '../hooks/useWindowDimensions';

function MainBackgroundSection({ children }) {
    const [imagesPreview, setImagesPreview] = useState(undefined);
    const [imageWidth, setimageWidth] = useState(2160)
    const { data: userData, loading: userLoading, error: userError } = useQuery(QUERY.ME);

    // const { height, width } = useWindowDimensions();

    const {
        state: { showBackgroundSelect },
        context: { setShowBackgroundSelect }
    } = React.useContext(AuthContext);

    const setBackground = () => {
        if (userData && !userLoading) {
            if (imagesPreview) {
                return imagesPreview;
            } else if (userData && userData.me.backgroundImageUrl) {
                return cloudinaryCore.url(userData.me.backgroundImageUrl, {
                    width: imageWidth,
                    quality: 'auto',
                    fetchFormat: 'auto',
                    crop: 'fit'
                });
            } else {
                return null;
            }
        }
    };

    return (
        <>
            <MainSection
                style={{
                    background: `url(${setBackground()})`
                }}
            >
                {children}
            </MainSection>
            <BackgroundPreviewModal
                isOpen={showBackgroundSelect}
                title="Set background image"
                imagePreview={value => {
                    console.log('this is preview', value);
                    setImagesPreview(value[0].reader.result);
                    // setImagePreview(value);
                }}
                closeModal={value => {
                    setImagesPreview(undefined);
                    setShowBackgroundSelect(false);
                }}
            ></BackgroundPreviewModal>
        </>
    );
}

const MainSection = styled.section`
    background-color: ${PRIMARY_COLOR};
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-attachment: fixed !important;
    height: 100%;
`;

export default MainBackgroundSection;
