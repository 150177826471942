import { gql } from 'apollo-boost';

export const GET_TRASH_FOLDERS_GLOBAL = gql`
    query {
        getTrashFoldersGlobal {
            folders {
                id
                title
                parent
                owner
                before
                path
                cursor
                deletedAt
            }
            cursor
        }
    }
`;
