import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { format, compareAsc } from 'date-fns';

import { GREEN, NOTE_BACKGROUND, PALE_WHITE, PALE_WHITE_6, RED } from '../appearance/Colors';
import SearchMessage from './Messages/SearchMessage';

import { Waypoint } from 'react-waypoint';

const useKeyPress = function (targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);

    function downHandler({ key }) {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    const upHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    });

    return keyPressed;
};

// const items = [
// 	{ id: 1, name: 'Josh Weir' },
// 	{ id: 2, name: 'Sarah Weir' },
// 	{ id: 3, name: 'Alicia Weir' },
// 	{ id: 4, name: 'Doo Weir' },
// 	{ id: 5, name: 'Grooft Weir' }
// ];

const renderWaypoint = (i, items, loadMore) => {
    // console.log('waypoint entered', i);
    // console.log('hello', data.getFolderContent.messages.messages.length);
    // console.log('hello 3', data.getFolderContent.messages.messages.length - 3);
    if (items.length > 2) {
        // console.log('this is the size');
        if (i === items.length - 3) {
            return <Waypoint onEnter={() => loadMore()} />;
        } else {
            return null;
        }
    }
};

const ListItem = ({ item, items, active, index, setSelected, setHovered, loadMore }) => (
    <div
        key={index}
        className={`item ${active ? 'active' : ''}`}
        onClick={e => {
            if (e.target?.tagName === 'A') {
                e.stopPropagation();
            } else {
                setSelected(item)
            }
        }}
        onMouseEnter={() => setHovered(item)}
        onMouseLeave={() => setHovered(undefined)}
        // style={{ ...customStyles, backgroundColor: active ? 'rgba(255,255,255,.4)' : 'transparent' }}
        style={{
            // color: 'white',
            cursor: 'pointer',
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 4,
            backgroundColor: active ? 'rgba(255,255,255,.2)' : 'transparent'
        }}
    >
        <SearchMessage message={item} key={item.id}></SearchMessage>
        {renderWaypoint(index, items, loadMore)}
    </div>
);

const SelectableMessagesList = ({
    items,
    selectedItem,
    showDeleted,
    showGlobal,
    actionDeleted,
    actionGlobal,
    loadMore,
    currentfolder
}) => {
    const [selected, setSelected] = useState(undefined);
    const downPress = useKeyPress('ArrowDown');
    const upPress = useKeyPress('ArrowUp');
    const enterPress = useKeyPress('Enter');
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState(undefined);

    useEffect(() => {
        setCursor(0);
    }, [items]);

    useEffect(() => {
        if (items && items.length && downPress) {
            setCursor(prevState => (prevState < items.length - 1 ? prevState + 1 : prevState));
            if (items.length - 1 === cursor) {
                setCursor(0);
            }
        }
    }, [downPress]);

    useEffect(() => {
        if (items && items.length && upPress) {
            setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
            if (cursor === 0) {
                setCursor(items.length - 1);
            }
        }
    }, [upPress]);

    useEffect(() => {
        if (items && items.length && enterPress) {
            setSelected(items[cursor]);
            selectedItem(items[cursor]);
        }
    }, [cursor, enterPress]);

    useEffect(() => {
        if (items && items.length && hovered) {
            setCursor(items.indexOf(hovered));
        }
    }, [hovered]);

    // console.log('this is selected', selected)
    return (
        <div>
            {items &&
                items.map((item, i) => (
                    <ListItem
                        key={item.id}
                        index={i}
                        active={i === cursor}
                        item={item}
                        items={items}
                        setSelected={value => {
                            setSelected(value);
                            selectedItem(value);
                        }}
                        setHovered={setHovered}
                        loadMore={loadMore}
                    />
                ))}

            {items && items.length === 0 ? (
                <>
                    <div style={{ color: PALE_WHITE_6 }}>No messages found</div>

                    {currentfolder && !showGlobal ? (
                        <div style={{ color: PALE_WHITE_6, marginTop: 4 }}>
                            Search in{' '}
                            <span onClick={actionGlobal} style={{ color: GREEN, cursor: 'pointer' }}>
                                All
                            </span>
                        </div>
                    ) : null}
                    {showGlobal && !showDeleted ? (
                        <div style={{ color: PALE_WHITE_6, marginTop: 4 }}>
                            Search in{' '}
                            <span onClick={actionDeleted} style={{ color: RED, cursor: 'pointer' }}>
                                Deleted
                            </span>
                        </div>
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

const customStyles = {
    color: 'white',
    cursor: 'pointer'
};

export default SelectableMessagesList;
