import React from 'react';
import useCheckValidity from '../../../util/useCheckValidity';
import { Image, Transformation } from 'cloudinary-react';
import environment from '../../../env-config';
import { PRIMARY_COLOR_1, NOTE_BACKGROUND } from '../../../appearance/Colors';
import { cloudinaryCore } from '../../../util/cloudinary';

function ImageSection({ url, width, image, crop, onClick, ...rest }) {
    // Just checking if the image exist from cloudinary
    // const originalSize = cloudinaryCore.url(metaData.image, { quality: 'auto', fetchFormat: 'auto' });
    // console.log('url', originalSize)
    // let valid = checkImageValidity(originalSize);
    // console.log('valid is', valid)

    // const imageWidth = width ? image && image.width < 100 ? image.width : width : 300;

    const imageWidth = (image && image.width) < 100 ? 60 : width ? width : 300;

    // calculating tempHeight based on image info
    let tempHeight = Math.floor(image.aspect_ratio * imageWidth);

    if (url) {
        return (
            <div
                style={{
                    minHeight: 40,
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: NOTE_BACKGROUND
                }}
                onClick={e => {
                    // open the carousel
                    if (onClick) {
                        e.stopPropagation();
                        onClick();
                    }
                }}
            >
                {image.format === 'heic' ? (
                    <Image
                        cloudName={environment.REACT_APP_CLOUD_NAME}
                        // using regular url
                        publicId={image.public_id}
                        width={width ? width : '300'}
                        crop={crop ? crop : 'fit'}
                        style={{
                            borderRadius: 4,
                            maxWidth: 300,
                            minHeight: tempHeight
                        }}
                        {...rest}
                    >
                        <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                    </Image>
                ) : (
                    <Image
                        cloudName={environment.REACT_APP_CLOUD_NAME}
                        // using regular url
                        publicId={url}
                        // width={width ? width : '300'}
                        // crop={crop ? crop : 'fit'}
                        style={{
                            borderRadius: 4,
                            maxWidth: 300,
                            minWidth: 100,
                            minHeight: tempHeight
                        }}
                        {...rest}
                    >
                        <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                    </Image>
                )}
            </div>
        );
    }
    // return null;
}

export default ImageSection;
