import { gql } from 'apollo-boost';

// input CreateFolderInput {
// 	title: String!
// 	parent: ID
// 	before: ID
// }

export const CREATE_FOLDER = gql`
    mutation($data: CreateFolderInput!) {
        createFolder(data: $data) {
            id
            title
            parent
            owner
            before
        }
    }
`;