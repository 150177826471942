import { gql } from 'apollo-boost';

export const GET_HOME_FOLDERS = gql`
    query($data: GetHomeFoldersInput) {
        getHomeFolders(data: $data) {
            folders {
                id
                title
                parent
                owner
                before
                deletedAt
            }
            cursor
        }
    }
`;