import React from 'react';
import { QUERY, MUTATION } from '../gql-operations';
import { useMutation } from '@apollo/react-hooks';
import useDeleteFoldersMutation from './useDeleteFoldersMutation';
import useNotificationSystem from '../components/Notifications/useNotificationSystem';
import { AuthContext } from '../util/context';
import customAnalytics from '../util/custom-analytics';

function useMoveFolderMutation() {
    const [moveFolder, { data, loading, error }] = useMutation(MUTATION.MOVE_FOLDER, {
        onCompleted: res => {
            // analytics below
        }
    });
    const { deleteChildFromTree } = useDeleteFoldersMutation();
    const { movedFolderNotification } = useNotificationSystem();

    const {
        state: { showDeletedMessages }
    } = React.useContext(AuthContext);

    const createRefetchQuery = (currentFolder, toFolder) => {
        let queries = [];
        if (currentFolder) {
            try {
                queries.push({
                    query: QUERY.GET_FOLDER_CONTENT,
                    variables: {
                        data: {
                            folderId: currentFolder.id,
                            isTrash: showDeletedMessages
                        }
                    }
                });
            } catch (error) {
                console.log('Current folder not yet rendered', error)
            }

        }
        if (toFolder) {
            try {
                queries.push({
                    query: QUERY.GET_FOLDER_CONTENT,
                    variables: {
                        data: {
                            folderId: toFolder.id,
                            isTrash: showDeletedMessages
                        }
                    }
                });
            } catch (error) {
                console.log('To Folder not yet rendered', error)
            }
        }
        return queries;
    };

    const runThis = async (currentFolder, toFolder) => {
        console.log('do you get them bot', currentFolder, toFolder);
        let toFolderSend = toFolder ? toFolder.id : null;

        let response = await moveFolder({
            variables: {
                data: {
                    folderToMove: currentFolder.id,
                    newParent: toFolderSend
                }
            },
            refetchQueries: await createRefetchQuery(currentFolder, toFolder)
            // update: async (store, {data: {moveFolder}}) => {
            //     // TODO this only works if they are on the first level
            //     // would not work in subfolders
            //     const data = store.readQuery({
            //         query: GET_HOME_FOLDERS_QUERY
            //     })

            //     const newFolders = [...data.getHomeFolders.folders];

            //     console.log('all folders', newFolders)

            //     let index = newFolders.findIndex(obj => obj.id === currentFolder.id);

            //     console.log('did you find the index', index);

            //     if (index > -1) {
            //         newFolders.splice(index, 1);
            //     }

            //     console.log('all folders new ones', newFolders)

            //     const newData = {
            //         getHomeFolders: {
            //             folders: [...newFolders],
            //             cursor: data.getHomeFolders.cursor,
            //             __typename: data.getHomeFolders.__typename
            //         }
            //     }

            //     store.writeQuery({
            //         query: GET_HOME_FOLDERS_QUERY,
            //         data: newData
            //     })
            // }
        });
        movedFolderNotification(toFolder ? toFolder : { id: 'root', title: 'Root' });

        // NOT USED, BETTER MANAGEMENT ON OTHER PLACES
        // customAnalytics.folder_edit({
        //     'action': "Move",
        //     'destination': toFolder ? "Subfolder" : 'Root'
        // })

        return response;
    };

    return [runThis, { data }];
}

export default useMoveFolderMutation;
