import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import customAnalytics from '../../../../util/custom-analytics';
import { useMediaQuery } from 'react-responsive';
import { PRIMARY_COLOR_6 } from '../../../../appearance/Colors';

const propTypes = {
    ...SectionTilesProps.types
};

const defaultProps = {
    ...SectionTilesProps.defaults
};

const Testimonial = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {
    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        // topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: 'What our lucky users say',
        paragraph: 'Here are some reactions of our happy customers.'
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });
    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        I like how Luckynote is practical at note taking unlike other note taking apps.
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                    {/* <span className="testimonial-item-name text-color-high">justingoldern21</span>
                  <span className="text-color-low"> / </span> */}
                                    <span className="" style={{ color: PRIMARY_COLOR_6 }}>
                                        Tina
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        This is awesome. Useful for people who WhatsApp themselves.
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                    {/* <span className="testimonial-item-name text-color-high">Royal_lobster</span>
                                    <span className="text-color-low"> / </span> */}
                                    <span className="testimonial-item-link">
                                        <a
                                            href="https://www.reddit.com/r/web_design/comments/qtqaab/ive_made_an_app_where_you_message_yourself_notes/hkphtwj/?context=3"
                                            target="_blank"
                                            onClick={() => {
                                                customAnalytics.testimonial_open();
                                                customAnalytics.landingpage_action({
                                                    "action": "Testimonial Open"
                                                })
                                            }}
                                        >
                                            Royal_lobster
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        Exceptionally good for keeping self notes with time stamps. Great flow and
                                        style, a must have app in your arsenal.
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                    <span className="testimonial-item-link">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.luckynote&reviewId=gp%3AAOqpTOFc7lonA3fgbPazLPPFqo5d9YSTOnm9PY4u76xPToXqCSK_YBbv_zVpwmfic_w7SUiLk9zOFciMIYHgKL0"
                                            target="_blank"
                                            onClick={() => {
                                                customAnalytics.testimonial_open();
                                                customAnalytics.landingpage_action({
                                                    "action": "Testimonial Open"
                                                })
                                            }}
                                        >
                                            John T. Lovelace
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        I like the app so far. It feels like a chat app and it's peaceful. More than ever I need a space to express myself.
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                    {/* <span className="testimonial-item-name text-color-high">justingoldern21</span>
                  <span className="text-color-low"> / </span> */}
                                    <span className="" style={{ color: PRIMARY_COLOR_6 }}>
                                        Amelie
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        I've been waiting for something like this for so long, so glad I came across
                                        this post.
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                    <span className="testimonial-item-link">
                                        <a
                                            href="https://www.reddit.com/r/web_design/comments/qtqaab/comment/hkmth82/?utm_source=share&utm_medium=web2x&context=3"
                                            target="_blank"
                                            onClick={() => {
                                                customAnalytics.testimonial_open();
                                                customAnalytics.landingpage_action({
                                                    "action": "Testimonial Open"
                                                })
                                            }}
                                        >
                                            monsieurtendhal
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div> */}

                        {!isMobile ?
                            (
                                <>
                                    <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                                        <div className="tiles-item-inner">
                                            <div className="testimonial-item-content">
                                                <p className="text-sm mb-0">
                                                    I saw this app on Reddit and honestly, my mind is blown away! I used to send
                                                    everything I needed to myself though telegram or whatsapp but this!!! Really
                                                    good, congratulations on such a good app. Love it
                                                </p>
                                            </div>
                                            <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                                {/* <span className="testimonial-item-name text-color-high">justingoldern21</span>
                  <span className="text-color-low"> / </span> */}
                                                <span className="testimonial-item-link">
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=com.luckynote&reviewId=gp%3AAOqpTOHnbYaV1sq7Kq0ag0LfJkkKYPP5PoByv8FX6XcQZT0QAgmqspqh8a96rjGin7LNLJ1TyHfk92zcaNSKs5M"
                                                        target="_blank"
                                                        onClick={() => {
                                                            customAnalytics.testimonial_open();
                                                            customAnalytics.landingpage_action({
                                                                "action": "Testimonial Open"
                                                            })
                                                        }}
                                                    >
                                                        Lisa S
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                                        <div className="tiles-item-inner">
                                            <div className="testimonial-item-content">
                                                <p className="text-sm mb-0">
                                                    I have been using messenger to message myself notes for years and been telling
                                                    myself to just use a todos app or Google docs or something. I'll finally say
                                                    screw it and try this, because it seems like and easy adaptation and looks like
                                                    a cool design. Thanks :)
                                                </p>
                                            </div>
                                            <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                                {/* <span className="testimonial-item-name text-color-high">justingoldern21</span>
                  <span className="text-color-low"> / </span> */}
                                                <span className="testimonial-item-link">
                                                    <a
                                                        href="https://www.reddit.com/r/web_design/comments/qtqaab/comment/hkmlv8t/?utm_source=share&utm_medium=web2x&context=3"
                                                        target="_blank"
                                                        onClick={() => {
                                                            customAnalytics.testimonial_open();
                                                            customAnalytics.landingpage_action({
                                                                "action": "Testimonial Open"
                                                            })
                                                        }}
                                                    >
                                                        justingoldern21
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}

                        {/* <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        Nice! Looks like a more organized version of Telegram's Saved Messages.
                                    </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-0 mb-0">
                                    <span className="testimonial-item-link">
                                        <a
                                            href="https://www.reddit.com/r/web_design/comments/qtqaab/comment/hkndt9x/?utm_source=share&utm_medium=web2x&context=3"
                                            target="_blank"
                                        >
                                            2legited2
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
