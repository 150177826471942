import { gql } from 'apollo-boost';

// input ForgotPasswordInput {
// 	email: String!
// }

export const FORGOT_PASSWORD_SEND_EMAIL = gql`
    mutation($data: ForgotPasswordInput!) {
        forgotPasswordMail(data: $data)
    }
`;
