import { gql } from 'apollo-boost';

// input DeleteAccountInput {
// 	email: String!
// }

export const DELETE_ACCOUNT = gql`
    mutation($data: DeleteAccountInput!) {
        deleteAccount(data: $data)
    }
`;
