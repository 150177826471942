import React from 'react';

import Modal from './Modal';

import { useMutation } from '@apollo/react-hooks';

import { MUTATION, QUERY } from '../../gql-operations';

import styled from 'styled-components';

function DeleteUserModal({ isOpen, closeModal, user }) {
    const [deleteUserAdmin, { loading }] = useMutation(MUTATION.DELETE_USER_ADMIN, {
        onCompleted: res => {
            console.log('completed', res)
            responseToDelete(res);
        },
        onError: res => {
            console.log('error', res)
        }
    })

    const responseToDelete = (regResponse) => {
        if (regResponse) {
            switch (regResponse.deleteUserAdmin.__typename) {
                case 'User':
                    alert("Successfully deleted")
                case 'DbStartTransactionError':
                    return <ErrorText>{regResponse.deleteUserAdmin.message}</ErrorText>;
                case 'InternalServerError':
                    return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
                default:
                    return <ErrorText>Unknown error</ErrorText>;
            }
        }
    }


    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    const deleteAccountMutation = () => {
        deleteUserAdmin({
            variables: {
                data: {
                    userId: user.id,
                    email: user.email
                }
            },
            update: (store, { data }) => {
                console.log('store for users', store);
                console.log('func for users', data);
                try {
                    const dataUsers = store.readQuery({
                        query: QUERY.GET_ALL_USERS,
                        variables: {
                            data: {}
                        }
                    });

                    const newUsers = [...dataUsers.getAllUsers.users];

                    let index = newUsers.indexOf(user);

                    if (index > -1) {
                        newUsers.splice(index, 1);
                    }

                    const newData = {
                        getAllUsers: {
                            users: [...newUsers],
                            cursor: dataUsers.getAllUsers.cursor,
                            __typename: dataUsers.getAllUsers.__typename
                        }
                    };

                    store.writeQuery({
                        query: QUERY.GET_ALL_USERS,
                        variables: {
                            data: {}
                        },
                        data: newData
                    });

                    console.log('all user from store', dataUsers);

                } catch (error) {

                }
            }
        });
        closeModal();
        // console.log('he deleted the account', response);
    };

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={deleteUserModalStyle}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1, fontSize: 18 }}>{user.fullName} will be permanently deleted.</div>
                <p>You won't be able to undo this action</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button onClick={closeModal}>close</button>
                    <button onClick={deleteAccountMutation}>Delete forever</button>
                </div>
            </div>
        </Modal>
    );
}

const deleteUserModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 0,
        maxWidth: 640
    }
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;


export default DeleteUserModal;
