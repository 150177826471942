import { gql } from 'apollo-boost';

export const ME = gql`
    query {
        me {
            id
            email
            fullName
            isVerified
            role
            planType
            quota {
                maxMessages
                totalMessages
                maxStorage
                totalStorage
                maxFileSize
            }
            profilePictureUrl
            homeFolder
            backgroundImageUrl
            createdAt
            deletedAt
            referralId
            referralCount
            referralCountMax
            emergencyBuffer
            stripeCustomerId
        }
    }
`;
