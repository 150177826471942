import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import environment from '../../env-config';
import { withRouter } from 'react-router-dom';

import SettingsModal from '../Modals/SettingsModal';

import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';

import { QUERY } from '../../gql-operations';

import { IoIosArrowDown } from 'react-icons/io';

import { WHITE, DETAIL_TEXT, PALE_WHITE_4 } from '../../appearance/Colors';

import { Image, Transformation } from 'cloudinary-react';

import useRedirectErrors from '../../errors/useRedirectErrors';
import { AuthContext } from '../../util/context';
import { setupInitialTree } from '../../util/serializeTreeArray';
import useCheckBrowser from '../../util/useCheckBrowser';
import customAnalytics from '../../util/custom-analytics';

function ProfileDropdownMenu({ history }) {
    const dropdownMenu = useRef(null);
    const client = useApolloClient();
    const { passingErrors } = useRedirectErrors();
    // const currentUser = JSON.parse(localStorage.getItem('userData'));
    const { Browser } = useCheckBrowser();

    const [showDropMenu, setShowDropMenu] = useState(false);
    const [showUpdateFolderModal, setShowUpdateFolderModal] = useState(false);

    const {
        context: { setShowBackgroundSelect, setNewTree, setShowPaperclip }
    } = React.useContext(AuthContext);

    const { loading, error, data } = useQuery(QUERY.ME, {
        onError: res => {
            passingErrors(res);
        }
    });

    const [getHomeFolders, { data: folders, loading: foldersLoading, error: foldersError, subscribeToMore }] =
        useLazyQuery(QUERY.GET_HOME_FOLDERS, {
            onError: res => {
                passingErrors(res);
            },
            onCompleted: res => {
                console.log('i set you up only once', res);

                let createdTree = setupInitialTree(res.getHomeFolders.folders);
                setNewTree(createdTree);
            },
            fetchPolicy: 'network-only'
        });

    const showMenu = event => {
        event.preventDefault();
        if (!showDropMenu) {
            document.addEventListener('click', closeMenu);
        } else {
            document.removeEventListener('click', closeMenu);
        }
        setShowDropMenu(!showDropMenu);
    };

    useEffect(() => {
        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, []);

    const closeMenu = event => {
        // use contains only if you want not to close when clicking on an option
        // if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
        setShowDropMenu(false);
        document.removeEventListener('click', closeMenu);
        // }
    };

    const signOut = async () => {
        localStorage.clear();
        // await localStorage.removeItem('token');
        // await localStorage.removeItem('refreshToken');
        // await localStorage.removeItem('userData');
        client.resetStore();
        history.push('/login');
        customAnalytics.sign_out();
    };

    if (loading) return null;

    return (
        <React.Fragment>
            <div>
                <DropDownElement onClick={showMenu}>
                    {data && data.me.profilePictureUrl ? (
                        <Image
                            cloudName={environment.REACT_APP_CLOUD_NAME}
                            publicId={data.me.profilePictureUrl}
                            width={24}
                            height={24}
                            alt="profile"
                            crop={'scale'}
                            style={{
                                borderRadius: 50
                                // border: BORDER,
                                // borderStyle: 'solid',
                                // borderColor: 'rgba(255,255,255,.6)'
                            }}
                        >
                            <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                        </Image>
                    ) : (
                        <div
                            style={{
                                height: 24,
                                width: 24,
                                borderRadius: 50,
                                border: `1px solid ${DETAIL_TEXT}`,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <span style={{ textTransform: 'uppercase', color: WHITE }}>
                                {data && data.me.fullName.slice(0, 1)}
                            </span>
                        </div>
                    )}

                    <div style={{ marginLeft: 8 }}>
                        {data && data.me.fullName}
                        {/* <div style={{ fontSize: 14, marginTop: 4, color: 'rgba(255,255,255,.6)' }}>
                            Settings
                        </div> */}
                    </div>
                    <div style={{ marginLeft: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IoIosArrowDown size={12} color={'rgba(255,255,255,.6)'}></IoIosArrowDown>
                    </div>
                </DropDownElement>
                {showDropMenu ? (
                    <DropDownMenu ref={dropdownMenu}>
                        <div role="menu" style={{ width: 200 }}>
                            <div role="content">
                                <ul>
                                    <li>
                                        <Element
                                            onClick={() => {
                                                setShowUpdateFolderModal(true);
                                                // should close the picker here
                                            }}
                                        >
                                            Settings & Account
                                        </Element>
                                    </li>
                                    <li>
                                        <Element onClick={() => setShowBackgroundSelect(true)}>
                                            Change background
                                        </Element>
                                    </li>
                                    <li>
                                        <Element
                                            onClick={() => {
                                                if (Browser === 'Firefox') {
                                                    window.open(
                                                        'https://addons.mozilla.org/en-US/firefox/addon/luckynote/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search',
                                                        '_blank'
                                                    );
                                                } else {
                                                    window.open(
                                                        'https://chrome.google.com/webstore/detail/luckynote/dgnamkhpffokcdhjnlebokmcgkkecncl',
                                                        '_blank'
                                                    );
                                                }
                                            }}
                                        >
                                            Get web extension
                                        </Element>
                                    </li>
                                    <li>
                                        <Element onClick={() => setShowPaperclip(true)}>Help & Feedback</Element>
                                    </li>
                                    <SeparatorLI />
                                    <li>
                                        <InfoSection>
                                            {/* <div>{data.me.email}</div> */}
                                            <div style={{ fontSize: 12 }}>
                                                Can't find a folder?
                                                <span
                                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        console.log('did you do it');
                                                        getHomeFolders();
                                                        console.log('did do it');
                                                    }}
                                                >
                                                    {' '}
                                                    Reload
                                                </span>
                                            </div>
                                        </InfoSection>
                                    </li>
                                    <SeparatorLI />
                                    <li>
                                        <Element onClick={signOut}>Sign Out</Element>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </DropDownMenu>
                ) : null}
            </div>
            <SettingsModal
                // user={data}
                isOpen={showUpdateFolderModal}
                closeModal={() => {
                    setShowUpdateFolderModal(false);
                }}
            ></SettingsModal>
        </React.Fragment>
    );
}

const DropDownElement = styled.div`
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 4px 10px;
    flex-direction: row;
    margin-top: 8px;
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
`;

const DropDownMenu = styled.div`
    position: absolute;
    margin-top: 6px;
    margin-left: 4px;
    background-color: white;
    min-height: 15px;
    min-width: 200px;
    max-width: 304px;
    z-index: 1500;
    color: #262626;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
`;

const SeparatorLI = styled.li`
    padding-bottom: 1px;
    margin-bottom: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const Element = styled.div`
    padding: 10px 12px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const InfoSection = styled.div`
    color: rgba(0, 0, 0, 0.4);
    padding: 10px 12px;
    user-select: none;
`;

export default withRouter(ProfileDropdownMenu);
