import useCheckBrowser from '../util/useCheckBrowser';
import SAVED_TYPE from '../util/saved-from-enum';

function useAddMetadata() {
    const { Browser, BrowserVersion, Platform, PlatformVersion, PLATFORMS } = useCheckBrowser();

   const savedFromMeta = () => {
        const metadata = {
                savedFrom: {
                    type: SAVED_TYPE.WEB,
                    device: Platform,
                    // deviceVersion: PlatformVersion.toString(),
                    browser: Browser,
                    browserVersion: BrowserVersion.toString()
            }
        }
        return metadata;
   }

   const updatedFromMeta = () => {
        const metadata = {
                updatedFrom: {
                    type: SAVED_TYPE.WEB,
                    device: Platform,
                    // deviceVersion: PlatformVersion.toString(),
                    browser: Browser,
                    browserVersion: BrowserVersion.toString()
            }
        }
        return metadata;
   }



    return { savedFromMeta, updatedFromMeta };
}

export default useAddMetadata;
