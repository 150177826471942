import React from 'react';
import { AuthContext } from '../../util/context';
import { RED, WHITE } from '../../appearance/Colors';
import ActionTagButton from '../Action/ActionTagButton';

function HideDeletedMessagesComponent() {
    const {
        state: { selectedFolder, showDeletedMessages },
        context: { setSelectedMessage, setShowDeletedMessages }
    } = React.useContext(AuthContext);

    if (showDeletedMessages && selectedFolder && !selectedFolder.deletedAt) {
        return (
            <div
                style={{
                    backgroundColor: RED,
                    alignItems: 'center',
                    padding: 10,
                    flexDirection: 'row',
                    display: 'flex'
                }}
            >
                <div style={{ color: WHITE, fontWeight: '600', flex: 1 }}>Showing trash messages</div>

                <ActionTagButton
                    label="Hide"
                    onClick={() => {
                        setShowDeletedMessages(false);
                    }}
                ></ActionTagButton>
            </div>
        );
    } else {
        return null;
    }
}

export default HideDeletedMessagesComponent;
