import { gql } from 'apollo-boost';

// input ResetPasswordManualInput {
// 	newPassword: String!
// }

export const RESET_PASSWORD_MANUAL = gql`
    mutation($data: ResetPasswordManualInput!) {
        resetPasswordManual(data: $data)
    }
`;
