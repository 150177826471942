import React, { useEffect, useRef } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import classNames from 'classnames';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';
import { useLocation, Switch } from 'react-router-dom';
import styled from 'styled-components';
// import ScrollReveal from '../utils/ScrollReveal';

import '../assets/scss/style.scss';
import Pricing from '../components/sections/Pricing';
import { Helmet } from 'react-helmet';

const Privacy = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        // const page = location.pathname;
        // document.body.classList.add('is-loaded');
        // childRef.current.init();
        // trackPage(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
        title: 'Privacy policy'
        // paragraph: "Modularity and functionality. That's what we love the most."
    };

    return (
        <CustomStyle>
            <Helmet>
                <title>Privacy</title>
                <description>
                    Your privacy is important to us, so is being transparent about how we collect and use your
                    information and data.
                </description>
            </Helmet>
            <Header navPosition="right" className="reveal-from-bottom" />
            <main className="site-content">
                <section {...props} className={outerClasses}>
                    <div className="container-sm">
                        <div className={innerClasses} style={{ textAlign: 'left' }}>
                            <SectionHeader data={sectionHeader} className="center-content" />
                            {/* <p className="m-0 text-sm mb-16"><em>Last Updated: November 21, 2021</em></p> */}
                            <p className="m-0 text-sm">
                                Your privacy is important to us, and we are committed to being transparent about how we collect, use, and protect your information. This policy is intended to help you understand:
                            </p>
                            <ul className="mt-12" style={{ listStyle: 'initial', paddingLeft: 40 }}>
                                <li>
                                    <p className="m-0 text-sm">What information we collect from you</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">What we do with your information</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">How long we keep information</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">Our payment services</p>
                                </li>
                            </ul>

                            <h4 className="mt-32 mb-8">Information Gathering and Usage</h4>
                            <p className="m-0 text-sm">
                                When you create a Luckynote account to sync your notes across devices, we ask for basic account information like an email address and password. Your notes are stored on a central server, allowing them to be synced on your other devices. Subscribing to Luckynote Pro requires billing information, but we only use your information internally and will not share it with others.
                                {/* Luckynote collects aggregated, anynomous
                                user data regarding app usage. The user data we collect is used to enhance the quality
                                of our service and product. Luckynote also automatically collects crash log so that we
                                can improve the product reliability. The information in the crash log may include your
                                device information, stack trace, your IP address and other data that can be helpful to
                            debug the issue. */}
                            </p>
                            <h4 className="mt-32 mb-8">Types of Data Collected</h4>
                            <h6 className="m-0 mb-16">Personal Data</h6>
                            <p className="m-0 text-sm mb-16">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                            <ul className="mt-12 mb-16" style={{ listStyle: 'initial', paddingLeft: 40 }}>
                                <li>
                                    <p className="m-0 text-sm">Email address</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">Cookies and Usage Data</p>
                                </li>

                            </ul>
                            <p className="m-0 text-sm mb-16">We may use your Personal Data to contact you through newsletters, marketing materials, or promotional materials, as well as other communication that may interest you. Opting out of these communications can be done by following the unsubscribe link or instructions provided in any sent email, or by contacting us.</p>
                            <h6 className="m-0 mb-16">Usage Data</h6>
                            <p className="m-0 text-sm mb-16">We may collect Usage Data, including information related to how our Service is accessed and used. This data can include your computer's Internet Protocol address (IP address), browser type and version, visited pages of our Service, date and time of your visit, time spent on these pages, unique device identifiers, and diagnostic data.</p>
                            <h6 className="m-0 mb-16">Tracking & Cookies Data</h6>
                            <p className="m-0 text-sm mb-16">We use cookies and similar tracking technologies to monitor our Service's activity and collect certain information. These cookies are small data files that may include an anonymous, unique identifier. Sent to your browser from our website and stored on your device, beacons, tags, and scripts are also used, among other tracking technologies, to gather and track information and to improve or analyze our Service.</p>
                            <p className="m-0 text-sm mb-16">Rejecting all cookies from your browser or adjusting it to send notifications when a cookie is sent are both options. However, refusing cookies may make some of our Service's portions unusable.</p>
                            <p className="m-0 text-sm mb-16">Examples of Cookies we use:</p>
                            <ul className="mt-12 mb-16" style={{ listStyle: 'initial', paddingLeft: 40 }}>
                                <li>
                                    <p className="m-0 text-sm">Session Cookies: These are required for our Service to operate.</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">Preference Cookies: These store your preferences and settings.</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">Security Cookies: These are used for security purposes.</p>
                                </li>

                            </ul>


                            <h4 className="mt-32 mb-8">Your Information is Protected</h4>
                            <p className="m-0 text-sm">
                                We are fully committed to protecting your privacy. We use Amazon Web Services (AWS) and Cloudinary servers located in the EU to store data. Both services are certified under significant privacy and security standards, such as the EU General Data Protection Regulation (GDPR). AWS and Cloudinary encrypt your data in transit using HTTPS and encrypt your data at rest. Learn more at {' '}
                                <a href="https://aws.amazon.com/privacy/" style={{ color: '#2b88d9' }}>
                                    https://aws.amazon.com/privacy/
                                </a>{' '}
                                and{' '}
                                <a href="https://cloudinary.com/privacy" style={{ color: '#2b88d9' }}>
                                    https://cloudinary.com/privacy.
                                </a>
                            </p>
                            <h4 className="mt-32 mb-8">How Long We Keep Information</h4>
                            <p className="m-0 text-sm">
                                Your information remains with Luckynote while you use our service. To delete content, move your notes to the trash, then empty it. This will eliminate the data on the server and any devices you've logged into. To delete your account, email support@luckynote.io so we can remove your account data. Please note this process is irreversible.
                            </p>
                            <h4 className="mt-32 mb-8">Service Providers</h4>
                            <p className="m-0 text-sm">
                                Third-party companies and individuals may be employed to facilitate our Service, provide the Service on our behalf, perform Service-related tasks, or assist in analyzing our Service's use. These third parties can access your Personal Data to perform these tasks on our behalf but are not allowed to disclose or use it for any other purpose.
                            </p>
                            <h4 className="mt-32 mb-8">Analytics</h4>
                            <p className="m-0 text-sm mb-16">Third-party Service Providers are sometimes used to monitor and analyze our Service's use.</p>

                            <ul className="mt-12" style={{ listStyle: 'initial', paddingLeft: 40 }}>
                                <li>
                                    <p className="m-0 text-sm">Google Analytics</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">Google Analytics, a web analytics service by Google, tracks and reports website traffic. Google may use the collected data to contextualize and personalize their advertising network's ads. The Google Analytics opt-out browser add-on allows you to prevent Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing visit activity information with Google Analytics. More information about Google's privacy practices can be found at <a href="https://policies.google.com/privacy" style={{ color: '#2b88d9' }}>
                                        https://policies.google.com/privacy
                                    </a></p>
                                </li>
                            </ul>

                            <h4 className="mt-32 mb-8">Payments</h4>
                            <p className="m-0 text-sm mb-16">We may offer paid products and/or services within our Service. In these cases, third-party services handle payment processing (e.g., payment processors).</p>
                            <p className="m-0 text-sm mb-16">We don't store or collect your payment card details; this information is provided directly to third-party payment processors, whose use of your personal information is governed by their Privacy Policy. These processors adhere to PCI-DSS standards managed by the PCI Security Standards Council, a collaboration between brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements ensure the secure handling of payment information.</p>
                            <p className="m-0 text-sm mb-16">Our payment processor:</p>
                            <ul className="mt-12 mb-16" style={{ listStyle: 'initial', paddingLeft: 40 }}>
                                <li>
                                    <p className="m-0 text-sm">Stripe</p>
                                </li>
                                <li>
                                    <p className="m-0 text-sm">Their Privacy Policy can be viewed at <a href='https://stripe.com/us/privacy' style={{ color: '#2b88d9' }}>https://stripe.com/us/privacy</a></p>
                                </li>
                            </ul>



                            <h4 className="mt-32 mb-8">Changes To This Privacy Policy</h4>
                            <p className="m-0 text-sm mb-16">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                            <p className="m-0 text-sm mb-16">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>



                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </CustomStyle>
    );
};

const CustomStyle = styled.div`
    body {
        font-size: 1rem;
    }
    // color: #9ca9b3;
    a {
        // font-weight: 400 !important;
        // text-decoration: none;
        //     &:not(.button) {
        //         color: #9ca9b3;
        //     }
        //     &.button-link {
        //         color: #9ca9b3;
        //     }

        //     &.func-link {
        //         color: #9ca9b3;
        //     }
        //     &:hover {
        //         color: #eceded;
        //     }
    }
    // h2,
    // .h2 {
    //     font-size: 44px !important;
    //     line-height: 54px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // h3,
    // .h3 {
    //     font-weight: 700 !important;
    //     font-size: 32px !important;
    //     line-height: 42px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // p {
    //     font-size: 1rem;
    //     line-height: 30px;
    // }
`;

export default Privacy;
