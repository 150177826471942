import { gql } from 'apollo-boost';

// input GetTrashMessagesInFolderInput {
//     folder: ID!
//     cursor: String
// }
export const GET_TRASH_MESSAGES_IN_FOLDER = gql`
    query($data: GetTrashMessagesInFolderInput!) {
        getTrashMessagesInFolder(data: $data) {
            messages {
                id
                title
                body
                createdAt
                updatedAt
                deletedAt
                image {
                    url
                    name
                    displayName
                    type
                    format
                    size
                    width
                    aspect_ratio
                    public_id
                }
                file {
                    url
                    name
                    displayName
                    type
                    format
                    size
                }
                isCheckbox
                isChecked
                isStarred
                folder {
                    id
                    title
                    owner
                    parent
                    deletedAt
                }
                urlMeta {
                    author
                    date
                    description
                    image
                    logo
                    publisher
                    title
                    url
                    public_id
                    width
                    format
                    aspect_ratio
                    size
                }
            }
            cursor
        }
    }
`;
