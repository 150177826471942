export default function domain_from_url(url) {
    var result
    var match
    if (url) {
        if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
            result = match[1]
            if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
                result = match[1]
            }
        }
    }
    return result
}

// console.log(domain_from_url("www.google.com"))
// console.log(domain_from_url("yahoo.com/something"))
// console.log(domain_from_url("freds.meatmarket.co.uk?someparameter"))
// console.log(domain_from_url("josh.meatmarket.co.uk/asldf/asdf"))

// google.com
// yahoo.com
// meatmarket.co.uk
// meatmarket.co.uk