import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const propTypes = {
    ...SectionProps.types
};

const defaultProps = {
    ...SectionProps.defaults
};

const Hero = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const [videoModalActive, setVideomodalactive] = useState(false);

    const openModal = e => {
        e.preventDefault();
        setVideomodalactive(true);
    };

    const closeModal = e => {
        e.preventDefault();
        setVideomodalactive(false);
    };

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <section {...props} className={outerClasses}>
            <div className="container-sm" style={{ maxWidth: '100%' }}>
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            Message Yourself
                            <br />
                            Everything
                            {/* <span className="text-color-primary">startups</span> */}
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                                Send Notes, Bookmarks, Files and Tasks to yourself with Luckynote.
                            </p>
                            <div className="reveal-from-bottom" data-reveal-delay="600">
                                <ButtonGroup>
                                    {/* <Button tag="a" color="primary" wideMobile href="register">
                                        Get started
                                    </Button> */}

                                    <Link
                                        to={isMobile ? 'mobile' : 'register'}
                                        className="button button-primary button-wide-mobile button-md"
                                    >
                                        {isMobile ? "Download" : "Get Started"}
                                    </Link>

                                    {/* <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button> */}
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div
                        className="hero-figure reveal-from-bottom illustration-element-01"
                        data-reveal-value="20px"
                        data-reveal-delay="800"
                        style={{ marginLeft: isMobile ? -20 : -24, marginRight: isMobile ? -20 : -24, marginTop: 0 }}
                    >
                        {/* Autoplaying video */}
                        {/* <video
                            playsInline
                            loop
                            autoPlay
                            muted
                            style={{ width: '100%', height: 540, display: 'block', margin: 0 }}
                        >
                            <source
                                src="https://player.vimeo.com/external/476859761.hd.mp4?s=ed59dcdf64b9587f07f922dd1e26c47e4839d036&amp;profile_id=169"
                                type="video/mp4"
                            />
                        </video> */}
                        <a
                            data-video="https://player.vimeo.com/video/174002812"
                            // href="#0"
                            aria-controls="video-modal"
                        // onClick={openModal}
                        >
                            {isMobile ? (
                                <Image
                                    // className="has-shadow"
                                    src={require('./../../assets/images/hero-image-mobile.webp')}
                                    alt="Hero"
                                // width={'100%'}
                                // height={504}
                                />
                            ) : (
                                <Image
                                    // className="has-shadow"
                                    src={require('./../../assets/images/hero-image.webp')}
                                    alt="Hero"
                                // width={'100%'}
                                // height={504}
                                />
                            )}
                        </a>
                    </div>
                    {/* <Modal
                        id="video-modal"
                        show={videoModalActive}
                        handleClose={closeModal}
                        video="https://player.vimeo.com/video/174002812"
                        videoTag="iframe"
                    /> */}
                </div>
            </div>
        </section>
    );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
